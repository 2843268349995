import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

constructor(private injector: Injector) { }
  public handleError(error: Error) {
    const router = this.injector.get(Router);
    // const authService = this.injector.get(AuthService);
    let errStatus = '';
    if (error instanceof HttpErrorResponse) {
      if (error.status === 401 ) {
        // authService.login();
        return;
      }
      errStatus = error.status.toString();
      // Backend returns unsuccessful response codes such as 404, 500 etc.
      console.error('Backend returned status code: ', error.status);
      console.error('Response body:', error.message);
    } else {
      // A client-side or network error occurred.
      errStatus = error.name;
      console.error('Uncaught Exception in Angular. name = [' + error.name + '] message = [' + error.message, error);
    }

    // NOTE:  Comment out this line to stop error redirects
    // window.location.href = '/error?error=' + errStatus;
  }
}
