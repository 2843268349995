import { VehicleService } from "./../../services/vehicle.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { ParkingSpaceInfo } from "src/app/models/parking-space-info";
import { TenantInfo } from "src/app/models/tenant-info";
import { ParkingSpaceService } from "src/app/services/parking-space.service";
import { SecurityService } from "src/app/services/security.service";
import { TenantService } from "src/app/services/tenant.service";
import * as _moment from "moment";
import { VehicleInfo } from "src/app/models/vehicle-info";
import { TenantAddendum } from "src/app/models/tenant-addendum";
import { File } from "src/app/models/file";
import {
  faWrench,
  faPlusSquare,
  faMinusSquare,
  faHome,
  faParking,
} from "@fortawesome/free-solid-svg-icons";
import { AddendumConfirmed } from "src/app/models/addendum-confirmed";
import { AddendumEligible } from "src/app/models/addendum-eligible";
import { LeaseService } from "src/app/services/lease.service";
const moment = _moment;

@Component({
  selector: "app-tenant-section",
  templateUrl: "./tenant-section.component.html",
  styleUrls: ["./tenant-section.component.scss"],
})
export class TenantSectionComponent implements OnInit {
  showTenant: boolean;
  showExt: boolean;
  showParkingAdd: boolean;
  showParkingTerm: boolean;
  showParkingTermLink: boolean;
  showSvcRequest: boolean;
  showTypes = false;
  tenant: TenantInfo;
  memberId: number;
  signedIn$: Observable<boolean>;
  availableParking: ParkingSpaceInfo;
  eligible: AddendumEligible;
  vehicle: VehicleInfo;
  faWrench = faWrench;
  faPlusSquare = faPlusSquare;
  faMinusSquare = faMinusSquare;
  faHome = faHome;
  faParking = faParking;
  tenantAddendum: TenantAddendum;
  tenantSig: File;
  constructor(
    private tenantService: TenantService,
    private securityService: SecurityService,
    private router: Router,
    private parkingService: ParkingSpaceService,
    private leaseService: LeaseService
  ) {}

  ngOnInit() {
    this.checkTenantSignedIn();
  }

  checkTenantSignedIn() {
    this.signedIn$ = this.securityService.isSignedIn();
    this.signedIn$.subscribe((result) => {
      if (result) {
        this.memberId = this.securityService.getMemberId();
        this.getTenant();
      } else {
        this.router.navigate(["/login"]);
      }
    });
  }

  getTenant() {
    if (!this.memberId) {
      return;
    }
    this.tenantService.tenantGetByMember(this.memberId).subscribe((data) => {
      this.tenant = Object.assign({}, data);
      this.showTenant = true;
      this.checkExtEligiblity();
    });
  }

  checkExtEligiblity() {
    if (!this.tenant) {
      return;
    }

    this.tenantService.tenantExtEligible(this.tenant.id).subscribe((res) => {
      this.eligible = Object.assign(res);
      this.vehicle =
        this.tenant.vehicles && this.tenant.vehicles.length > 0
          ? this.tenant.vehicles[0]
          : null;
      const existingParkingAddendum =
        this.tenant.leaseInfo.addendums &&
        this.tenant.leaseInfo.addendums.length > 0
          ? this.tenant.leaseInfo.addendums.find(
              (a) => a.type === "Parking-Add" && a.statusId == 4
            )
          : null;
      let soonToExpire =
        existingParkingAddendum &&
        moment(existingParkingAddendum.endDate).diff(moment()) + 1 <= 90
          ? true
          : false;
      if (this.eligible.parkingEligible && !soonToExpire) {
        this.availableParking =
          this.vehicle && this.vehicle.parkingSpace
            ? this.vehicle.parkingSpace
            : null;
        if (!this.availableParking) {
          this.getAvailableParking();
        }
      }
      this.showParkingTermLink = this.vehicle ? true : false;
      if (this.vehicle) {
        const termAddemdum =
          this.tenant.leaseInfo.addendums &&
          this.tenant.leaseInfo.addendums.length > 0
            ? this.tenant.leaseInfo.addendums.find(
                (a) =>
                  a.type === "Parking-Term" &&
                  a.vehicleId === this.vehicle.id &&
                  (a.statusId == 3 || a.statusId === 4)
              )
            : null;
        this.showParkingTermLink = termAddemdum ? false : true;
      }
      if (this.eligible) {
        this.leaseService
          .tenantSignatureGet(this.tenant.id)
          .subscribe((res) => {
            if (res) {
              this.tenantSig = Object.assign(res);
            }
          });
      }
    });
  }

  getAvailableParking() {
    this.parkingService.parkingSpaceList().subscribe((data) => {
      if (data) {
        this.availableParking = data.find((p) => p.isAvailable);
        if (this.availableParking) {
          this.eligible.parkingEligible = true;
        } else {
          this.eligible.parkingEligible = false;
        }
      }
    });
  }

  addendumRequestClick(type: string) {
    if (!this.tenant) {
      return;
    }
    this.tenantAddendum = {
      eligible: this.eligible,
      tenant: this.tenant,
      type: type,
      availableParkingSpace: this.availableParking,
      tenantSig: this.tenantSig,
    };
    //this.tenantService.addendumReqClicked(add);
    //this.router.navigate(["addendum/add"]);
  }

  typeClick(name: string) {
    if (name === "Tenant") {
      this.showTenant = true;
      this.showExt = false;
      this.showParkingAdd = false;
      this.showParkingTerm = false;
      this.showSvcRequest = false;
    } else if (name === "Lease-Ext") {
      this.showTenant = false;
      this.addendumRequestClick("Lease-Ext");
      this.showExt = true;
      this.showParkingAdd = false;
      this.showParkingTerm = false;
      this.showSvcRequest = false;
    } else if (name === "Parking-Add") {
      this.addendumRequestClick("Parking-Add");
      this.showTenant = false;
      this.showExt = false;
      this.showParkingAdd = true;
      this.showParkingTerm = false;
      this.showSvcRequest = false;
    } else if (name === "Parking-Term") {
      this.addendumRequestClick("Parking-Term");
      this.showTenant = false;
      this.showExt = false;
      this.showParkingAdd = false;
      this.showParkingTerm = true;
      this.showSvcRequest = false;
    } else if (name === "Svc-Request") {
      this.showTenant = false;
      this.showExt = false;
      this.showParkingAdd = false;
      this.showParkingTerm = false;
      this.showSvcRequest = true;
    }
  }

  rentCollected(hasCollected: boolean) {
    if (hasCollected) {
      this.checkExtEligiblity();
    }
  }
  addendumNotification(addendumAdded: AddendumConfirmed) {
    if (addendumAdded && addendumAdded.addendumId > 0) {
      this.tenant.leaseInfo = Object.assign(addendumAdded.leaseInfo);
      if (this.tenantAddendum.type === "Lease-Ext") {
        this.eligible.extEligible = false;
        this.showExt = false;
      } else if (this.tenantAddendum.type === "Parking-Add") {
        this.eligible.parkingEligible = false;
        this.showParkingAdd = false;
      } else if (this.tenantAddendum.type === "Parking-Term") {
        this.checkExtEligiblity();
        this.showParkingTermLink = false;
        this.showParkingTerm = false;
      }
      this.showTenant = true;
    }
  }

  svcRequestSent(requestSent: boolean) {
    if (requestSent) {
      this.showSvcRequest = false;
      this.showTenant = true;
    }
  }
}
