import { LoadService } from './load.service';
import { RegRequest } from './../models/school/reg-request';
import { RegModel } from './../models/school/reg-model';
import { RegInfo } from './../models/school/reg-info';
import { SelectListItem } from 'src/app/models/select-list-item';
import { map,share,concatMap, scan, switchMap, withLatestFrom, startWith, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, combineLatest, merge, BehaviorSubject } from 'rxjs';
import { GenericDataService } from './generic-data.service';
import { SecurityService } from './security.service';
import * as _moment from "moment";
import { Action } from '../models/stream-action';
import { ConfirmationService } from './confirmation.service';
import { SemesterDates } from '../models/school/semester-dates';
import { HelperService } from './helper.service';


const moment = _moment;

@Injectable({
  providedIn: 'root'
})
export class IebRegisterService {
  regSource$ = this.getLatest(this.securityService.getMemberId()).pipe(share());
  formSource$ = this.getForm(this.securityService.getMemberId());


  private newRegAddSubject = new Subject<Action<RegRequest>>();
/** The exposed parent action observable merged with the CRUD stream */
  newRegAdd$ = this.newRegAddSubject.asObservable();
  private kidsCountUpdatedBS = new BehaviorSubject<boolean>(false);
  kidsCountUpdated$ = this.kidsCountUpdatedBS.asObservable();

  regFormLatestSource$ =  this.kidsCountUpdated$.pipe(
    startWith({}),
    mergeMap(() =>  {
      return this.getForm(this.securityService.getMemberId()).pipe(share());
    })
  );

  regFormSource$ = combineLatest([this.kidsCountUpdated$, this.formSource$, this.regFormLatestSource$]).pipe(
    map(([kidsUpdated, reg, latestReg]) =>{
      return kidsUpdated ? latestReg: reg;
    })
  );


  regModel$ = merge(this.regSource$, this.newRegAdd$.pipe(
    concatMap((request) => this.regAction(request)))).pipe(
      scan((acc: RegInfo, value: Action<RegInfo>) => {
        if(value.item && value.action === 'add') {
          this.confirmService.confirmationToggle( {message: 'School registration is saved', toggle: true, type : 'success'});
        }
        this.loadService.loadToggle(false);
        return value && value.item && value.item.id ? value.item : acc;
      })
  );
  semesterInfo$ = this.getSemesterDates();

  // This is used to add new reg by admins
  private  regBS = new BehaviorSubject<number|null>(null);
  regAdminSource$ = this.regBS.pipe(
  switchMap((memberId) => {
    if(!memberId) {
      return of (null as RegInfo);
    }
    return this.getLatest(memberId);
  }));

  private  regFormBS = new BehaviorSubject<number|null>(null);
  regFormAdminSource$ = this.regFormBS.pipe(
  switchMap((memberId) => {
    if(!memberId) {
      return of (null as RegModel);
    }
    return this.getForm(memberId);
  }));




  regAdminModel$ = merge(this.regAdminSource$, this.newRegAdd$.pipe(
    concatMap((request) => this.regAction(request)))).pipe(
      scan((acc: RegInfo, value: Action<RegInfo>) => {
        this.loadService.loadToggle(false);
        if(value.item && value.action === 'add') {
          this.confirmService.confirmationToggle( {message: 'School registration is saved', toggle: true, type : 'success'});
          return value.item;
        }
        return !value ? acc : value ;

      })
  );


constructor(private genericDataService: GenericDataService,
  private securityService: SecurityService,
  private confirmService: ConfirmationService,
  private loadService : LoadService,
  private helperService: HelperService
  ) { }


  getLatest(memberId: number): Observable<RegInfo> {
    if(!memberId) {
      return of( {} as RegInfo);
    }
    return this.genericDataService.readWithEndpoint<RegInfo>('SchoolRegistration/Member', memberId);
  }

  getForm(memberId: number): Observable<RegModel> {
    if(!memberId) {
      return of( {} as RegModel);
    }
    return this.genericDataService.readWithEndpoint<RegModel>('SchoolRegistration/Form', memberId).pipe(
      map((model) => this.mapSchoolFeeList(model)));
  }

  regAction(regAction: Action<RegRequest>) : Observable<Action<RegInfo>> {
    switch(regAction.action){
      case 'add':
        this.genericDataService.endPoint = "SchoolRegistration/New";
          return this.genericDataService.post<RegInfo>(regAction.item).pipe(
          map((reg: any) => (
            { item: reg, action: 'add' } as Action<RegInfo>
            ))
        );
      default:
        return of({} as Action<RegInfo>);
    }
   }

  mapSchoolFeeList(model: RegModel) : RegModel {
    let list: SelectListItem[] = [];
    let preschoolList : SelectListItem[] = [];
    let month = moment().clone().local().month() + 1;
    let feeList = month > 5 ? model.schoolFees.filter((f) => (f.type === 'Year' || f.type === 'Fall Semester')) :
    model.schoolFees.filter((f) => (f.type === 'Spring Semester'));

    let preschoolFees = month > 5 ? model.schoolFees.filter((f) => (f.type === 'Year Preschool' || f.type === 'Fall Semester Preschool')) :
    model.schoolFees.filter((f) => (f.type === 'Spring Semester Preschool'));

    list = feeList.map((fee) => ({
      label : `${fee.name} $${fee.amount}`,
      value: fee.schoolFeeTypeId,
      disabled: false
    } as SelectListItem));

    preschoolList = preschoolFees.map((fee) => ({
      label : `${fee.name} $${fee.amount}`,
      value: fee.schoolFeeTypeId,
      disabled: false
    } as SelectListItem));

    model.schoolFeeList = list;
    model.preschoolFeeList = preschoolList;
    model.paymentModes = this.helperService.paymentModesGet(true);

    return model;
  }

  newRegAdd(request: RegRequest): void {
    this.loadService.loadToggle(true);
    this.newRegAddSubject.next({item: request, action: 'add'});
  }

  onFechRegForm(kidsUpdated : boolean): void{
    this.kidsCountUpdatedBS.next(kidsUpdated);
  }

  memberSelected(memberId: number) : void {
    this.regFormBS.next(memberId);
  }
  regForMemberSelected(memberId: number) : void {
    this.regBS.next(memberId);
  }

  getSemesterDates(): Observable<SemesterDates> {
    return this.genericDataService.readWithEndpoint<SemesterDates>('SchoolRegistration/SemesterDates');
  }
}
