import { ProfileImage } from "./../models/profile-image";
import { MemberKeyFob } from "./../models/member-keyfob";
import { MemberListResponse } from "./../models/member-list-response";
import { MemberInfo } from "./../models/member-info";
import { Injectable } from "@angular/core";
import { GenericDataService } from "./generic-data.service";
import { Observable, throwError, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { MemberKid } from "../models/member-kid";
import { Member } from "../models/member";
import { ListRequest } from "../models/list-request";
import { MemberDownload } from "../models/member-download";
import { ConfigService } from "./config.service";
import { File } from "../models/file";
@Injectable({
  providedIn: "root",
})
export class MemberService {
  memberInfo: MemberInfo;
  member: Member;
  members: MemberListResponse[];
  downloadList: MemberDownload[];
  memberKid: MemberKid;
  newMemberKid: MemberKid;
  keyFob: MemberKeyFob;
  file: File;
  public kidEvent$ = new Subject<boolean>();
  public keyFobEvent$ = new Subject<boolean>();
  constructor(
    private genericDataService: GenericDataService,
    private configService: ConfigService
  ) {}

  public memberExists(email: string): any {
    this.genericDataService.endPoint = "Member/Exists";
    const memberObservable$ = new Observable((subscriber) => {
      this.genericDataService
        .readById<MemberInfo>(null, email)
        .subscribe((data) => {
          this.memberInfo = Object.assign({}, data);
          subscriber.next(this.memberInfo);
        });
    });
    return memberObservable$;
  }

  public memberGet(id: number): any {
    this.genericDataService.endPoint = "Member";
    const memberObservable$ = new Observable((subscriber) => {
      this.genericDataService
        .readById<MemberInfo>(null, id)
        .subscribe((data) => {
          this.memberInfo = Object.assign({}, data);
          if (this.memberInfo.file) {
            this.memberInfo.file.url = `${this.configService.config.fileBaseUrl}${this.memberInfo.file.storedFileName}`;
            this.memberInfo.file.thumbNail = `${this.configService.config.fileBaseUrl}${this.memberInfo.file.thumbNail}`;
          }
          subscriber.next(this.memberInfo);
        });
    });
    return memberObservable$;
  }

  public memberAdd(request: Member): any {
    this.genericDataService.endPoint = "Member/New";
    const memberObservable$ = new Observable((subscriber) => {
      this.genericDataService
        .create<Member>(null, request)
        .subscribe((data) => {
          this.member = Object.assign(data);
          subscriber.next(this.member);
        });
    });
    return memberObservable$;
  }

  public memberUpdate(request: any): any {
    this.genericDataService.endPoint = "Member/Update";
    const memberObservable$ = new Observable((subscriber) => {
      this.genericDataService
        .create<Member>(null, request)
        .subscribe((data) => {
          this.member = Object.assign(data);
          subscriber.next(this.member);
        });
    });
    return memberObservable$;
  }

  public memberDelete(id: number): any {
    this.genericDataService.endPoint = "Member/Delete";
    return this.genericDataService.delete<any>(null, id).pipe(
      map((res: Response) => {
        if (res) {
          if (res.status === 201) {
            return [{ status: res.status, json: res }];
          } else if (res.status === 200) {
            return [{ status: res.status, json: res }];
          }
        }
      })
    );
  }

  public profileImageAdd(request: ProfileImage): any {
    this.genericDataService.endPoint = "Member/Image";
    const fileObservable$ = new Observable((subscriber) => {
      this.genericDataService.post<any>(request).subscribe((data) => {
        this.file = Object.assign({}, data);
        this.file.url = `${this.configService.config.fileBaseUrl}${this.file.storedFileName}`;
        this.file.thumbNail = `${this.configService.config.fileBaseUrl}${this.file.thumbNail}`;
        subscriber.next(this.file);
      });
    });

    return fileObservable$;
  }

  public memberList(request: ListRequest): any {
    this.genericDataService.endPoint = "Member/List";

    const listObservable$ = new Observable((subscriber) => {
      this.genericDataService.post<any>(request).subscribe((data) => {
        this.members = Object.assign([], data);
        subscriber.next(this.members);
      });
    });
    return listObservable$;
  }

  public memberDownloadList(request: ListRequest): any {
    this.genericDataService.endPoint = "Member/Download";

    const listObservable$ = new Observable((subscriber) => {
      this.genericDataService.post<any>(request).subscribe((data) => {
        this.downloadList = Object.assign([], data);
        subscriber.next(this.downloadList);
      });
    });
    return listObservable$;
  }

  public kidUpdate(request: MemberKid): any {
    this.genericDataService.endPoint = "Member/Kid/Update";
    return this.genericDataService.post<MemberKid>(request);
  }

  public kidAdd(request: MemberKid): Observable<MemberKid>  {
    this.genericDataService.endPoint = "Member/Kid/Add";
    return this.genericDataService.post<MemberKid>(request);

  }

  public KidDelete(request: MemberKid): any {
    this.genericDataService.endPoint = "Member/Kid/Remove";
    const memberKidObservable$ = new Observable((subscriber) => {
      this.genericDataService
        .create<MemberKid>(null, request)
        .subscribe((data) => {
          this.kidEvent$.next(true);
        });
    });

    return memberKidObservable$;
  }

  public keyFobGet(memberId: number): any {
    this.genericDataService.endPoint = "Member/KeyFob/Get";
    const keyFobObservable$ = new Observable((subscriber) => {
      this.genericDataService
        .readById<MemberKeyFob>(null, memberId)
        .subscribe((data) => {
          this.keyFob = Object.assign({}, data);
          subscriber.next(this.keyFob);
        });
    });
    return keyFobObservable$;
  }

  public keyFobAdd(request: MemberKeyFob): any {
    this.genericDataService.endPoint = "Member/KeyFob/Add";
    const keyFobObservable$ = new Observable((subscriber) => {
      this.genericDataService
        .create<MemberKeyFob>(null, request)
        .subscribe((data) => {
          this.keyFob = Object.assign(data);
          subscriber.next(this.keyFob);
        });
    });
    return keyFobObservable$;
  }

  public keyFobUpdate(request: MemberKeyFob): any {
    this.genericDataService.endPoint = "Member/KeyFob/Update";
    const keyFobObservable$ = new Observable((subscriber) => {
      this.genericDataService.post<MemberKeyFob>(request).subscribe((data) => {
        this.keyFob = Object.assign(data);
        subscriber.next(this.keyFob);
      });
    });
    return keyFobObservable$;
  }

  public keyFobDelete(id: number): any {
    this.genericDataService.endPoint = "Member/KeyFob/Remove";
    const keyFobObservable$ = new Observable((subscriber) => {
      this.genericDataService.delete<any>(null, id).subscribe((data) => {
        this.keyFobEvent$.next(true);
      });
    });

    return keyFobObservable$;
  }
}
