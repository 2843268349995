import { VehicleRequest } from "./../../models/vehicle-request";
import { Guarantor } from "./../../models/guarantor";
import { UploadFile } from "./../../models/upload-file";
import { AddFileRequest } from "src/app/models/add-file-request";
import { TenantRequest } from "./../../models/tenant-request";
import { TenantService } from "./../../services/tenant.service";
import { SelectListItem } from "src/app/models/select-list-item";
import { ParkingSpaceService } from "./../../services/parking-space.service";
import { RoomInfo } from "src/app/models/room-info";
import { SecurityService } from "src/app/services/security.service";
import { RoomService } from "./../../services/room.service";
import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { MemberInfo } from "src/app/models/member-info";
import { Router } from "@angular/router";
import { RoomFilters } from "src/app/models/room-filters";
import { ListRequest } from "src/app/models/list-request";
import { TenantInfo } from "src/app/models/tenant-info";
import { BookRoom } from "src/app/models/book-room";
import { BookRoomValidation } from "src/app/models/book-room-validation";
import { ValidationError } from "src/app/models/validation-error";
import * as _moment from "moment";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { fadeInItems, MatDialog } from "@angular/material";
import { ParkingSpaceInfo } from "src/app/models/parking-space-info";
import { TenantProfileComponent } from "../tenant-profile/tenant-profile.component";
import { TenantProfile } from "src/app/models/tenant-profile";
import { TenantProfileValidation } from "src/app/models/tenant-profile-validate";
import { VehicleValidation } from "src/app/models/vehicle-validation";
import { GuarantorValidation } from "src/app/models/guarantor-validation";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfirmationSnackbarComponent } from "../confirmation-snackbar/confirmation-snackbar.component";
import { UploadDialogModel } from "src/app/models/upload-dialog-model";
import { UploadModalComponent } from "../upload-modal/upload-modal.component";
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY",
  },
  display: {
    dateInput: "MM-DD-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "MM-DD-YYYY",
    monthYearA11yLabel: "MM-DD-YYYY",
  },
};
@Component({
  selector: "app-room-book",
  templateUrl: "./room-book.component.html",
  styleUrls: ["./room-book.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class RoomBookComponent implements OnInit {
  memberId: number;
  signedIn$: Observable<boolean>;
  profile: MemberInfo;
  selectedRoom: RoomInfo;
  rooms: RoomInfo[];
  roomList: SelectListItem[];
  filters: RoomFilters;
  listRequest: ListRequest;
  itemsPerPage = 10;
  pageNumber: number;
  parkingSpaces: ParkingSpaceInfo[];
  tenantRequest: TenantRequest;
  tenant: TenantInfo;
  model: BookRoom;
  defaultLabel = "Select";
  amountTotal: string;
  btnText = "Save";
  uploadedFiles: UploadFile[];
  availableParking: ParkingSpaceInfo;
  showUploadFiles: boolean;
  tenantProfile: TenantProfile;
  guarantor: Guarantor;
  vehicle: VehicleRequest;
  uploadFileModel: UploadDialogModel;
  showfileListDelete = false;
  showfileListTitle = false;
  uploadError: boolean;
  uploadErrorMessage: string;
  showParking: boolean;
  showRentBreakup: boolean;
  constructor(
    private roomService: RoomService,
    private securityService: SecurityService,
    private router: Router,
    private parkingSpaceService: ParkingSpaceService,
    private tenantService: TenantService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.getRooms();
    this.getParkingSpaces();
  }
  ngOnInit() {
    this.getTenant();
  }

  getTenant() {
    this.signedIn$ = this.securityService.isSignedIn();
    this.signedIn$.subscribe((result) => {
      if (result) {
        this.memberId = this.securityService.getMemberId();
        this.showRentBreakup = this.memberId ? true : false;
      } else {
        this.router.navigate(["/login"]);
      }
    });

    if (this.memberId) {
      this.tenantService.tenantGetByMember(this.memberId).subscribe((data) => {
        this.tenant = Object.assign({}, data);
        if (this.tenant) {
          if (this.tenant.isVerified) {
            this.router.navigate(["/leaseRequest"]);
          }
          this.showUploadFiles = this.tenant.id > 0 ? false : true;
          this.btnText = this.tenant.id > 0 ? "Update" : "Save";
          if (this.tenant.files && this.tenant.files.length > 0) {
            this.uploadedFiles = this.tenant.files.map((item) => ({
              id: item.fileId,
              name: item.uploadedFileName,
              fileType: item.fileType,
              fileByteString: "",
              progress: 100,
              size: 10000,
            }));
            this.uploadError = false;
          } else {
            this.uploadError = true;
            this.toggleError();
          }
          this.setModels();
        }
      });
    }
  }

  setModels() {
    const profileError =
      this.tenant.referenceName &&
      this.tenant.referenceEmail &&
      this.tenant.referencePhone
        ? false
        : true;
    this.tenantProfile = {
      firstName: this.tenant.memberInfo.firstName,
      lastName: this.tenant.memberInfo.lastName,
      gender: this.tenant.memberInfo.gender
        ? this.tenant.memberInfo.gender
        : "M",
      age: this.tenant.memberInfo.age ? this.tenant.memberInfo.age : 18,
      isStudent: this.tenant.memberInfo.isStudent ? "true" : "false",
      residencyStatus: this.tenant.memberInfo.residencyStatus
        ? "true"
        : "false",
      refName: !this.tenant.referenceName ? "" : this.tenant.referenceName,
      refEmail: !this.tenant.referenceEmail ? "" : this.tenant.referenceEmail,
      refPhone: !this.tenant.referencePhone ? "" : this.tenant.referencePhone,
      validation: new TenantProfileValidation(
        new ValidationError(false),
        new ValidationError(false),
        new ValidationError(false),
        new ValidationError(false),
        profileError
      ),
    };
    this.model.tenantProfile = this.tenantProfile;
    this.model.isParkingSpace =
      this.tenant && this.tenant.parkingSpaceRent ? true : false;
    this.showParking = this.model.isParkingSpace
      ? true
      : this.availableParking
      ? true
      : false;
    this.model.isGuarantor =
      this.tenant && this.tenant.guarantor ? true : false;

    this.model.vehicle.validation.hasError = this.model.isParkingSpace;
    const guarantorError = !this.tenant.guarantor ? true : false;
    this.guarantor = {
      id: !this.tenant.guarantor ? null : this.tenant.guarantor.id,
      name: !this.tenant.guarantor ? "" : this.tenant.guarantor.name,
      email: !this.tenant.guarantor ? "" : this.tenant.guarantor.email,
      phone: !this.tenant.guarantor ? "" : this.tenant.guarantor.phone,
      address: !this.tenant.guarantor ? "" : this.tenant.guarantor.address,
      city: !this.tenant.guarantor ? "" : this.tenant.guarantor.city,
      zip: !this.tenant.guarantor ? "" : this.tenant.guarantor.zip,
      state: !this.tenant.guarantor ? "IN" : this.tenant.guarantor.state,
      initials: "",
      signatureDate: null,
      validation: new GuarantorValidation(
        new ValidationError(false),
        new ValidationError(false),
        new ValidationError(false),
        new ValidationError(false),
        new ValidationError(false),
        new ValidationError(false),
        guarantorError
      ),
    };
    this.model.guarantor = this.guarantor;
    const hasVehicle =
      this.tenant.vehicles && this.tenant.vehicles.length > 0 ? true : false;

    this.vehicle = {
      id: hasVehicle ? this.tenant.vehicles[0].id : null,
      licensePlate: hasVehicle ? this.tenant.vehicles[0].licensePlate : "",
      model: hasVehicle ? this.tenant.vehicles[0].model : "",
      name: hasVehicle ? this.tenant.vehicles[0].name : "",
      year: hasVehicle ? this.tenant.vehicles[0].year : "",
      tenantId: hasVehicle ? this.tenant.id : null,
      parkingSpaceId: hasVehicle
        ? this.tenant.vehicles[0].parkingSpace.id
        : null,
      validation: new VehicleValidation(
        new ValidationError(false),
        new ValidationError(false),
        new ValidationError(false),
        new ValidationError(false),
        true
      ),
    };
    this.vehicle.validation.hasError =
      this.vehicle.name.length > 0 &&
      this.vehicle.licensePlate.length > 0 &&
      this.vehicle.model.length > 0 &&
      this.vehicle.year.length > 0
        ? false
        : true;
    this.model.vehicle = this.vehicle;
    this.uploadFileModel = {
      title: "Drag and drop verification document here",
      subTitle:
        "Valid passport or US driver license or Purdue Id or admission letter is required",
      btnText: "Browse",
      imageOnly: false,
      allowMultiple: true,
      hasError: false,
      errorMessage: null,
      showCancel: false,
      uploadedFiles: this.uploadedFiles,
    };
    this.setModel();
  }

  setListRequest() {
    if (!this.filters) {
      this.filters = {
        name: null,
        occupancy: null,
        rentId: null,
      };
    }
    this.listRequest = {
      filter: this.filters,
      itemsPerPage: !this.itemsPerPage ? null : this.itemsPerPage,
      pageNumber: !this.pageNumber ? 1 : this.pageNumber,
    };
  }

  getRooms() {
    this.setListRequest();
    this.roomService.roomList(this.listRequest).subscribe((data) => {
      this.rooms = Object.assign([], data);
      if (this.rooms && this.rooms.length > 0) {
        this.roomList = this.rooms.map((item) => ({
          label: `${item.name} ${item.description}`,
          value: item.id,
          disabled: !item.isAvailable,
        }));
      }

      this.model = new BookRoom();
      this.model.validation = new BookRoomValidation(
        new ValidationError(false),
        new ValidationError(false),
        true
      );
    });
  }

  getParkingSpaces() {
    this.parkingSpaceService.parkingSpaceList().subscribe((data) => {
      if (data) {
        this.parkingSpaces = Object.assign([], data);
        this.availableParking = this.parkingSpaces.find((p) => p.isAvailable);
      }
    });
  }

  setSelectedRoom(roomId: number) {
    this.selectedRoom = this.rooms.find((r) => r.id === roomId);
    this.model.roomId = this.selectedRoom.id;
    this.model.rentId = this.selectedRoom.rents[0].id;
    let total = 2 * this.selectedRoom.rents[0].amount;
    if (this.model.isParkingSpace) {
      let pAmount =
        this.tenant && this.tenant.parkingSpaceRent
          ? this.tenant.parkingSpaceRent.amount
          : this.availableParking.amount;
      total = total + pAmount;
      this.amountTotal = `$${this.selectedRoom.rents[0].amount} (rent monthly) + $${this.selectedRoom.rents[0].amount} (refundable security deposit) + $${pAmount} (parking fee monthly) = $${total}`;
    } else {
      this.amountTotal = `$${this.selectedRoom.rents[0].amount} (rent monthly) + $${this.selectedRoom.rents[0].amount} (refundable security deposit) = $${total}`;
    }
    this.model.roomAvailableDate = `Available Date: ${moment(
      this.selectedRoom.availableDate
    )
      .clone()
      .format("MM-DD-YYYY")}`;
    this.model.rentAmount = `Rent: $${this.selectedRoom.rents[0].amount} monthly all inclusive`;

    // this.setModel();
  }

  setModel() {
    let selectedRoomId;
    if (
      this.tenant &&
      this.tenant.id &&
      this.tenant.roomInfo &&
      this.tenant.roomInfo.id
    ) {
      selectedRoomId = this.tenant.roomInfo.id;
    } else {
      if (this.roomService.selectedRoom) {
        selectedRoomId = this.roomService.selectedRoom.id;
        this.roomService.selectedRoom = null;
      }
    }

    this.selectedRoom = !selectedRoomId
      ? this.rooms.find((r) => r.isAvailable)
      : this.rooms.find((r) => r.id === selectedRoomId);
    this.model.roomId = this.selectedRoom.id;
    this.model.rentId = this.selectedRoom.rents[0].id;
    let total = 2 * this.selectedRoom.rents[0].amount;

    if (this.model.isParkingSpace) {
      let pAmount =
        this.tenant && this.tenant.parkingSpaceRent
          ? this.tenant.parkingSpaceRent.amount
          : this.availableParking.amount;
      total = total + pAmount;
      this.amountTotal = `$${this.selectedRoom.rents[0].amount} (rent monthly) + $${this.selectedRoom.rents[0].amount} (refundable security deposit) + $${pAmount} (parking fee monthly) = $${total}`;
    } else {
      this.amountTotal = `$${this.selectedRoom.rents[0].amount} (rent monthly) + $${this.selectedRoom.rents[0].amount} (refundable security deposit) = $${total}`;
    }

    this.model.roomAvailableDate = `Available Date: ${moment(
      this.selectedRoom.availableDate
    )
      .clone()
      .format("MM-DD-YYYY")}`;
    this.model.rentAmount = `Rent: $${this.selectedRoom.rents[0].amount} monthly all inclusive`;
    this.model.tentativeStartDate =
      this.tenant && this.tenant.tentativeStartDate
        ? moment(this.tenant.tentativeStartDate).local()
        : moment().add(1, "M").startOf("month").local();
    this.model.uploadedFiles = [];
    this.model.uploadedFiles =
      this.uploadedFiles && this.uploadedFiles.length > 0
        ? this.uploadedFiles
        : [];
  }

  setModelValidation() {
    this.model.validation.hasError = this.validateInfo();
  }

  validateInfo() {
    let hasError = false;
    if (this.model.uploadedFiles.length === 0) {
      this.model.validation.uploadedFiles.hasError = true;
      this.model.validation.uploadedFiles.errorMessage = "Required";
    } else {
      this.model.validation.uploadedFiles.hasError = false;
    }

    hasError = this.model.validation.uploadedFiles.hasError;
    if (hasError) {
      return hasError;
    }

    hasError = this.model.tenantProfile.validation.hasError;
    if (hasError) {
      return hasError;
    }

    if (this.model.isParkingSpace) {
      hasError = this.model.vehicle.validation.hasError;
      if (hasError) {
        return hasError;
      }
    }
    if (this.model.isGuarantor) {
      hasError = this.model.guarantor.validation.hasError;
    }
    return hasError;
  }

  getTenantProfile(tenantProfile: TenantProfile) {
    this.model.tenantProfile = Object.assign({}, tenantProfile);
    this.setModelValidation();
  }

  getVehicle(vehicle: VehicleRequest) {
    this.model.vehicle = Object.assign({}, vehicle);
    this.setModelValidation();
  }

  getGuarantor(guarantor: Guarantor) {
    this.model.guarantor = Object.assign({}, guarantor);
    this.setModelValidation();
  }

  onCheckChange(event: any) {
    let total = 2 * this.selectedRoom.rents[0].amount;
    if (event.checked) {
      total = total + 25;
      this.amountTotal = `$${this.selectedRoom.rents[0].amount} (rent monthly) + $${this.selectedRoom.rents[0].amount} (refundable security deposit) + $${this.availableParking.amount} (parking fee monthly) = $${total}`;
    } else {
      this.amountTotal = `$${this.selectedRoom.rents[0].amount} (rent monthly) + $${this.selectedRoom.rents[0].amount} (refundable security deposit) = $${total}`;
    }
    this.model.vehicle.validation.hasError = event.checked;
    this.setModelValidation();
  }

  onIsGuarantorChange(event: any) {
    this.model.guarantor.validation.hasError = event.checked;
    this.setModelValidation();
  }

  addTenant() {
    this.setSaveModel();
    if (this.tenant.id) {
      this.tenantService.tenantUpdate(this.tenantRequest).subscribe((data) => {
        this.tenant = Object.assign({}, data);
        this.btnText = this.tenant && this.tenant.id ? "Update" : "Save";
        if (this.tenant.files && this.tenant.files.length > 0) {
          this.uploadedFiles = this.tenant.files.map((item) => ({
            id: item.fileId,
            name: item.storedFileName,
            fileType: item.fileType,
            fileByteString: "",
            progress: 100,
            size: null,
          }));
        }
        this.setModels();
        this.showConfirmationDialog(
          "Your information is updated, we will send you an email, once your request is approved."
        );
      });
    } else {
      this.tenantService.tenantAdd(this.tenantRequest).subscribe((data) => {
        this.tenant = Object.assign({}, data);
        this.btnText = this.tenant && this.tenant.id ? "Update" : "Save";
        if (this.tenant.files && this.tenant.files.length > 0) {
          this.uploadedFiles = this.tenant.files.map((item) => ({
            id: item.fileId,
            name: item.storedFileName,
            fileType: item.fileType,
            fileByteString: "",
            progress: 100,
            size: null,
          }));
        }
        this.setModels();
        this.showConfirmationDialog(
          "Your tenant request is submitted, we will send you an email, once your request is approved."
        );
      });
    }
  }

  setSaveModel() {
    this.tenantRequest = new TenantRequest();
    this.tenantRequest.id = !this.tenant.id ? null : this.tenant.id;
    this.tenantRequest.memberId = this.tenant
      ? this.tenant.memberInfo.id
      : this.memberId;
    this.tenantRequest.referenceEmail = this.model.tenantProfile.refEmail;
    this.tenantRequest.referenceName = this.model.tenantProfile.refName;
    this.tenantRequest.referencePhone = this.model.tenantProfile.refPhone;
    this.tenantRequest.roomId = this.model.roomId;
    this.tenantRequest.rentId = this.model.rentId;
    if (!this.tenant.id) {
      this.tenantRequest.files = this.model.uploadedFiles.map((item) => ({
        tenantId: 0,
        isImage: false,
        fileRequest: new AddFileRequest(
          "G",
          item.name,
          "",
          item.fileByteString,
          null
        ),
      }));
    }

    this.tenantRequest.tentativeStartDate = this.model.tentativeStartDate;
    if (this.model.isParkingSpace) {
      this.tenantRequest.vehicles = [];
      this.model.vehicle.parkingSpaceId =
        (!this.model.vehicle.parkingSpaceId ||
          this.model.vehicle.parkingSpaceId === undefined) &&
        this.availableParking
          ? this.availableParking.id
          : null;
      if (this.model.vehicle.parkingSpaceId) {
        this.tenantRequest.vehicles.push(this.model.vehicle);
      }
    }
    let age = this.model.tenantProfile.age.toString();
    this.tenantRequest.age = parseInt(age);
    this.tenantRequest.isStudent =
      this.model.tenantProfile.isStudent === "true" ? true : false;
    this.tenantRequest.residencyStatus =
      this.model.tenantProfile.residencyStatus === "true" ? true : false;
    if (this.model.isGuarantor) {
      this.tenantRequest.guarantor = Object.assign({}, this.model.guarantor);
      this.tenantRequest.guarantor.validation = null;
      this.tenantRequest.guarantor.signatureDate = null;
    } else {
      this.tenantRequest.guarantor = null;
    }
  }

  showConfirmationDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 20000,
      panelClass: "confirmation-message-snack-bar",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: "message",
      },
    });
  }

  openUploadFileDialog(): void {
    const dialogRef = this.dialog.open(UploadModalComponent, {
      height: "580px",
      width: "740px",
      data: this.uploadFileModel,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data.uploadedFiles) {
        this.model.uploadedFiles = this.uploadedFiles = Object.assign(
          [],
          data.uploadedFiles
        );
      }
      if (!this.uploadedFiles || this.uploadedFiles.length === 0) {
        this.uploadError = true;
        this.uploadErrorMessage = "Required";
      } else {
        this.uploadError = false;
        this.uploadErrorMessage = "";
      }
      this.uploadFileModel.uploadedFiles = this.uploadedFiles;
      this.toggleError();
      this.setModelValidation();
    });
  }

  toggleError(): void {
    setTimeout(
      function () {
        this.uploadErrorMessage = "";
        this.uploadError = false;
      }.bind(this),
      3000
    );
  }
}
