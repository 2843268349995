import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams  } from '@angular/common/http';
import { Config } from '../models/config';
@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  config: Config;
constructor(private http: HttpClient) { }
  loadConfig() {
    return this.http
      .get<Config>('./assets/config.json')
      .toPromise()
      .then(config => {
        this.config = config;
      });
  }
}
