import { StripePaymentModalComponent } from "./../stripe-payment-modal/stripe-payment-modal.component";
import { ConfigService } from "src/app/services/config.service";
import { TenantPaymentSummary } from "./../../models/tenant-payment-summary";
import { ConfirmationSnackbarComponent } from "./../confirmation-snackbar/confirmation-snackbar.component";
import { LeaseService } from "./../../services/lease.service";
import { LeaseCreate } from "./../../models/lease-create";
import { AddFileRequest } from "./../../models/add-file-request";
import { HelperService } from "src/app/services/helper.service";
import { TenantService } from "./../../services/tenant.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { TenantInfo } from "src/app/models/tenant-info";
import { Observable } from "rxjs";
import { SecurityService } from "src/app/services/security.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SelectListItem } from "src/app/models/select-list-item";
import { Lease } from "src/app/models/lease";
import * as _moment from "moment";
import { MatDialog, MatSnackBar } from "@angular/material";
import { faMosque, faSignature } from "@fortawesome/free-solid-svg-icons";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DonorboxTenantPayment } from "src/app/models/donorbox-tenant-payment";
import { LeaseFile } from "src/app/models/lease-file";
import { SignatureModalComponent } from "../signature-modal/signature-modal.component";
import { StripeTenantPayment } from "src/app/models/stripe-tenant-payment";
import { ConfirmDialogModel } from "src/app/models/confirm-dialog-model";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { NgxSpinnerService } from "ngx-spinner";
import { LeaseSignature } from "src/app/models/lease-signature";
import { StripePayment } from "src/app/models/stripe-payment";

const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY",
  },
  display: {
    dateInput: "MM-DD-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "MM-DD-YYYY",
    monthYearA11yLabel: "MM-DD-YYYY",
  },
};

@Component({
  selector: "app-lease-request",
  templateUrl: "./lease-request.component.html",
  styleUrls: ["./lease-request.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
//declare const stripe;
export class LeaseRequestComponent implements OnInit {
  constructor(
    private tenantService: TenantService,
    private securityService: SecurityService,
    private router: Router,
    private helperService: HelperService,
    private leaseService: LeaseService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService
  ) {}

  tenant: TenantInfo;
  memberId: number;
  signedIn$: Observable<boolean>;
  amountTotal: string;
  amountDue: number;
  amountPaid: string;
  roomName: string;
  rentAmount: string;
  securityDeposit: string;
  firstMonthRent: string;
  leaseDurationList: SelectListItem[];
  selectedLeaseDuration: SelectListItem;
  minDate: any;
  parkingFee: string;
  signee: string = "Tenant";
  tenantInitials: string;
  tenantSignature: AddFileRequest;
  hasError: boolean = true;
  hasSigError: boolean;
  model: LeaseCreate = new LeaseCreate();
  public leaseSource: string;
  showTenantInfo: boolean = true;
  donorboxPaymentModalOpen: boolean;
  donorboxPayment: DonorboxTenantPayment;
  paymentCollected: boolean = false;
  faMosque = faMosque;
  faSignature = faSignature;
  leaseModel: Lease;
  paymentSummary: TenantPaymentSummary;
  currentLease: LeaseFile;
  tenantVerificationToken: string;
  handler: any = null;
  stripePayment: StripeTenantPayment;
  card: any;
  notLoading = true;

  ngOnInit() {
    this.tenantVerificationToken =
      this.route.snapshot.queryParamMap.get("verificationToken");
    if (this.tenantVerificationToken) {
    }
    this.getTenant();
    this.getLists();
    //this.minDate = moment().clone();
    //this.loadStripe();
  }

  getTenant() {
    this.signedIn$ = this.securityService.isSignedIn();
    this.signedIn$.subscribe((result) => {
      if (result) {
        this.memberId = this.securityService.getMemberId();
        if (this.memberId) {
          this.tenantService
            .tenantGetByMember(this.memberId)
            .subscribe((data) => {
              this.tenant = Object.assign({}, data);
              this.setLease();
              this.setLeaseDates();
              if (this.tenant && this.tenant.roomInfo) {
                this.roomName = `${this.tenant.roomInfo.name} ${this.tenant.roomInfo.description}`;
                this.setTotal();
              }
              if (this.tenant) {
                if (this.tenant.leaseInfo) {
                  if (this.tenant.leaseInfo.statusId === 2) {
                    this.leaseService
                      .leaseCurrentGet(this.tenant.leaseInfo.id)
                      .subscribe((response) => {
                        this.leaseSource = response.file
                          ? response.file.url
                          : null;
                        this.showTenantInfo = this.leaseSource ? false : true;
                      });
                  } else if (this.tenant.leaseInfo.statusId > 2) {
                    // redirect to new tanant page
                    this.router.navigate(["/tenant"]);
                  }
                }
                //this.collectPaymentInfo();
              }
              //this.collectPaymentInfo();
            });
        }
      } else {
        this.router.navigate(["/login"]);
      }
    });
  }

  setLeaseDates() {
    const currentDate = moment().clone().local();
    let sDate = null;
    if (!this.tenant.tentativeStartDate) {
      sDate = currentDate;
    } else {
      sDate = moment(this.tenant.tentativeStartDate).local();
    }

    const endOfMonth = sDate.clone().endOf("month").local();
    const sFirstDay = sDate.date();
    const sLastDay = endOfMonth.date();
    const monthDays = sDate.daysInMonth();
    let prorateDays = 0;

    if (sFirstDay === 1) {
      this.tenant.prorateAmount = 0;
    } else if (sDate.isSame(endOfMonth)) {
      sDate = sDate.add(1, "M").startOf("month").local();
      this.tenant.prorateAmount = 0;
    } else {
      const sDay = sDate.date();
      prorateDays = sLastDay - sDay + 1;
      const dailyRent = this.tenant.roomRent.amount / monthDays;
      this.tenant.prorateAmount = Number(
        Math.round(prorateDays * dailyRent).toFixed(2)
      );
    }
    this.leaseModel.prorateAmount = this.tenant.prorateAmount;
    this.leaseModel.startDate = this.tenant.startDate = sDate;
    this.leaseModel.endDate = this.tenant.leaseInfo
      ? this.tenant.leaseInfo.endDate
      : this.tenant.startDate
          .clone()
          .add(11, "M")
          .endOf("month")
          .local()
          .format("MM-DD-YYYY");
    this.leaseModel.startDate = this.tenant.startDate;
    this.tenant.endDate = moment(this.leaseModel.endDate)
      .clone()
      .local()
      .format("MM-DD-YYYY");
  }

  startDateChange() {
    const endOfMonth = this.tenant.startDate.clone().endOf("month").local();
    const sFirstDay = this.tenant.startDate.date();

    if (sFirstDay === 1) {
      this.tenant.prorateAmount = 0;
    } else if (this.tenant.startDate.isSame(endOfMonth)) {
      this.tenant.startDate = this.tenant.startDate
        .add(1, "M")
        .startOf("month")
        .local();
      this.tenant.prorateAmount = 0;
    } else {
      this.calculateProrate();
    }
    this.leaseModel.prorateAmount = this.tenant.prorateAmount;
    this.leaseModel.startDate = this.tenant.startDate;
    this.leaseModel.endDate = this.tenant.endDate = this.tenant.startDate
      .clone()
      .add(this.selectedLeaseDuration.value - 1, "M")
      .endOf("month")
      .local()
      .format("MM-DD-YYYY");
    this.setTotal();
    // this.updateLease();
  }

  calculateProrate() {
    let prorateDays = 0;
    const endOfMonth = this.tenant.startDate.clone().endOf("month").local();
    const sLastDay = endOfMonth.date();
    const sDay = this.tenant.startDate.date();
    const monthDays = this.tenant.startDate.daysInMonth();
    prorateDays = sLastDay - sDay + 1;
    if (prorateDays !== monthDays) {
      const dailyRent = this.tenant.roomRent.amount / monthDays;
      this.leaseModel.prorateAmount = this.tenant.prorateAmount = Number(
        Math.round(prorateDays * dailyRent).toFixed(2)
      );
    } else {
      this.leaseModel.prorateAmount = null;
    }
  }

  setTotal() {
    let rentOverride = !this.tenant.overrides ? null : this.tenant.overrides.find(o => o.paymentTypeId == 1);
    let secDepOverride = !this.tenant.overrides ? null : this.tenant.overrides.find(o => o.paymentTypeId == 3);

    this.rentAmount = `$${this.tenant.roomRent.amount}`;
    this.securityDeposit = !secDepOverride && !this.tenant.securityDeposit
      ? "$0"
      : secDepOverride ?`$${secDepOverride.amount}` : `$${this.tenant.securityDeposit}`;
    const secDeposit = !secDepOverride && !this.tenant.securityDeposit
      ? 0
      : secDepOverride ? secDepOverride.amount : this.tenant.securityDeposit;
    const firstRent = rentOverride ? rentOverride.amount :
      this.tenant.prorateAmount && this.tenant.prorateAmount > 0
        ? this.tenant.prorateAmount
        : this.tenant.roomRent.amount;
    this.firstMonthRent = `$${firstRent}`;
    const parkingRent = this.tenant.parkingSpaceRent
      ? this.tenant.parkingSpaceRent.amount
      : 0;
    this.parkingFee = this.tenant.parkingSpaceRent
      ? `$${this.tenant.parkingSpaceRent.amount}`
      : "$0";
    if (!this.tenant.paymentSummary) {
      this.amountTotal = `$${firstRent + secDeposit + parkingRent}`;
      this.amountDue = firstRent + secDeposit + parkingRent;
      this.amountPaid = "$0";
    } else {
      this.amountDue = this.tenant.paymentSummary.amountDue;
      this.amountTotal = `$${this.amountDue}`;
      this.amountPaid = `$${this.tenant.paymentSummary.amountPaid}`;
      this.paymentCollected = this.amountDue === 0 ? true : false;
    }
  }

  getLists() {
    this.leaseDurationList = this.helperService.tenantLeaseDurationsGet();
    this.selectedLeaseDuration = this.leaseDurationList[1];
  }

  setDuration(value: any) {
    this.selectedLeaseDuration = this.leaseDurationList.find(
      (l) => l.value === value
    );
    let roomRent;
    if (this.selectedLeaseDuration.label !== "1 year") {
      roomRent = this.tenant.roomInfo.rents.find((r) =>
        r.name.toLowerCase().includes("short term")
      );
    } else {
      roomRent = this.tenant.roomInfo.rents.find(
        (r) => !r.name.toLowerCase().includes("short term")
      );
    }
    this.leaseModel.rentId = roomRent ? roomRent.id : this.tenant.roomRent.id;
    this.tenant.rentId = this.leaseModel.rentId;
    this.tenant.roomRent = roomRent ? roomRent : this.tenant.roomRent;
    this.leaseModel.endDate = this.tenant.endDate = this.tenant.startDate
      .clone()
      .add(this.selectedLeaseDuration.value - 1, "M")
      .endOf("month")
      .local()
      .format("MM-DD-YYYY");
    this.calculateProrate();
    this.setTotal();
  }

  createLease() {
    this.notLoading = false;
    this.spinner.show();
    this.model.tenantId = this.tenant.id;
    this.model.leaseDate = moment().clone().local(true);
    this.model.startDate = moment(this.tenant.startDate).clone().local();
    this.model.endDate = moment(this.tenant.endDate).clone().local();
    this.model.rentId = this.tenant.rentId;
    this.model.prorateAmount = this.tenant.prorateAmount;
    this.model.securityDeposit = this.tenant.securityDeposit;
    this.model.tenantSignatureDate = moment().clone().local();
    this.model.tenantInitials = this.tenantInitials;
    this.leaseService.leaseDoc(this.model).subscribe((response) => {
      if (response) {
        if (response.file) {
          this.leaseSource = response.file.url;
          this.showTenantInfo = false;
        }
      }
      this.notLoading = true;
      this.spinner.hide();
    });
  }
  b64toBlob(b64Data, contentType, sliceSize) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  savePayment() {
    this.notLoading = false;
    this.spinner.show();
    if (this.leaseModel.id === 0) {
      this.leaseModel.securityDeposit = this.tenant.securityDeposit;
      this.leaseModel.endDate = moment(this.leaseModel.endDate);
      this.leaseService.leaseAdd(this.leaseModel).subscribe((data) => {
        if (data) {
          this.leaseModel.id = data.id;
        }
        this.tenantService
          .tenantPayment(this.stripePayment)
          .subscribe((data) => {
            if (data.paymentError) {
              const dialogData = new ConfirmDialogModel(
                "Payment Issue",
                `${data.paymentError.code}: ${data.paymentError.message}`,
                "alert",
                false
              );
              this.notLoading = true;
              this.spinner.hide();
              this.openAlertDialog(dialogData);
              return;
            }
            this.paymentSummary = Object.assign({}, data);
            this.amountDue = this.paymentSummary.amountDue;
            this.paymentCollected = this.amountDue <= 0 ? true : false;
            this.amountTotal = `$${this.amountDue}`;
            this.amountPaid = `$${this.paymentSummary.amountPaid}`;
            this.notLoading = true;
            this.spinner.hide();
            this.showConfirmationDialog(
              "Your payment is saved, please continue to save your lease document"
            );
          });
      });
    } else {
      this.tenantService.tenantPayment(this.stripePayment).subscribe((data) => {
        if (data.paymentError) {
          const dialogData = new ConfirmDialogModel(
            "Payment Issue",
            `${data.paymentError.code}: ${data.paymentError.message}`,
            "alert",
            false
          );
          this.notLoading = true;
          this.spinner.hide();
          this.openAlertDialog(dialogData);

          return;
        }
        this.paymentSummary = Object.assign({}, data);
        this.amountDue = this.paymentSummary.amountDue;
        this.paymentCollected = this.amountDue <= 0 ? true : false;
        this.amountTotal = `$${this.amountDue}`;
        this.amountPaid = `$${this.paymentSummary.amountPaid}`;
        this.notLoading = true;
        this.spinner.hide();
        this.showConfirmationDialog(
          "Your payment is saved, please continue to save your lease document"
        );
      });
    }
  }



  setLease() {
    if (!this.tenant) {
      return;
    }

    this.leaseModel = new Lease();
    this.leaseModel = {
      id: this.tenant.leaseInfo ? this.tenant.leaseInfo.id : 0,
      tenantId: this.tenant.id,
      name: this.tenant.leaseInfo ? this.tenant.leaseInfo.name : "Tenant Lease",
      startDate: this.tenant.leaseInfo
        ? moment(this.tenant.leaseInfo.startDate).clone().local()
        : moment(this.tenant.tentativeStartDate).clone().local(),
      endDate: this.tenant.leaseInfo
        ? moment(this.tenant.leaseInfo.endDate).clone().local()
        : moment(this.tenant.tentativeStartDate)
            .clone()
            .add(11, "M")
            .endOf("month")
            .local(),
      roomId: this.tenant.roomInfo.id,
      rentId: this.tenant.rentId,
      prorateAmount: this.tenant.leaseInfo
        ? this.tenant.leaseInfo.prorateAmount
        : null,
      autoRenewal: this.tenant.leaseInfo
        ? this.tenant.leaseInfo.autoRenewal
        : true,
      securityDeposit: this.tenant.securityDeposit,
      securityDepositCollected: this.tenant.leaseInfo
        ? this.tenant.leaseInfo.securityDepositCollected
        : "No",
      statusId: this.tenant.leaseInfo ? this.tenant.leaseInfo.statusId : 1,
      leaseDate: moment().local(),
    };
    let months = this.leaseModel.endDate.diff(
      this.leaseModel.startDate,
      "months"
    );
    months =
      months < 12 && months > 9
        ? 12
        : months < 9 && months > 6
        ? 9
        : months < 6 && months > 3
        ? 3
        : months;
    this.selectedLeaseDuration = this.leaseDurationList.find(
      (x) => x.value === months
    );
  }

  leaseConfirmation() {
    if (this.leaseModel.id === 0) {
      return;
    }
    this.leaseModel.securityDeposit = this.tenant.securityDeposit;
    this.leaseModel.endDate = moment(this.leaseModel.endDate);
    this.leaseService.leaseConfirm(this.leaseModel).subscribe((data) => {
      if (data) {
        if (data.statusId === 3) {
          this.router.navigate(["/tenant"]);
        }
      }
    });
  }

  confirmLease() {
    this.leaseModel.statusId = 3;
    this.leaseConfirmation();
  }

  cancel() {
    this.showTenantInfo = true;
    this.leaseSource = null;
  }

  openSignatureDialog(): void {
    const dialogRef = this.dialog.open(SignatureModalComponent, {
      height: "400px",
      width: "600px",
      data: {
        signee: this.signee,
        role: "member",
        hasError: false,
        errorMessage: null,
        showCancel: false,
        existingSig: null,
        signeeName: `${this.tenant.memberInfo.firstName} ${this.tenant.memberInfo.lastName}`,
        useExisting: false,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((sigModel) => {
      if (!sigModel) {
        this.hasError = true;
        this.hasSigError = true;
        this.toggleError();
        return;
      }
      if (
        !sigModel.useExisting &&
        (!sigModel.signatureFile.fileByteString ||
          sigModel.signatureFile.length === 0)
      ) {
        this.hasError = true;
        this.hasSigError = true;
        this.toggleError();
        return;
      }
      if (
        !sigModel.useExisting &&
        sigModel.signatureFile &&
        sigModel.signatureFile.fileByteString &&
        sigModel.signatureFile.fileByteString.length > 0
      ) {
        this.model.tenantSignature = new LeaseSignature();

        this.model.tenantSignature.newSignature = Object.assign(
          {},
          sigModel.signatureFile
        );
        this.model.tenantSignature.hasPrintSignature = sigModel.usePrint;
        this.hasError = false;
        this.hasSigError = false;
      }

    });
  }

  toggleError(): void {
    setTimeout(
      function () {
        this.hasSigError = false;
      }.bind(this),
      3000
    );
  }

  processToken(payment: StripePayment) {
    if (payment.tokenId) {
      this.stripePayment.tokenId = payment.tokenId;
      this.savePayment();
    }
  }

  openAlertDialog(dialogData: ConfirmDialogModel) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "500px",
      height: "300px",
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      //this.openStripe();
    });
  }

  openStripeDialog() {
    let sPayment = {
      amount: this.amountDue,
      paymentDate: moment().local().clone(),
      tokenId: "",
      recipientEmail: this.tenant.memberInfo.email,
      title: "ISGL Tenant Portal",
      description: "First Rent",
      showCancel: false,
    } as StripePayment;

    const dialogRef = this.dialog.open(StripePaymentModalComponent, {
      width: "550px",
      height: "380px",
      data: sPayment,
      disableClose: true,
    });

    dialogRef.afterClosed()
    .subscribe((dialogResult) => {
      if (dialogResult && dialogResult.tokenId) {
        this.stripePayment = {
          tenantId : this.tenant.id,
          amount: this.amountDue,
          paymentDate: moment().local().clone(),
          tokenId: dialogResult.tokenId,
          recipientEmail: this.tenant.memberInfo.email,
          rentMonth: moment().local().month() + 1, // month is zero based 0-11
          rentYear: moment().local().year(),
          title: "ISGL Tenant Portal",
          description: "First Rent",
          showCancel: false,
        };

        //this.stripePayment.tokenId = dialogResult.tokenId;
        this.savePayment();
      }
      //this.openStripe();
    });
  }

  showConfirmationDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 20000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: "success",
      },
    });
  }
}
