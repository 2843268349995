import { VehicleService } from "./../../services/vehicle.service";
import { VehicleRequest } from "./../../models/vehicle-request";
import { VehicleInfo } from "./../../models/vehicle-info";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SelectListItem } from "src/app/models/select-list-item";
import { HelperService } from "src/app/services/helper.service";
import { VehicleValidation } from "src/app/models/vehicle-validation";
import { ValidationError } from "src/app/models/validation-error";

@Component({
  selector: "app-vehicle-manage",
  templateUrl: "./vehicle-manage.component.html",
  styleUrls: ["./vehicle-manage.component.scss"],
})
export class VehicleManageComponent implements OnInit {
  constructor(
    private helperService: HelperService,
    private vehicleService: VehicleService
  ) {}
  @Input() vehicle: VehicleInfo;
  @Input() tenantId: number;
  @Input() headerTitle: string = "Vehicle Info (All fields are required)";
  @Output() notifyParent = new EventEmitter<VehicleInfo>();
  vehicleYears: SelectListItem[];
  model: VehicleRequest;
  enableBtn: boolean = false;
  ngOnInit() {
    if (this.vehicle) {
      this.model = {
        id: this.vehicle.id,
        licensePlate: this.vehicle.licensePlate,
        model: this.vehicle.model,
        year: this.vehicle.year,
        name: this.vehicle.name,
        parkingSpaceId: this.vehicle.parkingSpace.id,
        tenantId: this.tenantId,
        validation: new VehicleValidation(
          new ValidationError(false),
          new ValidationError(false),
          new ValidationError(false),
          new ValidationError(false),
          false
        ),
      };
      this.getYears();
    }
  }

  getYears() {
    this.vehicleYears = this.helperService.vehicleYearsGet();
  }
  setValue(field: string, value: any) {
    this.model.validation.hasError = !this.validateFields(field, value);
    if (!this.model.validation.hasError) {
      this.enableBtn =
        this.model.name === this.vehicle.name &&
        this.model.licensePlate === this.vehicle.licensePlate &&
        this.model.year === this.vehicle.year
          ? false
          : true;
    }
  }

  validateFields(field: string, value: any): boolean {
    let isValid = true;
    if (field === "vehicleName") {
      if (value.length === 0) {
        this.model.validation.name.hasError = true;
        this.model.validation.name.errorMessage = "Required";
        isValid = false;
      } else {
        this.model.validation.name.hasError = false;
      }
    }
    if (field === "vehicleModel") {
      if (value.length === 0) {
        this.model.validation.model.hasError = true;
        this.model.validation.model.errorMessage = "Required";
        isValid = false;
      } else {
        this.model.validation.model.hasError = false;
      }
    }
    if (field === "vehicleYear") {
      if (value.length === 0) {
        this.model.validation.year.hasError = true;
        this.model.validation.year.errorMessage = "Required";
        isValid = false;
      } else {
        this.model.year = value;
        this.model.validation.year.hasError = false;
      }
    }
    if (field === "licensePlate") {
      if (value.length === 0) {
        this.model.validation.licensePlate.hasError = true;
        this.model.validation.licensePlate.errorMessage = "Required";
        isValid = false;
      } else {
        this.model.validation.licensePlate.hasError = false;
      }
    }

    isValid =
      this.model.name.length > 0 &&
      this.model.licensePlate.length > 0 &&
      this.model.model.length > 0 &&
      this.model.year.length > 0
        ? true
        : false;

    return isValid;
  }
  updateVehicle() {
    this.vehicleService.vehicleUpdate(this.model).subscribe((veh) => {
      if (veh) {
        this.vehicle = Object.assign(veh);
        this.notifyParent.emit(this.vehicle);
      }
    });
  }
}
