import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Confirm } from '../models/confirm';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {
  private confirmationBS = new BehaviorSubject<Confirm>({});
  confirmation$ = this.confirmationBS.asObservable();
constructor() { }

confirmationToggle(confirm: Confirm) {
  this.confirmationBS.next(confirm);
 }

}
