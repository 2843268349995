import { ValidationError } from './validation-error';

export class MemberValidation {
  firstName: ValidationError;
  lastName: ValidationError;
  address: ValidationError;
  city: ValidationError;
  state: ValidationError;
  zip: ValidationError;
  cellPhone: ValidationError;
  gender: ValidationError;
  hasError: boolean;

  constructor(firstName: ValidationError, lastName: ValidationError, address: ValidationError,
    city: ValidationError, state: ValidationError, zip: ValidationError, cellPhone: ValidationError,
    gender: ValidationError, hasError: boolean) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.address = address;
    this.city = city;
    this.state = state;
    this.zip = zip;
    this.cellPhone = cellPhone;
    this.gender = gender;
    this.hasError = hasError;
  }


}
