import { ParkingSpaceService } from "./../../services/parking-space.service";
import { VehicleInfo } from "./../../models/vehicle-info";
import { TenantAddendum } from "../../models/tenant-addendum";
import { LeaseService } from "./../../services/lease.service";
import { SecurityService } from "./../../services/security.service";
import { TenantService } from "./../../services/tenant.service";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { TenantInfo } from "src/app/models/tenant-info";
import { Observable, Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import {
  faDownload,
  faMosque,
  faWrench,
  faPlusSquare,
} from "@fortawesome/free-solid-svg-icons";
import { MatDialog, MatSnackBar } from "@angular/material";
import { DonorboxTenantPayment } from "src/app/models/donorbox-tenant-payment";
import * as _moment from "moment";
import { TenantPaymentSummary } from "src/app/models/tenant-payment-summary";
import { Rent } from "src/app/models/rent";
import { StripePayment } from "src/app/models/stripe-payment";
import { StripePaymentModalComponent } from "../stripe-payment-modal/stripe-payment-modal.component";
import { ConfirmationSnackbarComponent } from "../confirmation-snackbar/confirmation-snackbar.component";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmDialogModel } from "src/app/models/confirm-dialog-model";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { LeaseFile } from "src/app/models/lease-file";
import { AddendumInfo } from "src/app/models/addendum-info";
import { mergeMap, switchMap } from "rxjs/operators";
import { StripeTenantPayment } from "src/app/models/stripe-tenant-payment";
const moment = _moment;
@Component({
  selector: "app-tenant",
  templateUrl: "./tenant.component.html",
  styleUrls: ["./tenant.component.scss"],
})
export class TenantComponent implements OnInit, OnDestroy {
  tenant: TenantInfo;
  memberId: number;
  signedIn$: Observable<boolean>;
  roomName: string;
  monthlyRent: string;
  parkingFee: string;
  leaseStatusInfo: string;
  leaseStatusText: string;
  faDownload = faDownload;
  faMosque = faMosque;
  faWrench = faWrench;
  faPlusSquare = faPlusSquare;
  nextPaymentDueDate: string;
  nextPaymentAmount: number;
  nextPaymentTotal: string;
  donorboxPaymentModalOpen: boolean;
  donorboxPayment: DonorboxTenantPayment;
  paymentSummary: TenantPaymentSummary;
  paymentCollected: boolean;
  lateRent: Rent;
  showNextPayment: boolean;
  paymentDueStatus: string;
  paymentDueInfo: string;
  stripePayment: StripeTenantPayment;
  notLoading: boolean;
  addendumConfirmed$: Subscription;
  docs: LeaseFile[];
  showAddendumMessage: boolean;
  addendumExtMessage: string;
  latestAddendum: AddendumInfo;
  vehicle: VehicleInfo;
  vehicleTitle = "Vehicle Information";
  subscriptions = new Subscription();
  showAutoRenewal: boolean;
  autoRenewal = false;
  leaseDuration = 0;
  @Input() model: TenantInfo;
  @Output() notifyParent = new EventEmitter();
  constructor(
    private tenantService: TenantService,
    private router: Router,
    private leaseService: LeaseService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    if (this.model) {
      this.setModels();
    }
  }

  setModels() {
    this.memberId = this.model.memberInfo.id;
    this.tenant = Object.assign({}, this.model);
    this.paymentDueStatus = moment()
      .local()
      .isAfter(
        moment(this.tenant.paymentSummary.nextPaymentDate).local(),
        "day"
      )
      ? "red"
      : moment()
          .local()
          .isSame(
            moment(this.tenant.paymentSummary.nextPaymentDate).local(),
            "day"
          )
      ? "yellow"
      : "green";
    this.getLeaseDocs();

    this.roomName = `${this.tenant.roomInfo.name} ${this.tenant.roomInfo.description}`;
    this.monthlyRent = `$${this.tenant.roomRent.amount}`;
    this.parkingFee = this.tenant.parkingSpaceRent
      ? `$${this.tenant.parkingSpaceRent.amount}`
      : null;
    this.vehicle = !this.tenant.vehicles
      ? null
      : this.tenant.vehicles.length > 0
      ? this.tenant.vehicles[0]
      : null;
    this.setNextPayment();
    this.setLeaseStatusInfo();
    this.getLateRent();
    this.getNextPaymentDate();
    const extAddendumIndx = this.tenant.leaseInfo.addendums
      ? this.tenant.leaseInfo.addendums.findIndex((a) => a.type === "Lease-Ext")
      : -1;
    this.showAutoRenewal =
      extAddendumIndx !== -1
        ? false
        : moment(this.tenant.leaseInfo.endDate).diff(moment(), "d") + 1 > 90
        ? false
        : true;

    this.leaseDuration = Math.round(
      moment(new Date(this.tenant.leaseInfo.endDate))
        .clone()
        .diff(new Date(this.tenant.leaseInfo.startDate), "months", true)
    );

    this.addendumConfirmed$ = this.leaseService.addendumConfirmed$.subscribe(
      (data) => {
        if (data) {
          this.tenant.leaseInfo = Object.assign({}, data.leaseInfo);
          if (data.vehicle) {
            this.tenant.vehicles = !this.tenant.vehicles
              ? []
              : this.tenant.vehicles;
            this.tenant.vehicles.splice(0, 0, data.vehicle);
            this.vehicle = data.vehicle;
          }

          this.setAddendumMessage(data.addendumId);
        }
      }
    );
    if (!this.latestAddendum) {
      this.setAddendumMessage(null);
    }
    this.subscriptions.add(this.addendumConfirmed$);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  setNextPayment() {
    const days = moment(this.tenant.paymentSummary.nextPaymentDate).diff(
      moment(),
      "days"
    );
    this.showNextPayment = days <= 10 ? true : false;
    if(this.tenant.paymentSummary.amountDue > 0 &&
        moment(this.tenant.paymentSummary.nextPaymentDate).month() + 1 === moment(this.tenant.leaseInfo.startDate).month() +1 ) {
          this.showNextPayment = true;
        }
    this.paymentDueInfo =
      this.paymentDueStatus === "red"
        ? "Late"
        : this.paymentDueStatus === "yellow"
        ? "0 day left"
        : `${days} left`;
    this.nextPaymentAmount = this.tenant.paymentSummary.nextPaymentAmount
      ? this.tenant.paymentSummary.nextPaymentAmount
      : this.tenant.roomRent.amount;
    if (this.tenant.parkingSpaceRent && this.tenant.parkingSpaceRent.amount) {
      this.nextPaymentAmount += this.tenant.parkingSpaceRent.amount;
    }
    this.nextPaymentTotal = `$${this.nextPaymentAmount}`;
  }

  setLeaseStatusInfo() {
    if (this.tenant.leaseInfo.statusId === 3) {
      this.leaseStatusInfo = "Awaiting ISGL IEC Signatures.";
      this.leaseStatusText =
        "Email has been sent to ISGL IEC, Awaiting Signatures";
    } else if (this.tenant.leaseInfo.statusId === 4) {
      this.leaseStatusText = this.leaseStatusInfo = "Active Lease";
    } else if (this.tenant.leaseInfo.statusId === 5) {
      this.leaseStatusText =
        this.leaseStatusInfo = `Lease is expired on ${moment(
          this.tenant.leaseInfo.endDate
        )
          .clone()
          .format("MM-dd-yyyy")}`;
    }
  }

  getLeaseDocs() {
    this.leaseService
      .getLeaseDocs(this.tenant.leaseInfo.id)
      .subscribe((response) => {
        //this.leaseSource = response.file.url;
        this.docs = Object.assign([], response);
      });
  }

  getNextPaymentDate() {
    this.nextPaymentDueDate = moment(this.tenant.paymentSummary.nextPaymentDate)
      .clone()
      .format("MM-DD-YY");
  }

  getLateRent() {
    if (
      !this.tenant.paymentSummary.lateFeeWaiver &&
      moment().isAfter(
        moment(this.tenant.paymentSummary.nextPaymentDate),
        "day"
      )
    ) {
      this.tenantService.rentByName("Late Fee").subscribe((data) => {
        if (data) {
          this.lateRent = Object.assign({}, data);
          this.nextPaymentAmount += this.lateRent.amount;
          this.nextPaymentTotal = `$${this.nextPaymentAmount} includes $${this.lateRent.amount} Late Fee `;
        }
      });
    }
  }

  openStripeDialog() {
    let sPayment = {
      amount: this.nextPaymentAmount,
      paymentDate: moment().local().clone(),
      tokenId: "",
      recipientEmail: this.tenant.memberInfo.email,
      title: "ISGL Tenant Portal",
      description: "Monthly Rent",
      showCancel: false,
    } as StripePayment;

    const dialogRef = this.dialog.open(StripePaymentModalComponent, {
      width: "550px",
      height: "420px",
      data: sPayment,
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(
        mergeMap((res) => {
          if (res && res.tokenId) {
            this.stripePayment = {
              tenantId : this.tenant.id,
              amount: this.nextPaymentAmount,
              paymentDate: moment().local().clone(),
              tokenId: res.tokenId,
              recipientEmail: this.tenant.memberInfo.email,
              rentMonth: moment().local().month() + 1, // month is zero based 0-11
              rentYear: moment().local().year(),
              title: "ISGL Tenant Portal",
              description: "First Rent",
              showCancel: false,
            };
            this.notLoading = false;
            this.spinner.show();
            return this.tenantService.tenantMonthlyPayment(this.stripePayment);
          }
        })
      )
      .subscribe((data: any) => {
        if (data.paymentError) {
          const dialogData = new ConfirmDialogModel(
            "Payment Issue",
            `${data.paymentError.code}: ${data.paymentError.message}`,
            "alert",
            false
          );
          this.notLoading = true;
          this.spinner.hide();
          this.openAlertDialog(dialogData, "stripe");
          return;
        }

        this.tenant = Object.assign({}, data);
        this.paymentSummary = this.tenant.paymentSummary;

        this.nextPaymentAmount = this.paymentSummary.amountDue;
        this.paymentCollected = this.nextPaymentAmount <= 0 ? true : false;
        this.setNextPayment();
        this.getLateRent();
        this.getNextPaymentDate();
        this.notLoading = true;
        this.spinner.hide();
        this.showConfirmationDialog("Your monthly rent payment is saved.");
        if (this.paymentCollected) {
          this.notifyParent.emit(this.paymentCollected);
        }
      });
  }

  openAlertDialog(dialogData: ConfirmDialogModel, sender: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "500px",
      height: "300px",
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        if (sender === "autoRenewal") {
          this.autoRenewLease();
        }
      } else {
        if (sender === "autoRenewal") {
          this.autoRenewal = false;
        }
      }
    });
  }

  showConfirmationDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 20000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: "success",
      },
    });
  }

  removeQueryParams() {
    if (window.location.pathname === "/leaseRequest") {
      this.router.navigate(["."], {
        relativeTo: this.route,
        queryParams: {},
        replaceUrl: true,
      });
    }
  }

  setAddendumMessage(addendumId: number | null) {
    if (!this.tenant) {
      return;
    }
    let subMessage = "";
    if (addendumId) {
      if (
        this.tenant.leaseInfo.addendums &&
        this.tenant.leaseInfo.addendums.length > 0
      ) {
        this.latestAddendum = this.tenant.leaseInfo.addendums.find(
          (a) => a.id === addendumId
        );
        if (this.latestAddendum && this.latestAddendum.statusId === 3) {
          if (this.latestAddendum.type === "Lease-Ext") {
            subMessage = "lease extension addendum";
          } else if (this.latestAddendum.type === "Parking-Add") {
            subMessage = "Parking addition addendum";
          } else {
            subMessage = "Parking termination addendum";
          }
          this.addendumExtMessage =
            `Your ${subMessage} request is successfully submitted and awaiting ICB signature.` +
            `Once addendum is signed, your lease will be updated and you will recieve a confirmation email.`;
          this.showAddendumMessage = true;
        }
      }
    } else {
      if (
        this.tenant.leaseInfo.addendums &&
        this.tenant.leaseInfo.addendums.length > 0
      ) {
        let pendAddendums = ([] = this.tenant.leaseInfo.addendums
          .filter((a) => a.statusId === 3)
          .sort((a, b) => a.type.localeCompare(b.type)));
        if (pendAddendums && pendAddendums.length > 0) {
          pendAddendums.forEach((item, index) => {
            if (item.type === "Lease-Ext") {
              subMessage = "lease extension addendum";
            }
            if (item.type === "Parking-Add") {
              subMessage +=
                subMessage.length > 0 && index === pendAddendums.length - 1
                  ? " and Parking addition addendum"
                  : subMessage.length > 0 && index < pendAddendums.length - 1
                  ? ", Parking addition addendum"
                  : "Parking addition addendum";
            }
            if (item.type === "Parking-Term") {
              subMessage +=
                subMessage.length > 0
                  ? " and Parking termination addendum"
                  : "Parking termination addendum";
            }
          });
          if (pendAddendums.length > 1) {
            this.addendumExtMessage =
              `Your ${subMessage} requests are successfully submitted and awaiting ICB signature.` +
              `Once addendums are signed, your lease will be updated and you will recieve a confirmation email.`;
          } else {
            this.addendumExtMessage =
              `Your ${subMessage} request is successfully submitted and awaiting ICB signature.` +
              `Once addendum is signed, your lease will be updated and you will recieve a confirmation email.`;
          }
          this.showAddendumMessage = true;
        }
      }
    }
  }
  setVehicle(vehicle: VehicleInfo) {
    this.vehicle = Object.assign({}, vehicle);
    this.tenant.vehicles[0] = this.vehicle;
  }
  onAutoRenewalChange(event: any) {
    if (event.checked) {
      const dialogData = new ConfirmDialogModel(
        "Alert",
        "Are u sure? This action cannot be changed once started.",
        "alert",
        true
      );

      this.openAlertDialog(dialogData, "autoRenewal");
    }
  }
  autoRenewLease() {
    this.notLoading = false;
    this.spinner.show();
    this.leaseService
      .autoRenew(this.tenant.leaseInfo.id)
      .pipe(
        mergeMap((res) => {
          if (res) {
            this.tenant.leaseInfo = Object.assign(res);
            return this.leaseService.getLeaseDocs(this.tenant.leaseInfo.id);
          }
        })
      )
      .subscribe((data) => {
        if (data) {
          this.docs = Object.assign([], data);
          this.showConfirmationDialog(
            "Your renewal is saved and your lease is updated. A confirmation email has been sent."
          );
        }
        this.showAutoRenewal = false;
        this.notLoading = true;
        this.spinner.hide();
      });
  }
}
