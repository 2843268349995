import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-addendum-message",
  templateUrl: "./addendum-message.component.html",
  styleUrls: ["./addendum-message.component.scss"],
})
export class AddendumMessageComponent implements OnInit {
  @Input() extMessage: string;
  constructor() {}

  ngOnInit() {}
}
