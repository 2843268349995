import { fromEvent, Observable, Subscription } from "rxjs";
import { LeaseSignature } from "../../models/lease-signature";
import { HelperService } from "src/app/services/helper.service";
import { LeaseInfo } from "./../../models/lease-info";
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
} from "@angular/core";
import { ConfirmationSnackbarComponent } from "../confirmation-snackbar/confirmation-snackbar.component";
import { LeaseService } from "src/app/services/lease.service";
import { MatDialog, MatSnackBar } from "@angular/material";
import { faSignature, faDownload } from "@fortawesome/free-solid-svg-icons";
import * as _moment from "moment";
import { SignatureModalComponent } from "../signature-modal/signature-modal.component";
import { TenantInfo } from "src/app/models/tenant-info";
import { LeaseFile } from "src/app/models/lease-file";
import { File } from "src/app/models/file";
import { SecurityService } from "src/app/services/security.service";
import { debounceTime } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
const moment = _moment;
@Component({
  selector: "app-lease-details",
  templateUrl: "./lease-details.component.html",
  styleUrls: ["./lease-details.component.scss"],
})
export class LeaseDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() tenantInfo: TenantInfo;
  @Input() existingCoordSig: File;
  @Output() notifyParent = new EventEmitter();
  @Output() notifyLeaseSigned = new EventEmitter();
  model: LeaseInfo;
  startDate: string;
  endDate: string;
  status: string;
  rent: string;
  prorate: string;
  deposit: string;
  depositPaid: string;
  faSignature = faSignature;
  faDownload = faDownload;
  signee: string = "Coordinator";
  signeeName: string;
  coordinatorSig: LeaseSignature;
  showSave: boolean = false;
  sigBtnText: string = "Click to sign";
  tenantOverrideAdded$: Subscription;
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  screenWidth;
  mobileWidth = 768;
  isMobile = false;
  docs: LeaseFile[];
  subscriptions = new Subscription();
  notLoading = true;
  constructor(
    private leaseService: LeaseService,
    private snackBar: MatSnackBar,
    private helperService: HelperService,
    public dialog: MatDialog,
    private securityService: SecurityService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.signeeName = this.securityService.getUserName();
    this.screenWidth = window.innerWidth;
    this.isMobile = this.screenWidth <= this.mobileWidth ? true : false;
    this.resizeObservable$ = fromEvent(window, "resize");
    this.resizeSubscription$ = this.resizeObservable$
      .pipe(debounceTime(10))
      .subscribe((evt) => {
        this.getScreenWidth(evt);
      });
    this.subscriptions.add(this.resizeSubscription$);
  }
  ngOnChanges() {
    if (this.tenantInfo) {
      this.model = Object.assign({}, this.tenantInfo.leaseInfo);
      if (!this.model.id) {
        return;
      }
      this.setModel();
    }
    this.tenantOverrideAdded$ =
      this.leaseService.tenantOverrideAdded$.subscribe((data) => {
        if (data) {
          this.model = Object.assign({}, data.leaseInfo);
          this.setModel();
        }
      });
    this.subscriptions.add(this.tenantOverrideAdded$);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getScreenWidth(event) {
    const currentScreenWidth = event.target.innerWidth;
    this.isMobile = currentScreenWidth <= this.mobileWidth ? true : false;
    this.screenWidth = currentScreenWidth;
  }

  setModel() {
    this.getDocs();
    this.startDate = moment(this.model.startDate).clone().format("MM-DD-YYYY");
    this.endDate = moment(this.model.endDate).clone().format("MM-DD-YYYY");
    this.status = this.helperService.mapLeaseStatus(this.model.status);
    this.prorate = this.model.prorateAmount
      ? `$${this.model.prorateAmount}`
      : "$0";
    this.rent = `$${this.tenantInfo.roomRent.amount}`;
    this.deposit = this.model.securityDeposit
      ? `$${this.model.securityDeposit}`
      : "$0";
    this.depositPaid = this.model.securityDepositCollected;
  }

  showConfirmationDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 20000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: "success",
      },
    });
  }

  openSignatureDialog(): void {
    this.sigBtnText = "Click to sign";
    this.showSave = false;
    const dialogRef = this.dialog.open(SignatureModalComponent, {
      height: this.isMobile ? "500px" : "400px",
      width: this.isMobile ? "400px" : "600px",
      data: {
        signee: this.signee,
        role: "Admin",
        hasError: false,
        errorMessage: null,
        showCancel: false,
        existingSig: this.existingCoordSig,
        signeeName: this.signeeName,
        useExisting: false,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((sigModel) => {
      if (!sigModel) {
        return;
      }
      if (
        !sigModel.useExisting &&
        (!sigModel.signatureFile.fileByteString ||
          sigModel.signatureFile.length === 0)
      ) {
        return;
      }
      if (
        !sigModel.useExisting &&
        sigModel.signatureFile &&
        sigModel.signatureFile.fileByteString &&
        sigModel.signatureFile.fileByteString.length > 0
      ) {
        this.coordinatorSig = new LeaseSignature();
        this.coordinatorSig.leaseId = this.model.id;
        this.coordinatorSig.newSignature = Object.assign(
          {},
          sigModel.signatureFile
        );
        this.coordinatorSig.hasPrintSignature = sigModel.usePrint;
        this.sigBtnText = "Clear signature";
        this.showSave = true;
      }
      if (sigModel.useExisting) {
        this.coordinatorSig = new LeaseSignature();
        this.coordinatorSig.leaseId = this.model.id;
        this.coordinatorSig.existingSignature = this.existingCoordSig;
        this.coordinatorSig.hasPrintSignature = false;
        this.sigBtnText = "Clear signature";
        this.showSave = true;
      }
    });
  }

  getDocs() {
    if (!this.model.id) {
      return;
    }
    this.leaseService.getLeaseDocs(this.model.id).subscribe((res) => {
      if (res) {
        this.docs = Object.assign([], res);
      }
    });
  }

  saveSig() {
    if (
      (!this.coordinatorSig || !this.coordinatorSig.newSignature) &&
      !this.coordinatorSig.existingSignature
    ) {
      return;
    }
    this.notLoading = false;
    this.spinner.show();
    this.leaseService
      .coordinatureSigAdd(this.coordinatorSig)
      .subscribe((response) => {
        this.notLoading = true;
        this.spinner.hide();
        if (response) {
          if (response.file) {
            if (this.docs && this.docs.length > 0) {
              const indx = this.docs.findIndex((f) => f.name === response.name);
              if (indx !== 1) {
                let temp = this.docs.splice(indx, 1, response);
                this.docs = temp;
              } else {
                this.docs.push(response);
              }
            }
            this.model.statusId = 4;
            this.showConfirmationDialog("Lease is signed and active.");
            this.notifyLeaseSigned.emit("Active");
          }
        }
      });
  }
}
