import { TeacherSummary } from './../../models/school/teacher-summary';
import { SchoolManageService } from './../../services/school-manage.service';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IebTeacherModalComponent } from '../ieb-teacher-modal/ieb-teacher-modal.component';
import { faPlus} from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-ieb-teachers',
  templateUrl: './ieb-teachers.component.html',
  styleUrls: ['./ieb-teachers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IebTeachersComponent implements OnInit {
  private dataSource = new MatTableDataSource<TeacherSummary>();
  dataSource$ : Observable<MatTableDataSource<TeacherSummary>> = this.schoolService.teachers$.pipe(
    map((response) => {
      const dataSource = this.dataSource;
      dataSource.data = response;
      return dataSource;
    })
  );
  @ViewChild(MatSort, {static : false}) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  displayedColumns: string[] = ['name', 'email', 'validTill'];
  @Input() hideAdd: boolean;
  faPlus = faPlus;
  constructor(private schoolService: SchoolManageService, public dialog: MatDialog) { }

  ngOnInit() {
  }

  editTeacher(teacher: TeacherSummary) :void {
    this.openDialog(teacher);
  }
  addTeacher():void {
    let newTeacher = {}
    this.openDialog(newTeacher);
  }

  openDialog(teacher: TeacherSummary){
    const dialogRef = this.dialog.open(IebTeacherModalComponent, {
      //maxWidth: "800px",
      width: "800px",
      height: "600px",
      data: teacher,
      disableClose: true,
      panelClass: 'panel-dialog',
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
       if(!dialogResult.id) {
        this.schoolService.addNewTeacher(dialogResult);
       } else {
        this.schoolService.editTeacher(dialogResult);
       }
      }

    });
  }

}
