import { ListRequest } from "./../../models/list-request";
import { RoomFilters } from "./../../models/room-filters";
import { SelectListItem } from "./../../models/select-list-item";
import { RoomService } from "./../../services/room.service";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import { TenantInfo } from "src/app/models/tenant-info";
import { TenantService } from "src/app/services/tenant.service";
import { ConfirmationSnackbarComponent } from "../confirmation-snackbar/confirmation-snackbar.component";
import { RoomInfo } from "src/app/models/room-info";
import { Rent } from "src/app/models/rent";
import { RentService } from "src/app/services/rent.service";
import { ConfirmDialogModel } from "src/app/models/confirm-dialog-model";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { TenantUpdate } from "src/app/models/tenant-update";
import { faFile } from "@fortawesome/free-solid-svg-icons";
@Component({
  selector: "app-tenant-details",
  templateUrl: "./tenant-details.component.html",
  styleUrls: ["./tenant-details.component.scss"],
})
export class TenantDetailsComponent implements OnInit, OnChanges {
  @Input() model: TenantInfo;
  @Output() notifyVerification = new EventEmitter();
  @Output() notifyUpdate = new EventEmitter<TenantInfo>();
  @Output() notifyDelete = new EventEmitter<number>();
  isStudent: string;
  residencyStatus: string;
  hasParking: string;
  room: string;
  roomList: SelectListItem[];
  rooms: RoomInfo[];
  rents: Rent[];
  roomFilters: RoomFilters;
  listRequest: ListRequest;
  defaultLabel = "Select";
  rentList: SelectListItem[] = [];
  selectedRoom: RoomInfo;
  selectedRent: Rent;
  showEditRoom: boolean = false;
  updateRequest: TenantUpdate;
  faFile = faFile;
  showResend = false;

  constructor(
    private tenantService: TenantService,
    private snackBar: MatSnackBar,
    private roomService: RoomService,
    private rentService: RentService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getRooms();
    this.getRents();
  }
  ngOnChanges() {
    if (this.model) {
      this.isStudent = this.model.memberInfo.isStudent ? "Yes" : "No";
      this.residencyStatus = this.model.memberInfo.residencyStatus
        ? "Yes"
        : "No";
      this.hasParking = this.model.parkingSpaceRent ? "Yes" : "No";
      this.room = this.model.roomInfo
        ? `${this.model.roomInfo.name} ${this.model.roomInfo.description}`
        : "";
      this.showEditRoom = !this.model.leaseInfo
        ? true
        : this.model.leaseInfo && this.model.leaseInfo.statusId === 1
        ? true
        : false;
      this.rentList = this.model.roomInfo.rents.map((item) => ({
        label: `${item.name}-$${item.amount}`,
        value: item.id,
        disabled: false,
      }));
      this.showResend =
        this.model.isVerified && !this.model.leaseInfo
          ? true
          : this.model.isVerified &&
            this.model.leaseInfo &&
            this.model.leaseInfo.statusId === 1
          ? true
          : false;
    }
  }
  onCheckChange(event: any) {
    if (event.checked) {
      this.tenantService.tenantReferenceCheck(this.model.id, true);
      this.tenantService.refCheckEvent$.subscribe((checked) => {
        this.model.isVerified = true;
        this.showConfirmationDialog("Tenant verification is updated.");
        this.notifyVerification.emit(true);
      });
    }
  }

  showConfirmationDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 20000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: "success",
      },
    });
  }

  getRooms() {
    this.roomFilters = {
      name: null,
      occupancy: null,
      rentId: null,
    };

    this.listRequest = {
      filter: this.roomFilters,
      itemsPerPage: null,
      pageNumber: null,
    };
    this.roomService.roomList(this.listRequest).subscribe((data) => {
      if (data) {
        this.rooms = Object.assign([], data);
        this.selectedRoom =
          this.model && this.model.roomInfo ? this.model.roomInfo : null;
        this.roomList = this.rooms.map((item) => ({
          label: `${item.name}/${item.occupancy}`,
          value: item.id,
          disabled: !item.isAvailable,
        }));
      }
    });
  }

  getRents() {
    this.rentService.rentList().subscribe((data) => {
      if (data) {
        this.rents = Object.assign([], data);
      }
    });
  }

  setRentList(roomId: number) {
    this.selectedRoom = this.rooms.find((r) => r.id === roomId);
    if (this.selectedRoom == null) {
      return;
    }
    this.rentList = this.selectedRoom.rents.map((item) => ({
      label: `${item.name}-$${item.amount}`,
      value: item.id,
      disabled: false,
    }));
    this.selectedRent = this.rents.find((r) => r.id == this.rentList[0].value);
    if (this.selectedRent.amount === this.model.roomRent.amount) {
      this.updateTenantRoom();
    }
  }

  setRent(rentId: number) {
    this.selectedRent = this.rents.find((r) => r.id == rentId);
    if (this.selectedRent == null) {
      return;
    }

    if (this.selectedRent.amount !== this.model.roomRent.amount) {
      const dialogData = new ConfirmDialogModel(
        "Alert",
        "Are you sure? this will change room rent and security deposit!.",
        "alert",
        true
      );
      this.openAlertDialog(dialogData, "rent");
    }
  }

  openAlertDialog(dialogData: ConfirmDialogModel, sender: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "500px",
      height: "300px",
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (sender === "rent") {
        if (dialogResult) {
          this.updateTenantRoom();
        } else {
          this.selectedRent = this.rents.find(
            (r) => r.id == this.model.roomRent.id
          );
        }
      } else if (sender === "delete") {
        if (dialogResult) {
          this.deleteTenant();
        }
      } else {
        if (dialogResult) {
          this.resendLeaseSignEmail();
        }
      }
    });
  }

  updateTenantRoom() {
    this.updateRequest = {
      tenantId: this.model.id,
      rentId: this.selectedRent.id,
      roomId: this.selectedRoom.id,
      waiveSecurityDeposit: false,
    };
    this.tenantService
      .tenantRoomUpdate(this.updateRequest)
      .subscribe((data) => {
        if (data) {
          this.model = Object.assign(data);
          this.notifyUpdate.emit(this.model);
        }
      });
  }

  resendEmailAlert() {
    const dialogData = new ConfirmDialogModel(
      "Alert",
      "Are you sure? This action will re-send lease create email to tenant.",
      "alert",
      true
    );
    this.openAlertDialog(dialogData, "resend");
  }

  resendLeaseSignEmail() {
    this.tenantService
      .tenantResendLeaseSignEmail(this.model.id)
      .subscribe((res) => {
        if (res) {
          this.showConfirmationDialog("Lease signature email is re-sent.");
        }
      });
  }

  deleteAlert() {
    const dialogData = new ConfirmDialogModel(
      "Alert",
      "Are you sure? This action will delete this tenant record.",
      "alert",
      true
    );
    this.openAlertDialog(dialogData, "delete");
  }

  deleteTenant() {
    this.tenantService.tenantDelete(this.model.id).subscribe((res) => {
      if (res) {
        this.notifyDelete.emit(this.model.id);
        this.showConfirmationDialog("Tenant is deleted.");
      }
    });
  }
}
