import { EventService } from './../../services/event.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-events-manage',
  templateUrl: './events-manage.component.html',
  styleUrls: ['./events-manage.component.scss']
})
export class EventsManageComponent implements OnInit {

  showCategories = true;
  showAnnouncements: boolean;
  showAyahs: boolean;
  showTypes = false;
  constructor(private eventService: EventService) { }

  ngOnInit() {
  }

  eventTypeClick(name: string) {
    if (name === 'category') {
      this.showCategories = true;
      this.showAnnouncements = false;
      this.showAyahs = false;
    } else if (name === 'announcement') {
      this.showCategories = false;
      this.showAnnouncements = true;
      this.showAyahs = false;
    } else if (name === 'ayah') {
      this.showCategories = false;
      this.showAnnouncements = false;
      this.showAyahs = true;
    }
  }
}
