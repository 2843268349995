import { SelectListItem } from 'src/app/models/select-list-item';
import { KidInfo } from '../../models/school/kid-info';
import { Level } from './../../models/school/level';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Skill } from 'src/app/models/school/skill';
import * as _moment from "moment";
import { DateAdapter, MatSnackBar, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { KidRequest } from 'src/app/models/school/kid-request';
import { ConfirmationSnackbarComponent } from '../confirmation-snackbar/confirmation-snackbar.component';

const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY",
  },
  display: {
    dateInput: "MM-DD-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "MM-DD-YYYY",
    monthYearA11yLabel: "MM-DD-YYYY",
  },
};
@Component({
  selector: 'app-ieb-kid',
  templateUrl: './ieb-kid.component.html',
  styleUrls: ['./ieb-kid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class IebKidComponent implements OnInit, OnChanges {
@Input() model: KidInfo;
@Input() genderList: SelectListItem[];
@Input() arabicSkills: SelectListItem[];
@Input() quranSkills: SelectListItem[];
@Input() memberId: number;
@Input() levels: Level[];
@Input() levelList : SelectListItem[];
@Output() addKid = new EventEmitter<KidRequest>();
@Output() editKid = new EventEmitter<KidRequest>();
@Output() cancelKid = new EventEmitter<boolean>();
schoolKidForm!: FormGroup;
submitBtnText: string;
quranSkill: SelectListItem;
arabicSkill: SelectListItem;
level: Level;
selectedLevel: SelectListItem;
gender: string;
kidDob: any;
startDate: Date;
minDate: Date;
maxDate: Date;
emailValidation = new RegExp('[a-z0-9]+@gmail.com');
btnText = 'Save Child';
title: string;

  constructor(private formBuilder: FormBuilder, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.schoolKidForm = this.formBuilder.group({
      kidFirstName: ['', [Validators.required]],
      kidLastName: ['', [Validators.required]],
      kidEmail: [
        '',
        [Validators.required, Validators.email, Validators.pattern(this.emailValidation),Validators.maxLength(254)],
      ],
      dob: [new Date(), [Validators.required]],
      level: ['', [Validators.required]],

    });
    this.setObjects();
  }

  ngOnChanges(): void {
    if(this.model) {
      this.btnText = this.model.id ? 'Update' : 'Save';
      this.title = this.model.id ? 'Update Child Information' : 'Add Child Information';
      this.setObjects();
    }
  }

  setObjects() {
    let year = moment().clone().local().subtract(6, 'years').year();
    let month = moment().clone().local().month();
    let day = moment().clone().local().date();
    let tempDate = new Date(year, month, day);
    this.kidDob = this.startDate = this.subtractMonths(tempDate, 1);
    if(this.model) {
      if(this.model.id) {
        year = moment(this.model.dob).clone().local().year();
        month = moment(this.model.dob).clone().local().month();
        day = moment(this.model.dob).clone().local().date();
        tempDate = new Date(year, month, day);
        this.startDate = this.kidDob = this.subtractMonths(tempDate, 1);
      } else {
        year = this.model.kid  && this.model.kid.birthYear ? this.model.kid.birthYear : year;
        month = this.model.kid  && this.model.kid.birthMonth ? this.model.kid.birthMonth : month ;
        day = day;
        tempDate = new Date(year, month, day);
        this.startDate = this.kidDob = this.subtractMonths(tempDate, 1);
      }

      if(this.schoolKidForm) {
        this.schoolKidForm.controls['kidFirstName'].setValue(this.model.kid.firstName);
        this.schoolKidForm.controls['kidLastName'].setValue(this.model.kid.lastName);
        this.schoolKidForm.controls['kidEmail'].setValue(this.model.id ? this.model.email : '');
        this.schoolKidForm.controls['dob'].setValue(this.kidDob);
      }


    }
    this.quranSkill = this.model && this.model.id && this.model.skills && this.model.skills.length > 0 ? this.setSkills(2) : this.quranSkills[this.quranSkills.length-1];
    this.arabicSkill = this.model && this.model.id && this.model.skills && this.model.skills.length > 0 ? this.setSkills(1) :this.arabicSkills[0];
    if(this.model && this.model.kidLevel != null) {
      this.level = this.model && this.model.id ?  this.levels.find((l)=> l.id === this.model.kidLevel.schoolLevelId) : null;
      this.selectedLevel = this.level ? this.levelList.find(x=> x.value == this.level.id) : this.levelList[0];
    }

    if(this.schoolKidForm){
      this.schoolKidForm.controls['level'].setValue(this.level ? this.level.name : '');
    }

    this.gender = this.model && this.model.id ? this.model.kid.gender : 'M';
    this.setMinMaxDates();
    if(!this.level) {
      this.setLevel();
    }
  }

  setSkills(skillDefId: number) : SelectListItem {
    if(!this.model.skills || this.model.skills.length === 0) {
      return {} as SelectListItem;
    }

    const skill = this.model.skills.find((s) => s.skillDefId === skillDefId);
    if(skillDefId === 1) {
      return this.arabicSkills.find((s) => s.value === skill.skillAnswerId)
    }
    else {
      return this.quranSkills.find((s) => s.value === skill.skillAnswerId)
    }
  }

  setGender(gender: string){
    this.gender = gender;
  }
  setLevelValue(selectedLevelId: number){
    this.level = this.levels.find(x=> x.id == selectedLevelId);
  }


  setLevel() {
    let kidDob = moment(this.kidDob).clone().local();
    let compareDate =  moment().local();//moment(new Date(moment().year(), 8, 1)).local();//moment(`09-01-${moment().year()}`);
    let duration = moment.duration(compareDate.diff(kidDob));
    let age =  Math.round(duration.asYears());//compareDate.diff(moment(this.kidDob).clone().local(), 'years');

    if(age < 4 || age > 16) {
      this.level = null;
      if(this.schoolKidForm) {
        this.schoolKidForm.controls['level'].setValue('');
      }

      this.showDialog(`Age ${age} is not valid. Age must be from 4 to 16.`, 'error');
      return;
    }

    let level;
    switch (age){
      case 4:
        level = this.levels.find((l)=> l.name === 'Level-Pre KG');
        break;
      case 5:
        level = this.levels.find((l)=> l.name === 'Level-KG');
        break;
      case 6:
        level = this.levels.find((l)=> l.name === 'Level-1');
        break;
      case 7:
        level = this.levels.find((l)=> l.name === 'Level-2');
        break;
      case 8:
        level = this.levels.find((l)=> l.name === 'Level-3');
        break;
      case 9:
        level = this.levels.find((l)=> l.name === 'Level-4');
        break;
      case 10:
        level = this.levels.find((l)=> l.name === 'Level-5');
        break;
      case 11:
        level = this.levels.find((l)=> l.name === 'Level-6');
        break;
      case 12: case 13: case 14:
        level = this.levels.find((l)=> l.name === 'Level-7');
        break;
      case 15: case 16: case 17:
          level = this.levels.find((l)=> l.name === 'Level-8');
          break;
    }
    if(!level) {
      this.level = null;
      if(this.schoolKidForm) {
        this.schoolKidForm.controls['level'].setValue('');
      }
      this.showDialog(`Invalid age ${age}`, 'error');
      return;
    }

    if(!level.available) {
      this.level = null;
      if(this.schoolKidForm) {
        this.schoolKidForm.controls['level'].setValue('');
      }
      this.showDialog(`Sorry ${level.name} is not available, please try again next year.`, 'error');
      return;
    }
    this.level = level;
    this.selectedLevel = this.level ? this.levelList.find(x=> x.value == this.level.id) : this.levelList[0];
    this.startDate = this.kidDob;
    if(this.schoolKidForm) {
      this.schoolKidForm.controls['level'].setValue(this.level ? this.level.name : '');
    }


  }

  setArabicSkill(skillId: Skill) {
  this.arabicSkill = this.arabicSkills.find((s)=> s.value === skillId);
  }

  setQuranSkill(skillId: Skill) {
    this.quranSkill = this.quranSkills.find((s)=> s.value === skillId);
  }

  submit(){
    const newKid = this.setRequestObject();
    if(this.model.id) {
      this.editKid.emit(newKid);
    } else {
      this.addKid.emit(newKid);
    }
    this.resetForm();
  }

  setRequestObject(): KidRequest {
    let dob = this.schoolKidForm.controls['dob'].value|| null;
    //let currentMonth =  moment().clone().local().month() + 1; // months are 0 index based.
    return {
      id: this.model.id ? this.model.id : null,
      schoolParentId: this.model.schoolParentId,
      memberId: this.memberId,
      kidId : this.model.kidId,
      email: this.schoolKidForm.controls['kidEmail'].value || null,
      remove: false,
      firstName: this.schoolKidForm.controls['kidFirstName'].value || null,
      lastName: this.schoolKidForm.controls['kidLastName'].value || null,
      gender: this.gender,
      dob: moment(dob).clone().local(),
      age: null,
      relationship: '',
      kidLevel: {
        id: this.model.id  && this.model.kidLevel ? this.model.kidLevel.id : null,
        levelId: this.level.id,
        active: true,
      },
      kidSkills: {
        skills: [] = [ {
          id: this.model.id && this.model.skills != null && this.model.skills.length > 0 ? this.model.skills.find((s) => s.skillDefId === 1).id : null,
          skillAnswerId: this.arabicSkill.value
        }, {
          id: this.model.id && this.model.skills != null && this.model.skills.length > 0 ? this.model.skills.find((s) => s.skillDefId === 2).id : null,
          skillAnswerId: this.quranSkill.value
        }]
      }
    } as KidRequest;
  }

  onCancelNewKid() {
    this.resetForm();
    this.cancelKid.emit(true);
  }

  showDialog(message: string, type: string) {
    if(!message) {
      return;
    }
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 10000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: type,
      },
    });
  }

  setMinMaxDates() {
    let minYear = moment().clone().local().subtract(17, 'years').year();
    let maxYear = moment().clone().local().subtract(4, 'years').year();
    let month = 8;  // months are zero based
    let day = 1;
    this.minDate = new Date(minYear, month, day);
    this.maxDate = new Date(maxYear, month, day);
    if(this.kidDob) {
      if(moment(this.kidDob).isAfter(moment(this.maxDate))) {
        this.kidDob = this.maxDate;
      }
    }
  }

  resetForm() {
    this.schoolKidForm.controls['kidFirstName'].reset();
    this.schoolKidForm.controls['kidLastName'].reset();
    this.schoolKidForm.controls['kidEmail'].reset();
    this.schoolKidForm.controls['dob'].reset();
    this.schoolKidForm.controls['level'].reset();
    this.quranSkill = this.quranSkills[this.quranSkills.length-1];
    this.arabicSkill = this.arabicSkills[0];
    this.selectedLevel = null;
  }

  subtractMonths(date, months) {
    date.setMonth(date.getMonth() - months);
    return date;
  }

}
