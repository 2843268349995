import { UserUpdate } from './../models/user-update';
import { ApplicationUser } from './../models/application-user';

import { Injectable } from '@angular/core';
import { NewUser } from './../models/new-user';
import { GenericDataService } from './generic-data.service';
import { Observable, observable, throwError, of, Subject } from 'rxjs';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { map } from 'rxjs/operators';
import { ListRequest } from '../models/list-request';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  users: ApplicationUser[];
  user: ApplicationUser;
  updateUser: UserUpdate;
  constructor(private genericDataService: GenericDataService) { }

  public registerUser(newUser: NewUser): any {
    this.genericDataService.endPoint = 'user/Register';
    const registerObservable$ = new Observable(subscriber => {
      this.genericDataService.post<NewUser>(newUser).subscribe(data => {
        const result = data;
        subscriber.next(result);
      });
    });
    return registerObservable$;
  }

  public userExists(email: string): Observable<boolean> {
    this.genericDataService.endPoint = 'User/Exists';
    return (this.genericDataService.readById<boolean>(null, email)).pipe();
  }

  public emailExistsValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null);
      }
      return this.userExists(control.value).pipe(
        map(res => {
          return (res ? { emailExists: true } : null);
        })
      );
    };
  }

  public userList(request: ListRequest): any {
    this.genericDataService.endPoint = 'User/List';

    const listObservable$ = new Observable(observer => {
      this.genericDataService.post<any>(request).subscribe(data => {
        this.users = Object.assign([], data);
        observer.next(this.users);
      });
    });
    return listObservable$;
  }

  public userGet(id: string): any {
    this.genericDataService.endPoint = 'User';
    const userObservable$ = new Observable(subscriber => {
      this.genericDataService.readById<ApplicationUser>(null, id).subscribe(data => {
        this.user = Object.assign({}, data);
        subscriber.next(this.user);
      });
    });
    return userObservable$;
  }

  public userUpdate(request: any): any {
    this.genericDataService.endPoint = 'User/Update';
    const updateObservable$ = new Observable(subscriber => {
      this.genericDataService.create<UserUpdate>(null, request).subscribe(data => {
        this.updateUser = Object.assign(data);
        subscriber.next(this.updateUser);
      });
    });
    return updateObservable$;
  }


}
