import { Component, OnInit } from '@angular/core';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-nikah',
  templateUrl: './nikah.component.html',
  styleUrls: ['./nikah.component.scss']
})
export class NikahComponent implements OnInit {

  constructor() { }
  faArrowAltCircleRight = faArrowAltCircleRight;
  ngOnInit() {
  }

}
