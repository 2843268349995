export class TenantCustomPayment {
  tenantId: number;
  amount: number;
  paymentDate: any;
  paymentId: string;
  paymentTypeId: number;
  paymentMonth: number;
  paymentYear: number;
  description: string;
  rentId: number;
}
