import { Directive, HostListener  } from '@angular/core';

@Directive({
  selector: '[appCurrency]'
})
export class CurrencyDirective {
  specialKeys: Array<string> = ['Backspace', 'Tab', 'Delete', 'Enter', 'ArrowRight', 'ArrowLeft', '.'];
  currencyValidation = new RegExp('^\\d{0,18}(\\.\\d{1,2})?$');
  constructor() {

  }
  @HostListener('keydown', ['$event']) onKeyPress(event: KeyboardEvent) {

    if (this.specialKeys.includes(event.key) || event.key.match(this.currencyValidation)) {
      return true;
    } else {
      return false;
    }
  }
}
