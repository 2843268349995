import { ValidationError } from './validation-error';

export class QuranQuoteValidation {
  name: ValidationError;
  ayah: ValidationError;
  categoryId: ValidationError;
  translation: ValidationError;
  hasError: boolean;

  constructor(name: ValidationError, ayah: ValidationError, categoryId: ValidationError, translation: ValidationError, hasError: boolean) {
    this.name = name;
    this.ayah = ayah;
    this.categoryId = categoryId;
    this.translation = translation;
    this.hasError = hasError;
  }
}
