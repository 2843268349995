import { ValidationError } from './validation-error';
export class KeyFobValidation {
  keyFob: ValidationError;
  hasError: boolean;
  constructor(keyFob: ValidationError, hasError: boolean) {
    this.keyFob = keyFob;
    this.hasError = hasError;
  }
}

