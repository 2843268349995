import { Guarantor } from "./../../models/guarantor";
import { HelperService } from "src/app/services/helper.service";
import { SelectListItem } from "src/app/models/select-list-item";
import { TenantWaitService } from "../../services/tenant-wait.service";
import { TenantProfile } from "src/app/models/tenant-profile";
import { TenantWaitRequest } from "../../models/tenant-wait-request";
import { TenantWaitInfo } from "../../models/tenant-wait-info";
import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { SecurityService } from "src/app/services/security.service";
import { Router } from "@angular/router";
import { TenantProfileValidation } from "src/app/models/tenant-profile-validate";
import { ValidationError } from "src/app/models/validation-error";
import { MatDialog } from "@angular/material/dialog";
import * as _moment from "moment";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfirmationSnackbarComponent } from "../confirmation-snackbar/confirmation-snackbar.component";
import { UploadFile } from "src/app/models/upload-file";
import { AddFileRequest } from "src/app/models/add-file-request";
import { UploadDialogModel } from "src/app/models/upload-dialog-model";
import { UploadModalComponent } from "../upload-modal/upload-modal.component";
import { GuarantorValidation } from "src/app/models/guarantor-validation";
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY",
  },
  display: {
    dateInput: "MM-DD-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "MM-DD-YYYY",
    monthYearA11yLabel: "MM-DD-YYYY",
  },
};
@Component({
  selector: "app-wait-list-add",
  templateUrl: "./wait-list-add.component.html",
  styleUrls: ["./wait-list-add.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class WaitListAddComponent implements OnInit {
  waitInfo: TenantWaitInfo;
  waitRequest: TenantWaitRequest;
  profile: TenantProfile;
  memberId: number;
  signedIn$: Observable<boolean>;
  btnText: string;
  uploadedFiles: UploadFile[] = [];
  existingFiles: UploadFile[] = [];
  hasError: boolean;
  uploadError: boolean;
  roomTypes: SelectListItem[];
  selectedRoomType: SelectListItem;
  uploadFileModel: UploadDialogModel;
  showfileListDelete = false;
  showfileListTitle = false;
  displayError: boolean;
  guarantor: Guarantor;
  hasGuarantor: boolean;
  showRentBreakup: boolean;

  constructor(
    private tenantWaitService: TenantWaitService,
    private securityService: SecurityService,
    private router: Router,
    private snackBar: MatSnackBar,
    private helperService: HelperService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getInfo();
    this.getRoomTypes();
  }

  getInfo() {
    this.signedIn$ = this.securityService.isSignedIn();
    this.signedIn$.subscribe((result) => {
      if (result) {
        this.memberId = this.securityService.getMemberId();
        this.showRentBreakup = this.memberId ? true : false;
      } else {
        this.router.navigate(["/login"]);
      }
    });

    if (this.memberId) {
      this.tenantWaitService
        .tenantWaitGetByMember(this.memberId)
        .subscribe((data) => {
          if (data) {
            this.waitInfo = Object.assign({}, data);
            this.btnText = this.waitInfo.id ? "Update" : "Add";
            this.hasGuarantor = this.waitInfo.guarantor ? true : false;
            if (this.waitInfo.files && this.waitInfo.files.length > 0) {
              this.existingFiles = this.waitInfo.files.map((item) => ({
                id: item.fileId,
                name: item.uploadedFileName,
                fileType: item.fileType,
                fileByteString: "",
                progress: 100,
                size: 10000,
              }));
              this.uploadError = false;
            } else {
              this.uploadError = true;
              this.toggleError();
            }
          }
          this.setModel();
        });
    }
  }

  setModel() {
    this.selectedRoomType =
      this.waitInfo.roomType && this.waitInfo.roomType.length > 0
        ? this.roomTypes.find((r) => r.label === this.waitInfo.roomType)
        : this.selectedRoomType;
    const profileError =
      this.waitInfo.referenceName &&
      this.waitInfo.referenceEmail &&
      this.waitInfo.referencePhone
        ? false
        : true;
    this.profile = {
      firstName: this.waitInfo.memberInfo.firstName,
      lastName: this.waitInfo.memberInfo.lastName,
      age: this.waitInfo.memberInfo.age ? this.waitInfo.memberInfo.age : 18,
      isStudent: this.waitInfo.memberInfo.isStudent ? "true" : "false",
      residencyStatus: this.waitInfo.memberInfo.residencyStatus
        ? "true"
        : "false",
      refName: this.waitInfo.referenceName ? this.waitInfo.referenceName : "",
      refEmail: this.waitInfo.referenceEmail
        ? this.waitInfo.referenceEmail
        : "",
      refPhone: this.waitInfo.referencePhone
        ? this.waitInfo.referencePhone
        : "",
      gender: this.waitInfo.memberInfo.gender
        ? this.waitInfo.memberInfo.gender
        : "",
      validation: new TenantProfileValidation(
        new ValidationError(false),
        new ValidationError(false),
        new ValidationError(false),
        new ValidationError(false),
        profileError
      ),
    };
    this.waitInfo.tentativeStartDate =
      this.waitInfo && this.waitInfo.tentativeStartDate
        ? moment(this.waitInfo.tentativeStartDate).local()
        : moment().add(1, "M").startOf("month").local();
    this.waitInfo.roomType = this.selectedRoomType.label;
    this.uploadFileModel = {
      title: "Drag and drop verification document here",
      subTitle:
        "Valid passport or US driver license or Purdue Id or admission letter is required",
      btnText: "Browse",
      imageOnly: false,
      allowMultiple: true,
      hasError: false,
      errorMessage: null,
      showCancel: false,
      uploadedFiles: this.uploadedFiles,
    };
    this.setGuarantor();
    if (this.waitInfo.guarantor) {
      this.hasGuarantor = true;
      //this.guarantor = this.waitInfo.guarantor;
      this.guarantor.validation.hasError = this.gurantorValidation();
      this.hasError =
        this.profile.validation.hasError ||
        this.uploadError ||
        this.guarantor.validation.hasError;
    } else {
      this.hasError = this.profile.validation.hasError || this.uploadError;
    }
  }

  getProfile(profile: TenantProfile) {
    this.profile = Object.assign({}, profile);
    this.profile.validation.hasError =
      profile.validation.refName.hasError ||
      profile.validation.refEmail.hasError ||
      profile.validation.refPhone.hasError
        ? true
        : false;
    this.setRequest();
    if (this.hasGuarantor) {
      this.hasError =
        this.profile.validation.hasError ||
        this.uploadError ||
        this.guarantor.validation.hasError;
    } else {
      this.hasError = this.profile.validation.hasError || this.uploadError;
    }
  }

  setRequest() {
    this.waitRequest = {
      id: this.waitInfo.id ? this.waitInfo.id : null,
      memberId: this.memberId,
      age: this.profile.age,
      isStudent: this.profile.isStudent === "true" ? true : false,
      residencyStatus: this.profile.residencyStatus === "true" ? true : false,
      gender: this.profile.gender,
      referenceName: this.profile.refName,
      referenceEmail: this.profile.refEmail,
      referencePhone: this.profile.refPhone,
      tentativeStartDate: this.waitInfo.tentativeStartDate,
      roomType: this.waitInfo.roomType,
      guarantor: this.guarantor && this.guarantor.name ? this.guarantor : null,
      files:
        this.uploadedFiles && this.uploadedFiles.length > 0
          ? this.uploadedFiles.map((item) => ({
              tenantWaitId: this.waitInfo.id ? this.waitInfo.id : null,
              isImage: false,
              fileRequest: new AddFileRequest(
                "G",
                item.name,
                "",
                item.fileByteString,
                null
              ),
            }))
          : null,
    };
  }

  upsertWait() {
    this.setRequest();
    if (this.waitInfo.id) {
      this.tenantWaitService
        .tenantWaitUpdate(this.waitRequest)
        .subscribe((data) => {
          if (data) {
            this.waitInfo.id = data.id;
            this.waitRequest.id = data.id;
            this.showConfirmationDialog(
              "Your information is updated, we will send you an email, once your request is approved."
            );
          }
        });
    } else {
      this.tenantWaitService
        .tenantWaitAdd(this.waitRequest)
        .subscribe((data) => {
          if (data) {
            this.waitInfo.id = data.id;
            this.waitRequest.id = data.id;
            this.btnText = this.waitInfo.id ? "Update" : "Add";
            this.showConfirmationDialog(
              "You are added to tenant wait list, we will send you an email, once your request is approved."
            );
          }
        });
    }
  }

  showConfirmationDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 20000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: "success",
      },
    });
  }

  getRoomTypes() {
    this.roomTypes = this.helperService.roomTypesGet();
    this.selectedRoomType = this.roomTypes.find((r) => r.value === "A");
  }

  onRoomTypeSelection(option: any) {
    this.selectedRoomType = this.roomTypes.find(
      (r) => r.value === option.value
    );
    this.waitInfo.roomType = this.selectedRoomType.label;
  }

  openUploadFileDaialog(): void {
    const dialogRef = this.dialog.open(UploadModalComponent, {
      height: "580px",
      width: "740px",
      data: this.uploadFileModel,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data.uploadedFiles) {
        this.uploadedFiles = Object.assign([], data.uploadedFiles);
      }
      if (!this.uploadedFiles || this.uploadedFiles.length === 0) {
        this.uploadError = true;
        this.toggleError();
      } else {
        this.uploadError = false;
      }
      this.uploadFileModel.uploadedFiles = this.uploadedFiles;

      this.hasError = this.profile.validation.hasError || this.uploadError;
    });
  }

  toggleError(): void {
    this.displayError = true;
    setTimeout(
      function () {
        this.displayError = false;
      }.bind(this),
      3000
    );
  }

  gurantorValidation(): boolean {
    const gError = !this.waitInfo.guarantor
      ? true
      : this.waitInfo.guarantor.name &&
        this.waitInfo.guarantor.address &&
        this.waitInfo.guarantor.city &&
        this.waitInfo.guarantor.state &&
        this.waitInfo.guarantor.zip &&
        this.waitInfo.guarantor.email &&
        this.waitInfo.guarantor.phone
      ? false
      : true;

    return gError;
  }

  onGuarantorChange(event: any) {
    if (event.checked) {
      this.setGuarantor();
      this.guarantor.validation.hasError = this.gurantorValidation();
    } else {
      this.resetGuarantor();
      this.guarantor.validation.hasError = false;
    }
    this.hasError =
      this.profile.validation.hasError ||
      this.uploadError ||
      this.guarantor.validation.hasError;
  }

  getGuarantor(guarantor: Guarantor) {
    this.guarantor = Object.assign({}, guarantor);
    //this.guarantor.validation.hasError = this.gurantorValidation();
    this.hasError =
      this.profile.validation.hasError ||
      this.uploadError ||
      this.guarantor.validation.hasError;
  }

  setGuarantor() {
    const guarantorError = !this.waitInfo.guarantor ? true : false;
    this.guarantor = {
      id: !this.waitInfo.guarantor ? null : this.waitInfo.guarantor.id,
      name: !this.waitInfo.guarantor ? "" : this.waitInfo.guarantor.name,
      email: !this.waitInfo.guarantor ? "" : this.waitInfo.guarantor.email,
      phone: !this.waitInfo.guarantor ? "" : this.waitInfo.guarantor.phone,
      address: !this.waitInfo.guarantor ? "" : this.waitInfo.guarantor.address,
      city: !this.waitInfo.guarantor ? "" : this.waitInfo.guarantor.city,
      zip: !this.waitInfo.guarantor ? "" : this.waitInfo.guarantor.zip,
      state: !this.waitInfo.guarantor ? "IN" : this.waitInfo.guarantor.state,
      initials: "",
      signatureDate: null,
      validation: new GuarantorValidation(
        new ValidationError(false),
        new ValidationError(false),
        new ValidationError(false),
        new ValidationError(false),
        new ValidationError(false),
        new ValidationError(false),
        guarantorError
      ),
    };
  }
  resetGuarantor() {
    this.guarantor = {
      id: null,
      name: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      zip: "",
      state: "",
      initials: "",
      signatureDate: null,
      validation: new GuarantorValidation(
        new ValidationError(false),
        new ValidationError(false),
        new ValidationError(false),
        new ValidationError(false),
        new ValidationError(false),
        new ValidationError(false),
        false
      ),
    };
  }
}
