import { ValidationError } from "./validation-error";
export class RoomValidation {
  name: ValidationError;
  occupancy: ValidationError;
  hasError: boolean;
  constructor(
    name: ValidationError,
    occupancy: ValidationError,
    hasError: boolean
  ) {
    this.name = name;
    this.occupancy = occupancy;
    this.hasError = hasError;
  }
}
