import { GuarantorValidation } from './guarantor-validation';

export class Guarantor {
  id: number;
  name: string;
  phone: string;
  email: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  initials: string;
  signatureDate: any;
  validation: GuarantorValidation;

  constructor(id: number, name: string, phone: string, email: string, address: string, city: string,
    state: string, zip: string, initials: string, signatureDate: any, validation: GuarantorValidation) {
    this.id = id;
    this.name = name;
    this.phone = phone;
    this.email = email;
    this.address = address;
    this.city = city;
    this.state = state;
    this.zip = zip;
    this.initials = initials;
    this.signatureDate = signatureDate;
    this.validation = validation;
  }
}
