import { RegInfo } from './../../models/school/reg-info';
import { Component, Input, OnInit } from '@angular/core';
import { KidModel } from 'src/app/models/school/kid-model';
import * as _moment from "moment";
const moment = _moment;
@Component({
  selector: 'app-ieb-reg-detail',
  templateUrl: './ieb-reg-detail.component.html',
  styleUrls: ['./ieb-reg-detail.component.scss']
})
export class IebRegDetailComponent implements OnInit {
  @Input() regModel : RegInfo;
  infoText: string;
  displayedColumns: string[] = [
    "name",
    "level",
  ];
  constructor() { }

  ngOnInit() {
    if(this.regModel) {
      let kidsNumber =  this.regModel.kidRegistrations.length;
      let childText = kidsNumber > 1 ? 'children are' : 'child is';
      let regDate = moment(this.regModel.registrationDate).clone().local().format('MM-DD-YYYY');

      this.infoText = `${this.regModel.parentFirstName} following ${childText} registered on ${regDate}`;
    }
  }

}
