import { VehicleValidation } from "./vehicle-validation";

export class VehicleRequest {
  id: number;
  name: string;
  model: string;
  year: string;
  licensePlate: string;
  tenantId: number;
  parkingSpaceId: number;
  validation: VehicleValidation;

  constructor(
    id: number,
    name: string,
    model: string,
    year: string,
    licensePlate: string,
    tenantId: number,
    parkingSpaceId: number,
    validation: VehicleValidation
  ) {
    this.id = id;
    this.name = name;
    this.model = model;
    this.year = year;
    this.licensePlate = licensePlate;
    this.tenantId = tenantId;
    this.parkingSpaceId = parkingSpaceId;
    this.validation = validation;
  }
}
