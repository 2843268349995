import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  errorRoute: string;
  errorMessage: string;

  constructor(private router: Router) { }

  ngOnInit() {
    // this.errorRoute = this.router.url.replace('/', '');
    this.errorRoute = this.router.url.substr(1, 3);
    console.log(this.errorRoute);
    this.setErrorMessage(this.errorRoute);
  }

  setErrorMessage(errorCode: string) {
    if (errorCode === '401') {
      this.errorMessage = 'You are not authorized to access this page.';
    }
  }
}
