import { TenantService } from "src/app/services/tenant.service";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
} from "@angular/core";
import { TenantInfo } from "src/app/models/tenant-info";
import { MatDialog, MatSnackBar } from "@angular/material";
import { ConfirmationSnackbarComponent } from "../confirmation-snackbar/confirmation-snackbar.component";
import { Subscription } from "rxjs";
import * as _moment from "moment";
const moment = _moment;
@Component({
  selector: "app-late-fee-waiver",
  templateUrl: "./late-fee-waiver.component.html",
  styleUrls: ["./late-fee-waiver.component.scss"],
})
export class LateFeeWaiverComponent implements OnInit, OnChanges {
  @Input() tenantInfo: TenantInfo;
  @Output() notifyLateFeeWaiver = new EventEmitter();
  LateFeeWaiverRemove$: Subscription;
  disableDelete: boolean;
  disableSaveWaiver: boolean;
  nextPaymentMonth: string;
  nextPaymentYear: number;
  constructor(
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private tenantService: TenantService
  ) {}

  ngOnInit() {
    this.LateFeeWaiverRemove$ =
      this.tenantService.lateFeeWaiverRemove$.subscribe((res) => {
        if (res) {
          this.tenantInfo.paymentSummary.lateFeeWaiver = null;
          this.disableSaveWaiver = false;
          this.notifyLateFeeWaiver.emit(this.tenantInfo);
        }
      });
  }
  ngOnChanges(): void {
    if (
      this.tenantInfo.paymentSummary &&
      this.tenantInfo.paymentSummary.nextPaymentDate
    ) {
      let nextPaymentDate = moment(
        this.tenantInfo.paymentSummary.nextPaymentDate
      ).clone();
      this.nextPaymentMonth = nextPaymentDate.format("MMMM");
      this.nextPaymentYear = nextPaymentDate.year();
      this.disableSaveWaiver = this.tenantInfo.paymentSummary.lateFeeWaiver
        ? true
        : false;
    }
  }

  saveWaiver() {
    const request = {
      id: 0,
      tenantId: this.tenantInfo.id,
      month:
        moment(this.tenantInfo.paymentSummary.nextPaymentDate).clone().month() +
        1,
      year: this.nextPaymentYear,
    };
    this.tenantService.lateFeeWaiver(request).subscribe((data) => {
      this.tenantInfo.paymentSummary.lateFeeWaiver = Object.assign(data);
      this.notifyLateFeeWaiver.emit(this.tenantInfo);
      this.disableSaveWaiver = true;
      this.showConfirmationDialog("Late Fee waiver is saved.");
    });
  }

  deleteFeeWaiver() {
    if (!this.tenantInfo.paymentSummary.lateFeeWaiver) {
      return;
    }
    if (this.disableDelete) {
      return;
    }
    let dismissedByAction = false;
    this.disableDelete = true;
    const deleteSnackBar = this.snackBar.openFromComponent(
      ConfirmationSnackbarComponent,
      {
        horizontalPosition: "center",
        duration: 10000,
        panelClass: "delete-snack-bar",
        data: {
          undoDelete: () => {
            dismissedByAction = true;
            const undoSnackBar = this.snackBar.openFromComponent(
              ConfirmationSnackbarComponent,
              {
                horizontalPosition: "center",
                duration: 10000,
                panelClass: "delete-snack-bar",
                data: {
                  dismissSnackbar: () => {
                    this.snackBar.dismiss();
                    this.disableDelete = false;
                  },
                  displayText: "Undone!",
                  type: "message",
                },
              }
            );
            this.disableDelete = false;
          },
          dismissSnackbar: () => {
            dismissedByAction = true;
            this.tenantService
              .lateFeeWaiverRemove(
                this.tenantInfo.paymentSummary.lateFeeWaiver.id
              )
              .subscribe();
            this.snackBar.dismiss();
            this.disableDelete = false;
          },
          displayText: `Late Fee Waiver deleted`,
          type: "delete-kid",
        },
      }
    );
    // delete the kid if the snackbar closes due to duration and not closed by user.
    deleteSnackBar.afterDismissed().subscribe((info) => {
      if (info.dismissedByAction === false && dismissedByAction === false) {
        this.tenantService
          .lateFeeWaiverRemove(this.tenantInfo.paymentSummary.lateFeeWaiver.id)
          .subscribe();
        this.disableDelete = false;
      }
    });
  }

  showConfirmationDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 20000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: "success",
      },
    });
  }
}
