import { Injectable } from '@angular/core';
import { GenericDataService } from './generic-data.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class RoleService {
  roles: string[];
  constructor(private genericDataService: GenericDataService) { }

  public roleList(): any {
    this.genericDataService.endPoint = 'Role/Roles';

    const listObservable$ = new Observable(subscriber => {
      this.genericDataService.list<any>(null, null).subscribe(data => {
        this.roles = Object.assign([], data);
        subscriber.next(this.roles);
      });
    });
    return listObservable$;
  }

}
