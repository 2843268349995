import { TenantPaymentSummary } from "./../../models/tenant-payment-summary";
import { Component, OnInit } from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from "rxjs";
import { AddFileRequest } from "src/app/models/add-file-request";
import { LeaseCreate } from "src/app/models/lease-create";
import { LeaseFile } from "src/app/models/lease-file";
import { LeaseSignature } from "src/app/models/lease-signature";
import { StripeTenantPayment } from "src/app/models/stripe-tenant-payment";
import { TenantInfo } from "src/app/models/tenant-info";
import { LeaseService } from "src/app/services/lease.service";
import { SecurityService } from "src/app/services/security.service";
import { TenantService } from "src/app/services/tenant.service";
import { ConfirmationSnackbarComponent } from "../confirmation-snackbar/confirmation-snackbar.component";
import { SignatureModalComponent } from "../signature-modal/signature-modal.component";
import { StripePaymentModalComponent } from "../stripe-payment-modal/stripe-payment-modal.component";
import { faMosque, faSignature } from "@fortawesome/free-solid-svg-icons";
import { ConfirmDialogModel } from "src/app/models/confirm-dialog-model";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import * as _moment from "moment";
import { Lease } from "src/app/models/lease";
import { File } from "src/app/models/file";
import { mergeMap } from "rxjs/operators";
import { StripePayment } from "src/app/models/stripe-payment";
const moment = _moment;
@Component({
  selector: "app-lease-update-tenant",
  templateUrl: "./lease-update-tenant.component.html",
  styleUrls: ["./lease-update-tenant.component.scss"],
})
export class LeaseUpdateTenantComponent implements OnInit {
  tenant: TenantInfo;
  memberId: number;
  signedIn$: Observable<boolean>;
  amountTotal: string;
  amountDue: number;
  amountPaid: string;
  roomName: string;
  minDate: any;
  parkingFee: string;
  signee: string = "Tenant";
  tenantInitials: string;
  tenantSignature: AddFileRequest;
  hasError: boolean = true;
  hasSigError: boolean;
  public leaseSource: string;
  showTenantInfo: boolean = true;
  rentAmount: string;
  securityDeposit: string;
  firstMonthRent: string;
  paymentCollected: boolean;
  model: LeaseCreate = new LeaseCreate();
  faMosque = faMosque;
  faSignature = faSignature;
  currentLease: LeaseFile;
  tenantVerificationToken: string;
  handler: any = null;
  stripePayment: StripeTenantPayment;
  card: any;
  notLoading = true;
  paymentSummary: TenantPaymentSummary;
  lease: Lease;
  tenantSigFile: File;
  dueText: string;
  paidText: string;
  showPaid: boolean;
  constructor(
    private tenantService: TenantService,
    private securityService: SecurityService,
    private router: Router,
    private leaseService: LeaseService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.signedIn$ = this.securityService.isSignedIn();
    this.getTenant();
  }
  getTenant() {
    this.signedIn$.subscribe((result) => {
      if (result) {
        this.memberId = this.securityService.getMemberId();
        if (this.memberId) {
          this.tenantService
            .tenantGetByMember(this.memberId)
            .subscribe((data) => {
              this.tenant = Object.assign({}, data);
              if (!this.tenant.leaseInfo) {
                this.router.navigate(["/index"]);
              }

              if (this.tenant && this.tenant.roomInfo) {
                this.roomName = `${this.tenant.roomInfo.name} ${this.tenant.roomInfo.description}`;
                this.setTotals();
              }
              if (this.tenant) {
                if (this.tenant.leaseInfo) {
                  this.getTenantSig();
                  if (this.tenant.leaseInfo.statusId === 2) {
                    this.leaseService
                      .leaseCurrentGet(this.tenant.leaseInfo.id)
                      .subscribe((response) => {
                        this.leaseSource = response.file
                          ? response.file.url
                          : null;
                        this.showTenantInfo = this.leaseSource ? false : true;
                      });
                  } else if (this.tenant.leaseInfo.statusId < 6) {
                    // redirect to new tanant page
                    this.router.navigate(["/tenant"]);
                  }
                }
              }
            });
        }
      } else {
        this.router.navigate(["/login"]);
      }
    });
  }

  setTotals() {
    this.rentAmount = `$${this.tenant.roomRent.amount}`;
    this.securityDeposit = !this.tenant.leaseInfo.securityDeposit
      ? "$0"
      : `$${this.tenant.leaseInfo.securityDeposit}`;
    const secDeposit = !this.tenant.securityDeposit
      ? 0
      : this.tenant.securityDeposit;
    const firstRent =
      this.tenant.leaseInfo.prorateAmount &&
      this.tenant.leaseInfo.prorateAmount > 0
        ? this.tenant.leaseInfo.prorateAmount
        : this.tenant.roomRent.amount;
    this.firstMonthRent = `$${firstRent}`;
    this.parkingFee = this.tenant.parkingSpaceRent
      ? `$${this.tenant.parkingSpaceRent.amount}`
      : "$0";
    this.amountDue = this.tenant.paymentSummary.amountDue;
    this.amountTotal = `$${this.amountDue}`;
    this.amountPaid = `$${this.tenant.paymentSummary.amountPaid}`;
    this.paymentCollected = this.amountDue > 0 ? false : true;
    if (this.amountDue >= 0) {
      this.dueText = `Due: $${this.amountDue}`;
    } else {
      if (this.tenant.leaseInfo.statusId === 10) {
        this.paymentCollected = true;
        this.dueText = `Due: $${this.amountDue}`;
      }
      if (this.amountDue < 0) {
        this.dueText = `Credit: $${-this.amountDue}`;
      }
      this.showPaid = false;
    }
  }

  getTenantSig() {
    this.leaseService.tenantSignatureGet(this.tenant.id).subscribe((res) => {
      if (res) {
        this.tenantSigFile = Object.assign(res);
      }
    });
  }

  openSignatureDialog(): void {
    const dialogRef = this.dialog.open(SignatureModalComponent, {
      height: "400px",
      width: "600px",
      data: {
        signee: this.signee,
        role: "member",
        hasError: false,
        errorMessage: null,
        showCancel: false,
        existingSig: this.tenantSigFile ? this.tenantSigFile : null,
        signeeName: `${this.tenant.memberInfo.firstName} ${this.tenant.memberInfo.lastName}`,
        useExisting: false,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((sigModel) => {
      if (!sigModel) {
        this.hasError = true;
        this.hasSigError = true;
        this.toggleError();
        return;
      }
      if (
        !sigModel.useExisting &&
        (!sigModel.signatureFile ||
          !sigModel.signatureFile.fileByteString ||
          sigModel.signatureFile.length === 0)
      ) {
        this.hasError = true;
        this.hasSigError = true;
        this.toggleError();
        return;
      }
      if (
        !sigModel.useExisting &&
        sigModel.signatureFile &&
        sigModel.signatureFile.fileByteString &&
        sigModel.signatureFile.fileByteString.length > 0
      ) {
        this.model.tenantSignature = new LeaseSignature();

        this.model.tenantSignature.newSignature = Object.assign(
          {},
          sigModel.signatureFile
        );
        this.model.tenantSignature.hasPrintSignature = sigModel.usePrint;
        this.hasError = false;
        this.hasSigError = false;
      }
      if (sigModel.useExisting) {
        this.model.tenantSignature = new LeaseSignature();
        this.model.tenantSignature.existingSignature = Object.assign(
          this.tenantSigFile
        );
        this.model.tenantSignature.hasPrintSignature = false;
        this.hasError = this.hasSigError = false;
      }
    });
  }
  toggleError(): void {
    setTimeout(
      function () {
        this.hasSigError = false;
      }.bind(this),
      3000
    );
  }

  processToken(payment: StripeTenantPayment) {
    if (payment.tokenId) {
      this.stripePayment.tokenId = payment.tokenId;
      this.savePayment();
    }
  }
  openStripeDialog() {
    let sPayment = {
      amount: this.amountDue,
      paymentDate: moment().local().clone(),
      tokenId: "",
      recipientEmail: this.tenant.memberInfo.email,
      title: "ISGL Tenant Portal",
      description: "First Rent",
      showCancel: false,
    } as StripePayment;

    const dialogRef = this.dialog.open(StripePaymentModalComponent, {
      width: "550px",
      height: "380px",
      data: sPayment,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult && dialogResult.tokenId) {
        this.stripePayment = {
          tenantId : this.tenant.id,
          amount: this.amountDue,
          paymentDate: moment().local().clone(),
          tokenId: dialogResult.tokenId,
          recipientEmail: this.tenant.memberInfo.email,
          rentMonth: moment().local().month() + 1, // month is zero based 0-11
          rentYear: moment().local().year(),
          title: "ISGL Tenant Portal",
          description: "First Rent",
          showCancel: false,
        };

        this.savePayment();
      }
    });
  }

  showConfirmationDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 20000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: "success",
      },
    });
  }
  openAlertDialog(dialogData: ConfirmDialogModel) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "500px",
      height: "300px",
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      //this.openStripe();
    });
  }

  savePayment() {
    this.notLoading = false;
    this.spinner.show();
    this.tenantService.tenantPayment(this.stripePayment).subscribe((data) => {
      if (data.paymentError) {
        const dialogData = new ConfirmDialogModel(
          "Payment Issue",
          `${data.paymentError.code}: ${data.paymentError.message}`,
          "alert",
          false
        );
        this.notLoading = true;
        this.spinner.hide();
        this.openAlertDialog(dialogData);

        return;
      }
      this.paidText = `Paid: $${this.amountDue}`;
      this.showPaid = true;
      this.paymentSummary = Object.assign({}, data);
      this.amountDue = this.paymentSummary.amountDue;
      this.paymentCollected = this.amountDue === 0 ? true : false;
      this.amountTotal = `$${this.amountDue}`;
      this.dueText = `Due: $${this.amountDue}`;
      this.notLoading = true;
      this.spinner.hide();
      this.showConfirmationDialog(
        "Your payment is saved, please continue to save your lease document"
      );
    });
  }

  reconcilePayments() {
    if (this.tenant.leaseInfo.statusId !== 9) {
      return;
    }
    this.tenantService
      .reconcilePayments(this.tenant.id)
      .pipe(
        mergeMap((res) => {
          if (res) {
            return this.leaseService.leaseDoc(this.model);
          }
        })
      )
      .subscribe((response) => {
        if (response) {
          if (response.file) {
            this.leaseSource = response.file.url;
            this.showTenantInfo = false;
          }
        }
        this.notLoading = true;
        this.spinner.hide();
      });
  }

  createLease() {
    this.notLoading = false;
    this.spinner.show();
    this.model.tenantId = this.tenant.id;
    this.model.leaseDate = this.tenant.leaseInfo.leaseDate;
    this.model.startDate = this.tenant.leaseInfo.startDate;
    this.model.endDate = this.tenant.leaseInfo.endDate;
    this.model.rentId = this.tenant.leaseInfo.rentId;
    this.model.prorateAmount = this.tenant.leaseInfo.prorateAmount;
    this.model.securityDeposit = this.tenant.leaseInfo.securityDeposit;
    this.model.tenantSignatureDate = moment().clone().local();
    this.model.tenantInitials = this.tenantInitials;
    if (this.tenant.leaseInfo.statusId === 9) {
      this.reconcilePayments();
    } else {
      this.leaseService.leaseDoc(this.model).subscribe((response) => {
        if (response) {
          if (response.file) {
            this.leaseSource = response.file.url;
            this.showTenantInfo = false;
          }
        }
        this.notLoading = true;
        this.spinner.hide();
      });
    }
  }

  leaseConfirmation() {
    this.notLoading = false;
    this.spinner.show();
    this.lease = {
      id: this.tenant.leaseInfo.id,
      name: this.tenant.leaseInfo.name,
      startDate: this.tenant.leaseInfo.startDate,
      endDate: this.tenant.leaseInfo.endDate,
      autoRenewal: this.tenant.leaseInfo.autoRenewal,
      prorateAmount: this.tenant.leaseInfo.prorateAmount,
      securityDeposit: this.tenant.leaseInfo.securityDeposit,
      securityDepositCollected: this.tenant.leaseInfo.securityDepositCollected,
      tenantId: this.tenant.id,
      roomId: this.tenant.roomInfo.id,
      rentId: this.tenant.leaseInfo.rentId,
      statusId: 3,
      leaseDate: moment().local(),
    };

    this.leaseService.leaseConfirm(this.lease).subscribe((data) => {
      this.notLoading = true;
      this.spinner.hide();
      if (data) {
        if (data.statusId === 3) {
          this.router.navigate(["/tenant"]);
        }
      }
    });
  }

  confirmLease() {
    this.leaseConfirmation();
  }

  cancel() {
    this.showTenantInfo = true;
    this.leaseSource = null;
  }
}
