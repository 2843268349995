import { StudentForm } from '../models/school/student-form';
import { combineLatest, observable } from 'rxjs';
import { SearchTeacherResult } from './../models/school/search-teacher-result';
import { TeacherSummary } from './../models/school/teacher-summary';
import { Observable, BehaviorSubject, of, Subject, merge } from 'rxjs';
import { Injectable, Pipe } from '@angular/core';
import { GenericDataService } from './generic-data.service';
import { concatMap, map, scan, share, switchMap } from 'rxjs/operators';
import { Action } from '../models/stream-action';
import { KidRegSummary } from '../models/school/kid-reg-summary';
import * as _moment from "moment";
import { ListRequest } from '../models/list-request';
import { DEFAULT_LIMIT, DEFAULT_PAGE } from '../models/table-defaults';
import { ListResponse } from '../models/list-response';
import { SelectListItem } from '../models/select-list-item';
import { StudentDetailForm } from '../models/school/student-detail-form';
import { StudentDetails } from '../models/school/student-details';
import { StudentUpdateRequest } from '../models/school/student-update-request';
import { StudentsForm } from '../models/school/students-form';
import { ManageSemester } from '../models/school/manage-semester';
import { StudentsDownloadRequest } from '../models/school/students-download-request';
import { StudentsDownload } from '../models/school/students-download';
import { ReducedFeeKid } from '../models/school/reduced-fee-kid';
import { ReducedFeeKidSearchResult } from '../models/school/reduced-fee-kid-search-result';
import { RegMemberSearchResult } from '../models/school/reg-member-search-result';

const moment = _moment;
@Injectable({
  providedIn: 'root'
})
export class SchoolManageService {

teachersSource$ = this.getTeachers();
private memberSearchBS = new BehaviorSubject<string>('');
membersSource$ = this.memberSearchBS.pipe(
  switchMap((query) => {
    if(!query) {
     return of ([] as SearchTeacherResult[]) ;
    }
    return this.findMembers(query);
  })
);

private teacherActionSubject = new Subject<Action<TeacherSummary>>();
teacherAction$ = this.teacherActionSubject.asObservable();
teachers$ = merge(this.teachersSource$, this.teacherAction$.pipe(
  concatMap((request) => this.processTeacherAction(request)))
  ).pipe(
      scan((acc, value) => (value instanceof Array ? [...value] : this.modifyTeacherStream(acc, value)), [] as TeacherSummary[])
);

defaultListRequest : ListRequest = {
  filter : {
    query: '',
    semester: '',
    levelId: null
  },
  pageNumber: DEFAULT_PAGE,
  itemsPerPage: DEFAULT_LIMIT
}

studentsForm$ = this.getStudentsForm().pipe(share());
private studentsBS = new BehaviorSubject<ListRequest>({});
students$ = this.studentsBS.pipe(
  switchMap((request) => {
    if(!request.pageNumber || !request.itemsPerPage) {
      request = this.defaultListRequest;
    }
    if(!request.filter.semester) {
     let month = moment().clone().local().month() + 1;
     let year = moment().clone().local().year();
     request.filter.semester = month > 5 ? `Fall ${year}` : `Spring ${year}`;
    }
    return this.getStudents(request);
  })
);

private  studentDetailsBS = new BehaviorSubject<number|null>(null);
studentDetails$ = this.studentDetailsBS.pipe(
  switchMap((schoolKidId) => {
  if(!schoolKidId) {
    return of({} as StudentDetails)
  }

  return this.getStudentDetails(schoolKidId);
}));

studentForm$ = this.getStudentForm().pipe(share());

studentDetailsForm$ = combineLatest([this.studentDetails$, this.studentForm$]).pipe(
  map(([studentDetails, form]) => {
    const model : StudentForm = {
      studentDetails: studentDetails,
      studentForm: form
    };
    return model;
  })
);
semestersSource$ = this.getSemesters().pipe();
private semestersActionSubject = new Subject<Action<ManageSemester>>();
  /** The exposed parent action observable merged with the CRUD stream */
  semestersAction$ = this.semestersActionSubject.asObservable();

  semesters$ = merge(this.semestersSource$, this.semestersAction$.pipe(
    concatMap((response) => this.semestersAction(response)))).pipe(
      scan((acc: ManageSemester, value: Action<ManageSemester>) => {
       return this.modifySemestersStream(acc , value) as ManageSemester
      })
  );

  private semesterMessageSubject = new Subject<boolean>();
  semesterMessage$ = this.semesterMessageSubject.asObservable();

  reducedFeeKidsSource$ = this.getReducedFeeKids();
  private reducedFeeKidSearchBS = new BehaviorSubject<string>('');
  reducedFeeKidSearch$ = this.reducedFeeKidSearchBS.pipe(
    switchMap((query) => {
      if(!query) {
      return of ([] as ReducedFeeKidSearchResult[]) ;
      }
      return this.findKids(query);
    })
  );

private reducedFeeKidActionSubject = new Subject<Action<ReducedFeeKid>>();
reducedFeeKidAction$ = this.reducedFeeKidActionSubject.asObservable();
reducedFeeKids$ = merge(this.reducedFeeKidsSource$, this.reducedFeeKidAction$.pipe(
  concatMap((request) => this.reducedFeeKidAction(request)))
  ).pipe(
      scan((acc, value) => (value instanceof Array ? [...value] : this.modifyReducedFeeKid(acc, value)), [] as ReducedFeeKid[])
);

private regMemberSearchBS = new BehaviorSubject<string>('');
regMemberSource$ = this.regMemberSearchBS.pipe(
  switchMap((query) => {
    if(!query) {
     return of ([] as RegMemberSearchResult[]) ;
    }
    return this.findMembersForReg(query);
  })
);

constructor(private genericDataService: GenericDataService) { }

memberSearch(query: string) : void {
  this.memberSearchBS.next(query);
}


semesterChange(request: ListRequest) : void {
  this.studentsBS.next(request);
}
levelChange(request: ListRequest) : void {
  this.studentsBS.next(request);
}

studentSorting(request: ListRequest) : void {
  this.studentsBS.next(request);
}

studentPaging(request: ListRequest) : void{
  this.studentsBS.next(request);
}
studentSearch(request: ListRequest) : void{
  this.studentsBS.next(request);
}

studentSelected(schoolKidId: number) : void {
  this.studentDetailsBS.next(schoolKidId);
}

addNewTeacher(request: TeacherSummary): void {
  this.teacherActionSubject.next({ item: request, action: 'add' });
}

editTeacher(request: TeacherSummary): void {
  this.teacherActionSubject.next({ item: request, action: 'update' });
}

newSemester(request: ManageSemester): void {
  this.semestersActionSubject.next({item: request, action: 'add'});
 }

 semesterUpdate(request: ManageSemester): void {
  this.semestersActionSubject.next({item: request, action: 'update'});
 }
 semesterMessageToggle(toggle: boolean) {
  this.semesterMessageSubject.next(toggle);
 }

 kidSearch(query: string) : void {
  this.reducedFeeKidSearchBS.next(query);
}
regMemberSearch(query: string) : void {
  this.regMemberSearchBS.next(query);
}

addReducedFeeKid(request: ReducedFeeKid): void {
  this.reducedFeeKidActionSubject.next({ item: request, action: 'add' });
}
editReducedFeeKid(request: ReducedFeeKid): void {
  this.reducedFeeKidActionSubject.next({ item: request, action: 'update' });
}


getTeachers() : Observable<TeacherSummary[]> {
  return this.genericDataService.readWithEndpoint<TeacherSummary[]>('SchoolManage/teachers', null);
}

getStudents(request: ListRequest) : Observable<ListResponse<KidRegSummary>> {
  this.genericDataService.endPoint = "SchoolManage/kids/reg";
  return this.genericDataService.post<ListResponse<KidRegSummary>>(request);
}

findMembers(query: string): Observable<SearchTeacherResult[]> {
  return this.genericDataService.readWithEndpoint<SearchTeacherResult[]>('SchoolManage/teacher/Search/member', query);
}

getStudentsForm() : Observable<StudentsForm> {
  return this.genericDataService.readWithEndpoint<StudentsForm>('SchoolManage/Students/Form').pipe(
    map((response) => this.mapStudentsForm(response)));
}

downloadStudents(request: StudentsDownloadRequest): Observable<StudentsDownload[]> {
  this.genericDataService.endPoint = "SchoolManage/Students/download";
  return this.genericDataService.post<StudentsDownload[]>(request);
}

getStudentForm() : Observable<StudentDetailForm> {
  return this.genericDataService.readWithEndpoint<StudentDetailForm>('SchoolManage/Student/Form').pipe(
    map((response) => this.mapLists(response)));
}

getStudentDetails(schoolKidId: number) : Observable<StudentDetails> {
  return this.genericDataService.readWithEndpoint<StudentDetails>('SchoolManage/Student', schoolKidId);
}

studentUpdate(request:StudentUpdateRequest) : Observable<StudentDetails> {
  this.genericDataService.endPoint = "SchoolManage/Student/Update";
  return this.genericDataService.put<StudentDetails>(request);
}

getSemesters(): Observable<ManageSemester> {
  return this.genericDataService.readWithEndpoint<ManageSemester>('SchoolManage/Semesters');
}

getReducedFeeKids() : Observable<ReducedFeeKid[]> {
  return this.genericDataService.readWithEndpoint<ReducedFeeKid[]>('SchoolManage/ReducedFee/Kids', null);
}

findKids(query: string): Observable<ReducedFeeKidSearchResult[]> {
  return this.genericDataService.readWithEndpoint<ReducedFeeKidSearchResult[]>('SchoolManage/ReducedFee/Kid/Search', query);
}

findMembersForReg(query: string): Observable<RegMemberSearchResult[]> {
  return this.genericDataService.readWithEndpoint<RegMemberSearchResult[]>('SchoolManage/Registration/Member/Search', query);
}

semestersAction(manage: Action<ManageSemester>) : Observable<Action<ManageSemester>> {
  this.genericDataService.endPoint = "SchoolManage/Semesters/Upsert";
  switch(manage.action){
    case 'add':
      return this.genericDataService.put<Action<ManageSemester>>(manage.item).pipe(
        map((response: any) => ({ item: response, action: 'add' } as Action<ManageSemester>))
      );
    case 'update':

      return this.genericDataService.put<Action<ManageSemester>>(manage.item).pipe(
        map((response: any) => ({ item: response, action: 'update' } as Action<ManageSemester>))
      );
    default:
      return of({} as Action<ManageSemester>);
  }
 }



processTeacherAction(action: Action<TeacherSummary>) : Observable<Action<TeacherSummary>> {
  this.genericDataService.endPoint = "SchoolManage/teacher/upsert";
  switch (action.action) {
    case 'add':
      return this.genericDataService.post<TeacherSummary>(action.item).pipe(
        map((teacher) => ( { item: teacher, action: action.action } as Action<TeacherSummary>))
      );
    case 'update':
      return this.genericDataService.post<TeacherSummary>(action.item).pipe(
        map((teacher) => ({ item: teacher, action: action.action } as Action<TeacherSummary>))
      );
    default:
      return of({} as Action<TeacherSummary>);
  }
}

reducedFeeKidAction(action: Action<ReducedFeeKid>) : Observable<Action<ReducedFeeKid>> {
  this.genericDataService.endPoint = "SchoolManage/ReducedFee/Kid/Upsert";
  switch (action.action) {
    case 'add':
      return this.genericDataService.post<ReducedFeeKid>(action.item).pipe(
        map((teacher) => ( { item: teacher, action: action.action } as Action<ReducedFeeKid>))
      );
    case 'update':
      return this.genericDataService.post<ReducedFeeKid>(action.item).pipe(
        map((teacher) => ({ item: teacher, action: action.action } as Action<ReducedFeeKid>))
      );
    default:
      return of({} as Action<ReducedFeeKid>);
  }
}

modifyTeacherStream(response: TeacherSummary[], operation: Action<TeacherSummary>): TeacherSummary[] {
  if (operation!.action === 'add') {
   return  [...response, operation.item];

  } else if (operation.action === 'update') {
    return response.map((teacher) => (teacher.id === operation.item.id ? operation.item : teacher));

  }
  return response;
}

modifySemestersStream(semesters: ManageSemester, operation: Action<ManageSemester>): ManageSemester {
  if (operation.action === 'add' || operation.action === 'update') {
    this.semesterMessageToggle(true);
    return operation.item;
  }
  return semesters;
}

modifyReducedFeeKid(response: ReducedFeeKid[], operation: Action<ReducedFeeKid>): ReducedFeeKid[] {
  if (operation!.action === 'add') {
   return  [...response, operation.item];

  } else if (operation.action === 'update') {
    return response.map((rkid) => (rkid.id === operation.item.id ? operation.item : rkid));

  }
  return response;
}

mapStudentsForm(response: StudentsForm) : StudentsForm {
  let semesterList: SelectListItem[] = [];
  semesterList = response.semesters.filter(f=> f.name === 'Semester School Fee').map((fee) => ({
    label : fee.description,
    value: fee.description,
    disabled: false
  } as SelectListItem));

  response.semesterList = semesterList;
  let levelList: SelectListItem[] = [];
  //levelList.push({label: 'Select', value: 0, disabled: false});
  levelList = response.levels.map((level) => ({
    label : level.name,
    value: level.id,
    disabled: false
  } as SelectListItem));
  levelList.unshift({label: 'Levels', value: -1, disabled: false} as SelectListItem );
  response.levelList = levelList;

  let downloadList: SelectListItem[] = [];
  downloadList = response.semesters.map((fee) => ({
    label : `Download ${fee.description}`,
    value: fee.id,
    disabled: false
  } as SelectListItem));

  downloadList.unshift({label: 'Download csv', value: -1, disabled: false} as SelectListItem );
  response.downloadList = downloadList;
  return response;
}

mapLists(response: StudentDetailForm): StudentDetailForm {
  let levelList : SelectListItem[];
  levelList = response.levels.map(
    (level) => ({
      label: level.label,
      value: level.id,
      disabled: false
    })
  );
  response.levelList = levelList;

  let arabicList: SelectListItem[];
  arabicList = response.arabicSkills.map(
    (skill) => ({
      label: skill.answer,
      value: skill.id,
      disabled: false
    })
  );
  response.arabicSkillList = arabicList;
  let quarnList: SelectListItem[];
  quarnList = response.quranSkills.map(
    (skill) => ({
      label: skill.answer,
      value: skill.id,
      disabled: false
    })
  );
  response.quranSkillList = quarnList;
  return response;
}

}
