import { SelectListItem } from "./../models/select-list-item";
import { Injectable } from "@angular/core";
import * as _moment from "moment";
import { MultiSelectItem } from "../models/multi-select-item";

const moment = _moment;

@Injectable({
  providedIn: "root",
})
export class HelperService {
  private states: SelectListItem[];
  private birthYears: SelectListItem[];
  private birthMonths: SelectListItem[];
  private genderList: SelectListItem[];
  private relationShips: SelectListItem[];
  private validityInMonths: SelectListItem[];
  private membershipYears: SelectListItem[];
  private booleanList: SelectListItem[];
  private vehicleYears: SelectListItem[];
  private tenantLeaseDurations: SelectListItem[];
  private roomTypes: SelectListItem[];
  private rentTypes: SelectListItem[];
  private roomOccupancy: SelectListItem[];
  private serviceCategories: SelectListItem[];
  private serviceLocations: SelectListItem[];
  private paymentTypes: SelectListItem[];
  private paymentModes: SelectListItem[];
  private paymentYears: SelectListItem[];
  private primaryContact: MultiSelectItem[];

  constructor() {}

  public statesGet(): any {
    this.states = [];
    this.states.push(
      new SelectListItem("Select", "", true),
      new SelectListItem("AL", "AL", false),
      new SelectListItem("AK", "AK", false),
      new SelectListItem("AR", "AR", false),
      new SelectListItem("CA", "CA", false),
      new SelectListItem("CO", "CO", false),
      new SelectListItem("CT", "CT", false),
      new SelectListItem("DE", "DE", false),
      new SelectListItem("FL", "FL", false),
      new SelectListItem("GA", "GA", false),
      new SelectListItem("HI", "HI", false),
      new SelectListItem("ID", "ID", false),
      new SelectListItem("IL", "IL", false),
      new SelectListItem("IN", "IN", false),
      new SelectListItem("IA", "IA", false),
      new SelectListItem("KS", "KS", false),
      new SelectListItem("KY", "KY", false),
      new SelectListItem("LA", "LA", false),
      new SelectListItem("ME", "ME", false),
      new SelectListItem("MD", "MD", false),
      new SelectListItem("MA", "MA", false),
      new SelectListItem("MI", "MI", false),
      new SelectListItem("MN", "MN", false),
      new SelectListItem("MS", "MS", false),
      new SelectListItem("MO", "MO", false),
      new SelectListItem("MT", "MT", false),
      new SelectListItem("NE", "NE", false),
      new SelectListItem("NV", "NV", false),
      new SelectListItem("NH", "NH", false),
      new SelectListItem("NJ", "NJ", false),
      new SelectListItem("NM", "NM", false),
      new SelectListItem("NY", "NY", false),
      new SelectListItem("NC", "NC", false),
      new SelectListItem("ND", "ND", false),
      new SelectListItem("OH", "OH", false),
      new SelectListItem("OK", "OK", false),
      new SelectListItem("OR", "OR", false),
      new SelectListItem("PA", "PA", false),
      new SelectListItem("RI", "RI", false),
      new SelectListItem("SD", "SD", false),
      new SelectListItem("TN", "TN", false),
      new SelectListItem("TX", "TX", false),
      new SelectListItem("UT", "UT", false),
      new SelectListItem("VT", "VT", false),
      new SelectListItem("VA", "VA", false),
      new SelectListItem("WA", "WA", false),
      new SelectListItem("WV", "WV", false),
      new SelectListItem("WI", "WI", false),
      new SelectListItem("WY", "WY", false)
    );

    return this.states;
  }

  public birthYearsGet(forKids: boolean) {
    let count = 0;
    const limit = forKids ? 17 : 100;
    this.birthYears = [];
    this.birthYears.push(new SelectListItem("Select", "", true));
    while (count < limit) {
      const dt = moment().subtract(count, "years");
      this.birthYears.push(
        new SelectListItem(moment(dt).format("YYYY"), moment(dt).year(), false)
      );
      count++;
    }

    return this.birthYears;
  }

  public birthMonthsGet() {
    this.birthMonths = [];
    this.birthMonths.push(
      new SelectListItem("Select", "", true),
      new SelectListItem("January", 1, false),
      new SelectListItem("February", 2, false),
      new SelectListItem("March", 3, false),
      new SelectListItem("April", 4, false),
      new SelectListItem("May", 5, false),
      new SelectListItem("June", 6, false),
      new SelectListItem("July", 7, false),
      new SelectListItem("August", 8, false),
      new SelectListItem("September", 9, false),
      new SelectListItem("October", 10, false),
      new SelectListItem("November", 11, false),
      new SelectListItem("December", 12, false)
    );
    return this.birthMonths;
  }

  public genderListGet() {
    this.genderList = [];
    this.genderList.push(
      new SelectListItem("Select", "", true),
      new SelectListItem("Female", "F", false),
      new SelectListItem("Male", "M", false)
    );
    return this.genderList;
  }

  public gendersGet() {
    this.genderList = [];
    this.genderList.push(
      new SelectListItem("Female", "F", false),
      new SelectListItem("Male", "M", false)
    );
    return this.genderList;
  }

  public relationshipListGet() {
    this.relationShips = [];
    this.relationShips.push(
      new SelectListItem("Select", "", true),
      new SelectListItem("Father", "Father", false),
      new SelectListItem("Mother", "Mother", false),
      new SelectListItem("Guardian", "Guardian", false)
    );
    return this.relationShips;
  }

  public validityInMonthsListGet() {
    this.validityInMonths = [];
    let count = 1;
    const limit = 240;
    this.validityInMonths.push(new SelectListItem("Select", null, true));
    while (count <= limit) {
      this.validityInMonths.push(new SelectListItem(`${count}`, count, false));
      count++;
    }
    return this.validityInMonths;
  }

  public membershipYearsGet() {
    this.membershipYears = [];
    this.membershipYears.push(new SelectListItem("Select", null, true));
    let currentYear = moment().year();
    const limit = 2050;
    while (currentYear <= limit) {
      const nextYear = currentYear + 1;
      this.membershipYears.push(
        new SelectListItem(
          `${currentYear}-${nextYear}`,
          `${currentYear}-${nextYear}`,
          false
        )
      );
      currentYear++;
    }
    return this.membershipYears;
  }

  public booleanListGet() {
    this.booleanList = [];
    this.booleanList.push(
      new SelectListItem("Select", "", true),
      new SelectListItem("Yes", "true", false),
      new SelectListItem("No", "false", false)
    );
    return this.booleanList;
  }

  public vehicleYearsGet() {
    let count = 0;
    const limit = 40;
    this.vehicleYears = [];
    this.vehicleYears.push(new SelectListItem("Select", "", true));
    while (count < limit) {
      const dt = moment().subtract(count, "years");
      this.vehicleYears.push(
        new SelectListItem(
          moment(dt).format("YYYY"),
          moment(dt).format("YYYY"),
          false
        )
      );
      count++;
    }

    return this.vehicleYears;
  }

  public tenantLeaseDurationsGet() {
    this.tenantLeaseDurations = [];
    this.tenantLeaseDurations.push(
      new SelectListItem("Select", "", true),
      new SelectListItem("1 year", 12, false),
      new SelectListItem("9 months", 9, false),
      new SelectListItem("6 months", 6, false),
      new SelectListItem("3 months", 3, false),
      new SelectListItem("2 months", 2, false),
      new SelectListItem("1 months", 1, false)
    );
    return this.tenantLeaseDurations;
  }

  public roomTypesGet() {
    this.roomTypes = [];
    this.roomTypes.push(
      new SelectListItem("Any", "A", false),
      new SelectListItem("Single Occupancy", "S", false),
      new SelectListItem("Double Occupancy", "D", false)
    );
    return this.roomTypes;
  }

  public rentTypesGet() {
    this.rentTypes = [];
    this.rentTypes.push(
      new SelectListItem("Room", "Room", false),
      new SelectListItem("Parking", "Parking", false),
      new SelectListItem("Fine", "Fine", false)
    );
    return this.rentTypes;
  }

  public roomOccupancyGet() {
    this.roomOccupancy = [];
    this.roomOccupancy.push(
      new SelectListItem("Single", "1", false),
      new SelectListItem("Double", "2", false)
    );
    return this.roomOccupancy;
  }

  public mapLeaseStatus(code: string): string {
    let retValue: string;
    switch (code) {
      case "PNDP":
        retValue = "Pending Payment";
        break;
      case "PNDC":
        retValue = "Pending TEN Confm";
        break;
      case "PNDS":
        retValue = "Pending IEB Sig";
        break;
      case "ACTV":
        retValue = "Active";
        break;
      case "EXPR":
        retValue = "Expired";
        break;
      case "PNDT":
      case "PNDPP":
      case "PNDPA":
        retValue = "Pending Lease Upd";
        break;
    }
    return retValue;
  }

  public serviceCategoriesGet() {
    this.serviceCategories = [];
    this.serviceCategories.push(
      new SelectListItem("Appliances", "Appliances", false),
      new SelectListItem("Cleaning", "Cleaning", false),
      new SelectListItem("Electrical", "Electrical", false),
      new SelectListItem("Internet", "Internet", false),
      new SelectListItem("HVAC", "HVAC", false),
      new SelectListItem("Lawn", "Lawn", false),
      new SelectListItem("Plumbing", "Plumbing", false),
      new SelectListItem("Roofing", "Roofing", false)
    );
    return this.serviceCategories;
  }

  public serviceLocationsGet() {
    this.serviceLocations = [];
    this.serviceLocations.push(
      new SelectListItem("Bathroom", "Bathroom", false),
      new SelectListItem("Basement", "Basement", false),
      new SelectListItem("CommonArea", "CommonArea", false),
      new SelectListItem("Kitchen", "Kitchen", false),
      new SelectListItem("Stairs", "Stairs", false),
      new SelectListItem("MainHall", "MainHall", false),
      new SelectListItem("OuterHall", "OuterHall", false),
      new SelectListItem("Office", "Office", false),
      new SelectListItem("Outside", "Outside", false)
    );
    return this.serviceLocations;
  }

  public paymentTypesGet() {
    this.paymentTypes = [];
    this.paymentTypes.push(
      new SelectListItem("Room Rent", 1, false),
      new SelectListItem("Security Deposit", 3, false)
    );
    return this.paymentTypes;
  }

  public paymentModesGet(modeCash?:boolean ) {
    this.paymentModes = [];
    this.paymentModes.push(
      new SelectListItem("Bank Check", "Check", false),
      new SelectListItem("DBT", "DBT", false)
    );
    if(modeCash) {
      this.paymentModes.push(new SelectListItem("Cash", "Cash", false));
    }
    return this.paymentModes;
  }

  public paymentYearsGet() {
    let count = 0;
    const limit = 3;
    this.paymentYears = [];

    while (count < limit) {
      const dt = moment().add(1, "years").subtract(count, "years");
      this.paymentYears.push(
        new SelectListItem(moment(dt).format("YYYY"), moment(dt).year(), false)
      );
      count++;
    }

    return this.paymentYears;
  }

  public primaryContactGet() {
    this.primaryContact = [];
    this.primaryContact.push(
      { label: "Father", value: "Father", parentValue: "Father", disabled: false, checked: true },
      { label: "Mother", value: "Mother", parentValue: "Mother", disabled: false, checked: false }
    );
    return this.primaryContact;
  }
}
