import { FileValidation } from './file-validation';
export class File {
  id: number;
  access: string;
  uploadedFileName: string;
  storedFileName: string;
  fileType: string;
  thumbNail: string;
  url: string;
  validation: FileValidation;
}
