import { ValidationError } from './validation-error';

export class VehicleValidation {
  name: ValidationError;
  model: ValidationError;
  year: ValidationError;
  licensePlate: ValidationError;
  hasError: boolean;

  constructor(name: ValidationError, model: ValidationError, year: ValidationError,
    licensePlate: ValidationError, hasError: boolean) {
    this.name = name;
    this.model = model;
    this.year = year;
    this.licensePlate = licensePlate;
    this.hasError = hasError;
  }
}
