import { LateFeeWaiver } from "./late-fee-waiver";
import { TenantPaymentInfo } from "./tenant-payment-info";
import { TenantGroupedPayment } from "./tenant-grouped-payment";
export class TenantPaymentSummary {
  tenantId: number;
  amountPaid: number;
  amountDue: number;
  message: string;
  nextPaymentDate: any;
  nextPaymentAmount: number;
  lateFeeWaiver: LateFeeWaiver;
  payments: TenantPaymentInfo[];
  groupedPayments: TenantGroupedPayment[];
}
