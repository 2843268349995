import { LeaseSignature } from "./lease-signature";

export class LeaseCreate {
  tenantId: number;
  leaseDate: any;
  startDate: any;
  endDate: any;
  rentId: number;
  roomId: number;
  prorateAmount: number;
  securityDeposit: number;
  tenantInitials: string;
  tenantSignature: LeaseSignature;
  tenantSignatureDate: any;
  hasPrintSignature: boolean;
}
