import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-confirmation-snackbar',
  templateUrl: './confirmation-snackbar.component.html',
  styleUrls: ['./confirmation-snackbar.component.scss']
})
export class ConfirmationSnackbarComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data) { }

  ngOnInit() {
  }

  undo() {
    if (this.data.undoDelete()) {
      this.data.undoDelete();
    }
  }

  dismiss() {
    this.data.dismissSnackbar();
  }

  delete() {
    if (this.data.delete()) {
      this.data.delete();
    }
  }

}
