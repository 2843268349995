export class UploadFile {
  id: number;
  name: string;
  fileType: string;
  fileByteString: string;
  progress: number;
  size: number;


  constructor(id: number, fileName: string, fileType: string,
    fileByteString: string, progress: number, size: number) {
    this.id = id;
    this.name = fileName;
    this.fileType = fileType;
    this.fileByteString = fileByteString;
    this.progress = progress;
    this.size = size;
  }
}

