export class KeyFobRequest {
  memberId: number;
  firstName: string;
  lastName: string;
  email: string;

  constructor(memberId: number, firstName: string, lastName: string, email: string) {
    this.memberId = memberId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
  }
}

