import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { ConfigService } from "./config.service";

@Injectable({
  providedIn: "root",
})
export class GenericDataService {
  public endPoint: string;

  constructor(private http: HttpClient, private configService: ConfigService) {}
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
      // APIKey: this.configService.config.apiKey,
    }),
    params: new HttpParams(),
  };

  // List
  public list<T>(model: T | any, id: number = null): Observable<T | T[]> {
    let url = `${this.configService.config.apiBaseUrl}/${this.endPoint}`;
    if (id != null) {
      url += `/${id}`;
    }
    return this.http
      .get<T>(url, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  public listByPost<T>(request: T | any): Observable<T | T[]> {
    return this.http
      .post<T | T[]>(
        `${this.configService.config.apiBaseUrl}/${this.endPoint}`,
        request,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  // Post
  public post<T>(request: T | any): Observable<T | T> {
    return this.http
      .post<T | T>(
        `${this.configService.config.apiBaseUrl}/${this.endPoint}`,
        request,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  // Read
  public read<T>(id: number): Observable<T | T[]> {
    return this.http
      .get<T>(
        `${this.configService.config.apiBaseUrl}/${this.endPoint}/${id}`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  public readById<T>(model: T | any, id: any): Observable<T> {
    const url = !id
      ? `${this.configService.config.apiBaseUrl}/${this.endPoint}`
      : `${this.configService.config.apiBaseUrl}/${this.endPoint}/${id}`;
    return this.http
      .get<T>(url, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  public readByIds<T>(model: T | any, id: any, id2: any): Observable<T> {
    const url =
      !id && !id2
        ? `${this.configService.config.apiBaseUrl}/${this.endPoint}`
        : `${this.configService.config.apiBaseUrl}/${this.endPoint}/${id}/${id2}`;
    return this.http
      .get<T>(url, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  public readByIdsEnpoint<T>(
    endPoint: string,
    id: any,
    id2: any
  ): Observable<T> {
    const url =
      !id && !id2
        ? `${this.configService.config.apiBaseUrl}/${endPoint}`
        : `${this.configService.config.apiBaseUrl}/${endPoint}/${id}/${id2}`;
    return this.http
      .get<T>(url, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  public readWithEndpoint<T>(
    endPoint: string,
    id?: number | string
  ): Observable<T> {
    return this.http
      .get<T>(
        id
          ? `${this.configService.config.apiBaseUrl}/${endPoint}/${id}`
          : `${this.configService.config.apiBaseUrl}/${endPoint}`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  public create<T>(model: T | any, objToCreate: T | any): Observable<T | T[]> {
    return this.http
      .post<T | T[]>(
        `${this.configService.config.apiBaseUrl}/${this.endPoint}`,
        objToCreate,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  // Put
  public put<T>(request: T | any): Observable<T | T> {
    return this.http
      .put<T | T>(
        `${this.configService.config.apiBaseUrl}/${this.endPoint}`,
        request,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  // Update
  public update<T>(model: T | any, objToUpdate: T | any): Observable<T | T[]> {
    return this.http
      .put<T | T[]>(
        `${this.configService.config.apiBaseUrl}/${this.endPoint}/${objToUpdate.id}`,
        objToUpdate,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  // Delete
  public delete<T>(model: T | any, id: any): Observable<T | T[]> {
    return this.http
      .delete<T | T[]>(
        `${this.configService.config.apiBaseUrl}/${this.endPoint}/${id}`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  // Download
  public download<T>(obj: T | any): Observable<any> {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     "Content-Type": "application/json; charset=utf-8",
    //     Accept: "application/json",
    //   }),
    //   responseType: "blob" as "json",
    //   params: new HttpParams(),
    // };

    return this.http
      .post<Blob>(
        `${this.configService.config.apiBaseUrl}/${this.endPoint}`,
        obj,
        { responseType: "blob" as "json" }
      )
      .pipe(catchError(this.handleError));
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);
  }
}
