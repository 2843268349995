import { MemberInfo } from './member-info';
import { MemberUpdateValidation } from './member-update-validation';
export class MemberUpdate {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  cellPhone: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  gender: string;
  residencyStatus: boolean;
  isStudent: boolean;
  isAlumni: boolean;
  validation: MemberUpdateValidation;
  birthMonth: number;
  birthYear: number;

  constructor(memberInfo?: MemberInfo) {
    this.id = memberInfo && memberInfo.id || 0;
    this.email = memberInfo && memberInfo.email || '';
    this.firstName = memberInfo && memberInfo.firstName || '';
    this.lastName = memberInfo && memberInfo.lastName || '';
    this.cellPhone = memberInfo && memberInfo.cellPhone || '';
    this.address = memberInfo && memberInfo.address || '';
    this.city = memberInfo && memberInfo.city || '';
    this.state = memberInfo && memberInfo.state || '';
    this.zip = memberInfo && memberInfo.zip || '';
    this.gender = memberInfo && memberInfo.gender || '';
    this.residencyStatus = memberInfo && memberInfo.residencyStatus || false;
    this.isStudent = memberInfo && memberInfo.isStudent || false;
    this.isAlumni = memberInfo && memberInfo.isAlumni || false;
    this.birthMonth = null;
    this.birthYear = null;
  }


}
