import { File } from "./file";
export class SignatureDialogModel {
  signee: string;
  role: string;
  hasError: boolean;
  errorMessage: string;
  showCancel: boolean;
  existingSig?: File;
  signeeName?: string;
  useExisting?: boolean;
}
