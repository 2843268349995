import { ConfigService } from './../../services/config.service';
import { QuranQuote } from './../../models/quran-quote';
import { EventService } from './../../services/event.service';
import { Component, OnInit } from '@angular/core';
import { faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';
import { Event } from './../../models/event';
import { EventFilters } from 'src/app/models/event-filters';
import { ListRequest } from 'src/app/models/list-request';
import * as _moment from 'moment';
import { interval, Subscription } from 'rxjs';
const moment = _moment;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  faArrowAltCircleDown = faArrowAltCircleDown;
  announcements: Event[];
  filters: EventFilters;
  listRequest: ListRequest;
  itemsPerPage = null;
  pageNumber = null;
  quote: QuranQuote;
  quoteSubscription: Subscription;


  constructor(private eventService: EventService, private configService: ConfigService) {
    this.getAnnouncements();
    this.getQuote();
    this.quoteSubscription = interval(10000).subscribe(() => {
      if (this.quote) {
        const dt = moment().local(true);
        const quoteDate = moment(this.quote.displayDate);
        if (dt.diff(quoteDate, 'days') > 0) {
          this.getQuote();
        }
      }
    });

  }

  ngOnInit() {
    // this.checkQuote();
  }

  goToBottom() {
    window.scrollTo(0, document.body.scrollHeight);
  }

  getAnnouncements() {
    this.filters = null;
    this.listRequest = {
      filter: this.filters,
      itemsPerPage: this.itemsPerPage,
      pageNumber: this.pageNumber
    };
    this.eventService.announcementList(this.listRequest).subscribe(data => {
      if (data) {
        this.announcements = [];
        this.announcements = Object.assign([], data);
        this.announcements.map(item => {
          this.setEventDisplayDate(item)
          if (item.eventFilesSummaryList && item.eventFilesSummaryList.length > 0) {
            item.eventFilesSummaryList.map(f => {
              f.thumbNail = `${this.configService.config.fileBaseUrl}${f.thumbNail}`;
              f.storedFileName = `${this.configService.config.fileBaseUrl}${f.storedFileName}`;
            })
          }
        });
      }
    });
  }

  setEventDisplayDate(item: Event): Event {
    if (item.eventDate) {
      const eventDateLocal = moment(item.eventDate).local();
      item.eDate = `${moment(eventDateLocal).format('MMMM Do YYYY')}
      (${moment(eventDateLocal).format('dddd')}) ${moment(eventDateLocal).format('h:mm a')}`;
    } else {
      item.eDate = '';
    }
    return item;
  }

  getQuote() {
    this.eventService.quranQuoteGet().subscribe(data => {
      this.quote = Object.assign({}, data);
      this.quote.displayDate = moment.utc(this.quote.displayDate).local().format('YYYY-MM-DD HH:mm:ss');
    });
  }


}
