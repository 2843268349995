import { TenantOverride } from './../../models/tenant-override';
import { TenantCustomPayment } from './../../models/tenant-custom-payment';
import { SelectListItem } from 'src/app/models/select-list-item';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TenantInfo } from 'src/app/models/tenant-info';
import { HelperService } from 'src/app/services/helper.service';
import { TenantService } from 'src/app/services/tenant.service';
import * as moment from 'moment';
import { ConfirmationSnackbarComponent } from '../confirmation-snackbar/confirmation-snackbar.component';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-tenant-payment-add',
  templateUrl: './tenant-payment-add.component.html',
  styleUrls: ['./tenant-payment-add.component.scss']
})
export class TenantPaymentAddComponent implements OnInit, OnChanges  {
  @Input('tenant') tenantInfo: TenantInfo;
  @Output() notifyParent = new EventEmitter();
  paymentModes: SelectListItem[];
  months: SelectListItem[];
  years: SelectListItem[];
  paymentTypes: SelectListItem[];
  showMonthYear: boolean = true;
  model: TenantCustomPayment = new TenantCustomPayment();
  amount: string;
  selectedMonth: SelectListItem;
  selectedYear: SelectListItem;
  currentRentOverride: TenantOverride;
  secDepOverride: TenantOverride;
  paymentId: string;
  selectedPaymentType: SelectListItem;
  selectedPaymentMode: SelectListItem;
  rentAmount: number;



  constructor(private helperService: HelperService, private tenantService: TenantService, private snackBar: MatSnackBar,) { }

  ngOnInit() {
    //this.getLists();
  }

  ngOnChanges() {
    this.getLists();
    let currentYear = moment().clone().local().year();
    let currentMonth = moment().clone().local().month() + 1;
    this.selectedPaymentType = this.paymentTypes[0];
    this.selectedPaymentMode = this.paymentModes[0];
    this.selectedMonth = this.months.find(m=> m.value === currentMonth);
    this.selectedYear = this.years.find(m=> m.value === currentYear);
    if(this.tenantInfo) {
      this.model.rentId = this.tenantInfo.rentId;
      this.model.paymentTypeId = 1; // initialize to Monthly Rent

      this.model.amount = this.tenantInfo.roomRent.amount;
      this.rentAmount = this.tenantInfo.roomRent.amount;
      if(this.tenantInfo.paymentSummary && this.tenantInfo.paymentSummary.nextPaymentDate) {
        //this.rentAmount = this.tenantInfo.paymentSummary.nextPaymentAmount;
        this.amount = this.tenantInfo.paymentSummary.nextPaymentAmount.toLocaleString();
        currentMonth = moment(this.tenantInfo.paymentSummary.nextPaymentDate).clone().local().month() + 1;
        currentYear = moment(this.tenantInfo.paymentSummary.nextPaymentDate).clone().local().year();
        this.selectedMonth = this.months.find(m=> m.value === currentMonth);
        this.selectedYear = this.years.find(y=> y.value === currentYear);
      }

      if(this.tenantInfo.overrides && this.tenantInfo.overrides.length > 0) {
        this.currentRentOverride = this.tenantInfo.overrides
        .find(o => o.paymentTypeId === 1 && o.month >= currentMonth && o.year >= currentYear);
        this.secDepOverride = this.tenantInfo.overrides
        .find(o => o.paymentTypeId === 3);
      }
      this.selectedMonth = this.currentRentOverride ?
      this.months.find(m=> m.value === this.currentRentOverride.month) :
      this.selectedMonth;
      this.selectedYear = this.currentRentOverride ?
      this.years.find(y=> y.value === this.currentRentOverride.year) :
      this.selectedYear;
      this.amount = this.currentRentOverride ?
      this.currentRentOverride.amount.toLocaleString() :
      this.amount;
      if(this.secDepOverride) {
        if(this.tenantInfo.paymentSummary && this.tenantInfo.paymentSummary.payments){
          let secDepPayments = this.tenantInfo.paymentSummary.payments.filter(p => p.paymentTypeId == 3);
          if(secDepPayments) {
            this.paymentTypes.find(o => o.value === 3).disabled = true;
          }
        }

      }

      // this.paymentTypes.forEach((item) => {
      //   if(item.value === 3) {
      //     item.disabled = !this.secDepOverride ;
      //   }
      // })
    }

  }

  getLists() {
    this.paymentModes = this.helperService.paymentModesGet();
    this.months = this.helperService.birthMonthsGet();
    this.years = this.helperService.paymentYearsGet();
    this.paymentTypes = this.helperService.paymentTypesGet();

  }

  setType(paymentTypeId: number) {
    //let typeId = parseInt(paymentTypeId);
    this.model.paymentTypeId = paymentTypeId;
    this.showMonthYear = this.model.paymentTypeId === 1;
    if(paymentTypeId === 3) {
      this.amount = this.secDepOverride ? this.secDepOverride.amount.toLocaleString() : this.amount;
    }
    this.selectedPaymentType.value = paymentTypeId;
  }

  setMode(paymentMode: string) {
    this.selectedPaymentMode = this.paymentModes.find(m => m.value === paymentMode);
  }

  onMonthChange(selectedMonth : number) {
    if(this.currentRentOverride) {
      this.amount = selectedMonth === this.currentRentOverride.month &&
      this.selectedYear.value === this.currentRentOverride.year ?
      this.currentRentOverride.amount.toLocaleString() : this.rentAmount.toLocaleString();
    }

  }
  onYearChange(selectedYear : number) {
    if(this.currentRentOverride) {
      this.amount = selectedYear ===
      this.currentRentOverride.year && this.selectedMonth.value === this.currentRentOverride.month ?
      this.currentRentOverride.amount.toLocaleString() : this.rentAmount.toLocaleString();
    }
  }
  disableSave() : boolean{
    return !this.paymentId ? true : false;
  }

  onSave() {
    this.model.rentId = this.tenantInfo.rentId;
    this.model.paymentTypeId = this.selectedPaymentType.value;
    this.model.amount = parseInt(this.amount);
    this.model.paymentMonth = this.selectedMonth.value;
    this.model.paymentYear = this.selectedYear.value;
    let mon = this.months.find(m => m.value === this.selectedMonth.value).label;
    let year = this.years.find(m => m.value === this.selectedYear.value).label;
    this.model.description = this.selectedPaymentType.value === 1 ? `Monthly Rent for the Month/Year  ${mon}/${year}` :
    `Security deposit` ;
    this.model.paymentId = this.paymentId;
    this.model.tenantId = this.tenantInfo.id;
    this.model.paymentDate = moment().local();
    this.tenantService.tenantCustomPayment(this.model).subscribe((res) => {
      if(res) {
        this.tenantInfo = Object.assign({}, res);
        this.notifyParent.emit(this.tenantInfo);
        this.showConfirmationDialog("Payment saved.")
      }

    });
  }

  showConfirmationDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 20000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: "success",
      },
    });
  }

}
