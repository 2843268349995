import { UserToken } from "./../../models/user-token";
import { SecurityService } from "./../../services/security.service";
import { Component, OnInit, OnChanges } from "@angular/core";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { User } from "oidc-client";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnChanges {
  faBars = faBars;
  showLogo = true;
  // display = true;
  signedInUser$: Observable<UserToken>;
  signedIn$: Observable<boolean>;
  userName = "";
  currentUser: UserToken;
  showManageTab = false;
  showTenantTab = false;
  constructor(
    private router: Router,
    private securityService: SecurityService
  ) {}

  ngOnInit() {
    // const currentRoute = this.router.url.replace('/', '');
    // if (currentRoute === 'register' || currentRoute === 'members') {
    //   this.display = false;
    // }
    this.signedIn$ = this.securityService.isSignedIn();
    this.signedIn$.subscribe((signedIn) => {
      if (signedIn) {
        this.signedInUser$ = this.securityService.user$;
        this.signedInUser$.subscribe((data) => {
          if (data) {
            this.currentUser = data;
            if (
              (this.currentUser &&
                this.currentUser.role &&
                this.currentUser.role === "Admin") ||
              this.currentUser.role === "TenantAdmin" ||
              this.currentUser.role === "ContentAdmin"
            ) {
              this.showManageTab = true;
            }
            if (this.currentUser && this.currentUser.role === "Tenant") {
              this.showTenantTab = true;
            }
          }
        });
      }
    });
  }

  ngOnChanges() {
    // this.signedIn$ = this.securityService.isSignedIn();
    // this.signedIn$.subscribe(signedIn => {
    //   if (signedIn) {
    //     this.signedInUser$ = this.securityService.user$;
    //     this.signedInUser$.subscribe();
    //   }
    // });
  }

  redirect(route: string) {
    //const currentRoute = this.router.url.replace('/', '');
    // this.display = false;
    this.router.navigate([route]);
    // if (currentRoute === route) {
    //   this.display = false;
    // } else {
    //   this.router.navigate([route]);
    // }
  }

  logout() {
    this.securityService.logout();
    this.router.navigate(["index"]);
  }
  loginUser() {
    this.router.navigate(["login"]);
  }
}
