import { ParkingSpaceInfo } from "./parking-space-info";
import { TenantInfo } from "./tenant-info";

export class VehicleInfo {
  id: number;
  name: string;
  model: string;
  year: string;
  licensePlate: string;
  isActive: boolean;
  tenantInfo: TenantInfo;
  parkingSpace: ParkingSpaceInfo;
}
