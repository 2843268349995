import { RoomRentUpdate } from "./../../models/room-rent-update";
import { RoomInfo } from "src/app/models/room-info";
import { RoomService } from "src/app/services/room.service";
import { ValidationError } from "./../../models/validation-error";
import { HelperService } from "src/app/services/helper.service";
import { SelectListItem } from "./../../models/select-list-item";
import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { RentService } from "src/app/services/rent.service";
import { Rent } from "src/app/models/rent";
import { RentValidation } from "src/app/models/rent-validation";
import { ConfirmationSnackbarComponent } from "../confirmation-snackbar/confirmation-snackbar.component";
import { MatSnackBar } from "@angular/material";
import { RoomFilters } from "src/app/models/room-filters";
import { ListRequest } from "src/app/models/list-request";
import { RentNew } from "src/app/models/rent-new";
import { RentNewValidation } from "src/app/models/rent-new-validation";

@Component({
  selector: "app-rents-manage",
  templateUrl: "./rents-manage.component.html",
  styleUrls: ["./rents-manage.component.scss"],
})
export class RentsManageComponent implements OnInit {
  //@Input() hideAdd: boolean;
  rentTypes: SelectListItem[];
  rentList: Rent[];
  defaultLabel = "Please select";
  selectedRent: Rent;
  showAddRent: boolean;
  newRent: RentNew;
  disableDelete: boolean;
  hideRoomList: boolean;
  roomTypes: SelectListItem[];
  rentRoomTypes: SelectListItem[];
  roomRentUpdate: RoomRentUpdate;
  constructor(
    private rentService: RentService,
    private helperService: HelperService,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getRents();
    this.getRentTypes();
    this.resetNewRent();
  }

  resetNewRent() {
    this.newRent = {
      type: "Room",
      name: "",
      amount: 0,
      amountdisplay: "",
      roomType: "",
      validation: new RentNewValidation(
        new ValidationError(false),
        new ValidationError(false),
        new ValidationError(true),
        true
      ),
    };
  }

  getRents() {
    this.rentService.rentList().subscribe((data) => {
      if (data) {
        this.rentList = Object.assign([], data);
        this.rentList.forEach((r) => {
          (r.roomType = r.name.includes("Single")
            ? "Single"
            : r.name.includes("Double")
            ? "Double"
            : r.type),
            (r.validation = new RentValidation(
              new ValidationError(false),
              new ValidationError(false),
              false
            ));
          r.amountdisplay = `$${r.amount}`;
        });
      }
    });
  }

  getRentTypes() {
    this.rentTypes = this.helperService.rentTypesGet();
    this.roomTypes = [];
    this.roomTypes.push(
      new SelectListItem("Select Room Type", "0", false),
      new SelectListItem("Single", "Single", false),
      new SelectListItem("Double", "Double", false)
    );
    this.rentRoomTypes = [];
    this.rentRoomTypes.push(
      new SelectListItem("Single", "Single", false),
      new SelectListItem("Double", "Double", false)
    );
  }

  setRentType(rentType: string, rentId: number) {
    this.selectedRent = this.rentList.find((r) => r.id === rentId);
    if (this.selectedRent) {
      this.selectedRent.type = rentType;
      // update
      this.updateRent();
    }
  }

  setRentRoomType(rentId: number, roomType: string) {
    this.selectedRent = this.rentList.find((r) => r.id === rentId);
    if (!this.selectedRent.editable) {
      return;
    }
    this.selectedRent.roomType = roomType;
    this.roomRentUpdate = {
      rentId: this.selectedRent.id,
      roomType: roomType,
    };
    this.updateRoomRent();
  }

  updateRent() {
    if (!this.selectedRent) {
      return;
    }

    this.rentService.rentUpdate(this.selectedRent).subscribe((data) => {
      if (data) {
        this.showConfirmationDialog("Rent updated.");
      }
    });
  }

  updateRoomRent() {
    if (!this.roomRentUpdate) {
      return;
    }
    this.rentService.roomRentUpdate(this.roomRentUpdate).subscribe((data) => {
      if (data) {
        this.showConfirmationDialog("Room Rent relation is updated.");
      }
    });
  }

  saveRent() {
    if (this.newRent.validation.hasError) {
      return;
    }
    this.rentService.rentAdd(this.newRent).subscribe((data) => {
      this.getRents();
      if (data) {
        this.showConfirmationDialog("New rent saved.");
        this.resetNewRent();
        this.showAddRent = false;
      }
    });
  }

  delete(rent: Rent) {
    if (this.disableDelete) {
      return;
    }
    let dismissedByAction = false;
    this.disableDelete = true;

    const index = this.rentList.indexOf(rent);
    this.rentList.splice(index, 1);
    const deleteSnackBar = this.snackBar.openFromComponent(
      ConfirmationSnackbarComponent,
      {
        horizontalPosition: "center",
        duration: 10000,
        panelClass: "delete-snack-bar",
        data: {
          undoDelete: () => {
            dismissedByAction = true;
            this.rentList.splice(index, 0, rent);
            this.cd.detectChanges();
            const undoSnackBar = this.snackBar.openFromComponent(
              ConfirmationSnackbarComponent,
              {
                horizontalPosition: "center",
                duration: 10000,
                panelClass: "delete-snack-bar",
                data: {
                  dismissSnackbar: () => {
                    this.snackBar.dismiss();
                    this.disableDelete = false;
                  },
                  displayText: "Undone!",
                  type: "message",
                },
              }
            );
            this.disableDelete = false;
          },
          dismissSnackbar: () => {
            dismissedByAction = true;
            this.rentService.rentRemove(rent.id).subscribe();
            this.snackBar.dismiss();
            this.disableDelete = false;
          },
          displayText: `${rent.name} deleted`,
          type: "delete-kid",
        },
      }
    );
    // delete the kid if the snackbar closes due to duration and not closed by user.
    deleteSnackBar.afterDismissed().subscribe((info) => {
      if (info.dismissedByAction === false && dismissedByAction === false) {
        this.rentService.rentRemove(rent.id).subscribe();
        this.disableDelete = false;
      }
    });
  }
  addRent() {
    this.showAddRent = true;
  }
  closeAddRent() {
    this.showAddRent = false;
    this.resetNewRent();
  }

  setNewRentType(rentType: string) {
    this.newRent.type = rentType;
    this.hideRoomList = this.newRent.type === "Room" ? false : true;
  }

  setNewRent(field: string, value: any) {
    if (field === "amount" && value.length > 0) {
      let amountArry = value.split("$");
      this.newRent.amount =
        amountArry.length > 1 ? amountArry[1] : amountArry[0];
      value = this.newRent.amount;
    }
    if (field == "roomType" && value === "0") {
      value = "";
    }
    this.newRent.validation.hasError = !this.validateNewRent(
      field,
      value,
      this.newRent,
      true
    );
    if (!this.newRent.validation.hasError) {
      this.newRent.amountdisplay = `$${this.newRent.amount}`;
      this.newRent.amount = this.newRent.amount
        ? parseInt(this.newRent.amount.toString())
        : 0;
    }
  }

  setValue(field: string, rentId: number, value: any) {
    this.selectedRent = this.rentList.find((r) => r.id === rentId);
    if (!this.selectedRent) {
      return;
    }
    if (field === "amount" && value.length > 0) {
      let amountArry = value.split("$");
      this.selectedRent.amount =
        amountArry.length > 1 ? amountArry[1] : amountArry[0];
      value = this.selectedRent.amount;
    }
    this.selectedRent.validation.hasError = !this.validateValue(
      field,
      value,
      this.selectedRent,
      false
    );
    if (!this.selectedRent.validation.hasError) {
      this.selectedRent.amountdisplay = `$${this.selectedRent.amount}`;
      this.selectedRent.amount = parseInt(this.selectedRent.amount.toString());
      this.updateRent();
    }
  }

  validateValue(
    field: string,
    value: any,
    rent: Rent,
    nameCheck: boolean
  ): boolean {
    let isValid = true;
    if (field === "name") {
      if (value.length === 0) {
        rent.validation.name.hasError = true;
        rent.validation.name.errorMessage = "Required";
        isValid = false;
      } else {
        if (nameCheck) {
          let exist = this.rentList.find(
            (r) => r.name.toLowerCase() === value.toString().toLowerCase()
          );
          if (exist) {
            rent.validation.name.hasError = true;
            rent.validation.name.errorMessage = "Exists!";
          } else {
            rent.validation.name.hasError = false;
          }
        } else {
          rent.validation.name.hasError = false;
        }
      }
    }
    if (field === "amount") {
      if (value.length === 0) {
        rent.validation.amount.hasError = true;
        rent.validation.amount.errorMessage = "Required";
        isValid = false;
      } else if (value === 0) {
        rent.validation.amount.hasError = true;
        rent.validation.amount.errorMessage = "Invalid";
        isValid = false;
      } else {
        rent.validation.amount.hasError = false;
      }
    }

    isValid =
      rent.validation.name.hasError || rent.validation.amount.hasError
        ? false
        : true;

    return isValid;
  }

  validateNewRent(
    field: string,
    value: any,
    rent: RentNew,
    nameCheck: boolean
  ): boolean {
    let isValid = true;
    if (field === "name") {
      if (value.length === 0) {
        rent.validation.name.hasError = true;
        rent.validation.name.errorMessage = "Required";
        isValid = false;
      } else {
        if (nameCheck) {
          let exist = this.rentList.find(
            (r) => r.name.toLowerCase() === value.toString().toLowerCase()
          );
          if (exist) {
            rent.validation.name.hasError = true;
            rent.validation.name.errorMessage = "Exists!";
          } else {
            rent.validation.name.hasError = false;
          }
        } else {
          rent.validation.name.hasError = false;
        }
      }
    }
    if (field === "amount") {
      if (value.length === 0) {
        rent.validation.amount.hasError = true;
        rent.validation.amount.errorMessage = "Required";
        isValid = false;
      } else if (value === "0") {
        rent.validation.amount.hasError = true;
        rent.validation.amount.errorMessage = "Invalid";
        isValid = false;
      } else {
        rent.validation.amount.hasError = false;
      }
    }

    if (field === "roomType" && rent.type === "Room") {
      if (value.length === 0) {
        rent.validation.roomType.hasError = true;
        rent.validation.roomType.errorMessage = "Required";
        isValid = false;
      } else {
        rent.roomType = value;
        rent.validation.roomType.hasError = false;
      }
    }
    if (rent.type === "Room") {
      isValid =
        rent.validation.name.hasError ||
        rent.validation.amount.hasError ||
        rent.validation.roomType.hasError
          ? false
          : true;
    } else {
      isValid =
        rent.validation.name.hasError || rent.validation.amount.hasError
          ? false
          : true;
    }

    return isValid;
  }

  showConfirmationDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 20000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: "success",
      },
    });
  }
}
