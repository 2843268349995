import { VehicleRequest } from "./../models/vehicle-request";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { GenericDataService } from "./generic-data.service";
import { VehicleInfo } from "../models/vehicle-info";

@Injectable({
  providedIn: "root",
})
export class VehicleService {
  public vehicleSubject$ = new Subject<boolean>();
  vehicleRequest: VehicleRequest;
  vehicle: VehicleInfo;
  vehicles: VehicleInfo[];

  constructor(private genericDataService: GenericDataService) {}

  public vehicleList(): any {
    this.genericDataService.endPoint = "Vehicle/List";
    const listObservable$ = new Observable((subscriber) => {
      this.genericDataService.list<any>(null, null).subscribe((data) => {
        this.vehicles = Object.assign([], data);
        subscriber.next(this.vehicles);
      });
    });
    return listObservable$;
  }

  public tenantVehicles(tenantId: number): any {
    this.genericDataService.endPoint = "Vehicle/Tenant";
    const listObservable$ = new Observable((subscriber) => {
      this.genericDataService.list<any>(null, tenantId).subscribe((data) => {
        this.vehicles = Object.assign([], data);
        subscriber.next(this.vehicles);
      });
    });
    return listObservable$;
  }

  public parkingSpaceAdd(request: VehicleRequest): any {
    this.genericDataService.endPoint = "Vehicle/Add";
    const addObservable$ = new Observable((subscriber) => {
      this.genericDataService.post<any>(request).subscribe((data) => {
        this.vehicle = Object.assign({}, data);
        subscriber.next(this.vehicle);
      });
    });
    return addObservable$;
  }

  public parkingSpaceUpdate(request: VehicleRequest): any {
    this.genericDataService.endPoint = "Vehicle/Update";
    const updateObservable$ = new Observable((subscriber) => {
      this.genericDataService.post<any>(request).subscribe((data) => {
        this.vehicle = Object.assign({}, data);
        subscriber.next(this.vehicle);
      });
    });
    return updateObservable$;
  }

  public parkingSpaceRemove(id: number): any {
    this.genericDataService.endPoint = "Vehicle/Remove";
    const removeObservable$ = new Observable((observer) => {
      this.genericDataService.delete<any>(null, id).subscribe((data) => {
        this.vehicleSubject$.next(true);
      });
    });
    return removeObservable$;
  }

  public vehicleGet(id: number): any {
    this.genericDataService.endPoint = "Vehicle";
    const getObservable$ = new Observable((subscriber) => {
      this.genericDataService
        .readById<VehicleInfo>(null, id)
        .subscribe((data) => {
          this.vehicle = Object.assign({}, data);
          subscriber.next(this.vehicle);
        });
    });
    return getObservable$;
  }
  public vehicleUpdate(request: VehicleRequest): any {
    this.genericDataService.endPoint = "Vehicle/Update";
    return this.genericDataService.post<any>(request);
  }
}
