import { TeamService } from './../../services/team.service';
import { Component, OnInit } from '@angular/core';
import { Team } from 'src/app/models/team';
import { TeamFilters } from 'src/app/models/team-filters';
import { ListRequest } from 'src/app/models/list-request';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {
  teams: Team[];
  botTeam: Team[];
  ecTeam: Team[];
  filters: TeamFilters;
  listRequest: ListRequest;
  constructor(private teamService: TeamService) {
    this.getTeams();
  }

  ngOnInit() {
  }

  getTeams() {
    this.filters = {
      name: null,
      position: null,
      type: null
    };

    this.listRequest = {
      filter: this.filters,
      itemsPerPage: null,
      pageNumber: null,
    };
    this.teamService.teamList(this.listRequest).subscribe(data => {
      this.teams = [];
      this.teams = Object.assign([], data);
      this.botTeam = [];
      this.ecTeam = [];
      this.botTeam = this.teams.filter(t => t.type === 'BOT');
      this.botTeam.map(t => {
        if (t.position === 'Chairperson') {
          t.contact = 'Chairperson@masjidisgl.org';
        } else {
          t.contact = 'BoT@masjidisgl.org';
        }
      });
      this.ecTeam = this.teams.filter(t => t.type === 'EC');
      this.ecTeam.map(t => {
        if (t.position === 'EC-General Coordinator') {
          t.contact = 'GC@masjidisgl.org';
        } else if (t.position === 'EC-Treasurer') {
          t.contact = 'Accounts@masjidisgl.org';
        } else {
          t.contact = 'EC@masjidisgl.org';
        }
      });
    });
  }

}
