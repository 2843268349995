import { ValidationError } from './validation-error';

export class PaymentValidation {
  paymentId: ValidationError;
  amount: ValidationError;
  paymentDate: ValidationError;
  validityInMonths: ValidationError;
  hasError: boolean;
  constructor(paymentId: ValidationError, amount: ValidationError, paymentDate: ValidationError,
    validityInMonths: ValidationError, hasError: boolean) {
    this.paymentId = paymentId;
    this.amount = amount;
    this.paymentDate = paymentDate;
    this.validityInMonths = validityInMonths;
    this.hasError = hasError;
  }
}
