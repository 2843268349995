import { KeyFobRequest } from './../models/keyfob-request';
import { Injectable } from '@angular/core';
import { GenericDataService } from './generic-data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private genericDataService: GenericDataService) { }

  public keyFobRequest(request: KeyFobRequest): any {
    this.genericDataService.endPoint = 'Email/KeyfobRequest';
    const requestObservable$ = new Observable(subscriber => {
      this.genericDataService.post<any>(request).subscribe();
    });

    return requestObservable$;
  }

}
