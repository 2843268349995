import { HelperService } from './../../services/helper.service';
import { MemberKid } from './../../models/member-kid';
import { ListRequest } from './../../models/list-request';
import { MemberFilters } from './../../models/member-filters';
import { MemberInfo } from './../../models/member-info';
import { MemberListResponse } from './../../models/member-list-response';
import { MemberService } from './../../services/member.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { MemberDisplayValues } from 'src/app/models/member-display-values';
import { NgxSpinnerService } from 'ngx-spinner';
import { faSearch, faTimes, faTimesCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import { MemberKidValidation } from 'src/app/models/member-kid-validation';
import { ValidationError } from 'src/app/models/validation-error';
import { KidValidation } from 'src/app/models/kid-validation';
import { MemberPayment } from 'src/app/models/member-payment';
import * as _moment from 'moment';
import { MemberUpdate } from 'src/app/models/member-update';
import { MemberUpdateValidation } from 'src/app/models/member-update-validation';
import { Member } from 'src/app/models/member';
import { SelectListItem } from 'src/app/models/select-list-item';
import { MemberDownload } from 'src/app/models/member-download';
const moment = _moment;
@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit {

  displayedColumns: string[] = ['name', 'email', 'cellPhone', 'isStudent', 'residencyStatus', 'isVoting', 'membershipExpiration'];
  dataSource: MemberDisplayValues[];
  memberListResponse: MemberListResponse;
  memberList: MemberInfo[];
  downloadList: MemberDownload[];
  filters: MemberFilters;
  listRequest: ListRequest;
  itemsPerPage = 15;
  pageNumber: number;
  pageNumberWithFilter: number;
  totalRows = 0;
  membersLoaded: Promise<boolean>;
  notLoading = true;
  moreToLoad = true;
  faSearch = faSearch;
  faTimesCircle = faTimesCircle;
  faTimes = faTimes;
  faDownload = faDownload;
  query: string;
  showSummary = false;
  hideSummary = false;
  summaryMemberId: number;
  summaryMemberName: string;
  summaryMemberAddress: string;
  summaryMember: MemberInfo;
  tabs: string[] = ['Payments', 'Kids'];
  memberKids: MemberKid[];
  memberUpdate: MemberUpdate;
  hasFilters = false;
  genderList: SelectListItem[];
  constructor(private memberService: MemberService, private spinner: NgxSpinnerService,
    private helperService: HelperService) { }

  ngOnInit() {
    this.setListRequest();
    this.getMembers();
    this.getLists();
  }

  setListRequest() {
    if (!this.filters) {
      this.filters = {
        query: null,
        isStudent: null,
        isVoting: null,
        residencyStatus: null,
        gender: null
      };
    }
    this.listRequest = {
      filter: this.filters,
      itemsPerPage: !this.itemsPerPage ? null : this.itemsPerPage,
      pageNumber: !this.pageNumber ? 1 : this.pageNumber
    };
  }

  getMembers() {
    this.membersLoaded = Promise.resolve(false);
    this.memberService.memberList(this.listRequest).subscribe(data => {
      this.memberListResponse = Object.assign(data);
      this.memberList = Object.assign([], this.memberListResponse.items);
      this.totalRows = this.memberListResponse.totalCount;
      this.pageNumber = this.memberListResponse.request.pageNumber;
      this.setDataSource(this.memberList);
      this.membersLoaded = Promise.resolve(true);
    });

  }

  setDataSource(members: MemberInfo[]) {
    let dataSource: MemberDisplayValues[] = [];
    dataSource = members.map(value => ({
      id: value.id,
      name: `${value.firstName} ${value.lastName}`,
      email: value.email,
      cellPhone: value.cellPhone,
      isVoting: !value.isVoting ? false : value.isVoting,
      isStudent: !value.isStudent ? false : value.isStudent,
      residencyStatus: !value.residencyStatus ? false : value.residencyStatus,
      membershipExpiration: !value.paymentExpirationDate ? 'N/A' : value.paymentExpirationDate
    }));
    // this.dataSource = !this.filters.query && !this.hasFilters && this.dataSource && this.dataSource.length > 0 ?
    //   this.dataSource.concat(dataSource) : dataSource;
    this.dataSource = !this.filters.query && this.dataSource && this.dataSource.length > 0 ?
      this.dataSource.concat(dataSource) : dataSource;
  }

  onScroll() {
    if (this.notLoading) {
      this.incrementStartIndex();
      if (this.moreToLoad) {
        this.notLoading = false;
        this.loadMoreMembers();
      }
    }
  }

  incrementStartIndex() {
    const recordsFetched = this.listRequest.pageNumber * this.listRequest.itemsPerPage;
    this.pageNumber += 1;
    this.listRequest.pageNumber = this.pageNumber;
    if (recordsFetched >= this.totalRows) {
      this.moreToLoad = false;
    }
  }

  resetScroll() {
    this.moreToLoad = true;
    this.pageNumber = 1;
  }


  loadMoreMembers() {
    this.spinner.show();
    let moreMembers: MemberInfo[] = [];
    this.memberService.memberList(this.listRequest).subscribe(data => {
      const response = Object.assign(data);
      moreMembers = Object.assign([], response.items);
      this.memberList = this.memberList.concat(moreMembers);
      this.pageNumber = response.request.pageNumber;
      this.setDataSource(moreMembers);
      this.membersLoaded = Promise.resolve(true);
      this.spinner.hide();
      this.notLoading = true;
    });
  }

  memberSearch(event: any) {
    if (event.keyCode) {
      if (!this.query || event.keyCode === 27) {
        this.clearSearch();
        return;
      }
      if (event.keyCode !== 13) {
        return false;
      }
    }
    // if (event.keyCode && event.keyCode === 27) {
    //   this.clearSearch();
    // }
    // if (event.keyCode && event.keyCode !== 13) {
    //   return false;
    // }
    this.filters.query = this.query.trim();
    this.listRequest.pageNumber = 1;
    if (!this.filters.query) {
      this.dataSource = [];
    }
    this.getMembers();
  }

  clearSearch() {
    this.filters.query = null;
    this.query = null;
    this.listRequest.pageNumber = 1;
    this.dataSource = [];
    this.getMembers();
  }

  showMemberSummary(id: number, name: string) {
    this.summaryMemberId = id;
    const member = this.memberList.find(x => x.id === id);
    this.summaryMemberName = name;
    this.summaryMemberAddress = `${member.address}, ${member.city}, ${member.state} ${member.zip}`;
    this.getSummaryMember();
    this.showSummary = true;
    this.hideSummary = false;
  }

  hideMemberSummary() {
    this.showSummary = false;
    this.hideSummary = true;
    this.summaryMemberId = null;
    this.summaryMemberName = null;
    this.summaryMemberAddress = null;
  }

  getSummaryMember() {
    if (!this.summaryMemberId) {
      return;
    }
    this.memberService.memberGet(this.summaryMemberId).subscribe(data => {
      this.summaryMember = Object.assign({}, data);
      this.memberUpdate = new MemberUpdate(this.summaryMember);
      this.memberUpdate.validation = new MemberUpdateValidation(new ValidationError(false),
        new ValidationError(false), new ValidationError(false), new ValidationError(false), false);
      this.memberKids = [];
      this.memberKids = this.summaryMember.memberKids ? this.summaryMember.memberKids : null;
      if (this.memberKids && this.memberKids.length > 0) {
        this.memberKids.forEach(m => {
          m.kid.name = `${m.kid.firstName} ${m.kid.lastName}`;
          m.validation = new MemberKidValidation(new ValidationError(false)),
            m.kid.validation = new KidValidation(
              new ValidationError(false),
              new ValidationError(false),
              new ValidationError(false),
              new ValidationError(false),
              false);
        });
      }
    });
  }
  selectedTabChange(event: any) {
    const tabName = this.tabs[event];
  }

  updateMemberKids(memberKids: MemberKid[]) {
    this.memberKids = this.summaryMember.memberKids = memberKids;
  }

  paymentCollected(paymentCollected: MemberPayment) {
    if (paymentCollected) {
      const member = this.memberList.find(m => m.id === this.summaryMemberId);
      member.isVoting = true;
      member.paymentExpirationDate = moment(paymentCollected.expirationDate).format('MM-DD-YYYY');
      const data = this.dataSource.find(d => d.id === this.summaryMemberId);
      data.isVoting = true;
      data.membershipExpiration = moment(paymentCollected.expirationDate).format('MM-DD-YYYY');
    }
  }

  paymentDeleted(deleted: boolean) {
    if (deleted) {
      const member = this.memberList.find(m => m.id === this.summaryMemberId);
      member.isVoting = false;
      member.paymentExpirationDate = 'N/A';
      const data = this.dataSource.find(d => d.id === this.summaryMemberId);
      data.isVoting = false;
      data.membershipExpiration = 'N/A';
    }
  }

  paymentUpdated(paymentUpdated: MemberPayment) {
    if (paymentUpdated) {
      const member = this.memberList.find(m => m.id === this.summaryMemberId);
      member.paymentExpirationDate = moment(paymentUpdated.expirationDate).format('MM-DD-YYYY');
      const data = this.dataSource.find(d => d.id === this.summaryMemberId);
      data.membershipExpiration = moment(paymentUpdated.expirationDate).format('MM-DD-YYYY');
    }
  }

  updateMember(member: Member) {
    const mInfo = this.memberList.find(m => m.id === member.id);
    if (mInfo === null) {
      return;
    }
    this.summaryMemberName = `${member.firstName} ${member.lastName}`;
    this.summaryMemberAddress = `${member.address}, ${member.city}, ${member.state} ${member.zip}`;
    const dSource = this.dataSource.find(x => x.id === member.id);
    mInfo.gender = member.gender;
    mInfo.isAlumni = member.isAlumni;
    dSource.isStudent = mInfo.isStudent = member.isStudent;
    mInfo.lastName = member.lastName;
    dSource.residencyStatus = mInfo.residencyStatus = member.residencyStatus;
    mInfo.state = member.state;
    mInfo.zip = member.zip;
    mInfo.address = member.address;
    dSource.cellPhone = mInfo.cellPhone = member.cellPhone;
    mInfo.city = member.city;
    mInfo.firstName = member.firstName;
    dSource.name = `${member.firstName} ${member.lastName}`;

  }

  applyFilter() {
    this.filters.isStudent = !this.filters.isStudent ? null : this.filters.isStudent;
    this.filters.isVoting = !this.filters.isVoting ? null : this.filters.isVoting;
    this.filters.residencyStatus = !this.filters.residencyStatus ? null : this.filters.residencyStatus;
    this.setHasFilter();
    this.resetScroll();
    this.listRequest = {
      filter: this.filters,
      itemsPerPage: !this.itemsPerPage ? null : this.itemsPerPage,
      pageNumber: !this.pageNumber ? 1 : this.pageNumber
    };

    this.dataSource = [];
    this.getMembers();
  }

  setHasFilter() {
    this.hasFilters = this.filters.isStudent || this.filters.isVoting || this.filters.residencyStatus ? true : false;
  }

  getLists() {
    this.genderList = this.helperService.genderListGet();
  }

  downloadMembers() {
    const listRequest = {
      filter: this.filters,
      itemsPerPage: null,
      pageNumber: null
    };
    this.memberService.memberDownloadList(listRequest).subscribe(data => {
      this.downloadList = Object.assign([], data);
      const csv = this.generateCsv(this.downloadList);
      this.downloadCsv(csv);
    });
  }

  generateCsv(downloadList: MemberDownload[]) {
    const csvRows = [];
    const headers = Object.keys(downloadList[0]);
    const newHeaders = [
      'FirstName',
      'LastName',
      'Email',
      'Address',
      'City',
      'Zip',
      'CellPhone',
      'ResidencyStatus',
      'IsStudent',
      'Gender',
      'IsAlumni',
      'IsVoting',
      'PaymentExpirationDate'
    ];
    csvRows.push(newHeaders.join(','));
    for (const row of downloadList) {
      const values = headers.map((header) => {
        const escaped = ('' + row[header]).replace('"', '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  }

  downloadCsv(csvData: any) {
    let filters = '';
    if (this.filters) {
      if (this.filters.isStudent) {
        filters = 'Filterby-Student';
      }
      if (this.filters.residencyStatus) {
        if (filters.length > 0) {
          filters += '-Filterby-Resident';
        } else {
          filters = 'Filterby-Resident';
        }
      }
      if (this.filters.isVoting) {
        if (filters.length > 0) {
          filters += '-Filterby-Voting';
        } else {
          filters = 'Filterby-Voting';
        }
      }
    }
    const fileName = `members-${moment().format('MM-DD-YYYY')}-${filters}`;
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  @HostListener('document:keyup.esc')
  onkeyup() {
    this.hideMemberSummary();
  }

}
