import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "customDate",
})
export class CustomDatePipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return null;
    }
    if (value === "N/A") {
      return value;
    }
    // if (!(value instanceof Date)) {
    //   return value;
    // }
    return super.transform(value, "MM-dd-yyyy");
  }
}
