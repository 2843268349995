import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject, throwError } from "rxjs";
import { map} from 'rxjs/operators';
import { GenericDataService } from "./generic-data.service";
import { UserCredential } from "../models/user-credentials";
import { UserAuth } from "../models/user-auth";
import * as moment from "moment";
import * as jwt_decode from "jwt-decode";
import { UserToken } from "../models/user-token";
import { PasswordReset } from "../models/password-reset";

@Injectable({
  providedIn: "root",
})
export class SecurityService {
  auth: UserAuth = new UserAuth();
  isSignedInUserSubject: BehaviorSubject<UserToken> = new BehaviorSubject(
    this.getUser()
  );
  public user$: Observable<UserToken> = this.isSignedInUserSubject.asObservable();
  private isSignedInBS = new BehaviorSubject<boolean>(this.isLoggedIn());
  isSignedIn$ = this.isSignedInBS.asObservable();
  /**
   *
   * @returns {Observable<T>}
   */
  private memberIdSubject = new Subject<number>();
  memberId$ = this.memberIdSubject.pipe(
   map(() => {
      return this.getMemberId()
    })
  );

  constructor(private genericDataService: GenericDataService) {}

  login(entity: UserCredential): Observable<UserAuth> {
    this.resetSecurityObject();
    this.genericDataService.endPoint = "Security/Authenticate";

    const userAuthObservable$ = new Observable<UserAuth>((observer) => {
      this.genericDataService.post<UserAuth>(entity).subscribe((data) => {
        Object.assign(this.auth, data);
        this.setSession(data.bearerToken);
        observer.next(this.auth);
      });
    });

    return userAuthObservable$;
  }

  logout(): void {
    this.resetSecurityObject();
  }

  resetSecurityObject(): void {
    this.auth.userName = "";
    this.auth.bearerToken = "";
    this.auth.isAuthenticated = false;
    localStorage.removeItem("bearerToken");
    localStorage.removeItem("expiresAt");
    localStorage.removeItem("user");
    this.isSignedInUserSubject.next(null);
    this.isSignedInBS.next(false);
    this.memberIdSubject.next(0);
  }

  setSession(token): void {
    if (token) {
      const decoded = jwt_decode(token);
      const expiresAt = moment.unix(decoded.exp).utc().format();

      localStorage.setItem(
        "user",
        JSON.stringify(this.setUser(decoded).valueOf())
      );
      localStorage.setItem("bearerToken", token);
      localStorage.setItem("expiresAt", JSON.stringify(expiresAt.valueOf()));
    }
  }

  public isLoggedIn(): boolean {
    const loggedInStatus = moment().isBefore(this.getExpiration());
    return loggedInStatus;
  }

  isSignedIn(): Observable<boolean> {
    return this.isSignedInBS.asObservable();
  }

  getExpiration() {
    const expiration = localStorage.getItem("expiresAt");
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  setUser(token): UserToken {
    const user: UserToken = {
      firstName: token.firstName,
      lastName: token.lastName,
      userName: token.sub,
      id: token.userId,
      email: token.email,
      role: token.role,
      memberId: token.memberId,
      moduleAccess: token.ModuleAccess,
    };
    this.isSignedInUserSubject.next(user);
    this.isSignedInBS.next(true);
    this.memberIdSubject.next(user.memberId);
    return user;
  }

  getUser(): UserToken {
    return JSON.parse(localStorage.getItem("user"));
  }

  getRole(): string {
    const user = JSON.parse(localStorage.getItem("user"));
    return user ? user.role : null;
  }

  getMemberId(): number {
    const user = JSON.parse(localStorage.getItem("user"));
    return user ? parseInt(user.memberId): null;
  }

  getUserName(): string {
    const user = JSON.parse(localStorage.getItem("user"));
    return user ? `${user.firstName} ${user.lastName}` : null;
  }

  getModuleAccess(): string[] {
    const user = JSON.parse(localStorage.getItem("user"));
    return user ? user.moduleAccess: null;
  }

  checkAccess(module: string): boolean {
    const user = JSON.parse(localStorage.getItem("user"));
    return user? user.moduleAccess.includes(module) : null;
  }

  initiatePasswordReset(passwordReset: PasswordReset) {
    this.genericDataService.endPoint = "Security/PasswordReset/Initiate";
    const initiateResetObservable$ = new Observable((observer) => {
      this.genericDataService
        .post<PasswordReset>(passwordReset)
        .subscribe((data) => {
          observer.next();
        });
    });
    return initiateResetObservable$;
  }

  resetPassword(passwordReset: PasswordReset): any {
    this.genericDataService.endPoint = "Security/PasswordReset";
    const passwordResetObservable$ = new Observable((observer) => {
      this.genericDataService.post<PasswordReset>(passwordReset).subscribe(
        (data) => {
          observer.next();
        },
        (error) => {
          observer.next("error");
        }
      );
    });
    return passwordResetObservable$;
  }
}
