import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentDialogModel } from './../../models/payment-dialog-model';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-donorbox-payment-modal',
  templateUrl: './donorbox-payment-modal.component.html',
  styleUrls: ['./donorbox-payment-modal.component.scss']
})
export class DonorboxPaymentModalComponent implements OnInit {
  paymentUrl: string;
  faTimes = faTimes;
  constructor(public dialogRef: MatDialogRef<DonorboxPaymentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PaymentDialogModel) {
    this.paymentUrl = data.paymentUrl;
  }

  ngOnInit() {

  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
