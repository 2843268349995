import { TenantWaitInfo } from "./../models/tenant-wait-info";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { GenericDataService } from "./generic-data.service";
import { TenantWaitRequest } from "../models/tenant-wait-request";
import { first } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class TenantWaitService {
  public waitSubject$ = new Subject<boolean>();
  waitInfo: TenantWaitInfo;

  constructor(private genericDataService: GenericDataService) {}

  public tenantWaitList(): any {
    this.genericDataService.endPoint = "TenantWait/List";
    return this.genericDataService.post<any>(null);
  }

  public tenantWaitAdd(request: TenantWaitRequest): any {
    this.genericDataService.endPoint = "TenantWait/Add";
    const addObservable$ = new Observable((subscriber) => {
      this.genericDataService.post<any>(request).subscribe((data) => {
        this.waitInfo = Object.assign({}, data);
        subscriber.next(this.waitInfo);
      });
    });
    return addObservable$;
  }

  public tenantWaitUpdate(request: TenantWaitRequest): any {
    this.genericDataService.endPoint = "TenantWait/Update";
    const updateObservable$ = new Observable((subscriber) => {
      this.genericDataService.post<any>(request).subscribe((data) => {
        this.waitInfo = Object.assign({}, data);
        subscriber.next(this.waitInfo);
      });
    });
    return updateObservable$;
  }

  public tenantWaitRemove(id: number): any {
    this.genericDataService.endPoint = "TenantWait/Remove";
    return this.genericDataService.delete<any>(null, id);
  }

  public tenantWaitGet(id: number): any {
    this.genericDataService.endPoint = "TenantWait";
    return this.genericDataService
      .readById<TenantWaitInfo>(null, id)
      .pipe(first());
  }
  public tenantWaitGetByMember(memberId: number): any {
    this.genericDataService.endPoint = "TenantWait/Member";
    const getObservable$ = new Observable((subscriber) => {
      this.genericDataService
        .readById<TenantWaitInfo>(null, memberId)
        .subscribe((data) => {
          this.waitInfo = Object.assign({}, data);
          subscriber.next(this.waitInfo);
        });
    });
    return getObservable$;
  }
}
