import { SelectListItem } from "./../../models/select-list-item";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
} from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import { LeaseInfo } from "src/app/models/lease-info";
import { TenantInfo } from "src/app/models/tenant-info";
import { LeaseService } from "src/app/services/lease.service";
import { ConfirmationSnackbarComponent } from "../confirmation-snackbar/confirmation-snackbar.component";
import * as _moment from "moment";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { LeaseUpdate } from "src/app/models/lease-update";
import { RoomFilters } from "src/app/models/room-filters";
import { ListRequest } from "src/app/models/list-request";
import { RoomService } from "src/app/services/room.service";
import { RoomInfo } from "src/app/models/room-info";
import { Rent } from "src/app/models/rent";
import { RentService } from "src/app/services/rent.service";
import { ConfirmDialogModel } from "src/app/models/confirm-dialog-model";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { NgxSpinnerService } from "ngx-spinner";
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY",
  },
  display: {
    dateInput: "MM-DD-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "MM-DD-YYYY",
    monthYearA11yLabel: "MM-DD-YYYY",
  },
};
@Component({
  selector: "app-lease-update",
  templateUrl: "./lease-update.component.html",
  styleUrls: ["./lease-update.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class LeaseUpdateComponent implements OnInit, OnChanges {
  @Input() tenantInfo: TenantInfo;
  @Output() notifyParent = new EventEmitter();
  leaseInfo: LeaseInfo;
  model: LeaseUpdate;
  rentList: SelectListItem[];
  roomList: SelectListItem[];
  rooms: RoomInfo[];
  genderRooms: RoomInfo[];
  rents: Rent[];
  selectedRoom: RoomInfo;
  selectedRent: Rent;
  filters: RoomFilters;
  listRequest: ListRequest;
  itemsPerPage = 10;
  pageNumber: number;
  gender: string;
  deposit: string;
  defaultLabel = "Select";
  maxStartDate: any;
  minStartDate: any;
  updateDisable = true;
  durationMonths: number;
  disableView: boolean;
  notLoading = true;
  constructor(
    private leaseService: LeaseService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public roomService: RoomService,
    public rentService: RentService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {}
  ngOnChanges() {
    if (this.tenantInfo) {
      this.leaseInfo = Object.assign({}, this.tenantInfo.leaseInfo);
      this.gender = this.tenantInfo.memberInfo.gender
        ? this.tenantInfo.memberInfo.gender.toLowerCase()
        : null;
      this.setModel();
      this.getRooms();
      this.getRents();
      this.disableView =
        this.tenantInfo.leaseInfo.statusId !== 4 ? true : false;
    }
  }
  setModel() {
    this.minStartDate = moment(this.leaseInfo.startDate).local().isSameOrBefore(moment().local()) ?
       moment(this.leaseInfo.startDate).local() : moment().local();

    //moment().clone().add(1, "M").startOf("month").local();
    this.model = {
      leaseId: this.leaseInfo.id,
      startDate: moment(this.leaseInfo.startDate).clone().local(),
      endDate: moment(this.leaseInfo.endDate).clone().local(),
      rentId: this.leaseInfo.rentId,
      roomId: this.tenantInfo.roomInfo.id,
      securityDeposit: this.leaseInfo.securityDeposit,
    };
    this.deposit = `$${this.leaseInfo.securityDeposit}`;
    this.maxStartDate = this.model.endDate
      .clone()
      .add(-1, "M")
      .startOf("month")
      .local();
    this.durationMonths = this.model.endDate
      .clone()
      .diff(this.model.startDate.clone(), "months");
  }

  getRooms() {
    if (!this.filters) {
      this.filters = {
        name: null,
        occupancy: null,
        rentId: null,
      };
    }
    this.listRequest = {
      filter: this.filters,
      itemsPerPage: !this.itemsPerPage ? null : this.itemsPerPage,
      pageNumber: !this.pageNumber ? 1 : this.pageNumber,
    };
    this.roomService.roomList(this.listRequest).subscribe((data) => {
      this.rooms = Object.assign([], data);
      if (this.rooms && this.rooms.length > 0) {
        this.genderRooms = this.gender
          ? this.rooms.filter((r) =>
              r.name.toLowerCase().startsWith(this.gender)
            )
          : this.rooms;
        this.roomList = this.genderRooms.map((item) => ({
          label: `${item.name} ${item.description}`,
          value: item.id,
          disabled: this.disableView ? true : !item.isAvailable,
        }));
        this.selectedRoom = this.genderRooms.find(
          (r) => r.id === this.tenantInfo.roomInfo.id
        );
      }
    });
  }

  getRents() {
    this.rentService.rentList().subscribe((data) => {
      if (data) {
        this.rents = Object.assign([], data);
        this.rents = this.rents.filter((r) => r.type === "Room");
        this.selectedRent = this.rents.find(
          (r) => r.id === this.tenantInfo.leaseInfo.rentId
        );
        this.setRentList(this.tenantInfo.roomInfo.id);
        this.setRent(this.tenantInfo.leaseInfo.rentId);
      }
    });
  }
  onDateChange() {
    let leaseSDate = moment(this.leaseInfo.startDate).clone();
    let leaseEDate = moment(this.leaseInfo.endDate).clone();
    let sDate = moment(this.model.startDate);
    let eDate = moment(this.model.endDate);

    if (sDate.isSame(leaseSDate) && eDate.isSame(leaseEDate)) {
      this.toggleEnable();
      return;
    }
    this.durationMonths = eDate.diff(sDate, "months");
    if (this.durationMonths > 0) {
      this.genderRooms.forEach((item) => {
        if (!item.isAvailable) {
          item.isAvailable = sDate.isAfter(
            moment(item.availableDate).clone().add(this.durationMonths, "M")
          );
        }
      });
      this.roomList = this.genderRooms.map((item) => ({
        label: `${item.name} ${item.description}`,
        value: item.id,
        disabled: !item.isAvailable,
      }));
    }
    let name =
      this.durationMonths < 11 &&
      this.selectedRent.name.toLowerCase().includes("short term")
        ? this.selectedRent.name
        : this.selectedRent.name.includes("short term")
        ? this.selectedRent.name
        : `${this.selectedRent.name} short term`;
    this.selectedRent =
      this.durationMonths < 11
        ? this.rents.find((r) => r.name.toLowerCase() === name.toLowerCase())
        : this.rents.find(
            (r) =>
              r.name.toLowerCase() ===
              `${this.selectedRoom.description.toLowerCase()}`
          );
    this.deposit = `$${this.selectedRent.amount}`;
    this.model.rentId = this.selectedRent.id;
    this.model.securityDeposit = this.selectedRent.amount;
    this.maxStartDate = this.model.endDate
      .clone()
      .add(-1, "M")
      .startOf("month")
      .local();
    this.toggleEnable();
  }

  setRoomList(roomId: number) {
    this.selectedRoom = this.genderRooms.find(
      (r) => r.id === this.tenantInfo.roomInfo.id
    );
    this.setRentList(this.selectedRoom.id);
    this.model.roomId = this.selectedRoom.id;
  }

  setRentList(roomId: number) {
    this.selectedRoom = this.genderRooms.find((r) => r.id === roomId);
    if (this.selectedRoom == null) {
      return;
    }
    this.rentList = this.selectedRoom.rents.map((item) => ({
      label: `${item.name}-$${item.amount}`,
      value: item.id,
      disabled: this.disableView ? true : false,
    }));
    this.model.roomId = this.selectedRoom.id;
    this.selectedRent =
      this.durationMonths < 11
        ? this.selectedRoom.rents.find((r) =>
            r.name.toLowerCase().includes("short term")
          )
        : this.selectedRoom.rents.find(
            (r) => !r.name.toLowerCase().includes("short term")
          );
    this.model.securityDeposit = this.selectedRent.amount;
    this.model.roomId = this.selectedRoom.id;
    this.model.rentId = this.selectedRent.id;
    this.toggleEnable();
  }

  setRent(rentId: number) {
    this.selectedRent = this.rents.find((r) => r.id == rentId);
    if (this.selectedRent == null) {
      return;
    }
    this.deposit = `$${this.selectedRent.amount}`;
    this.model.rentId = this.selectedRent.id;
    this.model.securityDeposit = this.selectedRent.amount;
    this.toggleEnable();
  }

  toggleEnable() {
    if (!this.model) {
      return;
    }
    if (!this.leaseInfo) {
      return;
    }
    let leaseSDate = moment(this.leaseInfo.startDate)
    .clone()
    .format("MM-DD-YYYY");
    let modelSDate = moment(this.model.startDate).clone().format("MM-DD-YYYY");
    let leaseEDate = moment(this.leaseInfo.endDate)
      .clone()
      .format("MM-DD-YYYY");
    let modelEDate = moment(this.model.endDate).clone().format("MM-DD-YYYY");
    this.updateDisable =
      leaseSDate === modelSDate &&
      leaseEDate === modelEDate &&
      this.model.roomId === this.tenantInfo.roomInfo.id &&
      this.model.rentId === this.tenantInfo.rentId &&
      this.model.securityDeposit === this.leaseInfo.securityDeposit
        ? true
        : false;
  }

  openAlertDialog(dialogData: ConfirmDialogModel, sender: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "500px",
      height: "300px",
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (sender === "setRoom") {
        if (!dialogResult) {
          //this.setRoomList();
        }
      }
      if (sender === "update") {
        if (dialogResult) {
          //this.moveToTenant();
        }
      }
    });
  }
  showConfirmationDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 20000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: "success",
      },
    });
  }
  setSecurityDeposit(event: any) {
    this.model.securityDeposit =
      !event.target.value || event.target.value === "0"
        ? 0
        : Number(event.target.value);
    this.toggleEnable();
  }
  update() {
    this.notLoading = false;
    this.spinner.show();
    this.leaseService.leaseUpdate(this.model).subscribe((data) => {
      this.notLoading = true;
      this.spinner.hide();
      if (data) {
        this.showConfirmationDialog(
          "Lease is updated, a confirmation email is sent to tenant"
        );
        this.updateDisable = true;
        this.notifyParent.emit(true);
        this.disableView = true;
      }
    });
  }
}
