import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-ieb-manage',
  templateUrl: './ieb-manage.component.html',
  styleUrls: ['./ieb-manage.component.scss']
})
export class IebManageComponent implements OnInit {
  showTeachers = false;
  showStudents = true;
  showSemesters = false;
  showReducedFee = false;
  showAddReg = false;
  showTypes = false;

  constructor() { }

  ngOnInit() {
  }

  typeClick(name: string) {
    if (name === "teachers") {
      this.showTeachers = true;
      this.showStudents = false;
      this.showSemesters = false;
      this.showReducedFee = false;
      this.showAddReg = false;
    } else if (name === "students") {
      this.showTeachers = false;
      this.showStudents = true;
      this.showSemesters = false;
      this.showReducedFee = false;
      this.showAddReg = false;
    } else if (name === "semesters") {
      this.showTeachers = false;
      this.showStudents = false;
      this.showSemesters = true;
      this.showReducedFee = false;
      this.showAddReg = false;
    } else if (name === "reducedFee") {
      this.showTeachers = false;
      this.showStudents = false;
      this.showSemesters = false;
      this.showReducedFee = true;
      this.showAddReg = false;
    } else if(name === "addReg") {
      this.showTeachers = false;
      this.showStudents = false;
      this.showSemesters = false;
      this.showReducedFee = false;
      this.showAddReg = true;
    }
  }


}
