import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LoadService {
private loadBS = new BehaviorSubject<boolean>(false);
load$ = this.loadBS.asObservable();
constructor() { }

loadToggle(load: boolean) {
  this.loadBS.next(load);

 }



}
