import { RoomUpsert } from "./../../models/room-upsert";
import { ListRequest } from "./../../models/list-request";
import { RoomFilters } from "./../../models/room-filters";
import { SelectListItem } from "./../../models/select-list-item";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { RoomInfo } from "src/app/models/room-info";
import { RoomService } from "src/app/services/room.service";
import { HelperService } from "src/app/services/helper.service";
import * as _moment from "moment";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { RoomValidation } from "src/app/models/room-validation";
import { ValidationError } from "src/app/models/validation-error";
import { ConfirmationSnackbarComponent } from "../confirmation-snackbar/confirmation-snackbar.component";
import { Rent } from "src/app/models/rent";
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY",
  },
  display: {
    dateInput: "MM-DD-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "MM-DD-YYYY",
    monthYearA11yLabel: "MM-DD-YYYY",
  },
};
@Component({
  selector: "app-rooms-manage",
  templateUrl: "./rooms-manage.component.html",
  styleUrls: ["./rooms-manage.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class RoomsManageComponent implements OnInit {
  roomList: RoomInfo[];
  filters: RoomFilters;
  listRequest: ListRequest;
  occupanyList: SelectListItem[];
  selectedRoom: RoomInfo;
  hasError: boolean;
  errorMessage = "Required!";
  showAddRoom: boolean;
  newRoom: RoomInfo;
  disableDelete: boolean;

  constructor(
    private roomService: RoomService,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getRooms();
    this.getOccupancy();
    this.resetNewRoom();
  }
  getRooms() {
    this.filters = {
      name: null,
      occupancy: null,
      rentId: null,
    };
    this.listRequest = {
      filter: this.filters,
      itemsPerPage: null,
      pageNumber: null,
    };
    this.roomService.roomList(this.listRequest).subscribe((data) => {
      this.roomList = Object.assign([], data);
      this.roomList.forEach((item) => {
        item.availableDate = !item.availableDate
          ? moment().clone().local()
          : moment(item.availableDate).local();
        item.validation = new RoomValidation(
          new ValidationError(false),
          new ValidationError(false),
          false
        );
        this.setRoomRentList(item);
      });
    });
  }

  setRoomRentList(room: RoomInfo) {
    room.rentList = room.rents.map((item) => ({
      label: item.name,
      value: item.id,
      parentValue: room.id,
      checked:
        room.currentLease && room.currentLease.rentId === item.id
          ? true
          : false,
      disabled:
        room.currentLease && room.currentLease.rentId === item.id
          ? true
          : false,
    }));
    if (room.rents && room.rents.length > 0) {
      room.selectedRents = [];
      room.rents.forEach((item) => {
        room.selectedRents.push(item.id);
      });
    }
  }

  getOccupancy() {
    this.occupanyList = [];
    this.occupanyList.push(
      new SelectListItem("Single", 1, false),
      new SelectListItem("Double", 2, false)
    );
  }

  setUpdate(field: string, value: any, roomId: number) {
    let roomToUpdate = Object.assign(
      {},
      this.roomList.find((r) => r.id === roomId)
    );
    if (field === "availableDate") {
      roomToUpdate.availableDate = moment(value).clone().local();
    }
    roomToUpdate.validation.hasError = !this.validateUpdate(
      field,
      value,
      roomToUpdate
    );
    if (roomToUpdate.validation.hasError) {
      return;
    }
    if (field === "name") {
      roomToUpdate.name = value;
    } else if (field === "occupancy") {
      roomToUpdate.occupancy = value;
      roomToUpdate.description = value === 1 ? "Single" : "Double";
    }
    const roomUpdate: RoomUpsert = {
      id: roomToUpdate.id,
      name: roomToUpdate.name,
      description: roomToUpdate.description,
      occupancy: roomToUpdate.occupancy,
      availableDate: roomToUpdate.availableDate,
      roomRents: roomToUpdate.selectedRents,
    };
    this.updateRoom(roomUpdate);
  }

  validateUpdate(field: string, value: any, room: RoomInfo): boolean {
    let isValid = true;
    if (field === "name") {
      if (value.length === 0) {
        room.validation.name.hasError = true;
        room.validation.name.errorMessage = "Required!";
      } else {
        room.validation.name.hasError = false;
        //room.name = value;
      }
    }
    if (field === "occupancy") {
      if (value.length === 0) {
        room.validation.occupancy.hasError = true;
        room.validation.occupancy.errorMessage = "Required!";
      } else if (value === 0) {
        room.validation.occupancy.hasError = true;
        room.validation.occupancy.errorMessage = "Invalid!";
      } else {
        this.selectedRoom.validation.occupancy.hasError = false;
        //this.selectedRoom.occupancy = value;
        //this.selectedRoom.description = value === 1 ? "Single" : "Double";
      }
    }

    isValid =
      room.validation.name.hasError || room.validation.occupancy.hasError
        ? false
        : true;

    return isValid;
  }

  updateRoom(roomToUpdate: RoomUpsert) {
    if (!roomToUpdate) {
      return;
    }

    this.roomService.roomUpdate(roomToUpdate).subscribe((data) => {
      if (data) {
        this.showConfirmationDialog("Room is updated.");
      }
    });
  }

  delete(room: RoomInfo) {
    if (this.disableDelete) {
      return;
    }
    let dismissedByAction = false;
    this.disableDelete = true;

    const index = this.roomList.indexOf(room);
    this.roomList.splice(index, 1);
    const deleteSnackBar = this.snackBar.openFromComponent(
      ConfirmationSnackbarComponent,
      {
        horizontalPosition: "center",
        duration: 10000,
        panelClass: "delete-snack-bar",
        data: {
          undoDelete: () => {
            dismissedByAction = true;
            this.roomList.splice(index, 0, room);
            this.cd.detectChanges();
            const undoSnackBar = this.snackBar.openFromComponent(
              ConfirmationSnackbarComponent,
              {
                horizontalPosition: "center",
                duration: 10000,
                panelClass: "delete-snack-bar",
                data: {
                  dismissSnackbar: () => {
                    this.snackBar.dismiss();
                    this.disableDelete = false;
                  },
                  displayText: "Undone!",
                  type: "message",
                },
              }
            );
            this.disableDelete = false;
          },
          dismissSnackbar: () => {
            dismissedByAction = true;
            this.roomService.roomRemove(room.id).subscribe();
            this.snackBar.dismiss();
            this.disableDelete = false;
          },
          displayText: `Room ${room.name} deleted`,
          type: "delete-kid",
        },
      }
    );
    // delete the kid if the snackbar closes due to duration and not closed by user.
    deleteSnackBar.afterDismissed().subscribe((info) => {
      if (info.dismissedByAction === false && dismissedByAction === false) {
        this.roomService.roomRemove(room.id).subscribe();
        this.disableDelete = false;
      }
    });
  }

  resetNewRoom() {
    this.newRoom = {
      id: 0,
      name: "",
      description: "Single",
      occupancy: 1,
      availableDate: moment().clone().local(),
      isAvailable: true,
      isRoomAvailable: true,
      rents: null,
      roomImages: null,
      currentLease: null,
      rentList: null,
      selectedRents: null,
      validation: new RoomValidation(
        new ValidationError(true),
        new ValidationError(false),
        true
      ),
    };
    this.setNewRoomRentList(this.newRoom.occupancy);
  }

  setNewRent(field: string, value: any) {
    if (field === "availableDate") {
      this.newRoom.availableDate = moment(value).clone().local();
    }
    if (field === "occupancy") {
      this.setNewRoomRentList(value);
    }

    this.newRoom.validation.hasError = !this.validateNew(
      field,
      value,
      this.newRoom
    );
  }

  setNewRoomRentList(occupancy: number) {
    if (occupancy === 0) {
      return;
    }
    if (!this.roomList || this.roomList.length === 0) {
      return;
    }
    let description = occupancy === 1 ? "Single" : "Double";
    var room = this.roomList.find((r) => r.description === description);
    this.newRoom.rentList = room.rentList;
    this.newRoom.rentList.map((item) => {
      item.checked = room.description === description ? true : false;
      item.disabled = false;
    });
    this.newRoom.selectedRents = [];
    this.newRoom.selectedRents.push(
      this.newRoom.rentList.find((r) => r.checked).value
    );
  }

  validateNew(field: string, value: any, room: RoomInfo): boolean {
    let isValid = true;
    if (field === "name") {
      if (value.length === 0) {
        room.validation.name.hasError = true;
        room.validation.name.errorMessage = "Required!";
      } else {
        this.newRoom.validation.name.hasError = false;
        this.newRoom.name = value;
      }
    }
    if (field === "occupancy") {
      if (value.length === 0) {
        room.validation.occupancy.hasError = true;
        room.validation.occupancy.errorMessage = "Required!";
      } else if (value === 0) {
        room.validation.occupancy.hasError = true;
        room.validation.occupancy.errorMessage = "Invalid!";
      } else {
        this.newRoom.validation.occupancy.hasError = false;
        this.newRoom.occupancy = value;
        this.newRoom.description = value === 1 ? "Single" : "Double";
      }
    }

    isValid =
      room.validation.name.hasError || room.validation.occupancy.hasError
        ? false
        : true;

    return isValid;
  }

  saveRoom() {
    if (this.newRoom.validation.hasError) {
      return;
    }
    const roomNew: RoomUpsert = {
      id: 0,
      name: this.newRoom.name,
      description: this.newRoom.description,
      occupancy: this.newRoom.occupancy,
      availableDate: this.newRoom.availableDate,
      roomRents: this.newRoom.selectedRents,
    };

    this.roomService.roomAdd(roomNew).subscribe((data) => {
      if (data) {
        this.getRooms();
        this.showConfirmationDialog("Room is added.");
        this.resetNewRoom();
        this.showAddRoom = false;
      }
    });
  }

  addRoom() {
    this.showAddRoom = true;
    this.resetNewRoom();
  }

  closeAddRoom() {
    this.showAddRoom = false;
    this.resetNewRoom();
  }

  showConfirmationDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 20000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: "success",
      },
    });
  }

  onSelectionChange(event: any) {
    //this.updateRoom();
  }
}
