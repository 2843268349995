import { ValidationError } from './validation-error';
export class KidValidation {
  firstName: ValidationError;
  lastName: ValidationError;
  name: ValidationError;
  gender: ValidationError;
  hasError: boolean;

  constructor(firstName: ValidationError, lastName: ValidationError, name: ValidationError,
    gender: ValidationError, hasError: boolean) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.name = name;
    this.gender = gender;
    this.hasError = hasError;
  }
}

