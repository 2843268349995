import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { IebRegisterService } from 'src/app/services/ieb-register.service';


@Component({
  selector: 'app-ieb-information',
  templateUrl: './ieb-information.component.html',
  styleUrls: ['./ieb-information.component.scss']
})
export class IebInformationComponent implements OnInit {
  @Output() redirectEvent  = new EventEmitter<string>();
  semesterDates$ = this.iebService.semesterInfo$;
  constructor(private iebService: IebRegisterService) { }

  ngOnInit() {
  }

  onRegClick() {
    this.redirectEvent.emit('registration');
  }

}
