import {
  Component,
  OnInit,
  forwardRef,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

export const FLATINPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FlatInputComponent),
  multi: true,
};

@Component({
  selector: "app-flat-input",
  templateUrl: "./flat-input.component.html",
  styleUrls: ["./flat-input.component.scss"],
  providers: [FLATINPUT_VALUE_ACCESSOR],
})
export class FlatInputComponent implements OnInit, ControlValueAccessor {
  onChange;
  onTouched;
  @Input() disabled = false;
  @Input() buttonType: string;
  @Input() textMask = "";
  @Input() placeHolder: string;
  @Input() value = "copy";
  @Input() hasError = false;
  @Input() errorMessage = "";
  @Input() thumbnail: string;
  @Input() inputType: string = "text";
  @Input() isCurrency = false;
  @Output() valueChange = new EventEmitter<string>();
  @Output() focusChanged = new EventEmitter<string>();
  @Output() actionTriggered = new EventEmitter<string>();
  constructor() {}

  ngOnInit() {}

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  updateValue(value: any) {
    this.value = value;
    this.valueChange.emit(this.value);
  }

  blur() {
    this.focusChanged.emit();
  }

  buttonClicked(type: string) {
    this.actionTriggered.emit(type);
  }
}
