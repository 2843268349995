import { QuranQuote } from './../../models/quran-quote';
import { EventService } from 'src/app/services/event.service';
import { ChangeDetectorRef, Component, Input, OnInit, OnChanges } from '@angular/core';
import { SelectListItem } from './../../models/select-list-item';
import { Event } from './../../models/event';
import { MatSnackBar } from '@angular/material';
import { EventCategory } from 'src/app/models/event-category';
import { EventValidation } from 'src/app/models/event-validation';
import { ValidationError } from 'src/app/models/validation-error';
import { ConfirmationSnackbarComponent } from '../../components/confirmation-snackbar/confirmation-snackbar.component';
import { QuranQuoteValidation } from 'src/app/models/quran-quote-validation';

@Component({
  selector: 'app-ayah-manage',
  templateUrl: './ayah-manage.component.html',
  styleUrls: ['./ayah-manage.component.scss']
})
export class AyahManageComponent implements OnInit, OnChanges {
  categories: EventCategory[];
  categoryList: SelectListItem[];
  selectedCategory: SelectListItem;
  defaultLabel = 'Select';
  ayahs: QuranQuote[];
  ayahList: SelectListItem[];
  ayahListValue = '';
  resetAyahList: boolean;
  selectedAyah: QuranQuote;
  newAyah: QuranQuote;
  disableDelete: boolean;
  showAddAyah = false;
  placeHolderName = 'Ayah Name';
  @Input() hideAdd: boolean;

  constructor(private eventService: EventService
    , private snackBar: MatSnackBar
    , private cd: ChangeDetectorRef) {
    this.getAyahs();
    this.getCategories();
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.hideAdd) {
      this.showAddAyah = false;
    }
  }

  getAyahs() {
    this.eventService.quotesAll().subscribe(data => {
      if (data) {
        this.ayahs = [];
        this.ayahs = Object.assign([], data);
        this.ayahList = this.ayahs.map(item => ({
          label: item.name,
          value: item.id,
          disabled: false

        }));
        this.ayahListValue = this.ayahList[0].label;
      }
    });
  }

  getCategories() {
    this.eventService.categoriesList().subscribe(data => {
      this.categories = data;
      this.categoryList = this.categories.map(item => ({
        label: item.name,
        value: item.id,
        disabled: false
      }));

    });
  }

  OnAyahListSelectChange(value: any) {
    this.selectedAyah = this.ayahs.find(a => a.id === value);
    this.selectedCategory = this.categoryList.find(c => c.value === this.selectedAyah.categoryId);
    this.selectedAyah.validation = new QuranQuoteValidation(new ValidationError(false),
      new ValidationError(false),
      new ValidationError(false),
      new ValidationError(false), false);
  }

  OnCategorySelectChange(value: any, isNew: boolean) {
    if (!isNew) {
      this.selectedAyah.categoryId = value;
      this.updateAyah();
    } else {
      this.newAyah.categoryId = value;
    }
  }

  setUpdateValue(field: string, value: string) {
    let toUpdate = false;
    toUpdate = this.validateUpdate(field, value);
    if (toUpdate) {
      this.updateAyah();
    }
  }

  validateUpdate(field: string, value: string) {
    let isValid = true;
    if (field === 'name') {
      if (value.length === 0) {
        this.selectedAyah.validation.name.hasError = true;
        this.selectedAyah.validation.name.errorMessage = 'Required';
        isValid = false;
      } else {
        this.selectedAyah.validation.name.hasError = false;
      }
    }
    if (field === 'ayah') {
      if (value.length === 0) {
        this.selectedAyah.validation.ayah.hasError = true;
        this.selectedAyah.validation.ayah.errorMessage = 'Required';
        isValid = false;
      } else if (value.length > 250) {
        this.selectedAyah.validation.ayah.hasError = true;
        this.selectedAyah.validation.ayah.errorMessage = 'Max Allowed length is 250';
        isValid = false;
      } else {
        this.selectedAyah.validation.ayah.hasError = false;
      }
    }

    if (field === 'translation') {
      if (value.length === 0) {
        this.selectedAyah.validation.translation.hasError = true;
        this.selectedAyah.validation.translation.errorMessage = 'Required';
        isValid = false;
      } else if (value.length > 150) {
        this.selectedAyah.validation.translation.hasError = true;
        this.selectedAyah.validation.translation.errorMessage = 'Max Allowed length is 150';
        isValid = false;
      } else {
        this.selectedAyah.validation.translation.hasError = false;
      }
    }
    isValid =
      this.selectedAyah.validation.name.hasError || this.selectedAyah.validation.ayah.hasError
        || this.selectedAyah.validation.translation.hasError
        ? false
        : true;

    return isValid;
  }

  updateAyah() {
    this.eventService.quranQuoteUpdate(this.selectedAyah).subscribe(data => {
      if (data) {
        const index = this.ayahs.findIndex(c => c.id === this.selectedAyah.id);
        this.ayahs.splice(index, 1, this.selectedAyah);
        const indx = this.ayahList.findIndex(e => e.value === this.selectedAyah.id);
        this.ayahList[indx].label = this.selectedAyah.name;
      }

    });
  }

  deleteAyah() {
    if (!this.disableDelete) {
      let dismissedByAction = false;
      this.disableDelete = true;
      const selectedAyah = this.selectedAyah;
      const ayahToDelete = this.ayahs.find(c => c.id === selectedAyah.id);
      const index = this.ayahs.indexOf(ayahToDelete);
      this.ayahs.splice(index, 1); // remove the ayah from the array
      const ayahItemToDelete = this.ayahList.find(e => e.value === selectedAyah.id);
      const indx = this.ayahList.indexOf(ayahItemToDelete);
      this.ayahList.splice(indx, 1);
      this.ayahListValue = null;
      this.resetAyahList = true;

      const deleteSnackBar = this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
        horizontalPosition: 'center',
        duration: 5000,
        panelClass: 'ayah-snack-bar',
        data: {
          undoDelete: () => {
            dismissedByAction = true;
            this.ayahs.splice(index, 0, ayahToDelete);
            this.ayahList.splice(indx, 0, ayahItemToDelete);
            this.ayahListValue = ayahItemToDelete.label;
            this.resetAyahList = false;
            this.cd.detectChanges();
            const undoSnackBar = this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
              horizontalPosition: 'center',
              duration: 5000,
              panelClass: 'ayah-snack-bar',
              data: {
                dismissSnackbar: () => {
                  this.snackBar.dismiss();
                  this.disableDelete = false;
                },
                displayText: 'Undone!',
                type: 'message'
              }
            });
            this.disableDelete = false;
          },
          dismissSnackbar: () => {
            dismissedByAction = true;
            this.eventService.quranQuoteRemove(selectedAyah.id).subscribe();
            this.selectedAyah = null;
            this.ayahListValue = null;
            this.resetAyahList = false;
            this.snackBar.dismiss();
            this.disableDelete = false;
          },
          displayText: `Ayah ${selectedAyah.name}, is deleted`,
          type: 'delete-kid'
        }
      });

      // delete the contact if the snackbar closes due to duration and not closed by user.
      deleteSnackBar.afterDismissed().subscribe(info => {
        if (info.dismissedByAction === false && dismissedByAction === false) {
          this.eventService.quranQuoteRemove(selectedAyah.id).subscribe();
          this.selectedAyah = null;
          this.ayahListValue = null;
          this.resetAyahList = false;
          this.disableDelete = false;
        }
      });
    }
  }

  resetAyah() {
    this.newAyah = {
      id: 0,
      name: '',
      ayah: '',
      translation: '',
      categoryId: 1,
      displayDate: null,
      validation: new QuranQuoteValidation(new ValidationError(true),
        new ValidationError(false),
        new ValidationError(false),
        new ValidationError(false), false)
    };
  }

  addAyah() {
    this.resetAyah();
    this.showAddAyah = true;

  }

  saveAyah() {
    this.eventService.quranQuoteAdd(this.newAyah).subscribe(data => {
      this.getAyahs();
      this.closeAddAyah();
    });
  }

  closeAddAyah() {
    this.showAddAyah = false;
    this.resetAyah();
  }

  validateNew(field: string, value: string) {
    let isValid = true;
    if (field === 'name') {
      if (value.length === 0) {
        this.newAyah.validation.name.hasError = true;
        this.newAyah.validation.name.errorMessage = 'Required';
        isValid = false;
      } else {
        this.newAyah.validation.name.hasError = false;
      }
    }
    if (field === 'ayah') {
      if (value.length === 0) {
        this.newAyah.validation.ayah.hasError = true;
        this.newAyah.validation.ayah.errorMessage = 'Required';
        isValid = false;
      } else if (value.length > 250) {
        this.newAyah.validation.ayah.hasError = true;
        this.newAyah.validation.ayah.errorMessage = 'Max Allowed length is 250';
        isValid = false;
      } else {
        this.newAyah.validation.ayah.hasError = false;
      }
    }

    if (field === 'translation') {
      if (value.length === 0) {
        this.newAyah.validation.translation.hasError = true;
        this.newAyah.validation.translation.errorMessage = 'Required';
        isValid = false;
      } else if (value.length > 150) {
        this.newAyah.validation.translation.hasError = true;
        this.newAyah.validation.translation.errorMessage = 'Max Allowed length is 150';
        isValid = false;
      } else {
        this.newAyah.validation.translation.hasError = false;
      }
    }
    isValid =
      this.newAyah.validation.name.hasError || this.newAyah.validation.ayah.hasError
        || this.newAyah.validation.translation.hasError
        ? false
        : true;

    this.newAyah.validation.hasError = isValid;

  }


}
