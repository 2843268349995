export class DonorboxPayment {
  memberId: number;
  paymentId: string;
  amount: number;
  paymentDate: any;
  firstName: string;
  lastName: string;
  familyMemberNames: string[];

}
