import { PaymentValidation } from './payment-validation';
export class MemberPayment {
  id: number;
  memberId: number;
  paymentId: string;
  amount: number;
  paymentDate: any;
  paymentDateDisplay: string;
  expirationDate: Date;
  validityInMonths: number;
  membershipYear: string;
  note: string;
  validation: PaymentValidation;

}
