import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SchoolManageService } from 'src/app/services/school-manage.service';
import * as _moment from "moment";
import { DateAdapter, MatSnackBar, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Fee } from 'src/app/models/school/fee';
import { ManageSemester } from 'src/app/models/school/manage-semester';
import { map } from 'rxjs/operators';
import { ConfirmationSnackbarComponent } from '../confirmation-snackbar/confirmation-snackbar.component';

const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY",
  },
  display: {
    dateInput: "MM-DD-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "MM-DD-YYYY",
    monthYearA11yLabel: "MM-DD-YYYY",
  },
};
@Component({
  selector: 'app-ieb-semesters',
  templateUrl: './ieb-semesters.component.html',
  styleUrls: ['./ieb-semesters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class IebSemestersComponent implements OnInit {

  VM$ = this.schoolService.semesters$;

  message$ = this.schoolService.semesterMessage$.pipe(
    map((toggle) => {
      this.toggleConfirmation(toggle);
    })

  );


  displayedColumns: string[] = ['name', 'amount', 'validity'];
  disableAdd = false;
  showNewSemester = false;
  newSchoolFees : Fee[];
  disableBtn: boolean;
  constructor(private schoolService: SchoolManageService, private snackBar: MatSnackBar) { }

  ngOnInit() {
  }
  clickAddKid(): void {
    this.disableAdd = true;
    this.showNewSemester = true;
   }
   cancel() {
    this.disableAdd = false;
    this.showNewSemester = false;
   }

   setDisableBtn(amount): void{
    //let fee = parseFloat(amount).toFixed(2);
    this.disableBtn = !amount;
   }

   addSemester(model: ManageSemester): void {
    this.schoolService.newSemester(model);
   }

   toggleConfirmation(toggle: boolean){
    if(toggle) {
      this.showConfirmationDialog('Semester information is saved', 'success');
      this.schoolService.semesterMessageToggle(false);
    }
   }

   showConfirmationDialog(message: string, type: string) {
    if(!message) {
      return;
    }
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 5000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: type,
      },
    });
  }


}
