export class AddFileRequest {
  access: string;
  fileName: string;
  fileType: string;
  fileByteString: string;
  fileBytes: Uint8Array;

  constructor(access: string, fileName: string, fileType: string,
    fileByteString: string, fileBytes: Uint8Array) {
    this.access = access;
    this.fileName = fileName;
    this.fileType = fileType;
    this.fileByteString = fileByteString;
    this.fileBytes = fileBytes;
  }

}
