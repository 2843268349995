import { EventCategory } from "./event-category";
import { EventValidation } from "./event-validation";
import { EventFileSummary } from "./event-file-summary";
export class Event {
  id: number;
  categoryId: number;
  eventDate: any;
  eDate: string;
  name: string;
  description: string;
  address: string;
  videoUrl: string;
  anchorUrl: string;
  anchorUrlText: string;
  category: EventCategory;
  hasEventDetails: boolean;
  createdDate: any;
  eventFilesSummaryList: EventFileSummary[];
  validation: EventValidation;
}
