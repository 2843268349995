import { File } from './file'
import { TeamValidation } from './team-validation';
export class Team {
  id: number;
  fileId: number;
  type: string;
  name: string;
  position: string;
  file: File;
  contact: string;
  validation: TeamValidation;
}
