import { ParkingSpace } from "./../models/parking-space";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { GenericDataService } from "./generic-data.service";
import { ParkingSpaceInfo } from "../models/parking-space-info";

@Injectable({
  providedIn: 'root'
})
export class ParkingSpaceService {

  public parkingSubject$ = new Subject<boolean>();
  parkingSpaces: ParkingSpaceInfo[];
  parkingSpace: ParkingSpace;
  constructor(private genericDataService: GenericDataService) {}

  public parkingSpaceList(): any {
    this.genericDataService.endPoint = "ParkingSpace/List";

    const listObservable$ = new Observable((subscriber) => {
      this.genericDataService.list<any>(null, null).subscribe((data) => {
        this.parkingSpaces = Object.assign([], data);
        subscriber.next(this.parkingSpaces);
      });
    });
    return listObservable$;
  }

  public parkingSpaceAdd(request: ParkingSpace): any {
    this.genericDataService.endPoint = "ParkingSpace/Add";
    const addObservable$ = new Observable((subscriber) => {
      this.genericDataService.post<any>(request).subscribe((data) => {
        this.parkingSpace = Object.assign({}, data);
        subscriber.next(this.parkingSpace);
      });
    });
    return addObservable$;
  }

  public parkingSpaceUpdate(request: ParkingSpace): any {
    this.genericDataService.endPoint = "ParkingSpace/Update";
    const updateObservable$ = new Observable((subscriber) => {
      this.genericDataService.post<any>(request).subscribe((data) => {
        this.parkingSpace = Object.assign({}, data);
        subscriber.next(this.parkingSpace);
      });
    });
    return updateObservable$;
  }

  public parkingSpaceRemove(id: number): any {
    this.genericDataService.endPoint = "ParkingSpace/Remove";
    const removeObservable$ = new Observable((observer) => {
      this.genericDataService.delete<any>(null, id).subscribe((data) => {
        this.parkingSubject$.next(true);
      });
    });
    return removeObservable$;
  }

  public parkingSpaceGet(id: number): any {
    this.genericDataService.endPoint = "ParkingSpace";
    const getObservable$ = new Observable((subscriber) => {
      this.genericDataService
        .readById<ParkingSpace>(null, id)
        .subscribe((data) => {
          this.parkingSpace = Object.assign({}, data);
          subscriber.next(this.parkingSpace);
        });
    });
    return getObservable$;
  }

}
