import { Component, Inject, OnChanges, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { UploadDialogModel } from "src/app/models/upload-dialog-model";
import { UploadFile } from "src/app/models/upload-file";

@Component({
  selector: "app-upload-modal",
  templateUrl: "./upload-modal.component.html",
  styleUrls: ["./upload-modal.component.scss"],
})
export class UploadModalComponent implements OnInit, OnChanges {
  model: UploadDialogModel;
  uploadedfile: UploadFile;
  uploadedFiles: UploadFile[] = [];
  faTimes = faTimes;

  constructor(
    public dialogRef: MatDialogRef<UploadModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UploadDialogModel
  ) {
    this.model = Object.assign({}, data);
    this.model.uploadedFiles = !this.model.uploadedFiles
      ? []
      : this.model.uploadedFiles;
  }

  ngOnInit() {
    this.uploadedFiles = this.model.uploadedFiles;
  }

  ngOnChanges() {
    this.uploadedFiles = this.model.uploadedFiles;
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.model.errorMessage = null;
    this.model.hasError = false;
    if (!this.model.allowMultiple && this.uploadedFiles.length > 0) {
      //this.showConfirmationDialog("Only one file is allowed!");
      // this.model.errorMessage = 'Only one file is allowed!';
      // this.model.hasError = false;
      this.toggleError("Only one file is allowed!");
      return;
    }
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param id (File id)
   */
  deleteFile(id: number) {
    const index = this.uploadedFiles.findIndex((f) => f.id === id);
    //this.notifyDelete.emit(id);
    this.uploadedFiles.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.uploadedFiles.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.uploadedFiles[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.uploadedFiles[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    var index = this.uploadedFiles.findIndex(
      (f) => f.name.toLowerCase() === files[0].name.toLowerCase()
    );
    if (index !== -1) {
      // this.showConfirmationDialog("File already uploaded");
      this.toggleError("File already uploaded!");
      return;
    }
    for (const item of files) {
      item.progress = 0;
      this.onFileSelected(item);
    }

    this.uploadFilesSimulator(0);
  }

  // /**
  //  * format bytes
  //  * @param bytes (File size in bytes)
  //  * @param decimals (Decimals point)
  //  */
  // formatBytes(bytes, decimals) {
  //   if (!bytes || bytes === 0) {
  //     return "0 Bytes";
  //   }
  //   const k = 1024;
  //   const dm = decimals <= 0 ? 0 : decimals || 2;
  //   const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  //   const i = Math.floor(Math.log(bytes) / Math.log(k));
  //   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  // }

  async onFileSelected(file: any) {
    this.model.errorMessage = null;
    this.model.hasError = false;
    if (this.validateFileImage(file)) {
      const contentBuffer: any = await this.readFileAsync(file);
      const id = this.uploadedFiles.length + 1;
      this.uploadedfile = new UploadFile(
        id,
        file.name,
        "",
        contentBuffer,
        0,
        file.size
      );
      this.uploadedFiles.push(this.uploadedfile);
      //this.uploadedfile = new AddFileRequest('G', file.name, '', contentBuffer, null);

      //this.notifyUpload.emit(this.uploadedfile);
    }
  }

  readFileAsync(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().split(",")[1];
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };

      reader.onerror = reject;
    });
  }

  validateFileImage(file: any) {
    if (!this.model.imageOnly) {
      return true;
    }
    if (!file) {
      return false;
    }
    const fileType = file["type"];
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
    if (!validImageTypes.includes(fileType)) {
      //  this.model.errorMessage = 'Invalid Image!';
      //  this.model.hasError = false;
      this.toggleError("Invalid Image!");
      return false;
    }
    // 5MB
    if (file.size > 5242880) {
      //this.showConfirmationDialog("Image size should not exceed 5MB");
      this.toggleError("Image size should not exceed 5MB!");
      //this.model.errorMessage = 'Image size should not exceed 5MB!';
      //this.model.hasError = false;
      return false;
    }
    return true;
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.model.uploadedFiles = Object.assign([], this.uploadedFiles);
    this.dialogRef.close(this.model);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.model.uploadedFiles = Object.assign([], this.uploadedFiles);
    this.dialogRef.close(this.model);
  }

  toggleError(message: string): void {
    this.model.errorMessage = message;
    this.model.hasError = true;
    setTimeout(
      function () {
        this.model.errorMessage = "";
        this.model.hasError = false;
        //console.log(this.edited);
      }.bind(this),
      3000
    );
  }
}
