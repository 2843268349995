import { ImageSlide } from './../../models/image-slides';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventService } from 'src/app/services/event.service';
import { Event } from './../../models/event';
import * as _moment from 'moment';
import { ConfigService } from 'src/app/services/config.service';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';

const moment = _moment;
@Component({
  selector: 'app-announcement-detail',
  templateUrl: './announcement-detail.component.html',
  styleUrls: ['./announcement-detail.component.scss']
})
export class AnnouncementDetailComponent implements OnInit {

  eventId: number;
  event: Event;
  slides: ImageSlide[];
  eDateMobil: string;
  constructor(private eventService: EventService
    , private route: ActivatedRoute
    , private configService: ConfigService) { }

  ngOnInit() {
    this.getEventId();
    this.getEvent();
  }

  getEventId() {
    this.route.queryParams.subscribe(param => {
      this.eventId = param.eventId ? param.eventId : null;

    });
  }

  getEvent() {
    if (!this.eventId) {
      return;
    }
    this.eventService.annoucementGet(this.eventId).subscribe(data => {
      this.event = Object.assign({}, data);
      this.setEventDisplayDate();
      if (this.event.eventFilesSummaryList && this.event.eventFilesSummaryList.length > 0) {
        this.slides = this.event.eventFilesSummaryList.map((item) => ({
          image: `${this.configService.config.fileBaseUrl}${item.storedFileName}`
        }));
        console.log(this.slides);
      }
    });
  }

  setEventDisplayDate() {
    if (!this.event) {
      return;
    }

    if (!this.event.eventDate) {
      this.event.eDate = '';
      return;
    }
    const eventDateLocal = moment(this.event.eventDate).local();
    this.event.eDate = `${moment(eventDateLocal).format('MMMM Do YYYY')}
    (${moment(eventDateLocal).format('dddd')}) ${moment(eventDateLocal).format('h:mm a')}`;
    this.eDateMobil = moment(eventDateLocal).format('MMMM Do YYYY h:mm a');
  }

}
