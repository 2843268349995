import { ValidationError } from "./validation-error";

export class BookRoomValidation {
  tentativeStartDate: ValidationError;
  uploadedFiles: ValidationError;
  hasError: boolean;

  constructor(
    tentativeStartDate: ValidationError,
    uploadedFiles: ValidationError,
    hasError: boolean
  ) {
    this.tentativeStartDate = tentativeStartDate;
    this.uploadedFiles = uploadedFiles;
    this.hasError = hasError;
  }
}
