import { EventFileSummary } from './../models/event-file-summary';
import { ConfigService } from './config.service';
import { EventCategory } from './../models/event-category';
import { QuranQuote } from './../models/quran-quote';
import { Injectable } from '@angular/core';
import { GenericDataService } from './generic-data.service';
import { Event } from '.././models/event';
import { ListRequest } from '../models/list-request';
import { Observable, Subject } from 'rxjs';
import { AddEventFileRequest } from '../models/add-event-file-request';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  announcements: Event[];
  announcement: Event;
  categories: EventCategory[];
  category: EventCategory;
  quote: QuranQuote;
  quotes: QuranQuote[];
  categoryExists: boolean;
  eventFileSummary: EventFileSummary;

  public annoucementEvent$ = new Subject<boolean>();
  public categoryEvent$ = new Subject<boolean>();
  public quoteEvent$ = new Subject<boolean>();
  public eventFile$ = new Subject<boolean>();
  constructor(private genericDataService: GenericDataService, private configService: ConfigService) { }

  public announcementList(request: ListRequest): any {
    this.genericDataService.endPoint = 'Event/List';

    const listObservable$ = new Observable(subscriber => {
      this.genericDataService.post<any>(request).subscribe(data => {
        this.announcements = Object.assign([], data.items);
        subscriber.next(this.announcements);
      });
    });
    return listObservable$;

  }

  public announcementAll(): any {
    this.genericDataService.endPoint = 'Event/All';
    const listObservable$ = new Observable(subscriber => {
      this.genericDataService.list<any>(null, null).subscribe(data => {
        this.announcements = Object.assign([], data);
        subscriber.next(this.announcements);
      });
    });
    return listObservable$;
  }

  public annoucementAdd(request: Event): any {
    this.genericDataService.endPoint = 'Event/New';
    const eventObservable$ = new Observable(subscriber => {
      this.genericDataService.post<any>(request).subscribe(data => {
        this.announcement = Object.assign({}, data);
        subscriber.next(this.announcement);
      });
    });
    return eventObservable$;
  }

  public annoucementUpdate(request: Event): any {
    this.genericDataService.endPoint = 'Event/Update';
    const eventObservable$ = new Observable(subscriber => {
      this.genericDataService.post<any>(request).subscribe(data => {
        this.announcement = Object.assign({}, data);
        subscriber.next(this.announcement);
      });
    });
    return eventObservable$;
  }

  public annoucementRemove(id: number): any {
    this.genericDataService.endPoint = 'Event/Remove';
    const eventObservable$ = new Observable(observer => {
      this.genericDataService.delete<any>(null, id).subscribe(data => {
        this.annoucementEvent$.next(true);
      });
    });
    return eventObservable$;
  }

  public annoucementGet(eventId: number): any {
    this.genericDataService.endPoint = 'Event';
    const eventObservable$ = new Observable(subscriber => {
      this.genericDataService.readById<any>(null, eventId).subscribe(data => {
        this.announcement = Object.assign({}, data);
        subscriber.next(this.announcement);
      });
    });
    return eventObservable$;
  }

  public eventFileAdd(request: AddEventFileRequest): any {
    this.genericDataService.endPoint = 'Event/File/Add';
    const fileObservable$ = new Observable(subscriber => {
      this.genericDataService.post<any>(request).subscribe(data => {
        this.eventFileSummary = Object.assign({}, data);
        this.eventFileSummary.url = `${this.configService.config.fileBaseUrl}${this.eventFileSummary.storedFileName}`;
        this.eventFileSummary.thumbNail = `${this.configService.config.fileBaseUrl}${this.eventFileSummary.thumbNail}`;
        subscriber.next(this.eventFileSummary);
      });
    });

    return fileObservable$;
  }

  public eventFileRemove(eventFileId: number): any {
    this.genericDataService.endPoint = 'Event/File/Remove';
    const fileObservable$ = new Observable(observer => {
      this.genericDataService.delete<any>(null, eventFileId).subscribe(data => {
        this.eventFile$.next(true);
      });
    });
    return fileObservable$;
  }

  public categoriesList(): any {
    this.genericDataService.endPoint = 'Event/Categories/List';
    const listObservable$ = new Observable(subscriber => {
      this.genericDataService.list<any>(null, null).subscribe(data => {
        this.categories = Object.assign([], data);
        subscriber.next(this.categories);
      });
    });
    return listObservable$;
  }

  public categoryAdd(request: EventCategory): any {
    this.genericDataService.endPoint = 'Event/Category/New';
    const categoryObservable$ = new Observable(subscriber => {
      this.genericDataService.create<EventCategory>(null, request).subscribe(data => {
        this.category = Object.assign(data);
        subscriber.next(this.category);
      });
    });
    return categoryObservable$;
  }

  public categoryUpdate(request: EventCategory): any {
    this.genericDataService.endPoint = 'Event/Category/Update';
    const categoryObservable$ = new Observable(subscriber => {
      this.genericDataService.post<any>(request).subscribe(data => {
        this.category = Object.assign({}, data);
        subscriber.next(this.category);
      });
    });
    return categoryObservable$;
  }

  public categoryRemove(id: number): any {
    this.genericDataService.endPoint = 'Event/Category/Remove';
    const categoryObservable$ = new Observable(subscriber => {
      this.genericDataService.delete<any>(null, id).subscribe(data => {
        this.categoryEvent$.next(true);
      });
    });
    return categoryObservable$;
  }

  public checkCategoryExists(name: string): any {
    this.genericDataService.endPoint = 'Event/Category/Exists';
    const existsObservable$ = new Observable(subscriber => {
      this.genericDataService.readById<boolean>(null, name).subscribe(data => {
        this.categoryExists = data;
        subscriber.next(this.categoryExists);
      });
    });
    return existsObservable$;
  }

  public quranQuoteGet(): any {
    this.genericDataService.endPoint = 'Event/Quote';
    const quoteObservable$ = new Observable(subscriber => {
      this.genericDataService.readById<QuranQuote>(null, null).subscribe(data => {
        this.quote = Object.assign({}, data);
        subscriber.next(this.quote);
      });
    });
    return quoteObservable$;
  }

  public quotesAll(): any {
    this.genericDataService.endPoint = 'Event/Quotes';
    const listObservable$ = new Observable(subscriber => {
      this.genericDataService.list<any>(null, null).subscribe(data => {
        this.quotes = Object.assign([], data);
        subscriber.next(this.quotes);
      });
    });
    return listObservable$;
  }

  public quranQuoteAdd(request: QuranQuote): any {
    this.genericDataService.endPoint = 'Event/Quote/New';
    const quoteObservable$ = new Observable(subscriber => {
      this.genericDataService.post<any>(request).subscribe(data => {
        this.quote = Object.assign({}, data);
        subscriber.next(this.quote);
      });
    });
    return quoteObservable$;
  }

  public quranQuoteUpdate(request: QuranQuote): any {
    this.genericDataService.endPoint = 'Event/Quote/Update';
    const quoteObservable$ = new Observable(subscriber => {
      this.genericDataService.post<any>(request).subscribe(data => {
        this.quote = Object.assign({}, data);
        subscriber.next(this.quote);
      });
    });
    return quoteObservable$;
  }

  public quranQuoteRemove(id: number): any {
    this.genericDataService.endPoint = 'Event/Quote/Remove';
    const quoteObservable$ = new Observable(observer => {
      this.genericDataService.delete<any>(null, id).subscribe(data => {
        this.quoteEvent$.next(true);
      });
    });
    return quoteObservable$;
  }


}
