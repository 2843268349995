import { TenantWaitService } from "./../../services/tenant-wait.service";
import { TenantService } from "./../../services/tenant.service";
import { filter } from "rxjs/operators";
import { TenantWaitInfo } from "./../../models/tenant-wait-info";
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { RoomInfo } from "src/app/models/room-info";
import { RoomService } from "src/app/services/room.service";
import { SelectListItem } from "src/app/models/select-list-item";
import { Rent } from "src/app/models/rent";
import { RoomFilters } from "src/app/models/room-filters";
import { ListRequest } from "src/app/models/list-request";
import { RentService } from "src/app/services/rent.service";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { ConfirmDialogModel } from "src/app/models/confirm-dialog-model";
import { MatDialog, MatSnackBar } from "@angular/material";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { WaitToTenantRequest } from "src/app/models/wait-to-tenant-request";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import * as _moment from "moment";
import { ConfirmationSnackbarComponent } from "../confirmation-snackbar/confirmation-snackbar.component";
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY",
  },
  display: {
    dateInput: "MM-DD-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "MM-DD-YYYY",
    monthYearA11yLabel: "MM-DD-YYYY",
  },
};

@Component({
  selector: "app-wait-details",
  templateUrl: "./wait-details.component.html",
  styleUrls: ["./wait-details.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class WaitDetailsComponent implements OnInit, OnChanges {
  @Input() model: TenantWaitInfo;
  @Output() notifyParent = new EventEmitter<number>();
  roomList: SelectListItem[];
  rooms: RoomInfo[];
  rents: Rent[];
  roomFilters: RoomFilters;
  listRequest: ListRequest;
  defaultLabel = "Select";
  rentList: SelectListItem[];
  selectedRoom: RoomInfo;
  selectedRent: Rent;
  isStudent: string;
  residencyStatus: string;
  faFile = faFile;
  gender: string;
  moveRequest: WaitToTenantRequest;
  moveDisabled: boolean;
  tentativeSDate: any;
  minDate = moment().clone().local();
  constructor(
    private roomService: RoomService,
    private rentService: RentService,
    public dialog: MatDialog,
    private tenantService: TenantService,
    private waitService: TenantWaitService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.getRooms();
    this.getRents();
    this.moveRequest = {
      waitId: this.model.id,
      roomId: null,
      rentId: null,
      tentativeStartDate: this.model.tentativeStartDate,
    };
    // this.moveDisabled =
    //   this.moveRequest.rentId &&
    //   this.moveRequest.roomId &&
    //   this.moveRequest.waitId &&
    //   this.moveRequest.tentativeStartDate
    //     ? false
    //     : true;
  }

  ngOnChanges() {
    if (this.model) {
      this.isStudent = this.model.memberInfo.isStudent ? "Yes" : "No";
      this.residencyStatus = this.model.memberInfo.residencyStatus
        ? "Yes"
        : "No";
      this.model.memberInfo.gender = !this.model.memberInfo.gender
        ? "M"
        : this.model.memberInfo.gender;
      this.gender = this.model.memberInfo.gender === "M" ? "Male" : "Female";
      this.model.tentativeStartDate = this.model.tentativeStartDate
        ? moment(this.model.tentativeStartDate).clone().local()
        : moment().clone().add(1, "M").startOf("month").local();
      this.tentativeSDate = this.model.tentativeStartDate;
    }
  }

  getRooms() {
    this.roomFilters = {
      name: null,
      occupancy: null,
      rentId: null,
    };

    this.listRequest = {
      filter: this.roomFilters,
      itemsPerPage: null,
      pageNumber: null,
    };
    this.roomService.roomList(this.listRequest).subscribe((data) => {
      if (data) {
        this.rooms = Object.assign([], data);
        this.setRoomList();
      }
    });
  }

  setRoomList() {
    var filteredRooms = this.rooms;
    if (this.model.memberInfo.gender) {
      filteredRooms = this.rooms.filter(r=> r.name.toLowerCase().startsWith(this.model.memberInfo.gender.toLowerCase()));
      if (this.model.memberInfo.gender === "M") {
        this.selectedRoom =
          this.model.roomType === "Single"
            ? this.rooms.find(
                (r) => r.occupancy === 1 && r.name.toLowerCase().startsWith("m")
              )
            : this.rooms.find(
                (r) => r.occupancy === 2 && r.name.toLowerCase().startsWith("m")
              );
      } else {
        this.selectedRoom =
          this.model.roomType === "Single"
            ? this.rooms.find(
                (r) => r.occupancy === 1 && r.name.toLowerCase().startsWith("f")
              )
            : this.rooms.find(
                (r) => r.occupancy === 2 && r.name.toLowerCase().startsWith("f")
              );
      }
    }

    this.roomList = [];
    this.roomList = filteredRooms.map((item) => ({
      label: `${item.name}/${item.occupancy}(${moment(item.availableDate).clone().local().format('MM-DD-YYYY')})`,
      value: item.id,
      disabled: false//!sDate.isSameOrAfter(moment(item.availableDate).clone().local()),
    }));
    if(this.selectedRoom) {
      let sDate = moment(this.model.tentativeStartDate).clone().local();
      if(sDate.isBefore(this.selectedRoom.availableDate)) {
        this.tentativeSDate = this.selectedRoom.availableDate;
        this.minDate = this.tentativeSDate;
      }
    }
    this.setRentList(this.selectedRoom.id);
  }

  getRents() {
    this.rentService.rentList().subscribe((data) => {
      if (data) {
        this.rents = Object.assign([], data);
        this.rents = this.rents.filter((r) => r.type === "Room");
        this.selectedRent =
          this.model.roomType === "Single"
            ? this.rents.find((r) => r.name.toLowerCase() === "single")
            : this.rents.find((r) => r.name.toLowerCase() === "double");
        this.moveRequest.rentId = this.selectedRent.id;
        this.moveDisabled =
          this.moveRequest.rentId &&
          this.moveRequest.roomId &&
          this.moveRequest.waitId &&
          this.moveRequest.tentativeStartDate
            ? false
            : true;
        // this.rentList = this.rents.map((item) => ({
        //   label: `${item.name}-$${item.amount}`,
        //   value: item.id,
        //   disabled: false,
        // }));
      }
    });
  }

  setRentList(roomId: number) {
    this.selectedRoom = this.rooms.find((r) => r.id === roomId);
    if (this.selectedRoom == null) {
      return;
    }

    this.rentList = this.selectedRoom.rents.map((item) => ({
      label: `${item.name}-$${item.amount}`,
      value: item.id,
      disabled: false,
    }));
    let sDate = moment(this.model.tentativeStartDate).clone().local();
      if(sDate.isBefore(this.selectedRoom.availableDate)) {
        this.tentativeSDate = this.selectedRoom.availableDate;
        this.minDate = this.tentativeSDate;
      }
    this.selectedRoom.isAvailable = !this.roomList.find((r)=> r.value === roomId).disabled;
    this.moveRequest.roomId = this.selectedRoom.isAvailable
      ? this.selectedRoom.id
      : null;
    this.moveDisabled =
      this.moveRequest.rentId &&
      this.moveRequest.roomId &&
      this.moveRequest.waitId &&
      this.moveRequest.tentativeStartDate
        ? false
        : true;
    if (
      this.gender === "Male" &&
      this.selectedRoom.name.toLowerCase().startsWith("f")
    ) {
      const dialogData = new ConfirmDialogModel(
        "Alert",
        "Are you sure? This is a girl's room!.",
        "alert",
        true
      );
      this.openAlertDialog(dialogData, "setRoom");
    } else if (
      this.gender === "Female" &&
      this.selectedRoom.name.toLowerCase().startsWith("m")
    ) {
      const dialogData = new ConfirmDialogModel(
        "Alert",
        "Are you sure? This is a boy's room!.",
        "alert",
        true
      );
      this.openAlertDialog(dialogData, "setRoom");
    }
  }

  setRent(rentId: number) {
    this.selectedRent = this.rents.find((r) => r.id == rentId);
    if (this.selectedRent == null) {
      return;
    }
    this.moveRequest.rentId = this.selectedRent.id;
    this.moveDisabled =
      this.moveRequest.rentId &&
      this.moveRequest.roomId &&
      this.moveRequest.waitId &&
      this.moveRequest.tentativeStartDate
        ? false
        : true;
  }

  startDateChange() {
    //let sDate = moment(this.tentativeSDate).clone().local();
    this.roomList = this.rooms.map((item) => ({
      label: `${item.name}/${item.occupancy}`,
      value: item.id,
      disabled: false,
    }));
    this.setRentList(this.selectedRoom.id);
  }

  openAlertDialog(dialogData: ConfirmDialogModel, sender: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "500px",
      height: "300px",
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (sender === "setRoom") {
        if (!dialogResult) {
          this.setRoomList();
        }
      }
      if (sender === "save") {
        if (dialogResult) {
          this.moveToTenant();
        }
      }
      if (sender === "reject") {
        if (dialogResult) {
          this.removeWait();
        }
      }
    });
  }
  saveAlert() {
    const dialogData = new ConfirmDialogModel(
      "Alert",
      "Is this request verified? This action will send lease create request to tenant.",
      "alert",
      true
    );
    this.openAlertDialog(dialogData, "save");
  }

  rejectAlert() {
    const dialogData = new ConfirmDialogModel(
      "Alert",
      "Are you sure? This action will delete this wait list record.",
      "alert",
      true
    );
    this.openAlertDialog(dialogData, "reject");
  }

  moveToTenant() {
    if (this.moveDisabled) {
      return;
    }
    this.moveRequest.tentativeStartDate = this.tentativeSDate;
    this.tenantService.tenantFromWait(this.moveRequest).subscribe((data) => {
      if (data) {
        this.showConfirmationDialog("Your request is successfull.");
        this.notifyParent.emit(this.model.id);
      }
    });
  }

  removeWait() {
    this.waitService.tenantWaitRemove(this.model.id).subscribe((res) => {
      if (res) {
        this.notifyParent.emit(this.model.id);
        this.showConfirmationDialog("Your request is successfull.");
      }
    });
  }
  showConfirmationDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 20000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: "success",
      },
    });
  }
}
