import { Rent } from "./../../models/rent";
import { ParkingSpaceService } from "./../../services/parking-space.service";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ParkingSpaceInfo } from "src/app/models/parking-space-info";
import * as _moment from "moment";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { ParkingValidation } from "src/app/models/parking-validation";
import { ValidationError } from "src/app/models/validation-error";
import { RentService } from "src/app/services/rent.service";
import { ParkingSpace } from "src/app/models/parking-space";
import { ConfirmationSnackbarComponent } from "../confirmation-snackbar/confirmation-snackbar.component";
import { MatSnackBar } from "@angular/material";
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY",
  },
  display: {
    dateInput: "MM-DD-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "MM-DD-YYYY",
    monthYearA11yLabel: "MM-DD-YYYY",
  },
};
@Component({
  selector: "app-parking-manage",
  templateUrl: "./parking-manage.component.html",
  styleUrls: ["./parking-manage.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ParkingManageComponent implements OnInit {
  parkings: ParkingSpaceInfo[];
  parkingRent: Rent;
  showAddParking: boolean;
  selectedParking: ParkingSpaceInfo;
  disableDelete: boolean;
  newParking: ParkingSpaceInfo;
  constructor(
    private parkingService: ParkingSpaceService,
    private rentService: RentService,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getParkings();
    this.getParkingRent();
  }

  getParkings() {
    this.parkingService.parkingSpaceList().subscribe((data) => {
      if (data) {
        this.parkings = Object.assign([], data);
        this.parkings.forEach((item) => {
          item.availableDate = !item.availableDate
            ? moment().clone().local()
            : moment(item.availableDate).local();
          item.amountDisplay = `$${item.amount}`;
          item.validation = new ParkingValidation(
            new ValidationError(false),
            false
          );
        });
      }
    });
  }

  getParkingRent() {
    this.rentService.rentByType("Parking").subscribe((rents) => {
      if (rents && rents.length > 0) {
        this.parkingRent = rents[0];
        this.resetNewParking();
      }
    });
  }

  setUpdate(field: string, value: any, pId: number) {
    let parkingToUpdate = Object.assign(
      {},
      this.parkings.find((p) => p.id === pId)
    );
    parkingToUpdate.validation.hasError = !this.validateUpdate(
      field,
      value,
      parkingToUpdate
    );
    if (parkingToUpdate.validation.hasError) {
      return;
    }

    if (field === "availableDate") {
      parkingToUpdate.availableDate = moment(value).clone().local();
    }
    this.updateParking(parkingToUpdate);
  }

  validateUpdate(
    field: string,
    value: any,
    parking: ParkingSpaceInfo
  ): boolean {
    let isValid = true;
    if (field === "name") {
      if (value.length === 0) {
        parking.validation.name.hasError = true;
        parking.validation.name.errorMessage = "Required!";
      } else {
        parking.validation.name.hasError = false;
        //parking.name = value;
      }
    }

    isValid = parking.validation.name.hasError ? false : true;

    return isValid;
  }

  updateParking(parkingToUpdate: ParkingSpaceInfo) {
    if (!parkingToUpdate) {
      return;
    }
    let parking = new ParkingSpace();
    parking.id = parkingToUpdate.id;
    parking.name = parkingToUpdate.name;
    parking.availableDate = parkingToUpdate.availableDate;

    this.parkingService.parkingSpaceUpdate(parking).subscribe((data) => {
      if (data) {
        this.showConfirmationDialog("Room is updatd.");
      }
    });
  }

  delete(parking: ParkingSpaceInfo) {
    if (this.disableDelete) {
      return;
    }
    let dismissedByAction = false;
    this.disableDelete = true;

    const index = this.parkings.indexOf(parking);
    this.parkings.splice(index, 1);
    const deleteSnackBar = this.snackBar.openFromComponent(
      ConfirmationSnackbarComponent,
      {
        horizontalPosition: "center",
        duration: 10000,
        panelClass: "delete-snack-bar",
        data: {
          undoDelete: () => {
            dismissedByAction = true;
            this.parkings.splice(index, 0, parking);
            this.cd.detectChanges();
            const undoSnackBar = this.snackBar.openFromComponent(
              ConfirmationSnackbarComponent,
              {
                horizontalPosition: "center",
                duration: 10000,
                panelClass: "delete-snack-bar",
                data: {
                  dismissSnackbar: () => {
                    this.snackBar.dismiss();
                    this.disableDelete = false;
                  },
                  displayText: "Undone!",
                  type: "message",
                },
              }
            );
            this.disableDelete = false;
          },
          dismissSnackbar: () => {
            dismissedByAction = true;
            this.parkingService.parkingSpaceRemove(parking.id).subscribe();
            this.snackBar.dismiss();
            this.disableDelete = false;
          },
          displayText: `Parking ${parking.name} deleted`,
          type: "delete-kid",
        },
      }
    );
    // delete the kid if the snackbar closes due to duration and not closed by user.
    deleteSnackBar.afterDismissed().subscribe((info) => {
      if (info.dismissedByAction === false && dismissedByAction === false) {
        this.parkingService.parkingSpaceRemove(parking.id).subscribe();
        this.disableDelete = false;
      }
    });
  }

  resetNewParking() {
    this.newParking = {
      id: 0,
      name: "",
      availableDate: moment().clone().local(),
      isAvailable: true,
      amount: this.parkingRent.amount,
      amountDisplay: "",
      rentId: this.parkingRent.id,
      validation: new ParkingValidation(new ValidationError(false), true),
    };
  }

  setNewParking(field: string, value: any) {
    if (field === "availableDate") {
      this.newParking.availableDate = moment(value).clone().local();
    }
    this.newParking.validation.hasError = !this.validateNew(
      field,
      value,
      this.newParking
    );
  }

  validateNew(field: string, value: any, parking: ParkingSpaceInfo): boolean {
    let isValid = true;
    if (field === "name") {
      if (value.length === 0) {
        parking.validation.name.hasError = true;
        parking.validation.name.errorMessage = "Required!";
      } else {
        this.newParking.validation.name.hasError = false;
        this.newParking.name = value;
      }
    }

    isValid = parking.validation.name.hasError ? false : true;

    return isValid;
  }

  saveParking() {
    if (this.newParking.validation.hasError) {
      return;
    }
    let newP = new ParkingSpace();
    newP.id = this.newParking.id;
    newP.name = this.newParking.name;
    newP.availableDate = this.newParking.availableDate;
    newP.rentId = this.newParking.rentId;

    this.parkingService.parkingSpaceAdd(newP).subscribe((data) => {
      if (data) {
        this.getParkings();
        this.showConfirmationDialog("Parking is added.");
        this.resetNewParking();
        this.showAddParking = false;
      }
    });
  }

  addParking() {
    this.showAddParking = true;
  }

  closeAddParking() {
    this.showAddParking = false;
    this.resetNewParking();
  }

  showConfirmationDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 20000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: "success",
      },
    });
  }
}
