import { UserValidation } from "./user-validation";
export class ApplicationUser {
  id: string;
  memberId: number;
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  validation: UserValidation;
}
