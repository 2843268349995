import { AddendumRequest } from "./../../models/addendum-request";
import { LeaseService } from "src/app/services/lease.service";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SelectListItem } from "src/app/models/select-list-item";
import { HelperService } from "src/app/services/helper.service";
import { LeaseInfo } from "src/app/models/lease-info";
import { TenantInfo } from "src/app/models/tenant-info";
import * as _moment from "moment";
import { MatDialog, MatSnackBar } from "@angular/material";
import { NgxSpinnerService } from "ngx-spinner";
import { SignatureModalComponent } from "../signature-modal/signature-modal.component";
import { LeaseSignature } from "src/app/models/lease-signature";
import { faSignature } from "@fortawesome/free-solid-svg-icons";
import { SignatureDialogModel } from "src/app/models/signature-dialog-model";
const moment = _moment;
@Component({
  selector: "app-lease-ext-request",
  templateUrl: "./lease-ext-request.component.html",
  styleUrls: ["./lease-ext-request.component.scss"],
})
export class LeaseExtRequestComponent implements OnInit {
  constructor(
    private leaseService: LeaseService,
    private helperService: HelperService,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog
  ) {}
  extDurationList: SelectListItem[];
  selectedExtDuration: SelectListItem;
  model: AddendumRequest;
  startDate: string;
  endDate: string;
  rentAmount: string;
  roomName: string;
  addendumDate: string;
  hasSigError: boolean;
  faSignature = faSignature;
  notLoading = true;
  hasError = true;
  showAddendumDate: boolean;
  coordinatorSig: LeaseSignature;
  headerTitle: string;
  @Input() tenantInfo: TenantInfo;
  @Input() showSubHeader = false;
  @Input() disableControls = false;
  @Input() disableSig = false;
  @Input() sigModel: SignatureDialogModel;
  @Output() notifyParent = new EventEmitter();

  ngOnInit() {
    this.getLists();
    this.setModel();
  }

  getLists() {
    this.extDurationList = this.helperService.tenantLeaseDurationsGet();
    this.selectedExtDuration = this.extDurationList[1];
  }

  setModel() {
    if (!this.tenantInfo) {
      return;
    }
    const addendumToSign =
      this.tenantInfo.leaseInfo.addendums &&
      this.tenantInfo.leaseInfo.addendums.length > 0
        ? this.tenantInfo.leaseInfo.addendums.find(
            (a) => a.type === "Lease-Ext" && a.statusId === 3
          )
        : null;
    this.headerTitle =
      this.showSubHeader && addendumToSign
        ? "Lease Extension Addendum"
        : "Lease Extension Request";
    if (!addendumToSign) {
      this.model = {
        id: 0,
        leaseId: this.tenantInfo.leaseInfo.id,
        tenantId: this.tenantInfo.id,
        roomId: this.tenantInfo.roomInfo.id,
        rentId: this.tenantInfo.rentId,
        addendumDate: moment().clone().local(),
        type: "Lease-Ext",
        startDate: moment(this.tenantInfo.leaseInfo.endDate)
          .add(1, "d")
          .local()
          .clone(),
        endDate: moment(this.tenantInfo.leaseInfo.endDate)
          .add(1, "d")
          .local()
          .clone()
          .add(this.selectedExtDuration.value - 1, "M")
          .endOf("month")
          .local(),
        duration: this.selectedExtDuration.value,
        tenantSig: null,
      };
      this.startDate = moment(this.model.startDate)
        .clone()
        .format("MM-DD-YYYY");
      this.endDate = moment(this.model.endDate).clone().format("MM-DD-YYYY");
      this.rentAmount = `$${this.tenantInfo.roomRent.amount}`;
      this.roomName = `${this.tenantInfo.roomInfo.name} ${this.tenantInfo.roomInfo.description}`;
      this.showAddendumDate = false;
    } else {
      const duration = Math.round(
        moment(new Date(addendumToSign.endDate))
          .clone()
          .diff(new Date(addendumToSign.startDate), "months", true)
      );
      this.selectedExtDuration = this.extDurationList.find(
        (l) => l.value === duration
      );
      this.model = {
        id: addendumToSign.id,
        leaseId: this.tenantInfo.leaseInfo.id,
        tenantId: this.tenantInfo.id,
        roomId: addendumToSign.roomId,
        rentId: addendumToSign.rentId,
        addendumDate: moment(addendumToSign.addendumDate).clone().local(),
        type: addendumToSign.type,
        startDate: moment(addendumToSign.startDate).local().clone(),
        endDate: moment(addendumToSign.endDate).local().clone(),
        duration: this.selectedExtDuration.value,
        tenantSig: null,
      };
      this.startDate = moment(this.model.startDate)
        .clone()
        .format("MM-DD-YYYY");
      this.endDate = moment(this.model.endDate).clone().format("MM-DD-YYYY");
      this.rentAmount = `$${addendumToSign.rentAmount}`;
      this.roomName = `${this.tenantInfo.roomInfo.name} ${this.tenantInfo.roomInfo.description}`;
      this.addendumDate = moment(this.model.addendumDate)
        .clone()
        .format("MM-DD-YYYY");
      this.showAddendumDate = true;
    }
  }

  setDuration(value: any) {
    this.selectedExtDuration = this.extDurationList.find(
      (l) => l.value === value
    );
    let roomRent;
    if (this.selectedExtDuration.label !== "1 year") {
      roomRent = this.tenantInfo.roomInfo.rents.find((r) =>
        r.name.toLowerCase().includes("short term")
      );
    } else {
      roomRent = this.tenantInfo.roomInfo.rents.find(
        (r) => !r.name.toLowerCase().includes("short term")
      );
    }
    this.model.rentId = roomRent ? roomRent.id : this.tenantInfo.roomRent.id;
    this.model.endDate = this.model.startDate
      .clone()
      .add(this.selectedExtDuration.value - 1, "M")
      .endOf("month")
      .local();
    this.endDate = moment(this.model.endDate).clone().format("MM-DD-YYYY");
    this.rentAmount = roomRent
      ? `$${roomRent.amount}`
      : `$${this.tenantInfo.roomRent.amount}`;
  }

  addAddendum() {
    this.notLoading = false;
    this.spinner.show();
    if (this.model.id === 0) {
      this.model.endDate = moment(this.endDate).clone();
      this.leaseService.addAddendum(this.model).subscribe((data) => {
        if (data) {
          let leaseInfo: LeaseInfo = Object.assign({}, data);
          let addendum = leaseInfo.addendums.find(
            (a) => a.type === this.model.type
          );
          if (addendum) {
            this.model.id = addendum.id;
          }
          this.notifyParent.emit(data);
        }
        this.notLoading = true;
        this.spinner.hide();
      });
    } else {
      if (this.coordinatorSig && this.coordinatorSig.addendumId > 0) {
        this.leaseService
          .addIcbSigAddendum(this.coordinatorSig)
          .subscribe((data) => {
            if (data) {
              this.coordinatorSig = null;
              this.hasError = true;
              this.notifyParent.emit(data);
            }
            this.notLoading = true;
            this.spinner.hide();
          });
      }
    }
  }

  openSignatureDialog(): void {
    const dialogRef = this.dialog.open(SignatureModalComponent, {
      height: "400px",
      width: "600px",
      data: this.sigModel,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((sigModel) => {
      this.captureSig(sigModel);
    });
  }

  captureSig(sigRes: any) {
    if (!sigRes) {
      this.hasError = this.hasSigError = true;
      this.toggleError();
      return;
    }
    if (
      !sigRes.useExisting &&
      (!sigRes.signatureFile ||
        !sigRes.signatureFile.fileByteString ||
        sigRes.signatureFile.length === 0)
    ) {
      this.hasError = this.hasSigError = true;
      this.toggleError();
      return;
    }
    if (
      !sigRes.useExisting &&
      sigRes.signatureFile &&
      sigRes.signatureFile.fileByteString &&
      sigRes.signatureFile.fileByteString.length > 0
    ) {
      if (this.sigModel.signee === "Tenant") {
        this.model.tenantSig = new LeaseSignature();
        this.model.tenantSig.newSignature = Object.assign(
          {},
          sigRes.signatureFile
        );
        this.model.tenantSig.hasPrintSignature = sigRes.usePrint;
      } else {
        this.coordinatorSig = new LeaseSignature();
        this.coordinatorSig.leaseId = this.model.leaseId;
        this.coordinatorSig.addendumId = this.model.id;
        this.coordinatorSig.newSignature = Object.assign(
          {},
          sigRes.signatureFile
        );
        this.coordinatorSig.hasPrintSignature = sigRes.usePrint;
      }

      this.hasError = this.hasSigError = false;
    }
    if (sigRes.useExisting) {
      if (this.sigModel.signee === "Coordinator") {
        this.coordinatorSig = new LeaseSignature();
        this.coordinatorSig.leaseId = this.model.leaseId;
        this.coordinatorSig.addendumId = this.model.id;
        this.coordinatorSig.existingSignature = this.sigModel.existingSig;
        this.coordinatorSig.hasPrintSignature = false;
        this.hasError = this.hasSigError = false;
      } else {
        this.model.tenantSig = new LeaseSignature();
        this.model.tenantSig.existingSignature = Object.assign(
          this.sigModel.existingSig
        );
        this.model.tenantSig.hasPrintSignature = false;
        this.hasError = this.hasSigError = false;
      }
    }
  }

  toggleError(): void {
    setTimeout(
      function () {
        this.hasSigError = false;
      }.bind(this),
      3000
    );
  }
}
