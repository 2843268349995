import { ValidationError } from "./validation-error";

export class TenantProfileValidation {
  age: ValidationError;
  refName: ValidationError;
  refEmail: ValidationError;
  refPhone: ValidationError;
  hasError: boolean;

  constructor(
    age: ValidationError,
    refName: ValidationError,
    refEmail: ValidationError,
    refPhone: ValidationError,
    hasError: boolean
  ) {
    this.age = age;
    this.refName = refName;
    this.refEmail = refEmail;
    this.refPhone = refPhone;
    this.hasError = hasError;
  }
}
