import { ValidationError } from './validation-error';

export class FileValidation {
  uploadedFileName: ValidationError;
  hasError: boolean;

  constructor(uploadedFileName: ValidationError, hasError: boolean) {
    this.uploadedFileName = uploadedFileName;
    this.hasError = hasError;
  }
}
