import { ValidationError } from "./validation-error";

export class RentValidation {
  name: ValidationError;
  amount: ValidationError;
  hasError: boolean;
  constructor(
    name: ValidationError,
    amount: ValidationError,
    hasError: boolean
  ) {
    this.name = name;
    this.amount = amount;
    this.hasError = hasError;
  }
}
