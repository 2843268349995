import { AddendumRequest } from "./../models/addendum-request";
import { TenantInfo } from "src/app/models/tenant-info";
import { TenantOverride } from "./../models/tenant-override";
import { LeaseSignature } from "../models/lease-signature";
import { LeaseInfo } from "./../models/lease-info";
import { LeaseCreate } from "./../models/lease-create";
import { Injectable } from "@angular/core";
import { GenericDataService } from "./generic-data.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Lease } from "../models/lease";
import { LeaseFile } from "../models/lease-file";
import { ConfigService } from "./config.service";
import { first, map } from "rxjs/operators";
import { File } from "./../models/file";
import { AddendumConfirmed } from "../models/addendum-confirmed";
import { LeaseUpdate } from "../models/lease-update";

@Injectable({
  providedIn: "root",
})
export class LeaseService {
  constructor(
    private genericDataService: GenericDataService,
    private configService: ConfigService
  ) {}
  leaseInfo: LeaseInfo;
  leaseFile: LeaseFile;
  tenantOverrideAdded$ = new Subject<TenantInfo>();
  addendumConfirmed$ = new BehaviorSubject<AddendumConfirmed | null>(null);

  public leaseRequest(request: LeaseCreate): any {
    this.genericDataService.endPoint = "Lease/Doc";
    return this.genericDataService.download<Blob>(request);
  }

  public leaseDoc(request: LeaseCreate): any {
    this.genericDataService.endPoint = "Lease/Doc";
    const leaseObservable$ = new Observable((subscriber) => {
      this.genericDataService
        .create<LeaseFile>(null, request)
        .subscribe((data) => {
          this.leaseFile = Object.assign(data);
          if (this.leaseFile.file) {
            this.leaseFile.file.url = `${this.configService.config.fileBaseUrl}${this.leaseFile.file.storedFileName}`;
          }
          subscriber.next(this.leaseFile);
        });
    });
    return leaseObservable$;
  }

  public leaseGetbyTenant(tenantId: number): any {
    this.genericDataService.endPoint = "Lease/Tenant";
    const leaseObservable$ = new Observable((subscriber) => {
      this.genericDataService
        .readById<LeaseInfo>(null, tenantId)
        .subscribe((data) => {
          this.leaseInfo = Object.assign({}, data);
          subscriber.next(this.leaseInfo);
        });
    });
    return leaseObservable$;
  }

  public leaseGet(leaseId: number): any {
    this.genericDataService.endPoint = "Lease";
    const leaseObservable$ = new Observable((subscriber) => {
      this.genericDataService
        .readById<LeaseInfo>(null, leaseId)
        .subscribe((data) => {
          this.leaseInfo = Object.assign({}, data);
          subscriber.next(this.leaseInfo);
        });
    });
    return leaseObservable$;
  }

  public leaseConfirm(request: Lease): any {
    this.genericDataService.endPoint = "Lease/Confirm";
    const leaseObservable$ = new Observable((observer) => {
      this.genericDataService.post<LeaseInfo>(request).subscribe((data) => {
        this.leaseInfo = Object.assign({}, data);
        observer.next(this.leaseInfo);
      });
    });
    return leaseObservable$;
  }

  public leaseAdd(request: Lease): any {
    this.genericDataService.endPoint = "Lease/Add";
    const leaseObservable$ = new Observable((observer) => {
      this.genericDataService.post<LeaseInfo>(request).subscribe((data) => {
        this.leaseInfo = Object.assign({}, data);
        observer.next(this.leaseInfo);
      });
    });
    return leaseObservable$;
  }

  public leaseCurrentGet(leaseId: number): any {
    this.genericDataService.endPoint = "Lease/Current";
    const leaseObservable$ = new Observable((subscriber) => {
      this.genericDataService
        .readById<LeaseFile>(null, leaseId)
        .subscribe((data) => {
          this.leaseFile = Object.assign(data);
          if (this.leaseFile.file) {
            this.leaseFile.file.url = `${this.configService.config.fileBaseUrl}${this.leaseFile.file.storedFileName}`;
          }
          subscriber.next(this.leaseFile);
        });
    });
    return leaseObservable$;
  }

  public coordinatureSigAdd(request: LeaseSignature): any {
    this.genericDataService.endPoint = "Lease/Add/IcbSig";
    return this.genericDataService.post<any>(request).pipe(
      map((data: LeaseFile) => {
        if (data) {
          data.file.url = `${this.configService.config.fileBaseUrl}${data.file.storedFileName}`;
        }
        return data;
      })
    );
  }

  public leaseUpdate(request: LeaseUpdate): any {
    this.genericDataService.endPoint = "Lease/Update";
    return this.genericDataService.post<any>(request);
  }

  public Override(request: TenantOverride): any {
    this.genericDataService.endPoint = "Lease/Overrride";
    return this.genericDataService.post<any>(request);
  }

  tenantOverrideAdded(tenantInfo: TenantInfo): void {
    this.tenantOverrideAdded$.next(tenantInfo);
  }

  public coordSignatureGet(): Observable<File | null> {
    return this.genericDataService
      .readWithEndpoint<File>("Lease/CoordSig/Get")
      .pipe(
        map((data: File) => {
          if (data) {
            data.url = `${this.configService.config.fileBaseUrl}${data.storedFileName}`;
          }
          return data;
        })
      );
  }
  public tenantSignatureGet(leaseId: number): Observable<File | null> {
    this.genericDataService.endPoint = "Lease/TenantSig/Get";
    return this.genericDataService.read<File>(leaseId).pipe(
      map((data: File) => {
        if (data) {
          data.url = `${this.configService.config.fileBaseUrl}${data.storedFileName}`;
        }
        return data;
      })
    );
  }

  public addAddendum(request: AddendumRequest): any {
    this.genericDataService.endPoint = "Lease/Addendum/Add";
    return this.genericDataService.post<any>(request);
  }

  public confirmAddendum(addendumId: number) {
    this.genericDataService.endPoint = "Lease/Addendum/Confirm";
    return this.genericDataService.readById<any>(null, addendumId);
  }

  public addendumConfirmed(confirm: AddendumConfirmed): void {
    this.addendumConfirmed$.next(confirm);
  }

  public getCurrentAddendum(leaseId: number, type: string) {
    this.genericDataService.endPoint = "Lease/Addendum/Current";
    return this.genericDataService.readByIds<any>(null, leaseId, type).pipe(
      map((data: LeaseFile) => {
        if (data) {
          data.file.url = `${this.configService.config.fileBaseUrl}${data.file.storedFileName}`;
        }
        return data;
      })
    );
  }

  public getLeaseDocs(leaseId: number) {
    this.genericDataService.endPoint = "Lease/Docs";
    return this.genericDataService.readById<any>(null, leaseId).pipe(
      map((data: LeaseFile[]) => {
        if (data) {
          data.map((item) => {
            item.file.url = `${this.configService.config.fileBaseUrl}${item.file.storedFileName}`;
          });
        }
        return data;
      })
    );
  }

  public removeAddendum(addendumId: number) {
    this.genericDataService.endPoint = "Lease/Addendum/remove";
    return this.genericDataService.delete<any>(null, addendumId);
  }

  public addIcbSigAddendum(request: LeaseSignature): any {
    this.genericDataService.endPoint = "Lease/Addendum/Add/IcbSig";
    return this.genericDataService.post<any>(request).pipe(
      map((data: LeaseFile) => {
        if (data) {
          data.file.url = `${this.configService.config.fileBaseUrl}${data.file.storedFileName}`;
        }
        return data;
      })
    );
  }

  public getAddendumDocsForIcbSig(leaseId: number) {
    this.genericDataService.endPoint = "Lease/Addendum/Docs";
    return this.genericDataService.readById<any>(null, leaseId).pipe(
      map((data: LeaseFile[]) => {
        if (data) {
          data.map((item) => {
            item.file.url = `${this.configService.config.fileBaseUrl}${item.file.storedFileName}`;
          });
        }
        return data;
      })
    );
  }

  public autoRenew(leaseId: number) {
    this.genericDataService.endPoint = "Lease/AutoRenew";
    return this.genericDataService.readById<any>(null, leaseId).pipe(first());
  }
}
