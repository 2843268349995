export class ConfirmDialogModel {
  title: string;
  message: string;
  type: string;
  showCancel: boolean;

  constructor(title: string, message: string, type: string, showCancel: boolean) {
    this.title = title;
    this.message = message;
    this.type = type;
    this.showCancel = showCancel;
  }
}

