import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DonorboxPayment } from '../models/donorbox-payment';
import { MemberInfo } from '../models/member-info';
import { MemberPayment } from '../models/member-payment';
import { GenericDataService } from './generic-data.service';

@Injectable({
  providedIn: 'root'
})
export class MemberPaymentService {

  payments: MemberPayment[];
  payment: MemberPayment;
  memberInfo: MemberInfo;
  public paymentEvent$ = new Subject<boolean>();

  constructor(private genericDataService: GenericDataService) { }

  public paymentGet(memberId: number): any {
    this.genericDataService.endPoint = 'MemberPayment/Get';
    const paymentObservable$ = new Observable(observer => {
      this.genericDataService.readById<MemberPayment>(null, memberId).subscribe(data => {
        this.payment = Object.assign({}, data);
        observer.next(this.payment);
      });
    });
    return paymentObservable$;
  }

  public paymentlist(memberId: number): any {
    this.genericDataService.endPoint = 'MemberPayment/List';
    const paymentsObservable$ = new Observable(observer => {
      this.genericDataService.list<MemberPayment>(null, memberId).subscribe(data => {
        this.payments = Object.assign([], data);
        observer.next(this.payments);
      });
    });
    return paymentsObservable$;
  }

  public paymentAdd(request: MemberPayment): any {
    this.genericDataService.endPoint = 'MemberPayment/Add';
    const paymentObservable$ = new Observable(observer => {
      this.genericDataService.post<MemberPayment>(request).subscribe(data => {
        this.payment = Object.assign({}, data);
        observer.next(this.payment);
      });
    });
    return paymentObservable$;
  }

  public paymentVoterAdd(request: DonorboxPayment): any {
    this.genericDataService.endPoint = 'MemberPayment/Add/VoterMembership';
    const memberInfoObservable$ = new Observable(observer => {
      this.genericDataService.post<MemberInfo>(request).subscribe(data => {
        this.memberInfo = Object.assign({}, data);
        observer.next(this.memberInfo);
      });
    });
    return memberInfoObservable$;
  }

  public paymentUpdate(request: MemberPayment): any {
    this.genericDataService.endPoint = 'MemberPayment/Update';
    const paymentObservable$ = new Observable(observer => {
      this.genericDataService.post<MemberPayment>(request).subscribe(data => {
        this.payment = Object.assign({}, data);
        observer.next(this.payment);
      });
    });
    return paymentObservable$;
  }

  public paymentRemove(id: number): any {
    this.genericDataService.endPoint = 'MemberPayment/Remove';
    const paymentObservable$ = new Observable(observer => {
      this.genericDataService.delete<any>(null, id).subscribe(data => {
        this.paymentEvent$.next(true);
      });
    });

    return paymentObservable$;

  }

}
