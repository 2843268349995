export class SelectListItem {
  label: string;
  value: any;
  disabled: boolean;


  constructor(label: string, value: any, disabled: boolean) {
    this.label = label;
    this.value = value;
    this.disabled = disabled;
  }

  // clear() {
  //   this.label = '';
  //   this.value = '';
  //   this.disabled = false;
  // }
}
