import { ValidationError } from './validation-error';

export class EventCategoryValidation {
  name: ValidationError;
  hasError: boolean;

  constructor(name: ValidationError, hasError: boolean) {
    this.name = name;
    this.hasError = hasError;
  }
}
