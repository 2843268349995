import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-welfare',
  templateUrl: './welfare.component.html',
  styleUrls: ['./welfare.component.scss']
})
export class WelfareComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
