import { Guarantor } from "./guarantor";
import { TenantWaitFileInfo } from "./tenant-wait-file-info";
import { MemberInfo } from "src/app/models/member-info";

export class TenantWaitInfo {
  id: number;
  memberId: number;
  memberInfo: MemberInfo;
  referenceName: string;
  referenceEmail: string;
  referencePhone: string;
  tentativeStartDate: any;
  roomType: string;
  files: TenantWaitFileInfo[];
  guarantor?: Guarantor;
  requestDate?: any;
}
