import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-rent-info",
  templateUrl: "./rent-info.component.html",
  styleUrls: ["./rent-info.component.scss"],
})
export class RentInfoComponent implements OnInit {
  @Input() showWaitListInfo: boolean = false;
  @Input() showRentBreakup: boolean = false;
  constructor() {}

  ngOnInit() {}
}
