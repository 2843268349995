import { UploadFile } from "src/app/models/upload-file";
export class UploadDialogModel {
  title: string;
  subTitle: string;
  btnText: string;
  imageOnly: boolean;
  allowMultiple: boolean;
  hasError: boolean;
  errorMessage: string;
  showCancel: boolean;
  uploadedFiles: UploadFile[];
}
