import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReducedFeeKid } from 'src/app/models/school/reduced-fee-kid';
import { SchoolManageService } from 'src/app/services/school-manage.service';
import { faPlus} from '@fortawesome/free-solid-svg-icons';
import { IebReducedFeeKidModalComponent } from '../ieb-reduced-fee-kid-modal/ieb-reduced-fee-kid-modal.component';
@Component({
  selector: 'app-ieb-reduced-fee-kids',
  templateUrl: './ieb-reduced-fee-kids.component.html',
  styleUrls: ['./ieb-reduced-fee-kids.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IebReducedFeeKidsComponent implements OnInit {
  private dataSource = new MatTableDataSource<ReducedFeeKid>();
  dataSource$ : Observable<MatTableDataSource<ReducedFeeKid>> = this.schoolService.reducedFeeKids$.pipe(
    map((response) => {
      const dataSource = this.dataSource;
      dataSource.data = response;
      return dataSource;
    })
  );
  @ViewChild(MatSort, {static : false}) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  displayedColumns: string[] = ['name', 'parentName', 'validFrom','validTill'];
  @Input() hideAdd: boolean;
  faPlus = faPlus;

  constructor(private schoolService: SchoolManageService,  public dialog: MatDialog) { }

  ngOnInit() {
  }

  editKid(rKid: ReducedFeeKid) :void {
    this.openDialog(rKid);
  }
  addKid():void {
    let newKid = {}
    this.openDialog(newKid);
  }

  openDialog(rKid: ReducedFeeKid){
    const dialogRef = this.dialog.open(IebReducedFeeKidModalComponent, {
      //maxWidth: "800px",
      width: "800px",
      height: "600px",
      data: rKid,
      disableClose: true,
      panelClass: 'panel-dialog',
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
       if(!dialogResult.id) {
        this.schoolService.addReducedFeeKid(dialogResult);
       } else {
        this.schoolService.editReducedFeeKid(dialogResult);
       }
      }

    });
  }

}
