
import { IebKidService } from 'src/app/services/ieb-kid.service';
import { Action } from './../models/stream-action';
import { ParentRequest } from './../models/school/parent-request';
import { ParentModel } from './../models/school/parent-model';
import { HelperService } from 'src/app/services/helper.service';
import { map, concatMap, scan } from 'rxjs/operators';
import { SchoolParentInfo } from './../models/school/school-parent-info';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, combineLatest, merge, BehaviorSubject } from 'rxjs';
import { GenericDataService } from './generic-data.service';
import { SecurityService } from './security.service';
import { SelectListItem } from '../models/select-list-item';
import { MultiSelectItem } from '../models/multi-select-item';
import { ConfirmationService } from './confirmation.service';
import { LoadService } from './load.service';


@Injectable({
  providedIn: 'root'
})
export class IebParentService {


  parentSource$ = this.schoolParentGet(this.securityService.getMemberId()).pipe();
  primaryContactSource$ = of(this.helperService.primaryContactGet());
  // parentSource$ = this.securityService.memberId$.pipe(
  //   map((memberId) => {
  //     return this.schoolParentGet(memberId);
  //   })
  // );


  /** The parent action subject used to perform CRUD operations */
  private parentModifiedActionSubject = new Subject<Action<ParentRequest>>();
  /** The exposed parent action observable merged with the CRUD stream */
  parentModifiedAction$ = this.parentModifiedActionSubject.asObservable();

  parent$ = merge(this.parentSource$, this.parentModifiedAction$.pipe(
    concatMap((parent) => this.parentAction(parent)))).pipe(
      scan((acc: SchoolParentInfo, value: Action<SchoolParentInfo>) => {
       return this.modifyStream(acc , value) as SchoolParentInfo
      })
  );

  genderList$ = of(this.helperService.gendersGet());

  parentModel$ = combineLatest([this.parent$, this.primaryContactSource$, this.genderList$]).pipe(
    map(([parent, primaryContact, genders]) => {
      return this.mapDefaults(parent, primaryContact, genders);
    })
  );
  // This is used to add new reg by admins
  // private  parentInfoSubject = new Subject<number>();
  // parentInfoSource$ = this.parentInfoSubject.pipe(
  // switchMap((memberId) => {
  //   if(!memberId) {
  //     return of (null as SchoolParentInfo);

  //   }
  //   return this.schoolParentGet(memberId);
  // }));



  // parentInfo$ =  merge(this.parentInfoSource$, this.parentModifiedAction$.pipe(
  //   concatMap((parent) => this.parentAction(parent)))).pipe(
  //     scan((acc: SchoolParentInfo, value : Action<SchoolParentInfo>) => {
  //       if(value.action) {
  //         return this.modifyStream(acc , value) as SchoolParentInfo
  //       }
  //       return !value ? acc : value ;
  //     })
  // );
  // parentInfoModel$ = combineLatest([this.parentInfo$, this.primaryContactSource$, this.genderList$]).pipe(
  //   map(([parent, primaryContact, genders]) => {
  //     if(!parent) {
  //       return of ({} as ParentModel);
  //     }
  //     return this.mapDefaults(parent as SchoolParentInfo, primaryContact, genders);
  //   })
  // );

  constructor(private genericDataService: GenericDataService,
    private securityService: SecurityService,
    private helperService: HelperService,
    private kidService: IebKidService,
    private confirmService: ConfirmationService,
    private loadService: LoadService
   ) { }

   schoolParentGet(memberId: number): Observable<SchoolParentInfo> {
    //const memberId = this.securityService.getMemberId();
    if(!memberId) {
      return of ({} as SchoolParentInfo);
    }
    this.genericDataService.endPoint = "SchoolParent/Member";
      return this.genericDataService.readById<SchoolParentInfo>(null, memberId);
   }

   parentAction(parentAction: Action<ParentRequest>) : Observable<Action<SchoolParentInfo>> {
    switch(parentAction.action){
      case 'add':
        this.genericDataService.endPoint = "SchoolParent/New";
        return this.genericDataService.post<Action<SchoolParentInfo>>(parentAction.item).pipe(
          map((parent: any) => ({ item: parent, action: 'add' } as Action<SchoolParentInfo>))
        );
      case 'update':
        this.genericDataService.endPoint = "SchoolParent/Update";
        return this.genericDataService.put<Action<SchoolParentInfo>>(parentAction.item).pipe(
          map((parent: any) => ({ item: parent, action: 'update' } as Action<SchoolParentInfo>))
        );
      default:
        return of({} as Action<SchoolParentInfo>);
    }
   }

   addParentByAdmin(memberId: number): Observable<SchoolParentInfo>{
    this.genericDataService.endPoint = "SchoolParent/Add";
    return this.genericDataService.readById<SchoolParentInfo>(null, memberId);
   }

   parentInfoAction(parentAction: Action<ParentRequest>) : Observable<Action<SchoolParentInfo>> {
    switch(parentAction.action){
      case 'add':
        this.genericDataService.endPoint = "SchoolParent/New";
        return this.genericDataService.post<Action<SchoolParentInfo>>(parentAction.item).pipe(
          map((parent: any) => ({ item: parent, action: 'add' } as Action<SchoolParentInfo>))
        );
      case 'update':
        this.genericDataService.endPoint = "SchoolParent/Update";
        return this.genericDataService.put<Action<SchoolParentInfo>>(parentAction.item).pipe(
          map((parent: any) => ({ item: parent, action: 'update' } as Action<SchoolParentInfo>))
        );
      default:
        return of({} as Action<SchoolParentInfo>);
    }
   }

   newParentAdd(parent: ParentRequest): void {
    this.loadService.loadToggle(true);
    this.parentModifiedActionSubject.next({item: parent, action: 'add'});
   }
   parentUpdate(parent: ParentRequest): void {
    this.loadService.loadToggle(true);
    this.parentModifiedActionSubject.next({item: parent, action: 'update'});
   }

  //  memberSelected(memberId: number) : void {
  //   this.parentInfoSubject.next(memberId);
  // }

   /**
     * Action<> objects let us perform CRUD actions to the user array
     * @param {SchoolParentInfo} schoolParent schoolParent
     * @param {SchoolParentInfo} operation The schoolParent to perfomr CRUD operations on
     * @returns The modified SchoolParentInfo object
     */
    modifyStream(schoolParent: SchoolParentInfo, operation: Action<SchoolParentInfo>): SchoolParentInfo {
      this.loadService.loadToggle(false);
      if (operation.action === 'add') {
        this.kidService.newParentSaved(operation.item.id);
        this.confirmService.confirmationToggle( {message: 'Parent is saved', toggle: true, type : 'success'});
        return operation.item;
      } else if (operation.action === 'update') {
        this.confirmService.confirmationToggle( {message: 'Parent is updated', toggle: true, type : 'success'});
        return operation.item;
      }

      return schoolParent;
    }



    private mapDefaults(parent: SchoolParentInfo, primaryContacts: MultiSelectItem[], genders: SelectListItem[]): ParentModel {
      return {
        schoolParentInfo : parent,
        primaryContactList: primaryContacts,
        genderList: genders,
        gender : parent.parentInfo && parent.parentInfo.gender ? parent.parentInfo.gender : 'M',
        spouseGender: parent.spouseInfo && parent.spouseInfo.gender ? parent.spouseInfo.gender : 'F'
      };
    }
}
