import { TeacherSummary } from './../../models/school/teacher-summary';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { faChalkboardTeacher, faTimes} from '@fortawesome/free-solid-svg-icons';
import * as _moment from "moment";
import { DateAdapter, MatSnackBar, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SchoolManageService } from 'src/app/services/school-manage.service';
import { SearchTeacherResult } from 'src/app/models/school/search-teacher-result';
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY",
  },
  display: {
    dateInput: "MM-DD-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "MM-DD-YYYY",
    monthYearA11yLabel: "MM-DD-YYYY",
  },
};
@Component({
  selector: 'app-ieb-teacher-modal',
  templateUrl: './ieb-teacher-modal.component.html',
  styleUrls: ['./ieb-teacher-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class IebTeacherModalComponent implements OnInit, OnDestroy {
  model : TeacherSummary
  showSearch: boolean;
  faChalkboardTeacher = faChalkboardTeacher;
  faTimes= faTimes;
  headerTitle = 'Edit Teacher';
  minDate : Date;
  btnText = 'Update';
  isReadOnly = true;
  teacherForm!: FormGroup;
  searchSubscription$!: Subscription;
  private memberSearchSub = new Subject<string>();
  searchSource$ = this.schoolService.membersSource$;
  disableBtn = true;
  validTill: Date;
  name: string;
  constructor(public dialogRef: MatDialogRef<IebTeacherModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TeacherSummary, private formBuilder: FormBuilder,
    private schoolService: SchoolManageService) {
      this.model = Object.assign({}, data);
    }

  ngOnInit() {
    this.teacherForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: [
        { value: '' },
        [Validators.required, Validators.email, Validators.maxLength(254)],
      ],

      validTill: ['', [Validators.required]]
    });
    let dt = moment().clone().local();
    this.minDate = new Date(dt.year(), dt.month(), dt.date());
    if(!this.model || !this.model.validTill) {
      this.validTill = this.minDate;
    }
    if(this.model){
      this.showSearch = !this.model.memberId? true: false;
      this.headerTitle = !this.model.id ? 'Add Teacher' : this.headerTitle;
      this.btnText = !this.model.id ? 'Save' : this.btnText;
      this.isReadOnly = this.model.memberId ? true : false;
      this.validTill = this.minDate;
    }
    this.searchSubscription$ = this.memberSearchSub.pipe(debounceTime(500)).subscribe((query: string) => {
      this.searchChanged(query);
    });
  }

  ngOnDestroy(): void {
    this.searchSubscription$.unsubscribe();
  }

  setDisable(): void {
    if(moment(this.validTill).isSame(moment(this.model.validTill))) {
      this.disableBtn = true;
    } else {
      this.disableBtn = false;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  upsertTeacher(): void {
    this.model.validTill = this.validTill;
    this.dialogRef.close(this.model);
  }

  searchChanged(query: string): void {
    this.schoolService.memberSearch(query);
  }

  selectedMember(teacherResult : SearchTeacherResult) : void {
    this.model = {
      id : 0,
      memberId: teacherResult.memberId,
      firstName: teacherResult.firstName,
      lastName: teacherResult.lastName,
      email: teacherResult.email
    };
    this.name = `${this.model.firstName} ${this.model.lastName}`;
  }

}
