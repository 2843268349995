import { AddTeamFileRequest } from './../../models/add-team-file-request';
import { SelectListItem } from './../../models/select-list-item';
import { TeamService } from 'src/app/services/team.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Team } from 'src/app/models/team';
import { TeamFilters } from 'src/app/models/team-filters';
import { ListRequest } from 'src/app/models/list-request';
import { TeamValidation } from 'src/app/models/team-validation';
import { ValidationError } from 'src/app/models/validation-error';
import { AddFileRequest } from 'src/app/models/add-file-request';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationSnackbarComponent } from '../confirmation-snackbar/confirmation-snackbar.component';

@Component({
  selector: 'app-team-manage',
  templateUrl: './team-manage.component.html',
  styleUrls: ['./team-manage.component.scss']
})
export class TeamManageComponent implements OnInit {

  teams: Team[];
  teamList: SelectListItem[];
  selectedPerson: Team;
  filters: TeamFilters;
  listRequest: ListRequest;
  teamListValue = '';
  defaultLabel = 'Select';
  selectedPersonName = '';
  disableDelete: boolean;
  constructor(private teamService: TeamService
    , private snackBar: MatSnackBar
    , private cd: ChangeDetectorRef) {
    this.getTeams();
  }

  ngOnInit() {
  }

  getTeams() {
    this.filters = {
      name: null,
      position: null,
      type: null
    };

    this.listRequest = {
      filter: this.filters,
      itemsPerPage: null,
      pageNumber: null,
    };
    this.teamService.teamList(this.listRequest).subscribe(data => {
      this.teams = Object.assign([], data);
      this.teamList = this.teams.map(item => ({
        label: `${item.name} - ${item.type}`,
        value: item.id,
        disabled: false
      }));
      this.teamListValue = this.teamList[0].label;
    });
  }

  onTeamSelectChange(value: any) {
    this.selectedPerson = this.teams.find(t => t.id === value);
    this.selectedPersonName = this.selectedPerson.name;
    this.selectedPerson.validation = new TeamValidation(new ValidationError(false), false);
  }

  updateTeamPerson() {

    this.teamService.teamPersonUpdate(this.selectedPerson).subscribe(data => {
      if (data) {
        const index = this.teams.indexOf(this.selectedPerson);
        const indx = this.teamList.findIndex(t => t.value === this.selectedPerson.id);
        this.teamList[indx].label = `${data.name} - ${data.type}`;
        this.selectedPerson.name = data.name;
      }
    });
  }

  setUpdateValue(field: string, value: string) {
    this.selectedPerson.validation.hasError = this.validateUpdate(field, value);
    if (this.selectedPerson.validation.hasError) {
      this.selectedPerson.name = this.teams.find(t => t.id === this.selectedPerson.id).name;
    } else {
      this.updateTeamPerson();
    }
  }

  validateUpdate(field: string, value: string): boolean {
    let isValid = true;
    if (field === 'name') {
      if (value.length === 0) {
        this.selectedPerson.validation.name.hasError = true;
        this.selectedPerson.validation.name.errorMessage = 'Required';
        isValid = false;
      } else {
        this.selectedPerson.validation.name.hasError = false;
        this.selectedPerson.name = value;
      }
    }
    isValid = this.selectedPerson.validation.name.hasError;

    return isValid;
  }

  deleteTeamPerson(id: number) {
    if (this.disableDelete) {
      return;
    }
    let dismissedByAction = false;
    this.disableDelete = true;
    const index = this.teams.indexOf(this.selectedPerson);
    const indx = this.teamList.findIndex(t => t.value === id);
    const personName = this.teamList[indx].label;
    const name = this.selectedPerson.name;
    const file = Object.assign({}, this.selectedPerson.file);
    this.teamList[indx].label = `Vacant - ${this.teams[index].type}`;
    this.selectedPersonName = this.selectedPerson.name = 'Vacant';
    this.selectedPerson.file = null;
    const deleteSnackBar = this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: 'center',
      duration: 5000,
      panelClass: 'kid-snack-bar',
      data: {
        undoDelete: () => {
          dismissedByAction = true;
          this.teamList[indx].label = `${name} - ${this.teams[index].type}`;
          this.selectedPersonName = this.selectedPerson.name = this.teams[index].name = name;
          this.selectedPerson.file = file;
          this.cd.detectChanges();
          const undoSnackBar = this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
            horizontalPosition: 'center',
            duration: 5000,
            panelClass: 'teams-snack-bar',
            data: {
              dismissSnackbar: () => {
                this.snackBar.dismiss();
                this.disableDelete = false;
              },
              displayText: 'Undone!',
              type: 'message'
            }
          });
          this.disableDelete = false;
        },
        dismissSnackbar: () => {
          dismissedByAction = true;
          this.teamService.teamPersonRemove(id).subscribe();
          this.teams[index].name = 'Vacant';
          this.teams[index].file = null;
          this.snackBar.dismiss();
          this.disableDelete = false;
        },
        displayText: `${personName} deleted`,
        type: 'delete-kid'
      }
    });
    // delete the kid if the snackbar closes due to duration and not closed by user.
    deleteSnackBar.afterDismissed().subscribe(info => {
      if (info.dismissedByAction === false && dismissedByAction === false) {
        this.teamService.teamPersonRemove(id).subscribe();
        this.teams[index].name = 'Vacant';
        this.teams[index].file = null;
        this.disableDelete = false;
      }
    });

  }

  async onImageSelected() {
    const inputNode: any = document.querySelector('#image');
    if (this.validateFileImage(inputNode.files[0])) {
      const contentBuffer: any = await this.readFileAsync(inputNode.files[0]);
      // inputNode.files[0].name = `${this.profile.firstName}-${this.profile.lastName}-${inputNode.files[0].name}`;
      const profileImage = new AddTeamFileRequest;
      profileImage.fileRequest = new AddFileRequest('G', inputNode.files[0].name, '', contentBuffer, null);
      profileImage.teamId = this.selectedPerson.id;
      this.teamService.teamFileAdd(profileImage).subscribe(data => {
        if (data) {
          this.selectedPerson = Object.assign({}, data);
          this.selectedPerson.validation = new TeamValidation(new ValidationError(false), false);
          const index = this.teams.indexOf(this.selectedPerson);
          this.teams.splice(index, 1, this.selectedPerson);
          this.cd.detectChanges();
        }

      });
    }

  }

  readFileAsync(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().split(',')[1];
        if ((encoded.length % 4) > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };

      reader.onerror = reject;
    });
  }

  validateFileImage(file: any) {
    if (!file) {
      return false;
    }
    const fileType = file['type'];
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    if (!validImageTypes.includes(fileType)) {
      this.showConfirmationDialog('Invalid Image');
      return false;
    }
    // 1MB
    if (file.size > 1048576) {
      this.showConfirmationDialog('Image size should not exceed 1MB');
      return false;
    }
    return true;
  }

  showConfirmationDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: 'center',
      duration: 20000,
      panelClass: 'message',
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: 'success'
      }
    });
  }

}
