import { SelectListItem } from 'src/app/models/select-list-item';
import { HelperService } from 'src/app/services/helper.service';
import { Subscription } from "rxjs";
import { TenantService } from "src/app/services/tenant.service";
import { TenantOverride } from "./../../models/tenant-override";
import { File } from "src/app/models/file";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
} from "@angular/core";
import { TenantInfo } from "src/app/models/tenant-info";
import * as _moment from "moment";
import { MatDialog, MatGridTileHeaderCssMatStyler, MatSnackBar } from "@angular/material";
import { ConfirmationSnackbarComponent } from "../confirmation-snackbar/confirmation-snackbar.component";



const moment = _moment;

@Component({
  selector: "app-tenant-override",
  templateUrl: "./tenant-override.component.html",
  styleUrls: ["./tenant-override.component.scss"],

})
export class TenantOverrideComponent implements OnInit, OnChanges {
  @Input() tenantInfo: TenantInfo;
  @Output() notifyParent = new EventEmitter();
  @Output() notifyLateFeeWaiver = new EventEmitter();
  model: TenantOverride = new TenantOverride();
  disableSave = true;
  paymentMonth: string;
  pMonth: number;
  paymentYear: number;
  LateFeeWaiverRemove$: Subscription;
  disableSaveWaiver: boolean;
  overrideTypes: SelectListItem[] = [];
  selectedType: SelectListItem;
  showMonthYear: boolean = true;
  paymentAmount: string;
  currentRentOverride: TenantOverride;
  secDepOverride: TenantOverride;
  rentAmount: number;
  constructor(
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private tenantService: TenantService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    //this.getOverrideTypes();
    // this.LateFeeWaiverRemove$ =
    //   this.tenantService.lateFeeWaiverRemove$.subscribe((res) => {
    //     if (res) {
    //       this.tenantInfo.paymentSummary.lateFeeWaiver = null;
    //       this.disableSaveWaiver = false;
    //       this.notifyLateFeeWaiver.emit(this.tenantInfo);
    //     }
    //   });

  }

  ngOnChanges() {
    this.getOverrideTypes();

    if (this.tenantInfo) {
      let nextPaymentDate;
      this.model.tenantId = this.tenantInfo.id;
      this.rentAmount = this.tenantInfo.roomRent.amount;
      this.paymentAmount = this.rentAmount.toLocaleString();
      if(this.tenantInfo.paymentSummary &&
        this.tenantInfo.paymentSummary.nextPaymentDate) {
          nextPaymentDate = moment(
            this.tenantInfo.paymentSummary.nextPaymentDate
          ).clone();

      } else  {
        if(this.tenantInfo.leaseInfo) {
          nextPaymentDate = moment(
            this.tenantInfo.leaseInfo.startDate
          ).clone();
        }
        else {
          nextPaymentDate = moment(
            this.tenantInfo.tentativeStartDate
          ).clone();
        }
      }
      if(nextPaymentDate) {
        this.paymentMonth = nextPaymentDate.format("MMMM");
        this.pMonth = moment(nextPaymentDate).clone().local().month() + 1;
        this.paymentYear = nextPaymentDate.year();
      }

      if(this.tenantInfo.overrides && this.tenantInfo.overrides.length > 0) {
        this.currentRentOverride = this.tenantInfo.overrides
        .find(o => o.paymentTypeId === 1 && o.month >= this.pMonth && o.year >= this.paymentYear);
        this.secDepOverride = this.tenantInfo.overrides
        .find(o => o.paymentTypeId === 3);
      }
      if(this.currentRentOverride) {
        this.paymentAmount = this.currentRentOverride.amount.toLocaleString();
      }

      if(this.secDepOverride) {
        if(this.tenantInfo.paymentSummary && this.tenantInfo.paymentSummary.payments){
          let secDepPayments = this.tenantInfo.paymentSummary.payments.filter(p => p.paymentTypeId == 3);
          if(secDepPayments) {
            this.overrideTypes.find(o => o.value === 3).disabled = true;
          }
        }

      }

    }
  }

  getOverrideTypes() {
    this.overrideTypes = this.helperService.paymentTypesGet();
    this.selectedType = this.overrideTypes[0];
  }

  setType(paymentTypeId: string) {
    let typeId = parseInt(paymentTypeId);
    this.model.paymentTypeId = typeId;
    this.showMonthYear = this.model.paymentTypeId === 1;
    if(typeId === 3) {
      this.paymentAmount = this.secDepOverride ?
        this.secDepOverride.amount.toLocaleString() :
        this.tenantInfo.securityDeposit.toLocaleString();
    } else {
      this.paymentAmount = this.currentRentOverride ?
        this.currentRentOverride.amount.toLocaleString():
        this.tenantInfo.roomRent.amount.toLocaleString();
    }

  }

  setDisable(inputAmount: string) {
    if(!inputAmount) {
      this.disableSave = true;
      return;
    }
  let amount = parseInt(inputAmount);
  let oAmount;
  if(this.selectedType.value === 1) {
    oAmount = this.currentRentOverride ? this.currentRentOverride.amount : this.rentAmount;
  } else {
    oAmount = this.secDepOverride ? this.secDepOverride.amount : this.rentAmount;
  }

  this.disableSave = oAmount === amount || this.rentAmount === amount ? true : false;
  if(!this.disableSave) {
    this.model.amount = amount;
  }
  }

  save() {
    this.model.paymentTypeId = this.selectedType.value;
    this.model.tenantId = this.tenantInfo.id;
    if(this.model.paymentTypeId === 1) {
      this.model.month = this.pMonth;
      this.model.year = this.paymentYear;
    }
    this.tenantService.tenantOverride(this.model).subscribe((res) => {
      if(res) {
         let message : string = Object.assign(res);
         if(!message.includes(':Error')) {
          if(this.model.paymentTypeId === 3) {
            this.tenantInfo.securityDeposit = this.model.amount;
            this.notifyParent.emit(this.tenantInfo);
            this.disableSave = true;
          }
         }
         this.showConfirmationDialog(message);
      }
    });
  }





  showConfirmationDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 20000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: "success",
      },
    });
  }
}
