import { SKillAnswerUpdate } from 'src/app/models/school/skill-answer-update';
import { StudentLevelUpdate } from './../../models/school/student-level-update';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { StudentForm } from 'src/app/models/school/student-form';
import { StudentUpdateRequest } from 'src/app/models/school/student-update-request';
import { SelectListItem } from 'src/app/models/select-list-item';
import { SchoolManageService } from 'src/app/services/school-manage.service';
import { ConfirmationSnackbarComponent } from '../confirmation-snackbar/confirmation-snackbar.component';
import { MatSnackBar, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { StudentPaymentInfo } from 'src/app/models/school/student-payment-info';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-ieb-student-details',
  templateUrl: './ieb-student-details.component.html',
  styleUrls: ['./ieb-student-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IebStudentDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() model!: StudentForm;
  schoolDetailForm!: FormGroup;
  selectedLevel: SelectListItem;
  selectedArabicSkill: SelectListItem;
  selectedQuranSkill: SelectListItem;
  disableUpdateBtn = true;
  updatedLevel : StudentLevelUpdate;
  updatedArabicSkill: SKillAnswerUpdate;
  updatedQuranSkill: SKillAnswerUpdate;
  subscription: Subscription = new Subscription();
  displayedColumns: string[] = ['schoolFee', 'amount', 'paymentDate'];
  dataSourceActive = new MatTableDataSource<StudentPaymentInfo>();


  constructor(private schoolService: SchoolManageService,  private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.setSelectedListItems(this.model);
  }
  ngOnChanges(changes: SimpleChanges): void {
    const currentModel = changes['model'].currentValue as StudentForm;
    this.setSelectedListItems(currentModel);

  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  setSelectedListItems(model: StudentForm): void {
    if(!model) {
      return;
    }

    if(model.studentDetails && model.studentForm) {
      let selectedLevel = model.studentForm.levelList
                          .find(l=> l.value === model.studentDetails.activeLevel.schoolLevelId);
      if(selectedLevel) {
        this.selectedLevel = selectedLevel;
      }
      if(model.studentDetails.arabicSkill) {
        let selectedArabic = model.studentForm.arabicSkillList
        .find(l=> l.value === model.studentDetails.arabicSkill.skillAnswerId);
        if(selectedArabic) {
          this.selectedArabicSkill = selectedArabic;
        }
      }
      if(model.studentDetails.quranSkill) {
        let selectedQuran = model.studentForm.quranSkillList
        .find(l=> l.value === model.studentDetails.quranSkill.skillAnswerId);
        if(selectedQuran) {
          this.selectedQuranSkill = selectedQuran;
        }
      }
      if(model.studentDetails.activeSchoolKidReg && model.studentDetails.activeSchoolKidReg.payments) {
        this.dataSourceActive.data = model.studentDetails.activeSchoolKidReg.payments;
      }

    }
  }

  setLevel(levelId: number) {
    this.selectedLevel = this.model.studentForm.levelList.find(l=>l.value === levelId);
    this.updatedLevel = {
      id: this.model.studentDetails.activeLevel.id,
      schoolLevelId : this.selectedLevel.value
    };
    this.setDisablility();
  }
  setArabicSkill(skillId: number) {
    this.selectedArabicSkill = this.model.studentForm.arabicSkillList.find(l=>l.value === skillId);
    this.updatedArabicSkill ={
      id: this.model.studentDetails.arabicSkill.id,
      skillAnswerId: this.selectedArabicSkill.value
    };
    this.setDisablility();
  }
  setQuranSkill(skillId: number) {
    this.selectedQuranSkill = this.model.studentForm.quranSkillList.find(l=>l.value === skillId);
    this.updatedQuranSkill ={
      id: this.model.studentDetails.quranSkill.id,
      skillAnswerId: this.selectedQuranSkill.value
    };
    this.setDisablility();
  }

  setDisablility() {
    this.disableUpdateBtn = this.selectedLevel.value !== this.model.studentDetails.activeLevel.schoolLevelId ||
    this.selectedArabicSkill.value !== this.model.studentDetails.arabicSkill.skillAnswerId ||
    this.selectedQuranSkill.value !== this.model.studentDetails.quranSkill.skillAnswerId ? false : true;
  }

  updateStudent() {
    let levelUpdate = this.updatedLevel ? this.updatedLevel : null;
    let answers : SKillAnswerUpdate[] = [];
    if(this.updatedArabicSkill) {
      answers.push(this.updatedArabicSkill);
    }
    if(this.updatedQuranSkill) {
      answers.push(this.updatedQuranSkill);
    }

    let request : StudentUpdateRequest = {
      schoolKidRegistrationId : this.model.studentDetails.activeSchoolKidReg.id,
      schoolKidId : this.model.studentDetails.id,
      levelUpdate : levelUpdate,
      skillAnswerUpdates: answers && answers.length > 0 ? answers : null
    };
    const updateSubscription = this.schoolService.studentUpdate(request).subscribe((data) => {
      if(data) {
        this.model.studentDetails.activeLevel = data.activeLevel;
        this.model.studentDetails.arabicSkill = data.arabicSkill;
        this.model.studentDetails.quranSkill = data.quranSkill;
        this.showConfirmationDialog('Student is updated', 'success');
        this.disableUpdateBtn = true;
      }
    });
    this.subscription.add(updateSubscription);
  }

  showConfirmationDialog(message: string, type: string) {
    if(!message) {
      return;
    }
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 5000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: type,
      },
    });
  }

}
