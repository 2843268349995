import { TenantService } from "src/app/services/tenant.service";
import { MemberPaymentService } from "./../../services/member-payment.service";
import { DonorboxPaymentModalComponent } from "./../donorbox-payment-modal/donorbox-payment-modal.component";
import { ConfirmDialogModel } from "./../../models/confirm-dialog-model";
import { KeyFobRequest } from "./../../models/keyfob-request";
import { EmailService } from "./../../services/email.service";
import { MemberKid } from "./../../models/member-kid";
import { HelperService } from "src/app/services/helper.service";
import { ValidationError } from "src/app/models/validation-error";
import { SecurityService } from "src/app/services/security.service";
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from "@angular/core";
import { MemberInfo } from "src/app/models/member-info";
import { MemberService } from "src/app/services/member.service";
import { Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import {
  faUser,
  faChild,
  faVoteYea,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import { MemberValidation } from "src/app/models/member-validation";
import { SelectListItem } from "src/app/models/select-list-item";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfirmationSnackbarComponent } from "../confirmation-snackbar/confirmation-snackbar.component";
import { MemberKidValidation } from "src/app/models/member-kid-validation";
import { KidValidation } from "src/app/models/kid-validation";
import * as _moment from "moment";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { AddFileRequest } from "./../../models/add-file-request";
import { ProfileImage } from "./../../models/profile-image";
import { PaymentDialogModel } from "src/app/models/payment-dialog-model";
import { DonorboxPayment } from "src/app/models/donorbox-payment";
const moment = _moment;
@Component({
  selector: "app-member-profile",
  templateUrl: "./member-profile.component.html",
  styleUrls: ["./member-profile.component.scss"],
})
export class MemberProfileComponent implements OnInit {
  profile: MemberInfo;
  memberId: number;
  signedIn$: Observable<boolean>;
  faUser = faUser;
  faChild = faChild;
  faVote = faVoteYea;
  faKey = faKey;
  states: SelectListItem[];
  genderList: SelectListItem[];
  phoneFormat = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  zipFormat = /^\d{5}(-\d{4})?$/;
  defaultLabel = "Select";
  dataLoaded = true;
  kids: MemberKid[];
  KidBtnText: string;
  votingStatusText: string;
  votingStatus: string;
  showSummary = false;
  hideSummary = false;
  disableVotingBtn: boolean;
  donorboxPaymentModalOpen: boolean;
  donorboxPayment: DonorboxPayment;
  tenantUrl: string;
  tennatBtnText: string;
  constructor(
    private memberService: MemberService,
    private securityService: SecurityService,
    private router: Router,
    private helperService: HelperService,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private emailService: EmailService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private paymentService: MemberPaymentService,
    private tenantService: TenantService
  ) {
    this.collectPaymentInfo();
    this.getProfile();
    this.getLists();
  }

  ngOnInit() {}

  collectPaymentInfo() {
    this.donorboxPayment = new DonorboxPayment();
    //http://localhost:4200/profile?id=11471565&first_name=Faisal&last_name=Khan&amount=1.35&currency=USD&FamilyMembers=Humaira%20Khan,%20Hana%20Khan
    this.route.queryParams.subscribe((param) => {
      if (param.id) {
        this.donorboxPayment.paymentId = param.id ? param.id : "";
        this.donorboxPayment.firstName = param.first_name
          ? param.first_name
          : "";
        this.donorboxPayment.lastName = param.last_name ? param.last_name : "";
        this.donorboxPayment.amount = param.amount ? param.amount : "";
        this.donorboxPayment.familyMemberNames = param.FamilyMembers
          ? param.FamilyMembers.split(",")
          : null;
      }
    });

    if (this.donorboxPayment.paymentId) {
      this.removeQueryParams();
    }
  }

  removeQueryParams() {
    if (window.location.pathname === "/profile") {
      this.router.navigate(["."], {
        relativeTo: this.route,
        queryParams: {},
        replaceUrl: true,
      });
    }
  }

  getProfile() {
    this.signedIn$ = this.securityService.isSignedIn();
    this.signedIn$.subscribe((result) => {
      if (result) {
        this.memberId = this.securityService.getMemberId();
      } else {
        this.router.navigate(["/login"]);
      }
    });

    if (this.memberId) {
      this.dataLoaded = false;
      this.KidBtnText = "Add kids Info";
      this.memberService.memberGet(this.memberId).subscribe((data) => {
        this.profile = Object.assign({}, data);
        this.profile.state = !this.profile.state ? "IN" : this.profile.state;
        this.profile.validation = new MemberValidation(
          new ValidationError(false),
          new ValidationError(false),
          new ValidationError(false),
          new ValidationError(false),
          new ValidationError(false),
          new ValidationError(false),
          new ValidationError(false),
          new ValidationError(false),
          false
        );
        this.validateProfile();
        this.kids = [];
        this.kids = this.profile.memberKids ? this.profile.memberKids : null;
        if (this.kids && this.kids.length > 0) {
          this.kids.forEach((m) => {
            m.kid.name = `${m.kid.firstName} ${m.kid.lastName}`;
            (m.validation = new MemberKidValidation(
              new ValidationError(false)
            )),
              (m.kid.validation = new KidValidation(
                new ValidationError(false),
                new ValidationError(false),
                new ValidationError(false),
                new ValidationError(false),
                false
              ));
          });
          this.KidBtnText = "Update kids Info";
        }
        this.setVotingStatus();
        this.donorboxPayment.memberId = this.profile.id;
        if (this.donorboxPayment.paymentId) {
          this.addVoterPayment();
        }

        this.dataLoaded = true;
        if (this.profile.validation.hasError) {
          this.disableVotingBtn = true;
          const dialogData = new ConfirmDialogModel(
            "Incomplete Profile",
            "Please complete your profile to continue further!.",
            "alert",
            false
          );
          this.openAlertDialog(dialogData);
        } else {
          if (this.profile.votingDaysLeft <= 7) {
            let alertMessage = "";
            if (this.profile.votingDaysLeft < 0) {
              alertMessage = `${this.profile.firstName}, your voting membership is expired, please click on 'Voting Member' to pay.`;
            } else {
              if (this.profile.votingDaysLeft === 0) {
                alertMessage = `${this.profile.firstName}, your voting membership will be expired today, please click on 'Voting Member' to pay.`;
              } else if (this.profile.votingDaysLeft === 1) {
                alertMessage = `${this.profile.firstName}, your voting membership will be expired in one day, please click on 'Voting Member' to pay.`;
              } else {
                alertMessage = `${this.profile.firstName}, your voting membership will be expired in ${this.profile.votingDaysLeft} days, please click on 'Voting Member' to pay.`;
              }
            }
            if (this.profile.paymentExpirationDate) {
              const dialogData = new ConfirmDialogModel(
                "Voting Membership",
                alertMessage,
                "alert",
                false
              );
              this.openAlertDialog(dialogData);
            }
          }
        }
      });
      this.determineTenant();
    }
  }

  setVotingStatus() {
    let text;
    if (!this.profile.votingDaysLeft) {
      this.votingStatus = "Invalid";
      text = " (not activated)";
      this.disableVotingBtn = false;
    } else if (
      this.profile.votingDaysLeft >= 0 &&
      this.profile.votingDaysLeft <= 7
    ) {
      this.votingStatus = "Due to expire";
      text = ` (expires ${this.profile.paymentExpirationDate})`;
      this.disableVotingBtn = false;
    } else if (this.profile.votingDaysLeft > 7) {
      this.votingStatus = "Valid";
      text = ` (expires ${this.profile.paymentExpirationDate})`;
      this.disableVotingBtn = true;
    } else if (this.profile.votingDaysLeft < 0) {
      this.votingStatus = "Invalid";
      text = ` (expired on ${this.profile.paymentExpirationDate})`;
      this.disableVotingBtn = false;
    }
    this.votingStatusText = text;
  }

  getLists() {
    this.states = this.helperService.statesGet();
    this.genderList = this.helperService.genderListGet();
  }

  updateProfile() {
    this.memberService.memberUpdate(this.profile).subscribe((data) => {
      if (data) {
        this.showConfirmationDialog(
          `${this.profile.firstName}, your profile is updated`
        );
        // this.validateProfile();
        if (this.profile.validation.hasError) {
          this.disableVotingBtn = true;
        } else {
          this.disableVotingBtn = false;
        }
      }
    });
  }

  setUpdateValue(field: string, value: string) {
    this.profile.validation.hasError = !this.validateUpdate(field, value);
    this.disableVotingBtn = this.profile.validation.hasError;
  }

  validateUpdate(field: string, value: string): boolean {
    let isValid = true;
    if (field === "firstName") {
      if (value.length === 0) {
        this.profile.validation.firstName.hasError = true;
        this.profile.validation.firstName.errorMessage = "Required";
        isValid = false;
      } else {
        this.profile.validation.firstName.hasError = false;
      }
    }
    if (field === "lastName") {
      if (value.length === 0) {
        this.profile.validation.lastName.hasError = true;
        this.profile.validation.lastName.errorMessage = "Required";
        isValid = false;
      } else {
        this.profile.validation.lastName.hasError = false;
      }
    }
    if (field === "address") {
      if (value.length === 0) {
        this.profile.validation.address.hasError = true;
        this.profile.validation.address.errorMessage = "Required";
        isValid = false;
      } else {
        this.profile.validation.address.hasError = false;
      }
    }
    if (field === "city") {
      if (value.length === 0) {
        this.profile.validation.city.hasError = true;
        this.profile.validation.city.errorMessage = "Required";
        isValid = false;
      } else {
        this.profile.validation.city.hasError = false;
      }
    }
    if (field === "state") {
      if (value.length === 0) {
        this.profile.validation.state.hasError = true;
        this.profile.validation.state.errorMessage = "Required";
        isValid = false;
      } else {
        this.profile.validation.state.hasError = false;
        this.profile.state = value;
      }
    }

    if (field === "zip") {
      if (value.length === 0) {
        this.profile.validation.zip.hasError = true;
        this.profile.validation.zip.errorMessage = "Required";
        isValid = false;
      } else if (!value.match(this.zipFormat)) {
        this.profile.validation.zip.hasError = true;
        this.profile.validation.zip.errorMessage = "Invalid zip";
      } else {
        this.profile.validation.zip.hasError = false;
      }
    }

    if (field === "cellPhone") {
      if (value.length === 0) {
        this.profile.validation.cellPhone.hasError = true;
        this.profile.validation.cellPhone.errorMessage = "Required";
      } else if (!value.match(this.phoneFormat)) {
        this.profile.validation.cellPhone.hasError = true;
        this.profile.validation.cellPhone.errorMessage = "Invalid number";
      } else {
        this.profile.validation.cellPhone.hasError = false;
      }
    }

    if (field === "gender") {
      if (value.length === 0) {
        this.profile.validation.gender.hasError = true;
        this.profile.validation.gender.errorMessage = "Required";
        isValid = false;
      } else {
        this.profile.validation.gender.hasError = false;
        this.profile.gender = value;
      }
    }

    isValid =
      this.profile.validation.firstName.hasError ||
      this.profile.validation.lastName.hasError ||
      this.profile.validation.address.hasError ||
      this.profile.validation.city.hasError ||
      this.profile.validation.zip.hasError ||
      this.profile.validation.state.hasError ||
      this.profile.validation.cellPhone.hasError ||
      this.profile.validation.gender.hasError
        ? false
        : true;

    return isValid;
  }

  validateProfile() {
    if (this.profile.firstName.length === 0) {
      this.profile.validation.firstName.hasError = true;
      this.profile.validation.firstName.errorMessage = "Required";
    } else {
      this.profile.validation.firstName.hasError = false;
    }
    if (this.profile.lastName.length === 0) {
      this.profile.validation.lastName.hasError = true;
      this.profile.validation.lastName.errorMessage = "Required";
    } else {
      this.profile.validation.lastName.hasError = false;
    }

    if (!this.profile.address) {
      this.profile.validation.address.hasError = true;
      this.profile.validation.address.errorMessage = "Required";
    } else {
      this.profile.validation.address.hasError = false;
    }

    if (!this.profile.city) {
      this.profile.validation.city.hasError = true;
      this.profile.validation.city.errorMessage = "Required";
    } else {
      this.profile.validation.city.hasError = false;
    }

    if (!this.profile.state) {
      this.profile.validation.state.hasError = true;
      this.profile.validation.state.errorMessage = "Required";
    } else {
      this.profile.validation.state.hasError = false;
    }

    if (!this.profile.cellPhone) {
      this.profile.validation.cellPhone.hasError = true;
      this.profile.validation.cellPhone.errorMessage = "Required";
    } else if (!this.profile.cellPhone.match(this.phoneFormat)) {
      this.profile.validation.cellPhone.hasError = true;
      this.profile.validation.cellPhone.errorMessage = "Invalid number";
    } else {
      this.profile.validation.cellPhone.hasError = false;
    }

    if (!this.profile.gender) {
      this.profile.validation.gender.hasError = true;
      this.profile.validation.gender.errorMessage = "Required";
    } else {
      this.profile.validation.gender.hasError = false;
    }

    this.profile.validation.hasError =
      this.profile.validation.firstName.hasError ||
      this.profile.validation.lastName.hasError ||
      this.profile.validation.address.hasError ||
      this.profile.validation.city.hasError ||
      this.profile.validation.zip.hasError ||
      this.profile.validation.state.hasError ||
      this.profile.validation.cellPhone.hasError ||
      this.profile.validation.gender.hasError
        ? true
        : false;
  }

  onCheckChange(event: any) {
    if (event.source.id === "isStudent") {
      if (event.checked) {
        this.profile.isAlumni = false;
      }
    } else if (event.source.id === "isAlumni") {
      if (event.checked) {
        this.profile.isStudent = false;
      }
    }
  }

  openAlertDialog(dialogData: ConfirmDialogModel) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "500px",
      height: "300px",
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      // this.result = dialogResult;
    });
  }

  openPaymentDialog() {
    this.signedIn$ = this.securityService.isSignedIn();
    this.signedIn$.subscribe((result) => {
      if (result) {
        this.memberId = this.securityService.getMemberId();
      } else {
        this.router.navigate(["/login"]);
      }
    });
    const dialogData = new PaymentDialogModel(
      "https://donorbox.org/voter?default_interval=o&amount=10"
    );
    // this.donorboxPaymentModalOpen = true;
    const dialogRef = this.dialog.open(DonorboxPaymentModalComponent, {
      width: "700px",
      height: "900px",
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      //this.collectPaymentInfo();
    });
  }

  addVoterPayment() {
    if (!this.donorboxPayment.paymentId) {
      const dialogData = new ConfirmDialogModel(
        "Voting Membership Payment",
        "Your Payment is declined, please try again",
        "alert",
        false
      );
      this.openAlertDialog(dialogData);
      return;
    }
    this.donorboxPayment.paymentDate = moment();
    this.paymentService
      .paymentVoterAdd(this.donorboxPayment)
      .subscribe((data) => {
        if (data) {
          const mProfile = Object.assign({}, data);
          this.profile.paymentExpirationDate = mProfile.paymentExpirationDate;
          this.profile.votingDaysLeft = mProfile.votingDaysLeft;
          this.setVotingStatus();
          this.donorboxPayment = null;
          this.showConfirmationDialog(
            `Alhamdulliah, ${this.profile.firstName} your payment is added successfully.`
          );
        }
      });
  }

  showConfirmationDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 20000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: "success",
      },
    });
  }

  requestFob() {
    let request = new KeyFobRequest(
      this.profile.id,
      this.profile.firstName,
      this.profile.lastName,
      this.profile.email
    );
    this.emailService.keyFobRequest(request).subscribe();
    this.showConfirmationDialog(
      `Your request has been submitted. Please check your email:${this.profile.email} for confirmation. Make sure to check the spam folder.`
    );
  }

  showKidsSummary() {
    this.showSummary = true;
    this.hideSummary = false;
  }

  hideKidsSummary() {
    this.showSummary = false;
    this.hideSummary = true;
  }

  updateKids(memberKids: MemberKid[]) {
    this.kids = this.profile.memberKids = memberKids;
  }

  async onImageSelected() {
    const inputNode: any = document.querySelector("#image");
    if (this.validateFileImage(inputNode.files[0])) {
      const contentBuffer: any = await this.readFileAsync(inputNode.files[0]);
      // inputNode.files[0].name = `${this.profile.firstName}-${this.profile.lastName}-${inputNode.files[0].name}`;
      const profileImage = new ProfileImage();
      profileImage.fileRequest = new AddFileRequest(
        "G",
        inputNode.files[0].name,
        "",
        contentBuffer,
        null
      );
      profileImage.memberId = this.profile.id;
      this.memberService.profileImageAdd(profileImage).subscribe((data) => {
        if (data) {
          this.profile.file = data;
          this.profile.fileId = this.profile.file.id;
        }
      });
    }
  }

  readFileAsync(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().split(",")[1];
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };

      reader.onerror = reject;
    });
  }

  validateFileImage(file: any) {
    if (!file) {
      return false;
    }
    const fileType = file["type"];
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
    if (!validImageTypes.includes(fileType)) {
      this.showConfirmationDialog("Invalid Image");
      return false;
    }
    // 1MB
    if (file.size > 1048576) {
      this.showConfirmationDialog("Image size should not exceed 1MB");
      return false;
    }
    return true;
  }

  determineTenant() {
    this.tenantService
      .determineRedirect(this.memberId, "")
      .subscribe((redirect) => {
        if (redirect) {
          this.tenantUrl = redirect;
          if (redirect === "/waitList") {
            this.tenantUrl = "/waitList/add";
            this.tennatBtnText = "Finish Your WaitList Request";
          } else if (redirect === "/leaseRequest") {
            this.tennatBtnText = "Finish Your Lease Request";
          } else if (redirect === "/tenant") {
            this.tennatBtnText = "Tenant";
          } else if (redirect === "/bookRoom") {
            this.tennatBtnText = "Finish Your Room Request";
          } else if (redirect === "/lease/update") {
            this.tennatBtnText = "Finish Your Lease Update Request";
          } else {
            this.tenantUrl = null;
          }
        }
      });
  }
  redirectTenant() {
    if (!this.tenantUrl) {
      return;
    }
    this.router.navigate([this.tenantUrl]);
  }

  @HostListener("document:keyup.esc")
  onkeyup() {
    this.hideKidsSummary();
  }
}
