import { LeaseInfo } from "./lease-info";
import { MultiSelectItem } from "./multi-select-item";
import { Rent } from "./rent";
import { RoomImageInfo } from "./room-image-info";
import { RoomValidation } from "./room-validation";
export class RoomInfo {
  id: number;
  name: string;
  description: string;
  rents: Rent[];
  occupancy: number;
  roomImages: RoomImageInfo[];
  isAvailable: boolean;
  currentLease: LeaseInfo;
  availableDate: any;
  isRoomAvailable: boolean;
  rentList?: MultiSelectItem[];
  selectedRents?: number[];
  validation: RoomValidation;
}
