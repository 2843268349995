import { ValidationError } from './validation-error';

export class GuarantorValidation {
  name: ValidationError;
  email: ValidationError;
  phone: ValidationError;
  address: ValidationError;
  city: ValidationError;
  zip: ValidationError
  hasError: boolean;

  constructor(name: ValidationError, email: ValidationError, phone: ValidationError,
    address: ValidationError, city: ValidationError, zip: ValidationError, hasError: boolean) {
    this.name = name;
    this.email = email;
    this.phone = phone;
    this.address = address;
    this.city = city;
    this.zip = zip;
    this.hasError = hasError;
  }
}
