import { ListRequest } from './../models/list-request';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Team } from '../models/team';
import { ConfigService } from './config.service';
import { GenericDataService } from './generic-data.service';
import { AddTeamFileRequest } from '../models/add-team-file-request';

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  teams: Team[];
  team: Team;
  public teamEvent$ = new Subject<boolean>();
  constructor(private genericDataService: GenericDataService, private configService: ConfigService) { }
  public teamList(listRequest: ListRequest): any {
    this.genericDataService.endPoint = 'Team/List';

    const listObservable$ = new Observable(subscriber => {
      this.genericDataService.post<any>(listRequest).subscribe(data => {
        this.teams = Object.assign([], data.items);
        this.teams.map(team => {
          if (team.file) {
            team.file.url = `${this.configService.config.fileBaseUrl}${team.file.storedFileName}`;
            // team.file.thumbnail = `${this.configService.config.fileBaseUrl}${team.file.thumbnail}`;
          }
        });
        subscriber.next(this.teams);
      });
    });
    return listObservable$;
  }

  public TeamPersonAdd(request: Event): any {
    this.genericDataService.endPoint = 'Team/Person/Add';
    const teamObservable$ = new Observable(subscriber => {
      this.genericDataService.post<any>(request).subscribe(data => {
        this.team = Object.assign({}, data);
        subscriber.next(this.team);
      });
    });
    return teamObservable$;
  }

  public teamFileAdd(request: AddTeamFileRequest): any {
    this.genericDataService.endPoint = 'Team/File/Add';
    const fileObservable$ = new Observable(subscriber => {
      this.genericDataService.post<any>(request).subscribe(data => {
        this.team = Object.assign({}, data);
        this.team.file.url = `${this.configService.config.fileBaseUrl}${this.team.file.storedFileName}`;
        // this.team.file.thumbNail = `${this.configService.config.fileBaseUrl}${this.team.file.thumbNail}`;
        subscriber.next(this.team);
      });
    });

    return fileObservable$;
  }
  public teamPersonUpdate(request: Team): any {
    this.genericDataService.endPoint = 'Team/Person/Update';
    const teamObservable$ = new Observable(subscriber => {
      this.genericDataService.post<any>(request).subscribe(data => {
        this.team = Object.assign({}, data);
        subscriber.next(this.team);
      });
    });
    return teamObservable$;
  }

  public teamPersonRemove(id: number): any {
    this.genericDataService.endPoint = 'Team/Person/Remove';
    const teamObservable$ = new Observable(observer => {
      this.genericDataService.delete<any>(null, id).subscribe(data => {
        this.teamEvent$.next(true);
      });
    });
    return teamObservable$;
  }
}
