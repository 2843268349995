import { TenantProfileValidation } from "./tenant-profile-validate";

export class TenantProfile {
  firstName: string;
  lastName: string;
  age: number;
  gender: string;
  residencyStatus: string;
  isStudent: string;
  refName: string;
  refEmail: string;
  refPhone: string;
  validation: TenantProfileValidation;
}
