import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-constitution',
  templateUrl: './constitution.component.html',
  styleUrls: ['./constitution.component.scss']
})
export class ConstitutionComponent implements OnInit {

  constructor(private configService: ConfigService) { }
  storedFileName = '6ea6292c-a0c8-4dfa-92c8-97e5df1d0c19';
  fileUrl: string;
  fileName = 'ISGL Constitution 202003.pdf';
  faFileDownload = faFileDownload;
  ngOnInit() {
    this.fileUrl = `${this.configService.config.fileBaseUrl}${this.storedFileName}`;
  }

  // downloadFile() {
  //   window.open(this.fileUrl);
  // }

}
