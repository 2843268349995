import { IebRegisterService } from 'src/app/services/ieb-register.service';
import { HelperService } from 'src/app/services/helper.service';
import { SecurityService } from 'src/app/services/security.service';
import { KidRequest } from './../models/school/kid-request';
import { Level } from './../models/school/level';
import { SelectListItem } from 'src/app/models/select-list-item';
import { KidSkillInfo } from './../models/school/kid-skill-info';
import { KidInfo } from '../models/school/kid-info';
import { IebParentService } from './ieb-parent.service';
import { KidModel } from '../models/school/kid-model';
import { Observable, combineLatest, of, Subject, merge, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { GenericDataService } from './generic-data.service';
import { map, switchMap, concatMap, scan, } from 'rxjs/operators';
import { Skill } from '../models/school/skill';
import { Action } from '../models/stream-action';
import { ConfirmationService } from './confirmation.service';
import { LoadService } from './load.service';


@Injectable({
  providedIn: 'root'
})
export class IebKidService {
genderList$ = of(this.helperService.gendersGet());
formSource$ = this.getFormData(this.securityService.getMemberId()).pipe();




/** The parent action subject used to perform CRUD operations */
private kidModifiedActionSubject = new Subject<Action<KidRequest>>();
/** The exposed parent action observable merged with the CRUD stream */
kidModifiedAction$ = this.kidModifiedActionSubject.asObservable();

kid$ = merge(this.formSource$, this.kidModifiedAction$.pipe(
  concatMap((model) => this.KidAction(model)))).pipe(
    scan((acc: KidModel, value: Action<KidInfo>) => {
     return this.modifyStream(acc , value) as KidModel
    })
);

private newParentSavedBS = new BehaviorSubject<number>(0);
newParentSaved$ = this.newParentSavedBS.asObservable();

kidsModel$ = combineLatest([this.genderList$, this.kid$, this.newParentSaved$]).pipe(
  map(([gList, form, newParentId]) => {
    const model: KidModel = {
      schoolParentId: !form.schoolParentId && newParentId ? newParentId : form.schoolParentId,
      memberId: form.memberId,
      levels: form.levels,
      arabicSkillList: this.getSkills(form.arabicSkills),
      quranSkillList: this.getSkills(form.quranSkills),
      levelList: this.getLevels(form.levels),
      arabicSkills: form.arabicSkills,
      quranSkills: form.quranSkills,
      genderList: gList,
      kids: form.kids,
      schoolKids: form.schoolKids
    };
    return model;
  })
);

// This is used to add new reg by admins
private  kidsSubject = new Subject<number>();
kidsSource$ = this.kidsSubject.pipe(
switchMap((memberId) => {
  if(!memberId) {
    return of (null as KidModel);

  }
  return this.getFormData(memberId);
}));

kidsAdmin$ = merge(this.kidsSource$, this.kidModifiedAction$.pipe(
  concatMap((model) => this.KidAction(model)))).pipe(
    scan((acc: KidModel, value: Action<KidInfo>) => {
      if(value.action) {
        return this.modifyStream(acc , value) as KidModel
      }

     return (!value ? acc : value)  as KidModel;
    })
);

kidsAdminModel$ = combineLatest([this.genderList$, this.kidsAdmin$, this.newParentSaved$]).pipe(
  map(([gList, form, newParentId]) => {
    if(!form) {
      return null;
    }
    const model: KidModel = {
      schoolParentId: !form.schoolParentId && newParentId ? newParentId : form.schoolParentId,
      memberId: form.memberId,
      levels: form.levels,
      arabicSkillList: this.getSkills(form.arabicSkills),
      quranSkillList: this.getSkills(form.quranSkills),
      levelList: this.getLevels(form.levels),
      arabicSkills: form.arabicSkills,
      quranSkills: form.quranSkills,
      genderList: gList,
      kids: form.kids,
      schoolKids: form.schoolKids
    };
    return model;
  })
);


constructor(private genericDataService: GenericDataService, private helperService: HelperService,
  private securityService: SecurityService, private regService: IebRegisterService,
  private confirmService: ConfirmationService, private loadService: LoadService) {
}

getFormData(memberId: number): Observable<KidModel> {
  if(!memberId) {
    return of( {} as KidModel);
  }
  return this.genericDataService.readWithEndpoint<KidModel>('SchoolKid/Form', memberId);
}

KidAction(kidAction: Action<KidRequest>) : Observable<Action<KidInfo>> {
  switch(kidAction.action){
    case 'add':
      this.genericDataService.endPoint = "SchoolKid/New";
      return this.genericDataService.post<Action<KidInfo>>(kidAction.item).pipe(
        map((kid: any) => ({ item: kid, action: 'add' } as Action<KidInfo>))
      );
    case 'update':
      this.genericDataService.endPoint = "SchoolKid/Update";
      return this.genericDataService.put<Action<KidInfo>>(kidAction.item).pipe(
        map((kid: any) => ({ item: kid, action: 'update' } as Action<KidInfo>))
      );
    case 'delete':
      this.genericDataService.endPoint = "SchoolKid/Remove";
      return this.genericDataService.post<Action<KidInfo>>(kidAction.item).pipe(
        map(() => ({ item:  { id: kidAction.item.id } as KidInfo, action: 'delete' } as Action<KidInfo>))
      );
    default:
      return of({} as Action<KidInfo>);
  }
 }

 modifyStream(model: KidModel,  operation: Action<KidInfo>): KidModel {
  this.loadService.loadToggle(false);
  this.regService.onFechRegForm(true);
  if (operation.action === 'add') {
    if(!model.schoolKids || model.schoolKids.length === 0) {
      model.schoolKids = [];
    }

    model.schoolKids = [...model.schoolKids, operation.item]
    this.confirmService.confirmationToggle( {message: 'Child is saved', toggle: true, type : 'success'});
    return model;

  } else if (operation.action === 'update') {
      model.schoolKids = model.schoolKids.map((schoolKid) => (schoolKid.id === operation.item.id ? operation.item :schoolKid));
      this.confirmService.confirmationToggle( {message: 'Child is updated', toggle: true, type : 'success'});
      return model;
  } else if (operation.action === 'delete') {
    model.schoolKids = model.schoolKids.filter((schoolKid) => (schoolKid.id !== operation.item.id));
    this.confirmService.confirmationToggle( {message: 'Child is deleted', toggle: true, type : 'success'});
    return model;
}
  return model;
}

getSkills(skills: Skill[]): SelectListItem[] {
  let list: SelectListItem[];
  list = skills.map(
    (skill) => ({
      label: skill.answer,
      value: skill.id,
      disabled: false
    })
  );
  return list;
}

getLevels(levels: Level[]): SelectListItem[] {
  let list: SelectListItem[];
  list = levels.map(
    (level) => ({
      label: level.label,
      value: level.id,
      disabled: false
    })
  );
  return list;
}


newKidAdd(kid: KidRequest): void {
  this.loadService.loadToggle(true);
  this.kidModifiedActionSubject.next({item: kid, action: 'add'});
}

 kidUpdate(kid: KidRequest): void {
  this.loadService.loadToggle(true);
  this.kidModifiedActionSubject.next({item: kid, action: 'update'});
 }

 kidDelete(kid: KidRequest): void {
  this.loadService.loadToggle(true);
  this.kidModifiedActionSubject.next({item: kid, action: 'delete'});
 }

 newParentSaved(parentId: number): void {
  this.newParentSavedBS.next(parentId);
 }

 memberSelected(memberId: number) : void {
  this.kidsSubject.next(memberId);
}




}
