import { ParkingSpaceInfo } from "src/app/models/parking-space-info";
import { TenantInfo } from "src/app/models/tenant-info";
import { AddendumEligible } from "./addendum-eligible";
import { File } from "./file";
export class TenantAddendum {
  tenant: TenantInfo;
  eligible: AddendumEligible;
  type: string;
  availableParkingSpace?: ParkingSpaceInfo | null;
  tenantSig?: File | null;
}
