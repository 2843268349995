import { ValidationError } from './validation-error';

export class UserValidation {
  firstName: ValidationError;
  lastName: ValidationError;
  hasError: boolean;

  constructor(firstName: ValidationError, lastName: ValidationError, hasError: boolean) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.hasError = hasError;
  }
}
