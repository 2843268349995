import { KidValidation } from './kid-validation';

export class Kid {
  id: number;
  firstName: string;
  lastName: string;
  name: string;
  gender: string;
  birthYear: number;
  birthMonth: number;
  validation: KidValidation;
}
