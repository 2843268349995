import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-youth-groups',
  templateUrl: './youth-groups.component.html',
  styleUrls: ['./youth-groups.component.scss']
})
export class YouthGroupsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
