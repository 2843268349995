import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iqamah-times',
  templateUrl: './iqamah-times.component.html',
  styleUrls: ['./iqamah-times.component.scss']
})
export class IqamahTimesComponent implements OnInit {

  constructor() { }
  //url = 'https://isgl.bayyan.org/isgl_salahtime.php';//'https://masjidal.com/widget/simple?masjid_id=XEdozpA7';
  url = 'https://masjidal.com/widget/simple?masjid_id=XEdozpA7';
  ngOnInit() {
  }

}
