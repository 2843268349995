import { KeyFobValidation } from './../../models/keyfob-validation';
import { HelperService } from './../../services/helper.service';
import { MemberKeyFob } from './../../models/member-keyfob';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { Member } from 'src/app/models/member';
import { MemberService } from 'src/app/services/member.service';
import { MemberUpdate } from 'src/app/models/member-update';
import { SelectListItem } from 'src/app/models/select-list-item';
import { ValidationError } from 'src/app/models/validation-error';
import { ConfirmationSnackbarComponent } from '../confirmation-snackbar/confirmation-snackbar.component';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-member-info',
  templateUrl: './member-info.component.html',
  styleUrls: ['./member-info.component.scss']
})
export class MemberInfoComponent implements OnInit, OnChanges {

  @Input() memberUpdate: MemberUpdate;
  @Output() notifyUpdates = new EventEmitter<Member>();
  keyFob: MemberKeyFob;
  newKeyFob: MemberKeyFob;
  stateList: SelectListItem[];
  genderList: SelectListItem[];
  disableDelete: boolean;
  showAddKeyFob = false;
  member: Member;
  defaultLabel = 'Select';
  selectListPosition = 'top';
  @ViewChild('keyFobInput', {static: true}) keyFobInput: ElementRef;
  constructor(private memberService: MemberService, private helperService: HelperService,
    private renderer: Renderer2, private snackBar: MatSnackBar) {
    this.newKeyFob = {
      id: 0,
      memberId: 0,
      keyFob: '',
      validation: new KeyFobValidation(new ValidationError(false), true)
    };
  }

  ngOnInit() {
    this.getLists();
  }

  ngOnChanges() {
    if (this.memberUpdate && this.memberUpdate.id) {
      this.getKeyFob(this.memberUpdate.id);
    }
  }

  updateParent() {
    this.notifyUpdates.emit(this.member);
  }

  getKeyFob(memberId: number) {
    this.memberService.keyFobGet(memberId).subscribe(data => {
      if (data && data.id) {
        this.keyFob = Object.assign({}, data);
        this.keyFob.validation = new KeyFobValidation(new ValidationError(false), false);
      } else {
        this.keyFob = null;
      }

    });
  }

  updateMember() {
    this.memberService.memberUpdate(this.memberUpdate).subscribe(data => {
      this.member = Object.assign({}, data);
      this.updateParent();
    });
  }

  UpdateKeyFob() {
    if (!this.keyFob.keyFob) {
      this.keyFob.validation.keyFob.hasError = true;
      this.keyFob.validation.keyFob.errorMessage = 'Required';
      return;
    } else {
      this.keyFob.validation.keyFob.hasError = false;
      this.keyFob.validation.keyFob.errorMessage = '';
    }

    this.memberService.keyFobUpdate(this.keyFob).subscribe(data => {
      // confirmation message
    });
  }

  deleteKeyFob() {
    if (this.disableDelete) {
      return;
    }
    let dismissedByAction = false;
    this.disableDelete = true;
    const keyFob = Object.assign({}, this.keyFob);
    this.keyFob = null;
    const deleteSnackBar = this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: 'center',
      duration: 5000,
      panelClass: 'member-snack-bar',
      data: {
        undoDelete: () => {
          dismissedByAction = true;
          this.keyFob = Object.assign({}, keyFob);
          const undoSnackBar = this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
            horizontalPosition: 'center',
            duration: 5000,
            panelClass: 'member-snack-bar',
            data: {
              dismissSnackbar: () => {
                this.snackBar.dismiss();
                this.disableDelete = false;
              },
              displayText: 'Undone!',
              type: 'message'
            }
          });
          this.disableDelete = false;
        },
        dismissSnackbar: () => {
          dismissedByAction = true;
          this.memberService.keyFobDelete(keyFob.id).subscribe();
          this.snackBar.dismiss();
          this.disableDelete = false;
        },
        displayText: `Keyfob deleted`,
        type: 'delete-kid'
      }
    });
    // delete the kid if the snackbar closes due to duration and not closed by user.
    deleteSnackBar.afterDismissed().subscribe(info => {
      if (info.dismissedByAction === false && dismissedByAction === false) {
        this.memberService.keyFobDelete(keyFob.id).subscribe();
        this.disableDelete = false;
      }
    });
  }

  setUpdateValue(field: string, value: string) {
    let toUpdate = false;

    toUpdate = this.validateUpdate(field, value, this.memberUpdate);
    if (toUpdate) {
      this.updateMember();
    }
  }

  validateUpdate(field: string, value: string, member: MemberUpdate): boolean {
    let isValid = true;
    if (field === 'firstName') {
      if (value.length === 0) {
        member.validation.firstName.hasError = true;
        member.validation.firstName.errorMessage = 'Required';
        isValid = false;
      } else {
        member.validation.firstName.hasError = false;
      }
    }
    if (field === 'lastName') {
      if (value.length === 0) {
        member.validation.lastName.hasError = true;
        member.validation.lastName.errorMessage = 'Required';
        isValid = false;
      } else {
        member.validation.lastName.hasError = false;
      }
    }
    if (field === 'address') {
      if (!value) {
        member.validation.address.hasError = true;
        member.validation.address.errorMessage = 'Required';
        isValid = false;
      } else {
        member.validation.address.hasError = false;
      }
    }
    if (field === 'city') {
      if (!value) {
        member.validation.city.hasError = true;
        member.validation.city.errorMessage = 'Required';
        isValid = false;
      } else {
        member.validation.city.hasError = false;
      }
    }
    if (field === 'state') {
      member.state = value;
    }
    if (field === 'gender') {
      member.gender = value;;
    }

    isValid =
      member.validation.firstName.hasError || member.validation.lastName.hasError
        || member.validation.address.hasError || member.validation.city.hasError
        ? false
        : true;

    return isValid;

  }

  onCheckChange(event: any) {
    if (event.source.id === 'isStudent') {
      if (event.checked) {
        this.memberUpdate.isAlumni = false;
      }
    } else if (event.source.id === 'isAlumni') {
      if (event.checked) {
        this.memberUpdate.isStudent = false;
      }
    }
  }

  getLists() {
    this.stateList = this.helperService.statesGet();
    this.genderList = this.helperService.genderListGet();
  }

  addKeyFob() {
    this.newKeyFob.memberId = this.memberUpdate.id;
    this.showAddKeyFob = true;
    const input = this.renderer.selectRootElement(this.keyFobInput.nativeElement);
    setTimeout(() => input.focus(), 0);
  }

  closeAddKeyFob() {
    this.newKeyFob.id = 0;
    this.newKeyFob.memberId = 0;
    this.newKeyFob.keyFob = '';
    this.showAddKeyFob = false;
  }

  validateKeyFob() {
    if (this.newKeyFob.keyFob.length === 0) {
      this.newKeyFob.validation.hasError = true;
      this.newKeyFob.validation.keyFob.errorMessage = 'Required!';
    } else {
      this.newKeyFob.validation.hasError = false;
      this.newKeyFob.validation.keyFob.errorMessage = '';
    }
  }

  saveKeyFob() {
    if (this.newKeyFob.validation.hasError) {
      return;
    }
    this.memberService.keyFobAdd(this.newKeyFob).subscribe(data => {
      // confirmation message
      this.showConfirmationDialog('KeyFob is saved');
      this.keyFob = Object.assign({}, data);
      this.keyFob.validation = new KeyFobValidation(new ValidationError(false), false);
      this.closeAddKeyFob();
    });
  }

  showConfirmationDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: 'center',
      duration: 5000,
      panelClass: 'message',
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: 'success'
      }
    });
  }

}
