import { ChangeDetectorRef, Component, Input, OnInit, OnChanges } from '@angular/core';
import { EventCategory } from 'src/app/models/event-category';
import { EventService } from 'src/app/services/event.service';
import { ConfirmationSnackbarComponent } from '../../components/confirmation-snackbar/confirmation-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventCategoryValidation } from 'src/app/models/event-category-validation';
import { ValidationError } from 'src/app/models/validation-error';
@Component({
  selector: 'app-event-categories',
  templateUrl: './event-categories.component.html',
  styleUrls: ['./event-categories.component.scss']
})
export class EventCategoriesComponent implements OnInit, OnChanges {

  categories: EventCategory[];
  disableDelete = false;
  showAddCategory = false;
  newCategory: EventCategory;
  @Input() hideAdd: boolean;
  constructor(private eventService: EventService
    , private snackBar: MatSnackBar
    , private cd: ChangeDetectorRef) {
    this.getCategories();
    this.resetNewCategory();
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.hideAdd) {
      this.showAddCategory = false;
    }
  }

  getCategories() {
    this.eventService.categoriesList().subscribe(data => {
      this.categories = data;
      this.categories.forEach(c => {
        c.validation = new EventCategoryValidation(new ValidationError(false), false);
      });
    });
  }

  resetNewCategory() {
    this.newCategory = {
      id: 0,
      name: '',
      icon: '',
      active: false,
      validation: new EventCategoryValidation(new ValidationError(false), false)
    };
  }

  deleteCategory(id: number) {
    if (!this.disableDelete) {
      let dismissedByAction = false;
      this.disableDelete = true;
      const categoryToDelete = this.categories.find(c => c.id === id);
      const index = this.categories.indexOf(categoryToDelete);
      this.categories.splice(index, 1); // remove the contact from the array

      const deleteSnackBar = this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
        horizontalPosition: 'center',
        duration: 5000,
        panelClass: 'category-snack-bar',
        data: {
          undoDelete: () => {
            dismissedByAction = true;
            this.categories.splice(index, 0, categoryToDelete);
            this.cd.detectChanges();
            const undoSnackBar = this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
              horizontalPosition: 'center',
              duration: 5000,
              panelClass: 'category-snack-bar',
              data: {
                dismissSnackbar: () => {
                  this.snackBar.dismiss();
                  this.disableDelete = false;
                },
                displayText: 'Undone!',
                type: 'message'
              }
            });
            this.disableDelete = false;
          },
          dismissSnackbar: () => {
            dismissedByAction = true;
            this.eventService.categoryRemove(id).subscribe();
            this.snackBar.dismiss();
            this.disableDelete = false;
          },
          displayText: `Category ${categoryToDelete.name}, associated annoucements and aayahs deleted`,
          type: 'delete-kid'
        }
      });

      // delete the contact if the snackbar closes due to duration and not closed by user.
      deleteSnackBar.afterDismissed().subscribe(info => {
        if (info.dismissedByAction === false && dismissedByAction === false) {
          this.eventService.categoryRemove(id).subscribe();
          this.disableDelete = false;
        }
      });
    }
  }

  onCheckChange(event: any, category: EventCategory) {
    if (event.checked) {
      this.categories = this.categories.map(c => {
        if (c.id === category.id) {
          c.active = true;
        } else {
          c.active = false;
        }
        return c;
      });
      this.updateCategory(category);
    } else {
      category.active = false;
    }

  }

  updateCategory(categoryToUpdate: EventCategory) {
    this.eventService.categoryUpdate(categoryToUpdate).subscribe(data => {
      const index = this.categories.findIndex(c => c.id === categoryToUpdate.id);
      this.categories.splice(index, 1, categoryToUpdate);
    });
  }

  checkCategoryExists(name: string) {
    this.eventService.checkCategoryExists(name).subscribe(exists => {
      if (exists) {
        this.newCategory.validation.name.hasError = true;
        this.newCategory.validation.name.errorMessage = 'Exists';
      } else {
        this.newCategory.validation.name.hasError = false;
        this.newCategory.validation.name.errorMessage = '';
      }
    });
  }

  setUpdateValue(id: number, value: string) {
    let toUpdate = false;
    const updatedCategory = this.categories.find(c => c.id === id);
    toUpdate = this.validateUpdate(value, updatedCategory);

    if (toUpdate) {
      this.updateCategory(updatedCategory);
    }
  }

  validateUpdate(value: string, category: EventCategory): boolean {
    let isValid = true;
    if (value.length === 0) {
      category.validation.name.hasError = true;
      category.validation.name.errorMessage = 'Required';
      isValid = false;
    } else {
      category.validation.name.hasError = false;
      isValid = true;
    }

    return isValid;
  }

  addCategory() {
    this.newCategory.validation.name.hasError = true;
    this.newCategory.validation.name.errorMessage = 'Required';
    this.showAddCategory = true;
  }

  closeAddCategory() {
    this.showAddCategory = false;
    this.resetNewCategory();
  }

  validateNewCategory(name: string) {
    if (name.length === 0) {
      this.newCategory.validation.name.hasError = true;
      this.newCategory.validation.name.errorMessage = 'Required';
    } else {
      this.checkCategoryExists(name);
    }

  }

  saveCategory() {
    this.eventService.categoryAdd(this.newCategory).subscribe(data => {
      this.newCategory.id = data.id;
      this.categories.push(this.newCategory);
      this.closeAddCategory();
    });
  }
}


