import { ValidationError } from './validation-error';
export class MemberUpdateValidation {
  firstName: ValidationError;
  lastName: ValidationError;
  address: ValidationError;
  city: ValidationError;
  hasError: boolean;
  constructor(firstName: ValidationError, lastName: ValidationError, address: ValidationError, city: ValidationError, hasError: boolean) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.address = address;
    this.city = city;
    this.hasError = hasError;
  }
}
