import { Component, OnInit } from '@angular/core';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-ilgl',
  templateUrl: './ilgl.component.html',
  styleUrls: ['./ilgl.component.scss']
})
export class IlglComponent implements OnInit {
  faArrowAltCircleRight = faArrowAltCircleRight;
  constructor() { }

  ngOnInit() {
  }

}
