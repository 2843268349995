import { AddFileRequest } from './../models/add-file-request';
import { Injectable } from '@angular/core';
import { GenericDataService } from './generic-data.service';
import { Observable, Subject } from 'rxjs';
import { ConfigService } from './config.service';
import { File } from './../models/file';
import { UpdateFileRequest } from '../models/update-file-request';
@Injectable({
  providedIn: 'root'
})
export class FileService {
  file: File;
  public fileEvent$ = new Subject<boolean>();
  constructor(private genericDataService: GenericDataService, private configService: ConfigService) { }

  public FileAdd(request: AddFileRequest): any {
    this.genericDataService.endPoint = 'File/Add';
    const fileObservable$ = new Observable(subscriber => {
      this.genericDataService.post<any>(request).subscribe(data => {
        this.file = Object.assign({}, data);
        this.file.url = `${this.configService.config.fileBaseUrl}${this.file.storedFileName}`;
        this.file.thumbNail = `${this.configService.config.fileBaseUrl}${this.file.thumbNail}`;
        subscriber.next(this.file);
      });
    });

    return fileObservable$;
  }

  public FileUpdate(request: UpdateFileRequest): any {
    this.genericDataService.endPoint = 'File/Update';
    const fileObservable$ = new Observable(subscriber => {
      this.genericDataService.post<any>(request).subscribe(data => {
        this.file = Object.assign({}, data);
        subscriber.next(this.file);
      });
    });
    return fileObservable$;
  }

  public FileRemove(id: number): any {
    this.genericDataService.endPoint = 'File/Remove';
    const fileObservable$ = new Observable(observer => {
      this.genericDataService.delete<any>(null, id).subscribe(data => {
        this.fileEvent$.next(true);
      });
    });
    return fileObservable$;
  }
}
