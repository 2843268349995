import { ParentRequest } from './../../models/school/parent-request';
import { MultiSelectItem } from './../../models/multi-select-item';
import { ParentInfo } from '../../models/school/parent-info';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit, OnChanges, SimpleChanges, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { ParentModel } from 'src/app/models/school/parent-model';

@Component({
  selector: 'app-ieb-parent',
  templateUrl: './ieb-parent.component.html',
  styleUrls: ['./ieb-parent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IebParentComponent implements OnInit, OnChanges {
  @Input() model!: ParentModel;
  @Output() saveRequest = new EventEmitter<ParentRequest>();
  @Output() updateRequest = new EventEmitter<ParentRequest>();
  schoolParentForm!: FormGroup;
  mask = '000-000-0000';
  parent: ParentInfo;
  spouse: ParentInfo;
  gender: string;
  spouseGender: string;
  submitBtnText: string;
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.schoolParentForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: [
        { value: '', disabled: true },
        [Validators.required, Validators.email, Validators.maxLength(254)],
      ],
      phone: ['', [Validators.required]],
      spouseFirstName: ['', [Validators.required]],
      spouseLastName: ['', [Validators.required]],
      spouseEmail: ['', [Validators.required, Validators.email]],
      spousePhone: ['', [Validators.required]],
      address: ['', [Validators.required]],
      city: ['', [Validators.required]],
      zip: ['', [Validators.required]],
    });
    // if(this.model) {
    //   this.parent = this.model.schoolParentInfo.parentInfo as ParentInfo;
    //   let spouse = this.model.schoolParentInfo.spouseInfo as ParentInfo;
    //   if(!spouse) {
    //       const spouseSearch =  this.model.schoolParentInfo.spouseSearch as ParentInfo[];
    //       spouse = spouseSearch && spouseSearch.length > 0 ? spouseSearch[0] : {} as ParentInfo;
    //   }
    //   this.spouse = spouse;
    //   if(!this.spouse.id) {
    //     this.spouse.firstName = this.model.schoolParentInfo.spouseFirstName;
    //     this.spouse.lastName = this.model.schoolParentInfo.spouseLastName;
    //     this.spouse.email = this.model.schoolParentInfo.spouseEmail;
    //     this.spouse.phone = this.model.schoolParentInfo.spousePhone;
    //   }

    //   const pContact = this.model.primaryContactList as MultiSelectItem[];
    //     pContact.map((item)=> {
    //       if(item.label === "Father") {
    //         item.checked = true;
    //       }
    //     });

    //   this.gender = this.parent.gender ? this.parent.gender : 'M';
    //   this.spouseGender = this.gender === 'M' ? 'F' : 'M';
    //   this.schoolParentForm.controls['firstName'].setValue(this.parent.firstName);
    //   this.schoolParentForm.controls['lastName'].setValue(this.parent.lastName);
    //   this.schoolParentForm.controls['email'].setValue(this.parent.email);
    //   this.schoolParentForm.controls['phone'].setValue(this.parent.phone);
    //   this.schoolParentForm.controls['spouseFirstName'].setValue(this.spouse.firstName);
    //   this.schoolParentForm.controls['spouseLastName'].setValue(this.spouse.lastName);
    //   this.schoolParentForm.controls['spouseEmail'].setValue(this.spouse.email);
    //   this.schoolParentForm.controls['spousePhone'].setValue(this.spouse.phone);
    //   this.schoolParentForm.controls['address'].setValue(this.parent.address);
    //   this.schoolParentForm.controls['city'].setValue(this.parent.city);
    //   this.schoolParentForm.controls['zip'].setValue(this.parent.zip);
    // }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.model) {
      this.parent = this.model.schoolParentInfo.parentInfo as ParentInfo;
      let spouse = this.model.schoolParentInfo.spouseInfo as ParentInfo;
      if(!spouse) {
          const spouseSearch =  this.model.schoolParentInfo.spouseSearch as ParentInfo[];
          spouse = spouseSearch && spouseSearch.length > 0 ? spouseSearch[0] : {} as ParentInfo;
      }
      this.spouse = spouse;
      if(!this.spouse.id) {
        this.spouse.firstName = this.model.schoolParentInfo.spouseFirstName;
        this.spouse.lastName = this.model.schoolParentInfo.spouseLastName;
        this.spouse.email = this.model.schoolParentInfo.spouseEmail;
        this.spouse.phone = this.model.schoolParentInfo.spousePhone;
      }

      const pContact = this.model.primaryContactList as MultiSelectItem[];
        pContact.map((item)=> {
          if(item.label === "Father") {
            item.checked = true;
          }
        });

      this.gender = this.parent.gender ? this.parent.gender : 'M';
      this.spouseGender = this.gender === 'M' ? 'F' : 'M';
      this.submitBtnText = this.model.schoolParentInfo.id ? 'Update Parent' : 'Save Parent';
    }
  }
  setGender(gender: string) {
    this.gender = gender;
    this.spouseGender = this.gender === 'M' ? 'F' : 'M';
  }

  setSpouseGender(gender: string) {
    this.spouseGender = gender;
    this.gender = this.spouseGender === 'M' ? 'F' : 'M';
  }

  submit(){
    if(this.model.schoolParentInfo.id) {
      this.updatePartentInfo();
    } else {
      this.saveParentInfo();
    }

  }

  saveParentInfo() {
    const saveModel = this.setRequestObject();
    this.saveRequest.emit(saveModel);

  }

  updatePartentInfo() {
    const updateModel = this.setRequestObject();
    this.updateRequest.emit(updateModel);
  }

  setRequestObject(): ParentRequest {
    const primary = this.model.primaryContactList.find((item) => item.checked);
    this.parent.firstName = this.schoolParentForm.controls['firstName'].value || null;
    this.parent.lastName = this.schoolParentForm.controls['lastName'].value || null;
    this.parent.gender = this.gender;
    this.parent.phone = this.schoolParentForm.controls['phone'].value || null;
    this.parent.address = this.schoolParentForm.controls['address'].value || null;
    this.parent.city = this.schoolParentForm.controls['city'].value || null;
    this.parent.zip = this.schoolParentForm.controls['zip'].value || null;
    this.parent.spouseName = `${this.schoolParentForm.controls['spouseFirstName'].value} ${this.schoolParentForm.controls['spouseLastName'].value}`;
    let spouseEmail = this.schoolParentForm.controls['spouseEmail'].value || null;

    if(this.spouse.email && spouseEmail === this.spouse.email) {
      this.spouse.firstName = this.schoolParentForm.controls['spouseFirstName'].value || null;
      this.spouse.lastName = this.schoolParentForm.controls['spouseLastName'].value || null;
      this.spouse.phone = this.schoolParentForm.controls['spousePhone'].value || null;
      this.spouse.gender = this.spouseGender;
      this.spouse.spouseName = `${this.parent.firstName} ${this.parent.lastName}`;
    }
    if(this.spouse && this.spouse.id){
      this.spouse.id = this.spouse.email === spouseEmail ? this.spouse.id : 0;
    }
    return {
      id: this.model.schoolParentInfo.id || null,
      memberId: this.model.schoolParentInfo.parentInfo.id,
      relation: this.gender === 'M' ? 'Father' : 'Mother',
      spouseMemberId: this.spouse.id ? this.spouse.id: null,
      spouseFirstName : this.schoolParentForm.controls['spouseFirstName'].value || null,
      spouseLastName: this.schoolParentForm.controls['spouseLastName'].value || null,
      spouseEmail: spouseEmail,
      spousePhone: this.schoolParentForm.controls['spousePhone'].value || null,
      primaryContact: primary.label,
      parentInfo: this.parent,
      spouseInfo: this.spouse && this.spouse.id ? this.spouse : null
    } as ParentRequest;
  }

}
