import { RoomRentUpdate } from "./../models/room-rent-update";
import { Rent } from "./../models/rent";
import { Injectable } from "@angular/core";
import { GenericDataService } from "./generic-data.service";
import { Observable, Subject } from "rxjs";
import { RentNew } from "../models/rent-new";

@Injectable({
  providedIn: "root",
})
export class RentService {
  rent: Rent;
  rents: Rent[];
  public rentSubject$ = new Subject<boolean>();
  constructor(private genericDataService: GenericDataService) {}

  public rentList(): any {
    this.genericDataService.endPoint = "Rent/List";

    const listObservable$ = new Observable((subscriber) => {
      this.genericDataService.list<any>(null, null).subscribe((data) => {
        this.rents = Object.assign([], data);
        subscriber.next(this.rents);
      });
    });
    return listObservable$;
  }

  public rentAdd(request: RentNew): any {
    this.genericDataService.endPoint = "Rent/Add";
    const addObservable$ = new Observable((subscriber) => {
      this.genericDataService.post<any>(request).subscribe((data) => {
        this.rent = Object.assign({}, data);
        subscriber.next(this.rent);
      });
    });
    return addObservable$;
  }

  public rentUpdate(request: Rent): any {
    this.genericDataService.endPoint = "Rent/Update";
    const updateObservable$ = new Observable((subscriber) => {
      this.genericDataService.post<any>(request).subscribe((data) => {
        this.rent = Object.assign({}, data);
        subscriber.next(this.rent);
      });
    });
    return updateObservable$;
  }

  public rentRemove(id: number): any {
    this.genericDataService.endPoint = "Rent/Remove";
    this.genericDataService.delete<any>(null, id).subscribe((data) => {
      this.rentSubject$.next(true);
    });
    return this.rentSubject$;
  }

  public roomRentUpdate(request: RoomRentUpdate): any {
    this.genericDataService.endPoint = "Rent/RoomRent/Update";
    const addObservable$ = new Observable((subscriber) => {
      this.genericDataService.post<any>(request).subscribe((data) => {
        this.rent = Object.assign({}, data);
        subscriber.next(this.rent);
      });
    });
    return addObservable$;
  }

  public rentByType(type: string): any {
    this.genericDataService.endPoint = "Rent/Type";

    const listObservable$ = new Observable((subscriber) => {
      this.genericDataService.readById<any>(null, type).subscribe((data) => {
        this.rents = Object.assign([], data);
        subscriber.next(this.rents);
      });
    });
    return listObservable$;
  }
}
