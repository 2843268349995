import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'padLeftZero'
})
export class PadLeftZeroPipe implements PipeTransform {

  transform(item: string): string {
    return (String('0').repeat(2) + item).substr((2 * -1), 2);
  }

}
