import { AddFileRequest } from "./../../models/add-file-request";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { SignaturePad } from "angular2-signaturepad";
@Component({
  selector: "app-signature",
  templateUrl: "./signature.component.html",
  styleUrls: ["./signature.component.scss"],
})
export class SignatureComponent implements OnInit, AfterViewInit {
  @ViewChild(SignaturePad, {static: false}) signaturePad: SignaturePad;
  signaturePadOptions: Object = {
    // passed through to szimek/signature_pad constructor
    minWidth: 2,
    penColor: "#727272", //"rgb(66, 133, 244)",
    dotSize: function () {
      return (this.minWidth + this.maxWidth) / 2;
    },
    canvasWidth: 300,
    canvasHeight: 180,
    backgroundColor: "rgba(0,0,0,0)", //"#e6e9f0",
    velocityFilterWeight: 0.7,
  };
  @Input() signee: string;
  @Output() notifyCapture = new EventEmitter();
  signatureFile: AddFileRequest;
  hasError = true;
  showClear: boolean;
  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.signaturePad.set("minWidth", 2);
    this.signaturePad.clear();
  }

  // drawComplete() {
  //   console.log(this.signaturePad.toDataURL());
  // }

  drawStart() {
    this.hasError = false;
  }

  async saveSignature() {
    if (this.signaturePad.isEmpty()) {
      return;
    }
    this.showClear = true;
    // this.hasError = false;
    const dataURL = this.signaturePad.toDataURL("image/png");

    const data = atob(dataURL.substring("data:image/png;base64,".length)),
      asArray = new Uint8Array(data.length);
    for (var i = 0, len = data.length; i < len; ++i) {
      asArray[i] = data.charCodeAt(i);
    }
    const blob = new Blob([asArray], { type: "image/png" });
    const contentBuffer: any = await this.readFileAsync(blob);
    this.signatureFile = new AddFileRequest(
      "G",
      `${this.signee} Signature`,
      "",
      contentBuffer,
      null
    );
    this.notifyCapture.emit(this.signatureFile);
  }

  readFileAsync(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().split(",")[1];
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };

      reader.onerror = reject;
    });
  }

  clearSignature() {
    this.signaturePad.clear();
    this.signatureFile = null;
    this.showClear = false;
    this.hasError = true;
  }
}
