import { Guarantor } from "./guarantor";
import { VehicleRequest } from "./vehicle-request";
import { TenantFileRequest } from "./tenant-file-request";

export class TenantRequest {
  id: number;
  memberId: number;
  roomId: number;
  rentId: number;
  referenceName: string;
  referenceEmail: string;
  referencePhone: string;
  tentativeStartDate: string;
  age: number;
  isStudent: boolean;
  residencyStatus: boolean;
  guarantor: Guarantor;
  files: TenantFileRequest[];
  vehicles: VehicleRequest[];
}
