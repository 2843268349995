import { RoomInfo } from "src/app/models/room-info";
import { VehicleInfo } from "./vehicle-info";
import { MemberInfo } from "src/app/models/member-info";
import { LeaseInfo } from "./lease-info";
import { TenantFileInfo } from "./tenant-file-info";
import { Guarantor } from "./guarantor";
import { Rent } from "./rent";
import { TenantPaymentSummary } from "./tenant-payment-summary";
import { TenantOverride } from "./tenant-override";
export class TenantInfo {
  id: number;
  memberInfo: MemberInfo;
  referenceName: string;
  referenceEmail: string;
  referencePhone: string;
  isVerified: boolean;
  tentativeStartDate: string;
  startDate: any;
  endDate: any;
  prorateAmount: number;
  rentId: number;
  files: TenantFileInfo[];
  leaseInfo: LeaseInfo;
  roomInfo: RoomInfo;
  roomRent: Rent;
  vehicles: VehicleInfo[];
  guarantor: Guarantor;
  parkingSpaceRent: Rent;
  paymentSummary: TenantPaymentSummary;
  securityDeposit: number;
  hasPendingAddendums: boolean | null;
  monthlyRentCollected: string;
  overrides : TenantOverride[];
  requestDate?: any;

}
