import {
  Component,
  OnInit,
  Input,
  Renderer2,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges,
  forwardRef,
  HostListener,
  ChangeDetectionStrategy,
} from "@angular/core";
import { SelectListItem } from "./../../models/select-list-item";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

export enum KEY_CODES {
  UP_ARROW = "ArrowUp",
  DOWN_ARROW = "ArrowDown",
  TAB = "Tab",
  ENTER = "Enter",
}

export const FLATSELECT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FlatSelectComponent),
  multi: true,
};
@Component({
  selector: "app-flat-select",
  templateUrl: "./flat-select.component.html",
  styleUrls: ["./flat-select.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlatSelectComponent
  implements OnInit, ControlValueAccessor, OnChanges
{
  selectedOption: SelectListItem;
  show = false;
  onChange;
  onTouched;
  focusTriggered = false;
  isFocused = false;
  indexPosition = -1;

  @Input() selectOptions: SelectListItem[];
  @Input() position: string;
  @Input() class: string;
  @Input() defaultLabel: string;
  @Input() value: string;
  @Input() userFormValidation = false;
  @Input() displayArrow = false;
  @Input() resetSelect = false;
  @Input() hasError = false;
  @Input() errorMessage = "";
  @Input() disabledToolTipText = "";
  toolTipText: string;
  @Output() selectOptionChange = new EventEmitter();

  @ViewChild("selectTrigger", null) selectTrigger: ElementRef;
  @ViewChild("selectContent", null) selectContent: ElementRef;

  constructor(private renderer: Renderer2) {
    this.renderer.listen("window", "click", (e: Event) => {
      if (
        !this.selectTrigger.nativeElement.contains(e.target) &&
        !this.selectContent.nativeElement.contains(e.target) &&
        this.show)
        {
          this.show = false;
          if (this.userFormValidation) {
            this.onTouched(e.target);
          }
      }
    });
  }

  ngOnInit() {
    this.setDefaultOption();
  }

  ngOnChanges() {
    if (this.resetSelect && !this.value) {
      this.reset();
    }
    this.setDefaultOption();
  }

  setDefaultOption() {
    if (!this.value) {
      this.value = !this.selectOptions[0].value
        ? this.selectOptions[0].label
        : this.selectOptions[0].value;
    } else {
      this.selectedOption = this.selectOptions.find(
        (v) => v.value === this.value
      );
    }
    this.toolTipText =
      this.selectedOption &&
      this.selectedOption.disabled &&
      this.disabledToolTipText
        ? this.disabledToolTipText
        : "";
  }

  focus() {
    this.show = true;
    this.focusTriggered = true;
    this.isFocused = true;
  }

  unfocus() {
    this.show = false;
    this.focusTriggered = false;
    this.isFocused = false;
  }

  toggle($event) {

    if (!this.focusTriggered  && this.selectTrigger.nativeElement.contains($event.target) &&
    this.selectContent.nativeElement.contains($event.target)) {
      this.show = !this.show;
    }
    this.focusTriggered = false;
  }

  selectOptionChanged($event, option: SelectListItem) {
    if (this.userFormValidation) {
      this.onChange($event.target);
    }
    this.toggle($event);
    this.selectedOption = option;
    this.value = option.value;
    this.selectOptionChange.emit(option.value);
  }

  reset() {
    this.selectedOption = null;
    this.value = "";
  }

  writeValue(value: any): void {}

  registerOnChange(fn: any): void {
    if (this.userFormValidation) {
      this.onChange = fn;
    }
  }

  registerOnTouched(fn) {
    if (this.userFormValidation) {
      this.onTouched = fn;
    }
  }

  @HostListener("keydown", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.key === KEY_CODES.UP_ARROW && this.isFocused) {
      event.preventDefault();
      event.stopPropagation();
      this.incrementPosition();
    }

    if (event.key === KEY_CODES.DOWN_ARROW && this.isFocused) {
      event.preventDefault();
      event.stopPropagation();
      this.decrementPosition();
    }

    if (
      (event.key === KEY_CODES.TAB || event.key === KEY_CODES.ENTER) &&
      this.isFocused &&
      this.selectedOption
    ) {
      this.selectOptionChanged(event, this.selectedOption);
    }
  }


  decrementPosition() {
    if (this.indexPosition === -1) {
      this.indexPosition = 0;
    } else if (this.indexPosition !== this.selectOptions.length - 1) {
      this.indexPosition++;
    }
    this.selectedOption = this.selectOptions[this.indexPosition];
    this.value = this.selectedOption.value;
    this.selectOptionChange.emit(this.selectedOption);
  }

  incrementPosition() {
    if (this.indexPosition === -1) {
      this.indexPosition = 0;
    } else if (this.indexPosition > 0) {
      this.indexPosition--;
    }
    this.selectedOption = this.selectOptions[this.indexPosition];
    this.value = this.selectedOption.value;
    this.selectOptionChange.emit(this.selectedOption);
  }
}
