import { SecurityService } from './../../services/security.service';
import { MemberInfo } from './../../models/member-info';
import { KidValidation } from './../../models/kid-validation';
import { ValidationError } from './../../models/validation-error';
import { MemberKidValidation } from './../../models/member-kid-validation';
import { Component, OnInit, AfterViewChecked, ChangeDetectorRef, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MemberService } from './../../services/member.service';
import { HelperService } from './../../services/helper.service';
import { SelectListItem } from './../../models/select-list-item';
import { faUser, faUserPlus, faChild, faVoteYea } from '@fortawesome/free-solid-svg-icons';
import { MemberKid } from 'src/app/models/member-kid';
import { Member } from './../../models/member';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationSnackbarComponent } from '../confirmation-snackbar/confirmation-snackbar.component';
import { UserToken } from 'src/app/models/user-token';
@Component({
  selector: 'app-member-registration',
  templateUrl: './member-registration.component.html',
  styleUrls: ['./member-registration.component.scss']
})
export class MemberRegistrationComponent implements OnInit, AfterViewChecked {
  memberInfo: MemberInfo;
  memberForm: FormGroup;
  phoneRegEx = '^((\\+)?)([\\s-.\\(\\)]*\\d{1}){8,13}$';
  phoneFormat = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  zipFormat = /^\d{5}(-\d{4})?$/;
  states: SelectListItem[];
  birthYears: SelectListItem[];
  birthMonths: SelectListItem[];
  genderList: SelectListItem[];
  submitted = false;
  headerTitle: string;
  memberEmail: string;
  emailVerified: boolean;
  faUser = faUser;
  faUserPlus = faUserPlus;
  faChild = faChild;
  faVote = faVoteYea;
  submitText = 'Register';
  kidsSummaryText = 'Add Kids Information';
  showSummary = false;
  hideSummary = false;
  memberKids: MemberKid[];
  member: Member;
  dataLoaded = true;
  inProgress: boolean;
  isAcknowledged = false;
  user: UserToken;

  constructor(private formBuilder: FormBuilder
    , private cd: ChangeDetectorRef
    , private memberService: MemberService
    , private helperService: HelperService
    , private snackBar: MatSnackBar
    , private securityService: SecurityService) {
    this.createForm();
    this.getLists();
    // this.memberInfo = new MemberInfo(null, '', '', '', '', '', '', '', '', '', '', false, false, false, false, null, null, null, null);
    this.signedInUser();

  }

  ngOnInit() {
    this.resetProperties();
    this.memberForm.valueChanges.subscribe(val => {
      this.isAcknowledged = false;
    });
  }

  resetProperties() {
    this.headerTitle = 'Member Registration';
    this.memberEmail = '';
    this.emailVerified = false;
    this.submitText = 'Register';
  }

  setProperties() {
    if (this.emailVerified) {
      this.headerTitle = 'Update Registration';
      this.submitText = 'Update';
      this.kidsSummaryText = this.memberInfo.memberKids && this.memberInfo.memberKids.length > 0 ?
        'Update Kids Information' : 'Add Kids Information';
    }
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  get firstName() { return this.memberForm.get('firstName'); }
  get lastName() { return this.memberForm.get('lastName'); }
  // get phone() { return this.memberForm.get('phone'); }
  get cellPhone() { return this.memberForm.get('cellPhone'); }
  get email() { return this.memberForm.get('email'); }
  get gender() { return this.memberForm.get('gender'); }
  get address() { return this.memberForm.get('address'); }
  get city() { return this.memberForm.get('city'); }
  get state() { return this.memberForm.get('state'); }
  get zip() { return this.memberForm.get('zip'); }
  get birthYear() { return this.memberForm.get('birthYear'); }
  get birthMonth() { return this.memberForm.get('birthMonth'); }
  get residencyStatus() { return this.memberForm.get('residencyStatus'); }
  get isStudent() { return this.memberForm.get('isStudent'); }
  // get isVoting() { return this.memberForm.get('isVoting'); }
  get isAlumni() { return this.memberForm.get('isAlumni'); }
  get acknowledged() { return this.memberForm.get('acknowledged'); }

  createForm() {
    this.memberForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      city: ['', Validators.required],
      state: ['', Validators.required],
      address: ['', Validators.required],
      zip: ['', [Validators.required, Validators.pattern(this.zipFormat)]],
      cellPhone: ['', [Validators.required, Validators.pattern(this.phoneFormat)]],
      birthYear: [''],
      birthMonth: [''],
      residencyStatus: [''],
      isStudent: [''],
      // isVoting: [''],
      isAlumni: [''],
      gender: ['', Validators.required],
      acknowledged: ['', Validators.requiredTrue]
    }, { updateOn: 'change' });

  }

  getLists() {
    this.states = this.helperService.statesGet();
    // this.birthYears = this.helperService.birthYearsGet(false);
    // this.birthMonths = this.helperService.birthMonthsGet();
    this.genderList = this.helperService.genderListGet();
  }

  checkMemberExists(event: any) {
    if (!this.email.valid) {
      return;
    }
    this.getMember(event.currentTarget.value);
  }

  getMember(email: string) {
    if (this.inProgress) {
      return;
    }
    this.dataLoaded = false;
    this.inProgress = true;
    this.memberService.memberExists(email).subscribe(data => {
      if (data) {
        this.memberInfo = Object.assign({}, data);
        this.memberInfo.birthMonth = this.memberInfo.birthMonth === 0 ? null : this.memberInfo.birthMonth;
        this.memberInfo.birthYear = this.memberInfo.birthYear === 0 ? null : this.memberInfo.birthYear;
        this.memberKids = [];
        this.memberKids = this.memberInfo.memberKids ? this.memberInfo.memberKids : null;
        if (this.memberKids && this.memberKids.length > 0) {
          this.memberKids.forEach(m => {
            m.kid.name = `${m.kid.firstName} ${m.kid.lastName}`;
            m.validation = new MemberKidValidation(new ValidationError(false)),
              m.kid.validation = new KidValidation(
                new ValidationError(false),
                new ValidationError(false),
                new ValidationError(false),
                new ValidationError(false),
                false);
          });
        }
        this.setProperties();

      }
      this.dataLoaded = true;
      this.emailVerified = true;

      this.inProgress = false;
    });
  }

  onCheckChange(event: any) {
    if (event.source.id === 'isStudent') {
      if (event.checked) {
        this.memberInfo.isAlumni = false;
      }
    } else if (event.source.id === 'isAlumni') {
      if (event.checked) {
        this.memberInfo.isStudent = false;
      }
    }
  }


  onSubmit() {
    if (this.memberForm.valid) {
      this.member = new Member(this.memberInfo);
      if (this.member.id) {
        this.memberService.memberUpdate(this.member).subscribe(data => {
          this.memberInfo = Object.assign({}, data);
          this.setProperties();
          this.showConfirmationDialog('Registration is updated');
        });
      } else {
        this.memberService.memberAdd(this.member).subscribe(data => {
          this.memberInfo = Object.assign({}, data);
          this.setProperties();
          this.showConfirmationDialog('Registration is successful');
        });
      }

    }
  }

  showKidsSummary() {
    this.showSummary = true;
    this.hideSummary = false;
  }

  hideKidsSummary() {
    this.showSummary = false;
    this.hideSummary = true;
  }

  updateMemberKids(memberKids: MemberKid[]) {
    this.memberKids = this.memberInfo.memberKids = memberKids;
    this.setProperties();
  }

  registerNewMember() {
    // this.memberInfo = new MemberInfo(null, '', '', '', '', '', '', '', '', '', '', false, false, false, false, null, null, null, null);
    this.hideKidsSummary();
    this.resetProperties();
    this.memberForm.reset();
  }

  showConfirmationDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: 'center',
      duration: 20000,
      panelClass: 'message',
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: 'success'
      }
    });
  }

  signedInUser() {
    if (this.securityService.isLoggedIn()) {
      this.user = this.securityService.getUser();
      if (this.user && this.user.userName) {
        this.emailVerified = true;
        this.getMember(this.user.userName);
      }
    }
  }

  @HostListener('document:keyup.esc')
  onkeyup() {
    this.hideKidsSummary();
  }

}
