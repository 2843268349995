export class Lease {
  id: number;
  name: string;
  startDate: any;
  endDate: any;
  autoRenewal: boolean;
  prorateAmount: number;
  securityDeposit: number;
  securityDepositCollected: string;
  tenantId: number;
  roomId: number;
  rentId: number;
  statusId: number;
  leaseDate: any;
}
