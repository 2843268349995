import { Kid } from './kid';
import { MemberKidValidation } from './member-kid-validation';

export class MemberKid {
  id: number;
  memberId: number;
  kidId: number;
  relationship: string;
  kid: Kid;
  validation: MemberKidValidation;
}
