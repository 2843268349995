import { TenantProfile } from "src/app/models/tenant-profile";
import { GuarantorValidation } from "./guarantor-validation";
import { VehicleValidation } from "./vehicle-validation";
import { VehicleRequest } from "./vehicle-request";
import { UploadFile } from "./upload-file";
import { BookRoomValidation } from "./book-room-validation";
import { ValidationError } from "./validation-error";
import { Guarantor } from "./guarantor";

export class BookRoom {
  roomId: number;
  rentId: number;
  roomAvailableDate: string;
  rentAmount: string;
  tenantProfile: TenantProfile;
  isParkingSpace: boolean;
  isGuarantor: boolean;
  tentativeStartDate: any;
  uploadedFiles: UploadFile[];
  vehicle: VehicleRequest = new VehicleRequest(
    0,
    "",
    "",
    "",
    "",
    0,
    0,
    new VehicleValidation(
      new ValidationError(false),
      new ValidationError(false),
      new ValidationError(false),
      new ValidationError(false),
      false
    )
  );
  guarantor: Guarantor = new Guarantor(
    0,
    "",
    "",
    "",
    "",
    "",
    "IN",
    "",
    "",
    "",
    new GuarantorValidation(
      new ValidationError(false),
      new ValidationError(false),
      new ValidationError(false),
      new ValidationError(false),
      new ValidationError(false),
      new ValidationError(false),
      false
    )
  );
  validation: BookRoomValidation;
}
